
<template>
<!--  finish leftAnswersMessage question 66 -->
  <div id="questionType2" :key="componentKey" class="questionComponent">
    <h1  v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>
    
    <p
      v-langErr 
      class="skippableQuestionMessage"
      v-if="questionData.question.skippable == 1"
    >{{$t($i18n.locale).keys.generic.skippableQuestionMessage}}</p>
    
    <div class="chatStyleAnswer">
      <div class="answerWrapper">
        <div class="additionalMessage">
          <p
            class="firstMessage"
            v-if="questionData.question.minChoices === questionData.question.maxChoices && firstMessage"
          >{{firstLeftTermsMessage}}</p>
          <p
            class="secondMessage"
            v-if="questionData.question.minChoices === questionData.question.maxChoices && !firstMessage"
          >{{leftAnswersMessage}}</p>
          <p
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.questionnaire.type2.asManyMessage = $event.target.innerText"
            class="asManyMessage"
            v-if="(questionData.question.minChoices === 1) && (questionData.question.maxChoices === null)"
          >{{$t($i18n.locale).keys.questionnaire.type2.asManyMessage}}</p>
        </div>
        <div class="choices">
          <button
            v-langErr 
            class="genericButton btnAnswer"
            @click="addAnswer(i +1); leftAnswers()"
            :class="[{genericBtnSelected: answersArray.includes(i +1)}, (item.translations[selectedLanguage].answer.length > 15) && (item.translations[selectedLanguage].answer.length <= 65) ? 'buttonWider' : '', item.translations[selectedLanguage].answer.length > 65 ? 'buttonWidest' : '']"
            v-for="(item, i) in questionData.question.answerOptions"
            :key="i"
            :disabled="disableAdditionalChoices(i +1)"
          >{{item.translations[selectedLanguage].answer}}</button>
        </div>
      </div>
    </div>

    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
        skippable: questionData.question.skippable
      }"
      :nextDisabled="!answerSelected()"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"
      @answerLater="emptyAnswers(); sendAnswer()"
    />
                              
    <button
      v-else
      v-langErr
      @click="sendAnswer();"
      v-bind:class="{ 'btnContinue-disabled': !answerSelected() || disableButton() }"
      :disabled="!answerSelected() || disableButton()"
      class="genericButton btnContinue"
    >{{$t($i18n.locale).keys.generic.continueBtn}}</button>                               
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  components: {
    QuestionNavigation
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.answersArray = [];
      } else {
        if (!this.answersArray.length) {
          this.returnArray();
        }
      }
      this.setFirstMessage();
    }
  },
  name: "QuestionType2",
  data: () => {
    return {
      answersArray: [],
      leftAnswersMessage: String,
      firstMessage: true,
      leftAnswersToDisplay: 0,
      returningAnswersArray: [],
      componentKey: 0,
      firstLoad: true,
      lockSend: false,
    };
  },

  methods: {
    addAnswer(id) {
      if(this.questionData.question.id == 12 || this.questionData.question.id == 17){
        if (this.answersArray.includes(id)) {
          const filteredItems = this.answersArray.filter(item => item !== id);
          this.answersArray = filteredItems;
        } else if(id != this.questionData.question.answerOptions.length) {
          const newArray = this.answersArray.filter(item => item != this.questionData.question.answerOptions.length);
          this.answersArray = newArray;
          this.answersArray.push(id);
          this.answers = new Set([...this.answersArray]);
        } else if(id == this.questionData.question.answerOptions.length) {
          this.answersArray = [];
          this.answersArray.push(id);
          this.answers = this.answersArray;
        }
      } else {
        if (this.answersArray.includes(id)) {
            const filteredItems = this.answersArray.filter(item => item !== id);
            this.answersArray = filteredItems;
          } else  {
            this.answersArray.push(id);
            this.answers = new Set([...this.answersArray]);
          }
      }
    },
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        console.log("post-answer");
        this.lockSend = true;
        this.$emit("post-answer", this.answersArray);
        this.lockSend = false;
      }
      else if (this.questionData.answered) {
        console.log(this.answersArray, 'answersArray')
        this.$emit("put-answer", {
          answered: this.answersArray,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        console.log(this.answersArray, 'answersArray')
        this.$emit("put-answer", {
          answered: this.answersArray,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    emptyAnswers(){
      this.answersArray = [];
    },
    leftAnswers() {
      this.firstMessage = false;
      if (
        this.questionData.question.minChoices - this.answersArray.length >=
        0
      ) {
        this.leftAnswersToDisplay =
          this.questionData.question.minChoices - this.answersArray.length;
      }
      if (this.leftAnswersToDisplay != 0) {
        this.leftAnswersMessage = `${this.leftAnswersToDisplay} ${
          this.$t(this.$i18n.locale).keys.questionnaire.type2.leftTermsMessage
        }`;
      } else {
        this.leftAnswersMessage = `${
          this.$t(this.$i18n.locale).keys.questionnaire.type2.termsChosenQuantityMessage
        }`;
      }
    },
    answerSelected() {
      if (
        this.questionData.question.minChoices ===
        this.questionData.question.maxChoices
      ) {
        return (
          this.answersArray.length === this.questionData.question.minChoices
        );
      }
      if (
        this.questionData.question.minChoices !== null &&
        this.questionData.question.maxChoices === null
      ) {
        return (
          this.answersArray.length >= this.questionData.question.minChoices
        );
      }
    },
    disableAdditionalChoices(id) {
      if (
        this.questionData.question.minChoices ===
          this.questionData.question.maxChoices &&
        this.answersArray.length === this.questionData.question.minChoices
      ) {
        if (this.answersArray.includes(id)) {
          return false;
        } else {
          return true;
        }
      }
    },
    returnArray() {
      if (this.questionData.answered) {
        this.answersArray.push(...this.questionData.answered.answer);
      }
    },
    disableButton() {
      if (this.questionData.answered) {
        if (
          JSON.stringify(this.answersArray) ==
          JSON.stringify([...this.questionData.answered.answer])
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        false;
      }
    },
    setFirstMessage() {
      this.firstMessage = true;
      this.componentKey += 1;
    }
  },
  computed: {

    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },

    editable: function() {
      return this.$store.getters.editable;
    },

    firstLeftTermsMessage() {
      return `${this.$t(this.$i18n.locale).keys.questionnaire.type2.choose} ${this.questionData.question.minChoices} ${this.$t(this.$i18n.locale).keys.questionnaire.type2.terms}`;
    }
  },
  mounted() {
    this.returnArray();
    if(this.questionData.question.id == 12 || this.questionData.question.id == 17){
      //Move last button to the beginning
      var lastButton = $('.btnAnswer').last();
      lastButton.detach();
      $('.choices').prepend(lastButton);
      lastButton.trigger('click');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
