// export const types = {
//     toggleGalleryViewVisitors: 'toggleGalleryViewVisitors'
// }

export default {
    toggleGalleryViewVisitors(state){
        state.galleryViewVisitors = !state.galleryViewVisitors;
        if(state.galleryViewVisitors == true){
            state.listViewVisitors = false;
        } else {
            state.listViewVisitors = true;
        }
    },
    toggleListViewVisitors(state){
        state.listViewVisitors = !state.listViewVisitors;
        if(state.listViewVisitors == true){
            state.galleryViewVisitors = false;
        } else {
            state.galleryViewVisitors = true;
        }
    },
    setVisitors: (state, visitors) => (state.visitors = visitors),
    setVisitorsDefaultFilters: (state, defaultFilters) => (state.defaultVisitorsFilters = defaultFilters),
    filterVisitors: (state, filters) =>
     {
        state.activeVisitorsFilters = filters;
        let _filteredVisitors = state.visitors.filter(
            visitor => 
                //visitor.age >= filters.ageFrom && 
                //visitor.age <= filters.ageTo && 
                //parseInt(visitor.height) >= filters.heightFrom && 
                //parseInt(visitor.height) <= filters.heightTo && 
                //parseInt(visitor.distance) <= filters.distanceFilter) &&
                (filters.newFilter == 0 
                    ? parseInt(visitor.new) == 0 || parseInt(visitor.new) == 1 
                    : parseInt(visitor.new) == 1
                ) && 
                (filters.favouritesFilter == 0 
                    ? parseInt(visitor.bookmark) == 0 || parseInt(visitor.bookmark) == 1 
                    : parseInt(visitor.bookmark) == 1
                ) && 
                (parseInt(filters.photoFilter) == 0 || filters.photoFilter == undefined 
                    ? parseInt(visitor.has_photo) == 0 || parseInt(visitor.has_photo) == 1 
                    : parseInt(visitor.has_photo) == 1
                ) && 
                (filters.contactFilter == 0 
                    ? parseInt(visitor.in_contact) == 0 || parseInt(visitor.in_contact) == 1 
                    : parseInt(visitor.in_contact) == 1 
                ) && 
                (
                    (state.visitorsSearchValue !== "" 
                        ? (visitor.firstname.toLowerCase().includes(state.visitorsSearchValue.toLowerCase()))
                        : true 
                    ) || 
                    (state.visitorsSearchValue !== "" 
                        ? (visitor.profession.toLowerCase().includes(state.visitorsSearchValue.toLowerCase()))
                        : true 
                    ) || 
                    (state.visitorsSearchValue !== "" 
                        ? (visitor.city.toLowerCase().includes(state.visitorsSearchValue.toLowerCase()))
                        : true 
                    )
                )
        );
        state.filteredVisitors = _filteredVisitors;
        //(state.filteredVisitors = state.visitors.filter((visitor => (/*visitor.age >= filters.ageFrom && visitor.age <= filters.ageTo && parseInt(visitor.height) >= filters.heightFrom && parseInt(visitor.height) <= filters.heightTo && (parseInt(visitor.distance) <= filters.distanceFilter) &&*/ (filters.newFilter == 0 ? parseInt(visitor.new) == 0 || parseInt(visitor.new) == 1 : parseInt(visitor.new) == 1) && (filters.favouritesFilter == 0 ? parseInt(visitor.bookmark) == 0 || parseInt(visitor.bookmark) == 1 : parseInt(visitor.bookmark) == 1) && (parseInt(filters.photoFilter) == 0 || filters.photoFilter == undefined ? parseInt(visitor.has_photo) == 0 || parseInt(visitor.has_photo) == 1 : parseInt(visitor.has_photo) == 1) && (filters.contactFilter == 0 ? parseInt(visitor.in_contact) == 0 || parseInt(visitor.in_contact) == 1 : parseInt(visitor.in_contact) == 1) ) && ((state.visitorsSearchValue !== "" ? (visitor.firstname.toLowerCase().includes(state.visitorsSearchValue.toLowerCase())): true ) || (state.visitorsSearchValue !== "" ? (visitor.profession.toLowerCase().includes(state.visitorsSearchValue.toLowerCase())): true ) || (state.visitorsSearchValue !== "" ? (visitor.city.toLowerCase().includes(state.visitorsSearchValue.toLowerCase())): true ) ))))
     },
    setSearchValueVisitors: (state, searchValue) => (state.visitorsSearchValue = searchValue),
    countVisitors: (state, visitorsQuantity) => (state.visitorsQuantity = visitorsQuantity[0].new_visits),
    changeVisitorsList: (state) => (state.visitorsList++),
    setBookmarksVisitors: (state, bookmarks) => (state.visitorsBookmarks = bookmarks),
    toggleBookmarkVisitors: (state, visitor_id) => (state.visitorsBookmarks.forEach(item => item.id == visitor_id ? (parseInt(item.bookmark) == 0 ? item.bookmark = 1 : item.bookmark = 0 ): '')),
    
    changeVisitorBookmark: (state, visitor_id) => {
        console.log('changeVisitorBookmark', visitor_id);
        state.visitors.forEach(item => {
            if(item.id == visitor_id){
                console.log('changeVisitorBookmark item', item);
                parseInt(item.bookmark) == 0 ? item.bookmark = 1 : item.bookmark = 0
            }
        })
    },
    changeVisitorSmile: (state, visitor_id) => {
        state.visitors.forEach(item => {
            if(item.id == visitor_id){
            (parseInt(item.smile_sent) == 0 || !item.smile_sent)
            ? (item.smile_sent = 1)
            : (item.smile_sent = 0)
            }
        });
    },
    updateVisitors: (state, payload) => {
        console.log('updateVisitors', payload);

        var updatedVisitors = [...state.visitors, ...payload];

        state.visitors = updatedVisitors;
    },
    setActiveVisitorsFilters: (state, filters) => {
        state.activeVisitorsFilters = filters;
    }
};