<template>
  <div id="userAccountMembership" class="preferencesBox">
    <div class="userAccountMembershipWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr :contenteditable="editable"
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.userAccountMembership.membership =
              $event.target.innerText
          "
        >
          {{
            $t($i18n.locale).keys.membershipArea.userAccountMembership
              .membership
          }}
        </h1>
      </div>
      <div class="membershipWrapper">
        <p class="membershipText textWrapper">
          <span
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.userAccountMembership.memberSince =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea
                .userAccountMembership.memberSince
            }} </span
          >&nbsp;
          <span
            class="date"
            >
            <strong>
            {{
              membershipSince
            }}</strong>
            </span
          >
        </p>

        <p class="membershipText textWrapper">
          <span
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.userAccountMembership.typeOfMembership =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea
                .userAccountMembership.typeOfMembership
            }} </span
          >&nbsp;
          <strong>
          <span
            class="membershipType"
            v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >{{membershipType}}</span
          >
          <span
            class="membershipType"
            v-if="$store.getters.userRole.includes('ROLE_PREMIUM')"
            >{{membershipType}}</span
          >
          </strong>
        </p>
        <p class="membershipText textWrapper">
          <span>
            {{ $t($i18n.locale + '.keys.membershipArea.userAccountMembership.contact_id') }} 
          </span>&nbsp;
          <strong>
            <span
              class="membershipType"
            >{{contact_id}}
            </span>
          </strong>
        </p>
        <div v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')" class="mainBenefitsWrapper">
          <p
            class="benefitsTitleText"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.userAccountMembership.benefitsAs =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.membershipArea
                .userAccountMembership.benefitsAs
            }}
          </p>
          <div class="benefitsWrapper">
            <div class="benefitWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')"
                alt="Check icon"
              />
              <p
                class="benefitText"
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.detailedInsights =
                    $event.target.innerText
                "
              >
                {{
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.detailedInsights
                }}
              </p>
            </div>
            <div class="benefitWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')"
                alt="Check icon"
              />
              <p
                class="benefitText"
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.unlimitedWriting =
                    $event.target.innerText
                "
              >
                {{
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.unlimitedWriting
                }}
              </p>
            </div>
            <div class="benefitWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')"
                alt="Check icon"
              />
              <p
                class="benefitText"
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.unlimitedReading =
                    $event.target.innerText
                "
              >
                {{
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.unlimitedReading
                }}
              </p>
            </div>
            <div class="benefitWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')"
                alt="Check icon"
              />
              <p
                class="benefitText"
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.picturesUnblurred =
                    $event.target.innerText
                "
              >
                {{
                  $t(
                      $i18n.locale
                    ).keys.premiumPlans.processPayments.picturesUnblurred
                }}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="membershipText">
          {{ $t($i18n.locale + '.keys.membershipArea.userAccountMembership.cancelCode') }}: <strong>{{ this.cancellationCode }}</strong>
          </p>
        </div>
      </div>
      <div
        class="btnWrapper"
        v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')"
      >
        <router-link to="/premium-plans">
          <button class="btnPremium">
            <span
              >{{
                $t($i18n.locale).keys.membershipArea
                  .userAccountMembership.becomeA
              }} </span
            ><span class="premiumMember">{{
              $t($i18n.locale).keys.membershipArea
                .userAccountMembership.premiumMember
            }}</span>
          </button>
        </router-link>
        <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.userAccountMembership
              .becomeA
          "
          type="text"
        />
        <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.userAccountMembership
              .premiumMember
          "
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "UserAccountMembership",

  data() {
    return {
      membershipType: "",
      membershipSince:"",
      cancellationCode: "JDX75ZLS2",
      contact_id: "",
    };
  },

  computed: {
    ...mapGetters(["getClientSettings"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getClientSettings: function(newVal){
      if(!_.isEmpty(newVal)){
        console.log('getClientSettings',newVal);
          this.membershipType = this.getClientSettings.membership.title;
          this.membershipSince = this.getClientSettings.membership.since;
          this.contact_id = this.getClientSettings.membership.contact_id;

          if(this.getClientSettings.membership.cancellation_code){
            this.cancellationCode = this.getClientSettings.membership.cancellation_code
          }
      }
    }
  },
  mounted(){

  },
  methods: {

  }
};
</script>
