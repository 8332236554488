<template>
  <div id="children" :key="componentKey" class="preferencesBox">
    <div class="childrenWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="childrenUnderEighteenWrapper filterWrapper">
        <div class="childrenUnderEighteenTextWrapper textWrapper">
          <h3
            class="childrenUnderEighteenText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.parnterUnder18Question = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.parnterUnder18Question}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper ">
          <input
            v-model="childrenUnderEighteenFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive1 }"
            v-touch:start="changeChildrenUnderEighteenFilterValue"
            v-touch:longtap="changeChildrenUnderEighteenFilterValue"
            @change="changeChildrenUnderEighteenFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            v-if="childrenUnderEighteenFilterSlider == 1"
            class="switchName"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.yes = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.yes}}</p>
          <p
            v-if="childrenUnderEighteenFilterSlider == 0"
            class="switchName"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.no = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.desireToHaveChildren.no}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
      </div>

      <div class="buttonWrapper">
        <button
          @click="sendDesireToHaveChildrenValues()"
          class="btnSave"
        >{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Children",
  data() {
    return {
      componentKey: 0,
      childrenUnderEighteenFilterSlider: 0,
      isActive1: true,
      desireToHaveChildrenValues: {}
    };
  },
  mounted() {

  },
  computed: {
    ...mapGetters(["getDesireToHaveChildren"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getDesireToHaveChildren: function(newVal){
      if(!_.isEmpty(newVal)){
        this.setDesireToHaveChildrenFilter();
        this.underEighteenColouring();
      }
    }
  },
  methods: {
    setDesireToHaveChildrenFilter() {

          if (
            this.$store.getters.getDesireToHaveChildren.childrenImportance ==
            true
          ) {
            this.childrenUnderEighteenFilterSlider = 1;
            this.isActive1 = true;
          } else if (
            this.$store.getters.getDesireToHaveChildren.childrenImportance ==
            false
          ) {
            this.childrenUnderEighteenFilterSlider = 0;
            this.isActive1 = false;
          }

          this.componentKey++;

    },
    sendDesireToHaveChildrenValues() {
      this.desireToHaveChildrenValues = {
        childrenImportance: this.childrenUnderEighteenFilterSlider
      };
      this.$store
        .dispatch("updateDesireToHaveChildren", this.desireToHaveChildrenValues)
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            });
            this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Children'
              });
            this.componentKey++;
          }
        });
    },
    changeChildrenUnderEighteenFilterValue() {
      if (this.childrenUnderEighteenFilterSlider == 0) {
        this.childrenUnderEighteenFilterSlider = 1;
      } else if (this.childrenUnderEighteenFilterSlider == 1) {
        this.childrenUnderEighteenFilterSlider = 0;
      }
      if (this.childrenUnderEighteenFilterSlider == 0) {
        this.isActive1 = false;
      } else if (this.childrenUnderEighteenFilterSlider == 1) {
        this.isActive1 = true;
      }
    },
    underEighteenColouring() {
      if (this.childrenUnderEighteenFilterSlider == 0) {
        this.isActive1 = false;
      } else if (this.childrenUnderEighteenFilterSlider == 1) {
        this.isActive1 = true;
      }
    }
  }
};
</script>
