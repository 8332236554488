import { render, staticRenderFns } from "./MembersipChat.vue?vue&type=template&id=1722e561&scoped=true"
import script from "./MembersipChat.vue?vue&type=script&lang=js"
export * from "./MembersipChat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1722e561",
  null
  
)

export default component.exports