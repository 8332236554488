<template>
  <div id="onlineUserList" :key="componentKey">
    <div
      class="matchesWrapper"
      v-bind:class="[
        galleryView ? 'galleryView' : '',
        listView ? 'listView' : '',
        isMobile ? 'mobileView' : ''
      ]"
    >
    <template v-if="!isMobile">
        <div
          class="matchWrapper"
          v-for="(match) in onlineUsers"
          :key="match.profile_id + match.firstname + 'list'"
        >
          <div class="imageWrapper" @click="goToProfile(match.profile_id)">
            <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
              src="../../../assets/images/silhouette-man.svg"
              class="matchesImage"
            />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
              src="../../../assets/images/silhouette-woman.svg"
              class="matchesImage"
            />
            <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
              <p
                class="newMessage"
                v-if="match.new == 1"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipMatches.matchesList.new =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
            </div>

            <div
              v-if="!match.public_path && match.photo_request"
              class="photoRequestButton"
              @click.stop.prevent = "sendPhotoRequest(match.profile_id, $event)"
              >
              {{
                $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
              }}
            </div>
          </div>
          <div class="infoWrapper">
            <div class="nameAgeWrapper">
              <!--
              <img
                v-if="parseInt(match.entertainment) == 1"
                src="../../../assets/images/heart-green.svg"
                alt="Heart icon"
              />
              -->
              <div class="nameAge">{{ match.firstname }}, {{ getAge(match.birthday) }}</div>
            </div>
            <div class="basicInfoWrapper">
              <div class="workWrapper">
                <img src="../../../assets/images/job-icon.svg" alt="Job icon" />
                <p class="workText">{{ match.profession }}</p>
              </div>
              <div class="locationWrapper">
                <img src="../../../assets/images/house-icon.svg" alt="Location icon" />
                <p class="locationText">{{ match.city }}</p>
              </div>
            </div>

            <div class="actionsWrapper">
              <button @click="initiateConversation(match)" class="sendMessageButton">
                <img src="../../../assets/images/envelope-white-icon.svg" alt="Envelope icon" />
                {{$t($i18n.locale).keys.membershipArea.membershipMatches.sendMessage}}
              </button>
              <div @click="initiateConversation(match)" class="iconChat icons"></div>
              <input
                v-if="editable"
                v-model="
                  $t($i18n.locale).keys.membershipArea.membershipMatches.sendMessage
                "
                type="text"
              />
              <div
                v-if="match.icebreaker_question"
                class="iconIBQ icons"
                @click="showIBQ(match.profile_id, $event)"
                v-b-tooltip.hover
                :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
              ></div>
              <div
                v-on="
                  match.smile_sent == null || match.smile_sent == 0
                    ? { click: () => (smileSent(match)) }
                    : {}
                "
                :id="'match'+match.profile_id"
                class="iconSmile icons"
                :class="{ smileSent: match.smile_sent == 1 }"
                v-b-tooltip.hover
                :title="$t($i18n.locale + '.keys.tooltips.Smile_button')"
              ></div>
              <!--
              <div
                class="iconFavourites icons"
                @click="toggleBookmark(match.profile_id), addClassBookmark(match.profile_id)"
                :id="'bookmark'+match.profile_id"
                v-bind:class="[getBookmarkValue(match.profile_id) ? 'added' : '']"
                v-b-tooltip.hover
                :title="[getBookmarkValue(match.profile_id) ? $t($i18n.locale + '.keys.tooltips.FavRemove_button') : $t($i18n.locale + '.keys.tooltips.Fav_button')]"
              ></div>
              -->
            </div>
          </div>
        </div>
        <div
          @click="goToProfile(match.profile_id)"
          class="matchWrapperGallery"
          v-for="(match) in onlineUsers"
          :key="match.profile_id + match.firstname + 'gallery'"
        >
          <div class="imageWrapper">
            <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
              src="../../../assets/images/silhouette-man.svg"
              class="matchesImage"
            />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
              src="../../../assets/images/silhouette-woman.svg"
              class="matchesImage"
            />
            <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
              <p
                class="newMessage"
                v-if="match.new == 1"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipMatches.matchesList.new =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
              <div class="bottomTextWrapper">
                <div class="nameWrapper">
                  <div
                    class="nameAgeText"
                  >{{ match.firstname }}</div>
                </div>
                <div class="wrapper">
                  <div class="nameAgeTextWrapper">
                    <!--
                    <img
                      v-if="parseInt(match.entertainment) == 1"
                      src="../../../assets/images/heart-green.svg"
                      alt="Heart icon"
                    />
                    -->
                    <div class="nameAgeText">{{ getAge(match.birthday) }}, {{ match.city }}</div>
                  </div>
                  <div class="matchedPercentageWrapper">
                    <img src="../../../assets/images/onlineUser_icon.svg" alt="icon" />
                    <p class="matchedPercentage">{{ match.accuracy }}</p>
                  </div>
                </div>
              </div>
              <div class="overlayWrapper">
                <div class="darkOverlay"></div>
                <div class="basicInfoWrapper">
                  <div class="nameAgeTextWrapper">
                    <!--
                    <img
                      v-if="parseInt(match.entertainment) == 1"
                      src="../../../assets/images/heart-green.svg"
                      alt="Heart green"
                    />
                    -->
                    <div
                      class="nameText"
                    >{{ match.firstname }}</div>
                  </div>
                  <div class="locationAgeText">{{ getAge(match.birthday) }}, {{ match.city }}</div>
                  <div class="jobText">{{ match.profession }}</div>
                  <div
                    v-if="!match.public_path && true"
                    class="photoRequestButton"
                    @click.stop.prevent = "sendPhotoRequest(match.profile_id, $event)"
                    >
                    {{
                      $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
                    }}
                  </div>
                </div>
                <div class="matchedPercentageWrapper">
                  <img src="../../../assets/images/onlineUser_icon.svg" alt="icon" />
                  <p class="matchedPercentage">{{ match.accuracy }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isMobile">
        <div
          class="matchWrapperMobile"
          v-for="(match) in onlineUsers"
          :key="match.profile_id + match.firstname + 'mobile'"
        >
          <div class="imageWrapper" @click="goToProfile(match.profile_id)">
            <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
              src="../../../assets/images/silhouette-man.svg"
              class="matchesImage"
            />
            <img
              v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
              src="../../../assets/images/silhouette-woman.svg"
              class="matchesImage"
            />
            <img
              v-else
              src="../../../assets/images/silhouette-man.svg"
              class="matchesImage"
            />

            <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
              <p
                class="newMessage"
                v-if="match.new == 1"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipMatches.matchesList.new =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
            </div>
            <div
              v-if="!match.public_path && match.photo_request"
              class="photoRequestButton"
              @click.stop.prevent = "sendPhotoRequest(match.profile_id, $event)"
              >
              {{
                $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
              }}
            </div>
          </div>
          <div class="infoWrapper">
            <div class="nameAgeText">
              <!--
              <img
                v-if="parseInt(match.entertainment) == 1"
                src="../../../assets/images/heart-green.svg"
                alt="Heart icon"
              />
              -->

              <span class="matchesUsername">{{ match.firstname > 8 ? match.firstname.substring(0, 7).concat('...') : match.firstname}}</span>, {{ getAge(match.birthday) }}
            </div>
            <div class="locationText">{{ match.city }}</div>
            <div class="iconsWrapper">
              <div @click="initiateConversation(match)" class="iconChat"></div>
              <div v-if="match.icebreaker_question" @click="showIBQ(match.profile_id, $event)" class="iconIBQ" ></div>
              <div
                v-on="
                  match.smile_sent == null || match.smile_sent == 0
                    ? { click: () => (smileSent(match)) }
                    : {}
                "
                :id="'match'+match.profile_id"
                :class="{ smileSent: match.smile_sent == 1 }"
                class="iconSmile"
              ></div>
              <!--
              <div
                @click="toggleBookmark(match.profile_id), addClassBookmark(match.profile_id)"
                :id="'bookmark'+match.profile_id"
                v-bind:class="[getBookmarkValue(match.profile_id) ? 'added' : '']"
                class="iconFavourites"
              ></div>
              -->
            </div>
          </div>
        </div>
      </template>
    </div>
    <ModalIcebreakerQuestions ref="modalIBQ" />
  </div>
</template>

<script>
import ModalIcebreakerQuestions from "@/components/membership-area/ModalIcebreakerQuestions.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "OnlineList",
  components: {
    ModalIcebreakerQuestions
  },
  data() {
    return {
      componentKey: 0,
      url: process.env.VUE_APP_URL,

      listItems: 0,
      galleryItems: 0,
      mobileItems: 0,
      galleryView: false,
      listView: true,
    };
  },

  watch: {

  },
  computed: {
    ...mapGetters([
      "getBookmarks",
      "isMobile",
      "onlineUsers"
    ]),
    editable: function() {
      return this.$store.getters.editable;
    },

  },
  mounted() {
    window.scrollTo(0, 0);
    //disable tooltips on mobile 
    setTimeout(() => {
      if(this.isMobile) this.$root.$emit('bv::disable::tooltip');
    }, 100);

  },
  updated() {

  },
  methods: {

    registerMatch(profile_id){
       if(this.lockRequests) return true;
      return new Promise((resolve, reject) => {
        this.lockRequests = true;
        axios({ url: process.env.VUE_APP_URL + `/api/dashboard/view/${profile_id}`, method: "POST" })
          .then(resp => {
            this.lockRequests = false;
            //do something
            resolve(resp);
          })
          .catch(err => {
            reject(err);
            this.$store.dispatch(
              'updateAlerts',
              this.$t(this.$i18n.locale+'.keys.generic.alerts.maxOnlineUsersReached')
            );
            this.lockRequests = false;
          });
      });
    },

    showIBQ(profile_id, $event){
      this.registerMatch(profile_id).then((res) => {
        console.log('REGISTERMATCH', res);
        //var matchId = res.data.data.id;
        this.$refs.modalIBQ.show(profile_id, $event.currentTarget);
      });
    },



    initiateConversation(match) {
      console.log('initiateConversation', match);

      if(match.blockSend){
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });
        this.$router.push(`/premium-plans`);
        return false;
      }

      this.registerMatch(match.profile_id).then((res) => {
        console.log('REGISTERMATCH', res);
        var matchId = res.data.data.id;
        this.$store.dispatch("initiateConversation", matchId).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            this.$store.dispatch("hideContent");
          }
        });
      });



    },

    goToProfile(profile_id) {
       if(this.lockRequests) return true;

        this.lockRequests = true;
        axios({ url: process.env.VUE_APP_URL + `/api/dashboard/view/${profile_id}`, method: "POST" })
          .then(() => {
            this.lockRequests = false;
            this.$router.push(`member-profile/profile?id=${profile_id}`);
          })
          .catch(() => {
            this.$store.dispatch(
              'updateAlerts',
              this.$t(this.$i18n.locale+'.keys.generic.alerts.maxOnlineUsersReached')
            );
            this.lockRequests = false;
          });

    },

    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getBookmarkValue(bookmarkId) {
      let bookmarkValue;
      this.$store.getters.getBookmarks.forEach(item => {
        if (item.id == bookmarkId) {
          if (parseInt(item.bookmark) == 1) {
            bookmarkValue = true;
          } else {
            bookmarkValue = false;
          }
        }
      });
      return bookmarkValue;
    },
    toggleBookmark(profile_id) {
      this.registerMatch(profile_id).then((res) => {
        var matchId = res.data.data.id;
          this.$store.dispatch("toggleBookmark", matchId).then(res => {
            //this.$store.dispatch("hideLoader");
            if (res == "error") {
              //this.$store.dispatch("setErrorVisibility", true);
            } else {
              this.$store.dispatch("showContent");
            }
          });
      });
    },
    smileSent(match) {
      //if(true){

       this.registerMatch(match.profile_id).then((res) => {
        var matchId = res.data.data.id;
        this.$store.dispatch("sendSmile", matchId).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            this.$store.dispatch("hideContent");
          }
        });
        this.addClass(match.profile_id);
        this.$store.dispatch("reRenderMatchesList");
       }).catch(()=>{
         console.log('SEND SMILE ERROR')
       });
      // this.componentKey++;
      /*
      } else {
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Smile'
        });
        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }
      */
    },
    addClass(id) {
      const currentId = "match" + id;
      console.log(currentId, document.getElementById(currentId));
      //document.getElementById(currentId).classList.add("smileSent");
      $('#'+ currentId).addClass("smileSent");
    },
    addClassBookmark(id) {
      const currentId = "bookmark" + id;

      !document.getElementById(currentId).classList.contains("added")
        ? document.getElementById(currentId).classList.add("added")
        : "";
    },
    sendPhotoRequest(id, $event) {
      console.log('sendPhotoRequest method', $event);
      this.registerMatch(id).then((res) => {
        var matchId = res.data.data.id;
          this.$store.dispatch("sendPhotoRequest", matchId).then(res => {
            if (res == "error") {
              //this.$store.dispatch("setErrorVisibility", true);
              //this.$store.dispatch("hideContent");
            } else {
                //remove photo button here
                $($event.target).remove();
            }
          });
      });

    },

  }
};
</script>

<style lang="scss" scoped></style>
