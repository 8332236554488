<template>
  <div id="personalDescription">
    <Spinner v-if="isLoading" />
    <div class="sectionBox">
      <div class="sectionTitleWrapper">
        <h1
          class="sectionTitle"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="sectionItemsWrapper">
        <div class="leftContainer">
          <div class="infoWrapper">
            <div class="heightWrapper infoBox">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'height-icon.svg')" alt="Height icon" />
              <p class="height infoText">{{ this.heightValue }}</p>
              <div
                class="heightIconWrapper editIconWrapper"
                v-b-tooltip.hover
                :title="$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.changeHeightText"
              >
                <img
                  @mouseover="hover = true"
                  @mouseleave="hover = false"
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button-grey.svg')"
                  alt="Edit button"
                />
                <input
                  :style="{marginLeft : '20px'}"
                  v-if="editable"
                  v-model="$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.changeHeightText"
                  type="text"
                />
              </div>
            </div>
            <div class="raceWrapper infoBox">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'race-icon.svg')" alt="Race icon" />
              <p class="race infoText">{{raceValue}}</p>
              <img
                @click="toggleEdit('race')"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
              <div v-if="showEditRace" class="editItemsWrapper">
                <div id="editRace" class="itemsListWrapper">
                  <label
                    v-for="(race, index) in this.raceArray"
                    :key="index + 'race'"
                    @click="chooseRace"
                    class="item"
                    :id="'race' + index"
                  >
                    <input type="checkbox" class="input" />
                    <span class="checkmark"></span>
                    <p class="itemName">{{race}}</p>
                  </label>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="showEditRace = !showEditRace"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="showEditRace = !showEditRace; newRaceValue()"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="religionWrapper infoBox">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'religion-icon.svg')" alt="Religion icon" />
              <p class="religion infoText">{{religionValue}}</p>
              <img
                @click="toggleEdit('religion')"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
              <div v-if="showEditReligion" class="editItemsWrapper">
                <div id="editReligion" class="itemsListWrapper">
                  <label
                    v-for="(religion, index) in this.religionArray"
                    :key="index + 'religion'"
                    @click="chooseReligion"
                    class="item"
                    :id="'religion' + index"
                  >
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                    <p class="itemName">{{religion}}</p>
                  </label>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="showEditReligion = !showEditReligion"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="newReligionValue(); showEditReligion = !showEditReligion"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightContainer">
          <div class="infoWrapper">
            <div class="smokingWrapper infoBox">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'smoke-icon.svg')" alt="Smoking icon" />
              <p class="smoking infoText">{{smokingValue}}</p>
              <img
                @click="toggleEdit('smoking')"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
              <div v-if="showEditSmoking" class="editItemsWrapper">
                <div id="editSmoking" class="itemsListWrapper">
                  <label
                    v-for="(smoking, index) in this.smokingArray"
                    :key="index + 'smoking'"
                    @click="chooseSmoking"
                    class="item"
                    :id="'smoking' + index"
                  >
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                    <p class="itemName">{{smoking}}</p>
                  </label>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="showEditSmoking = !showEditSmoking"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="showEditSmoking = !showEditSmoking; newSmokingValue()"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="educationWrapper infoBox">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'studies-icon.svg')" alt="Education icon" />
              <p class="education infoText">{{educationValue}}</p>
              <img
                @click="toggleEdit('education')"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
              <div v-if="showEditEducation" class="editItemsWrapper">
                <div id="editEducation" class="itemsListWrapper">
                  <label
                    v-for="(education, index) in this.educationArray"
                    :key="index + 'education'"
                    @click="chooseEduation"
                    class="item"
                    :id="'education' + index"
                  >
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                    <p class="itemName">{{education}}</p>
                  </label>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="showEditEducation = !showEditEducation"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="showEditEducation = !showEditEducation; newEducationValue()"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="languageWrapper">
              <img src="../../../../assets/images/language-icon.svg" alt="Language icon" />
              <div class="language">
                <div v-for="(selectedItem, index) in selectedItems" :key="index" class="selectedItem">
                  {{selectedItem.name}}
                  <span v-if="index < selectedItems.length -1">,</span>
                </div>
              </div>
              <img
                @click="showEditLanguage = !showEditLanguage"
                src="../../../../assets/images/edit-button.svg"
                alt="Edit button"
              />
              <div v-if="showEditLanguage" class="editLanguageWrapper">
                <div class="editLanguageBox">
                  <div
                    v-for="(selectedItem, index) in selectedItems"
                    :key="index + componentKey"
                    class="selectedItem"
                  >
                    {{selectedItem.name}}
                    <span v-if="index < selectedItems.length -1">,</span>
                    <div class="deleteItem" @click="removeItem(index)">
                      <img src="../../../../assets/images/delete-language.svg" alt="Delete Icon" />
                    </div>
                  </div>
                  <div class="suggestionWrapper">
                    <vue-suggestion
                      v-if="!itemsLimit"
                      :key="componentKey2 + '-suggestion'"
                      :items="items"
                      v-model="item"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      @changed="inputChange"
                      @selected="itemSelected"
                    ></vue-suggestion>
                    <div class="hideDefaultEraser"></div>
                  </div>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="showEditLanguage = !showEditLanguage; resetLanguages()"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="showEditLanguage = !showEditLanguage; sendLanguages()"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sectionBox">
      <QuestionsAnswers />
    </div>
  </div>
</template>

<script>
import QuestionsAnswers from "./QuestionsAnswers";

// import { VueSuggestion } from "vue-suggestion";
import itemTemplate from "./item-template.vue";
import {mapGetters} from "vuex";

export default {
  name: "PersonalDescription",
  components: {
    QuestionsAnswers,
    // VueSuggestion
  },
  data() {
    return {
      hover: false,
      componentKey2: 0,
      itemsLimit: false,
      item: {},
      allItems: [
        { id: 1, name: "Bulgarian" },
        { id: 2, name: "Croatian" },
        { id: 3, name: "Czech" },
        { id: 4, name: "Danish" },
        { id: 5, name: "Dutch" },
        { id: 6, name: "English" },
        { id: 7, name: "Estonian" },
        { id: 8, name: "Finnish" },
        { id: 9, name: "French" },
        { id: 10, name: "German" },
        { id: 11, name: "Greek" },
        { id: 12, name: "Hungarian" },
        { id: 13, name: "Irish" },
        { id: 14, name: "Italian" },
        { id: 15, name: "Latvian" },
        { id: 16, name: "Lithuanian" },
        { id: 17, name: "Maltese" },
        { id: 18, name: "Polish" },
        { id: 19, name: "Portuguese" },
        { id: 20, name: "Romanian" },
        { id: 21, name: "Slovak" },
        { id: 22, name: "Slovene" },
        { id: 23, name: "Spanish" },
        { id: 24, name: "Swedish" }
      ],
      items: [],
      selectedItems: [],
      componentKey: 0,
      itemTemplate,

      showEditRace: false,
      showEditReligion: false,
      showEditSmoking: false,
      showEditEducation: false,
      showEditLanguage: false,
      heightValue: "",
      raceArray: [],
      raceValue: "",
      raceEditValueID: 1,
      chosenRaceID: 1,
      religionArray: [],
      religionValue: "",
      religionEditValueID: 1,
      chosenReligionID: 1,
      smokingArray: [],
      smokingValue: "",
      smokingEditValueID: 1,
      chosenSmokingID: 1,
      educationArray: [],
      educationValue: "",
      educationEditValueID: 1,
      chosenEducationID: 1,
      languagesArray: [],
      languagesElements: "",
      fetchedLanguages: [],
      languageModel: {},
      show: false,
      isLoading:true,
    };
  },
  computed: {
    ...mapGetters(["getCountry", "isMobile"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      return (this.getCountry != 'uk');
    }
  },
  mounted() {
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
    this.setProfileData();
    //disable tooltips on mobile
    setTimeout(() => {
      if(this.isMobile) this.$root.$emit('bv::disable::tooltip');
    }, 100);
    return true;
   
  },
  methods: {
    toggleEdit(type){
      if(type == 'race'){
        this.showEditRace = !this.showEditRace; 
        if(this.showEditRace) this.showEditReligion = this.showEditEducation = this.showEditSmoking = false;
        this.setEditRace();
      }
      else if(type == 'religion'){
        this.showEditReligion = !this.showEditReligion; 
        if(this.showEditReligion) this.showEditRace = this.showEditEducation = this.showEditSmoking = false;
        this.setEditReligion();
      }
      else if(type == 'education'){
        this.showEditEducation = !this.showEditEducation; 
        if(this.showEditEducation) this.showEditRace = this.showEditReligion = this.showEditSmoking = false;
        this.setEditEducation();
      }
      else if(type == 'smoking'){
        this.showEditSmoking = !this.showEditSmoking; 
        if(this.showEditSmoking) this.showEditRace = this.showEditReligion = this.showEditEducation = false;
        this.setEditSmoking();
      }
    },
    setProfileData(){
      if(!this.metricUnits){
          //set values to foot

          var measurement = this.$store.getters.getPersonalDescription.height.answer[0];
          var feet = parseInt(measurement);
          var fraction = measurement - feet;
          var inches = parseInt((12.0 * fraction));

          this.heightValue = feet + 'ft ' + inches + 'in';
        }else{
          this.heightValue = this.$store.getters.getPersonalDescription.height.answer[0] + "cm";
        }
        if(this.$store.getters.getPersonalDescription.ethnicy){
          for (let i = 0;i < this.$store.getters.getPersonalDescription.ethnicy.question.answerOptions.length; i++) {
            this.raceArray.push(
              this.$store.getters.getPersonalDescription.ethnicy.question
                .answerOptions[i].translations[this.$store.getters.selectedLanguage].answer
            );
          }
          this.raceValue = this.raceArray[
            this.$store.getters.getPersonalDescription.ethnicy.answer[0] - 1
          ];
          this.raceEditValueID =
            this.$store.getters.getPersonalDescription.ethnicy.answer[0] - 1;
        }
        for (
          let i = 0;
          i <
          this.$store.getters.getPersonalDescription.religion.question
            .answerOptions.length;
          i++
        ) {
          this.religionArray.push(
            this.$store.getters.getPersonalDescription.religion.question
              .answerOptions[i].translations[this.$store.getters.selectedLanguage].answer
          );
        }
        this.religionValue = this.religionArray[
          this.$store.getters.getPersonalDescription.religion.answer[0] - 1
        ];
        this.religionEditValueID =
          this.$store.getters.getPersonalDescription.religion.answer[0] - 1;

        for (
          let i = 0;
          i <
          this.$store.getters.getPersonalDescription.smoking.question
            .answerOptions.length;
          i++
        ) {
          this.smokingArray.push(
            this.$store.getters.getPersonalDescription.smoking.question
              .answerOptions[i].translations[this.$store.getters.selectedLanguage].answer
          );
        }
        this.smokingValue = this.smokingArray[
          this.$store.getters.getPersonalDescription.smoking.answer[0] - 1
        ];
        this.smokingEditValueID =
          this.$store.getters.getPersonalDescription.smoking.answer[0] - 1;

        for (
          let i = 0;
          i <
          this.$store.getters.getPersonalDescription.degree.question
            .answerOptions.length;
          i++
        ) {
          this.educationArray.push(
            this.$store.getters.getPersonalDescription.degree.question
              .answerOptions[i].translations[this.$store.getters.selectedLanguage].answer
          );
        }

        this.educationValue = this.educationArray[
          this.$store.getters.getPersonalDescription.degree.answer[0] - 1
        ];
        this.educationEditValueID =
          this.$store.getters.getPersonalDescription.degree.answer[0] - 1;

        this.selectedItemsOne = this.$store.getters.getPersonalDescription.languages;
        if (this.selectedItemsOne.length >= 10) {
          this.itemsLimit = true;
        }
        for (let i = 0; i < this.selectedItemsOne.length; i++) {
          this.selectedItems.push({ id: i, name: this.selectedItemsOne[i] });
        }
        this.isLoading = false;
    },
    resetLanguages() {
      this.selectedItems = [];
      for (let i = 0; i < this.selectedItemsOne.length; i++) {
        this.selectedItems.push({ id: i, name: this.selectedItemsOne[i] });
      }
      this.componentKey++;
    },
    sendLanguages() {
      let selectedItemsLanguages = [];
      this.selectedItems.forEach(language => {
        selectedItemsLanguages.push(language.name);
      });
      const data = {
        langs: [...selectedItemsLanguages]
      };
      this.$store.dispatch("updateLanguage", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
      // send this.selectedItems to server
    },
    removeItem(index) {
      if(this.selectedItems.length > 1){
        this.selectedItems.splice(index, 1);
        if (this.selectedItems.length < 10) {
          this.itemsLimit = false;
          this.componentKey2++;
        }
        this.componentKey++;
      }
    },
    itemSelected(item) {
      this.item = item;
      if (this.selectedItems.length < 10) {
        this.selectedItems.push(this.item);
      } else {
        this.itemsLimit = true;
        this.componentKey2++;
      }
      this.componentKey++;
      this.item = "";
    },
    setLabel(item) {
      console.log(item);
      return "";
    },
    inputChange(text) {
      this.items = this.allItems.filter(item =>
        item.name.toLowerCase().startsWith(text.toLowerCase())
      );
      // now `items` will be showed in the suggestion list
    },
    setEditRace() {
      if(!this.showEditRace) return false;
      setTimeout(() => {
        document.getElementById(
          `race${this.raceEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseRace(event) {
      document.querySelectorAll("#editRace .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenRaceID = parseInt(event.target.parentElement.id.substr(4)) + 1;
    },
    newRaceValue() {
      this.raceValue = this.raceArray[this.chosenRaceID - 1];
      this.raceEditValueID = parseInt(this.chosenRaceID - 1);

      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.ethnicy.id,
        answer: [this.chosenRaceID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
      //send value to server
    },
    setEditReligion() {
      if(!this.showEditReligion) return false;
      setTimeout(() => {
        document.getElementById(
          `religion${this.religionEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseReligion(event) {
      document.querySelectorAll("#editReligion .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenReligionID =
        parseInt(event.target.parentElement.id.substr(8)) + 1;
    },
    newReligionValue() {
      this.religionValue = this.religionArray[this.chosenReligionID - 1];
      this.religionEditValueID = parseInt(this.chosenReligionID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.religion
          .id,
        answer: [this.chosenReligionID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    setEditSmoking() {
      if(!this.showEditSmoking) return false;
      setTimeout(() => {
        document.getElementById(
          `smoking${this.smokingEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseSmoking(event) {
      document.querySelectorAll("#editSmoking .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenSmokingID =
        parseInt(event.target.parentElement.id.substr(7)) + 1;
    },
    newSmokingValue() {
      this.smokingValue = this.smokingArray[this.chosenSmokingID - 1];
      this.smokingEditValueID = parseInt(this.chosenSmokingID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.smoking.id,
        answer: [this.chosenSmokingID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    setEditEducation() {
      if(!this.showEditEducation) return false;
      setTimeout(() => {
        document.getElementById(
          `education${this.educationEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseEduation(event) {
      document.querySelectorAll("#editEducation .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenEducationID =
        parseInt(event.target.parentElement.id.substr(9)) + 1;
    },
    newEducationValue() {
      this.educationValue = this.educationArray[this.chosenEducationID - 1];
      this.educationEditValueID = parseInt(this.chosenEducationID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.degree.id,
        answer: [this.chosenEducationID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    }
  }
};
</script>

