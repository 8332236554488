<template>
  <div id="preferencesMenu" :class="{premium: $store.getters.userRole.includes('ROLE_PREMIUM')}">
    <div class="preferencesMenuWrapper">
      <img v-if="editable" src="../../../assets/images/Translate-Icon.svg" alt="Translation icon" />
      <h1
        class="menuItem ageAndHeight active"
        v-langErr 
        @click="scrollToSection('ageAndHeight')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.ageAndHeightTitle}}</h1>

      <h1
        class="menuItem distance"
        v-langErr 
        @click="scrollToSection('distance')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.distance}}</h1>
      <h1
        class="menuItem children"
        v-langErr 
        @click="scrollToSection('children')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.desireToHaveChildren}}</h1>
      <h1
        class="menuItem education"
        v-langErr 
        @click="scrollToSection('education')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.educationAndIncome}}</h1>
      <h1
        class="menuItem smokingAndDrinkingHabits"
        v-langErr 
        @click="scrollToSection('smokingAndDrinkingHabits')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.smokingAndDrinkingHabits}}</h1>
      <h1
        class="menuItem ethnicityAndReligion"
        v-langErr 
        @click="scrollToSection('ethnicityAndReligion')"
      >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.menu.ethnicityAndReligion}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreferencesMenu",

  data() {
    return {};
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {
    scrollToSection(section) {
      document.getElementById(section).scrollIntoView({ behavior: "smooth"});

    }

  }
};
</script>

