<template>
  <div id="matchingSettings" :key="componentKey" class="preferencesBox">
    <div class="matchingSettingsWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.myAccount.matchingSettings.matchingSettings =
            $event.target.innerText
        "
        >{{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingSettings}}</h1>
      </div>
      <div class="matchingSettingsWrapper">
        <div class="switchWrapper">
          <input
            v-model="matchingEnabledSlider"
            class="switchInput"
            v-bind:class="{ active: isActive1 }"
            v-touch:start="changeMatchingFilterValue"
            v-touch:longtap="changeMatchingFilterValue"
            @change="changeMatchingFilterValue()"
            type="range"
            min="0"
            max="1"
            step="1"
          />
          <p
            v-if="matchingEnabledSlider == 1"
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.myAccount.matchingSettings.matchingEnabled =
            $event.target.innerText
        "
          >{{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingEnabled}}</p>

          <p
            v-if="matchingEnabledSlider == 0"
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.myAccount.matchingSettings.matchingDisabled =
            $event.target.innerText
        "
          >{{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingDisabled}}</p>
        </div>
        <div class="explanationMessageWrapper">
          <p
            v-if="matchingEnabledSlider == 1"
            class="explanationMessage"
            v-langErr
            :contenteditable="editable"
            @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.myAccount.matchingSettings.youCurrently =
            $event.target.innerText
        "
          >{{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.youCurrently}}</p>
          <p
            v-if="matchingEnabledSlider == 0"
            class="explanationMessage"
            v-langErr
            :contenteditable="editable"
            @blur="
          $t(
            $i18n.locale
          ).keys.membershipArea.myAccount.matchingSettings.matchingDisabled =
            $event.target.innerText
        "
          > {{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingDisabled}}</p>
        </div>
      </div>

      <div class="buttonWrapper">
        <router-link to="/membership-area/matches-preferences">
          <button
            class="btnSave"
          >{{$t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences}}</button>
        </router-link>
        <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences
          "
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "MatchingSettings",
  data() {
    return {
      componentKey: 0,
      matchingEnabledSlider: 0,
      isActive1: false
    };
  },
  computed: {
    ...mapGetters(["getClientSettings"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getClientSettings: function(newVal){
      if(!_.isEmpty(newVal)){
          this.matchingEnabledSlider = this.getClientSettings.matching_settings[0];
          if (this.matchingEnabledSlider == 0) {
            this.isActive1 = false;
          } else if (this.matchingEnabledSlider == 1) {
            this.isActive1 = true;
          }
      }
    }
  },
  mounted() {

  },
  methods: {
    changeMatchingFilterValue() {
      if (this.matchingEnabledSlider == 0) {
        this.matchingEnabledSlider = 1;
      } else if (this.matchingEnabledSlider == 1) {
        this.matchingEnabledSlider = 0;
      }
      if (this.matchingEnabledSlider == 0) {
        this.isActive1 = false;
      } else if (this.matchingEnabledSlider == 1) {
        this.isActive1 = true;
      }
      setTimeout(() => {
        let value;
        if (this.matchingEnabledSlider == 0) {
          value = false;
        } else if (this.matchingEnabledSlider == 1) {
          value = true;
        }
        const matching = {
          value: value
        };
        this.$store.dispatch("updateMatchingEnabled", matching);
      }, 1000);
    }
    },
    // matchingFilterColouring() {
    //   if (this.matchingEnabledSlider == 0) {
    //     this.isActive1 = false;
    //   } else if (this.matchingEnabledSlider == 1) {
    //     this.isActive1 = true;
    //   }
    // },
    // setMatchingEnabled() {
    //   setTimeout(() => {
    //     let value;
    //     if (this.matchingEnabledSlider == 0) {
    //       value = false;
    //     } else if (this.matchingEnabledSlider == 1) {
    //       value = true;
    //     }
    //     const matching = {
    //       value: value
    //     };
    //     this.$store.dispatch("updateMatchingEnabled", matching);
    //   }, 1000);
    // }
  // }
};
</script>
