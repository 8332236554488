var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preferencesBox",attrs:{"id":"personalDetails"}},[_c('div',{staticClass:"personalDetailsWrapper preferencesContentWrapper"},[_c('div',{staticClass:"titleWrapper"},[_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"title",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
            _vm.$i18n.locale
          ).keys.membershipArea.myAccount.personalDetails.personalDetails =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .personalDetails)+" ")])]),_c('div',{staticClass:"detailsWrapper"},[(!_vm.mobileView)?_c('div',{staticClass:"detailWrapper"}):_vm._e(),_c('div',{staticClass:"detailWrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateEmail()}}},[_c('div',{staticClass:"detailsTextWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"detailsText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.email =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .email)+" ")])]),_c('div',{key:_vm.componentKeyEmail,staticClass:"detailsInputWrapper"},[_c('div',{staticClass:"genericInput"},[_vm._v(_vm._s(_vm.email))])]),_c('div',{staticClass:"detailsInputWrapper"},[(_vm.newEmailEmptyError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"emptyErrorText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.enterEmail =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .enterEmail)+" ")]):_vm._e(),(_vm.newEmailBadFormatError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"formatErrorText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.enterValidEmail =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .enterValidEmail)+" ")]):_vm._e(),(_vm.sameAsOldError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"sameAsOldError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.emailShouldMatch =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .emailShouldMatch)+" ")]):_vm._e(),(_vm.$store.getters.getEmail)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"emailExistsError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.alreadyExist =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .alreadyExist)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newEmail),expression:"newEmail"}],staticClass:"genericInput",class:{
                emptyError: _vm.newEmailEmptyError,
                emptyError: _vm.newEmailBadFormatError,
                emptyError: _vm.sameAsOldError,
                emptyError: _vm.$store.getters.getEmail,
              },attrs:{"type":"email","placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .newEmailAddress},domProps:{"value":(_vm.newEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.newEmail=$event.target.value}}})]),_c('div',{staticClass:"detailsInputWrapper"},[(_vm.repeatedEmailEmptyError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"emptyErrorText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.enterEmail =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .enterEmail)+" ")]):_vm._e(),(_vm.repeatedEmailBadFormatError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"formatErrorText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.enterValidEmail =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .enterValidEmail)+" ")]):_vm._e(),(_vm.notSameEmailsError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"sameEmailError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.repeatEmail =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .repeatEmail)+" ")]):_vm._e(),(_vm.sameAsOldError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"sameAsOldError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.emailShouldMatch =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .emailShouldMatch)+" ")]):_vm._e(),(_vm.$store.getters.getEmail)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"emailExistsError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.alreadyExist =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .alreadyExist)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repeatedEmail),expression:"repeatedEmail"}],staticClass:"genericInput",class:{
                emptyError: _vm.repeatedEmailEmptyError,
                emptyError: _vm.repeatedEmailBadFormatError,
                emptyError: _vm.notSameEmailsError,
                emptyError: _vm.sameAsOldError,
                emptyError: _vm.$store.getters.getEmail,
              },attrs:{"type":"email","placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .confirmEmail},domProps:{"value":(_vm.repeatedEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.repeatedEmail=$event.target.value}}})])]),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnSave",on:{"click":function($event){return _vm.validateEmail()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .saveChnages)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .saveChnages
              ),expression:"\n                $t($i18n.locale).keys.membershipArea.myAccount.personalDetails\n                  .saveChnages\n              "}],attrs:{"type":"text"},domProps:{"value":(
                _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .saveChnages
              )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  , "saveChnages", $event.target.value)}}}):_vm._e()])]),_c('div',{staticClass:"detailWrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword()}}},[_c('div',{staticClass:"detailsTextWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"detailsText"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .password)+" ")])]),_c('div',{staticClass:"detailsInputWrapper"},[(_vm.oldPasswordError)?_c('p',{staticClass:"passwordErrorMessage"},[_vm._v(" "+_vm._s(_vm.oldPasswordError)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"genericInput",class:{ emptyError: _vm.oldPasswordError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .oldPassword,"type":"password"},domProps:{"value":(_vm.oldPassword)},on:{"input":[function($event){if($event.target.composing)return;_vm.oldPassword=$event.target.value},_vm.resetPasswortErrors]}})]),_c('div',{staticClass:"detailsInputWrapper"},[(_vm.newPasswordError !== '')?_c('p',{staticClass:"passwordErrorMessage"},[_vm._v(" "+_vm._s(_vm.newPasswordError)+" ")]):_vm._e(),(!_vm.$v.newPassword.minLength)?_c('p',{staticClass:"passwordErrorMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.minNewPassword)+" "+_vm._s(_vm.$v.newPassword.$params.minLength.min)+" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.letters)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.newPassword.$model),expression:"$v.newPassword.$model",modifiers:{"trim":true}}],staticClass:"genericInput",class:{ emptyError: _vm.newPasswordError !== '' },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .newPassword,"type":"password"},domProps:{"value":(_vm.$v.newPassword.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.newPassword, "$model", $event.target.value.trim())},_vm.resetPasswortErrors],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"detailsInputWrapper"},[(_vm.confrimPasswordError !== '')?_c('p',{staticClass:"passwordErrorMessage"},[_vm._v(" "+_vm._s(_vm.confrimPasswordError)+" ")]):_vm._e(),(
                !_vm.$v.repeatNewPassword.sameAsPassword &&
                  _vm.$v.repeatNewPassword.$dirty
              )?_c('p',{staticClass:"passwordErrorMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.passwordIndentical)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.repeatNewPassword.$model),expression:"$v.repeatNewPassword.$model",modifiers:{"trim":true}}],staticClass:"genericInput",class:{ emptyError: _vm.confrimPasswordError !== '' },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .repeatPassword,"type":"password"},domProps:{"value":(_vm.$v.repeatNewPassword.$model)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.repeatNewPassword, "$model", $event.target.value.trim())},_vm.resetPasswortErrors],"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnSave",on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .saveChnages)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .saveChnages
              ),expression:"\n                $t($i18n.locale).keys.membershipArea.myAccount.personalDetails\n                  .saveChnages\n              "}],attrs:{"type":"text"},domProps:{"value":(
                _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .saveChnages
              )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  , "saveChnages", $event.target.value)}}}):_vm._e()])]),_c('div',{key:_vm.componentAddressKey,staticClass:"detailWrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changeAddress()}}},[_c('div',{staticClass:"detailsTextWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"detailsText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.address =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .address)+" ")])]),_c('div',{staticClass:"detailsInputsWrapper"},[(_vm.addressError)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"emptyError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.blankFieldsError =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .blankFieldsError)+" ")]):_vm._e(),(_vm.badFormatAddress)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"badFormatError",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.badFormatError =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .badFormatError)+" ")]):_vm._e(),_c('div',{staticClass:"addressFirstLine"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],staticClass:"genericInputSmall",class:{ error: _vm.addressError || _vm.firstnameError },attrs:{"placeholder":_vm.$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.firstName')},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing)return;_vm.firstname=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],staticClass:"genericInputSmall",class:{ error: _vm.addressError || _vm.lastnameError },attrs:{"placeholder":_vm.$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.lastName')},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing)return;_vm.lastname=$event.target.value}}})]),_c('div',{staticClass:"addressFirstLine"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.road),expression:"road"}],staticClass:"genericInputMedium",class:{ error: _vm.addressError || _vm.roadFormatError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.road},domProps:{"value":(_vm.road)},on:{"input":function($event){if($event.target.composing)return;_vm.road=$event.target.value}}}),(!_vm.mobileView)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.number),expression:"number"}],staticClass:"genericInputXsmall",class:{ error: _vm.addressError || _vm.numberFormatError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.number},domProps:{"value":(_vm.number)},on:{"input":function($event){if($event.target.composing)return;_vm.number=$event.target.value}}}):_vm._e()]),_c('div',{staticClass:"addressSecondLine"},[(_vm.mobileView)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.number),expression:"number"}],staticClass:"genericInputXsmall number",class:{ error: _vm.addressError || _vm.numberFormatError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.number},domProps:{"value":(_vm.number)},on:{"input":function($event){if($event.target.composing)return;_vm.number=$event.target.value}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postalCode),expression:"postalCode"}],staticClass:"genericInputXsmall postal",class:{ error: _vm.addressError || _vm.postalCodeFormatError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.postalCode},domProps:{"value":(_vm.postalCode)},on:{"input":function($event){if($event.target.composing)return;_vm.postalCode=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"cityDropdown genericInputXsmall",class:{ error: _vm.addressError || _vm.cityFormatError },attrs:{"disabled":true,"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.city},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing)return;_vm.city=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"countryDropdown genericInputXsmall",class:{ error: _vm.addressError || _vm.countryFormatError },attrs:{"disabled":true,"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount
                    .personalDetails.country},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})])])])]),_c('div',{staticClass:"detailWrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changeAddress()}}},[_c('div',{staticClass:"detailsTextWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"detailsText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.phone =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .phone)+" ")])]),_c('div',{staticClass:"detailsInputWrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"genericInput",class:{ error: _vm.addressError || _vm.phoneNumberError },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .enterYourNumber,"type":"tel"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value}}})])])]),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnSave",on:{"click":function($event){return _vm.changeAddress()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .saveChnages)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
              .saveChnages
          ),expression:"\n            $t($i18n.locale).keys.membershipArea.myAccount.personalDetails\n              .saveChnages\n          "}],attrs:{"type":"text"},domProps:{"value":(
            _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
              .saveChnages
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
              , "saveChnages", $event.target.value)}}}):_vm._e()])]),(!this.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('div',{staticClass:"deleteAccountWrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"title",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
            _vm.$i18n.locale
          ).keys.membershipArea.myAccount.personalDetails.deleteAccount =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .deleteAccount)+" ")]),(!this.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('p',{staticClass:"infoText"},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.allOfthe =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .allOfthe))]),_vm._v(" "),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"here",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-deletion-reasons')},"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.here =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .here))])]):_c('p',{staticClass:"infoText"},[_c('a',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"here",attrs:{"href":_vm.supportLink,"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.contactSupport =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .contactSupport)+" ")])]),_c('b-modal',{attrs:{"id":"bv-modal-deletion-reasons","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .deleteTheAccount))]},proxy:true}],null,false,3553308906)},[_c('div',{staticClass:"d-block text-center"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"listTitle",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                _vm.$i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.pleaseTell =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale+'.keys.membershipArea.myAccount.personalDetails.pleaseTell',{productName: _vm.$store.getters.productName,}))+" ")]),_c('div',{staticClass:"reasonsWrapper"},_vm._l((_vm.deletionReasons),function(reason){return _c('label',{key:reason.id + 'reason',staticClass:"reasonItem",on:{"click":function($event){_vm.chooseReason($event), _vm.setChosenReasonID(reason.id)}}},[_c('input',{staticClass:"input",attrs:{"type":"checkbox"}}),_c('span',{staticClass:"checkmark"}),_c('p',{staticClass:"reasonText"},[_vm._v(_vm._s(reason.text))])])}),0),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"suggestionsTitle",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                _vm.$i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.doYouHave =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale+'.keys.membershipArea.myAccount.personalDetails.doYouHave',{productName: _vm.$store.getters.productName,}))+" ")]),_c('textarea',{attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                .typeHere}}),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnCancel",on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-deletion-reasons')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn),expression:"$t($i18n.locale).keys.generic.cancelBtn"}],style:({ marginLeft: '20px' }),attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "cancelBtn", $event.target.value)}}}):_vm._e(),_c('button',{staticClass:"btnSave",attrs:{"disabled":!_vm.reasonChosen},on:{"click":function($event){_vm.$bvModal.hide('bv-modal-deletion-reasons');
                _vm.$bvModal.show('bv-modal-deletion-confirmation');}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn),expression:"$t($i18n.locale).keys.generic.saveBtn"}],style:({ marginLeft: '20px' }),attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "saveBtn", $event.target.value)}}}):_vm._e()])])]),_c('b-modal',{attrs:{"id":"bv-modal-deletion-confirmation","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .deleteMyAccount))]},proxy:true}],null,false,706254695)},[_c('div',{staticClass:"d-block text-center"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"deletionTitle",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                _vm.$i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.areYouAccount =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .areYouAccount)+" ")]),_c('div',{staticClass:"confirmationWrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"confirmationText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.confirmPassword =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .confirmPassword)+": ")]),(this.$store.getters.getAccountDeleteError !== '')?_c('p',{staticClass:"incorrecPasswordMessage"},[_vm._v(" "+_vm._s(this.$store.getters.getAccountDeleteError)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmationPassword),expression:"confirmationPassword"}],staticClass:"confirmationPassword",class:{
                error: this.$store.getters.getAccountDeleteError !== '',
              },attrs:{"placeholder":_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .password,"type":"password"},domProps:{"value":(_vm.confirmationPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmationPassword=$event.target.value}}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"infoMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                _vm.$i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.deletingYourAccount =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.personalDetails .deletingYourAccount)+" ")]),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnCancel",on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-deletion-confirmation')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn),expression:"$t($i18n.locale).keys.generic.cancelBtn"}],style:({ marginLeft: '20px' }),attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.cancelBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "cancelBtn", $event.target.value)}}}):_vm._e(),_c('button',{staticClass:"btnSave",on:{"click":function($event){return _vm.deleteAccount()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn),expression:"$t($i18n.locale).keys.generic.saveBtn"}],style:({ marginLeft: '20px' }),attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.saveBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "saveBtn", $event.target.value)}}}):_vm._e()])])])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }