import { render, staticRenderFns } from "./ActionsTab.vue?vue&type=template&id=463318c2&scoped=true"
import script from "./ActionsTab.vue?vue&type=script&lang=js"
export * from "./ActionsTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463318c2",
  null
  
)

export default component.exports