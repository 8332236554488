<template>
  <div id="memberPersonality">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.memberProfile.memberPersonality.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.memberProfile.memberPersonality.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <div v-if="PersonalityData && !boxBlurred" class="personalityWrapper">
      <div v-for="(item, index) in PersonalityData.scores" :key="index" class="personalityTraitWrapper">

        <div class="personalityTraitTitle">{{ $t($i18n.locale).keys.personality[index] }}</div>
        <div v-if="PersonalityData.texts[index][(item-1)]" class="personalityTraitRendering">
          <p
            class="personalityCharacteristic"
            v-bind:style="{ marginLeft: PersonalityData.texts[index][(item-1)].left + '%' }"
          >{{ PersonalityData.texts[index][(item-1)].characteristic }}</p>
          <div class="personalityPercentageWrapper">
            <div class="personalityPercentage"></div>
            <div
              v-bind:style="{ left: PersonalityData.texts[index][(item-1)].left + '%' }"
              class="personalityPercentageActive"
            ></div>
          </div>
          <div class="comparisonToPopulationMessages">
            <p class="leftMessage">{{ PersonalityData.texts[index][(item-1)].percentageText1 }}</p>
            <p class="rightMessage">{{ PersonalityData.texts[index][(item-1)].percentageText2 }}</p>
          </div>
          <div class="personalityTraitDescriptionWrapper">
            <p class="personalityDescription">{{ PersonalityData.texts[index][(item-1)].traitDescription }}</p>
          </div>
        </div>
      </div>
    </div>
    <ProfileBlurBanner v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileBlurBanner from "@/components/generic/ProfileBlurBanner.vue";

export default {
  name: "MemberPersonality",
  components: {
    ProfileBlurBanner
  },
  props: [
    "discriptionData",
    "profileId"
    ],
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapGetters(["MemberData", "PersonalityData",  "CurrentProfileID", "user"]),
    memberName() {
      return this.MemberData.profileCard.name;
    },
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    boxBlurred: function(){
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 16;
    },


  },
  mounted() {

    this.isLoading = false;
    this.$store.dispatch("fetchPersonalityData", {
      profileID: this.CurrentProfileID,
      locale: this.selectedLanguage
    }).then(() => {
      this.isLoading = false;
    });
  },

  methods: {}

};
</script>

<style>
.personalityTraitWrapper {
  margin-bottom: 15px;
}
.personalityTraitTitle {
  text-transform: capitalize;
}
</style>
