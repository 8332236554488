<template>
  <div id="visitorsSeachBar">
    <div class="leftContainer">
      <div class="iconWrapper">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'eye-white-icon.svg')" class="Visitors puzzle icon" />
        <p v-if="$store.getters.filteredVisitorsCount != 0">{{$store.getters.filteredVisitorsCount}}</p>
      </div>
      <div class="searchBarWrapper">
          <input class="searchBar" v-model="visitorsSearchValue" type="text" :placeholder="$t($i18n.locale).keys.membershipArea.membershipVisitors.searchPlaceholder">
      </div>
    </div>
    <div class="rightContainer">
        <div class="galleryViewIcon" @click="$store.dispatch('toggleGalleryViewVisitors')" v-bind:class="{ active: $store.getters.galleryViewVisitors }" ></div>
        <div class="listViewIcon" @click="$store.dispatch('toggleListViewVisitors')" v-bind:class="{ active: $store.getters.listViewVisitors }"></div>
        <router-link to="/membership-area/matches-preferences">
          <img class="preferencesIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'preferences-icon.svg')" alt="Preferences icon" />
        </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "VisitorsSearchBar",
  components: {},
  data() {
    return {
      visitorsSearchValue: ""
    };
  },
  watch:{
    visitorsSearchValue: function(){
      this.$store.dispatch("setSearchValueVisitors", this.visitorsSearchValue);
    }
  },
  computed: {
    ...mapGetters(["galleryViewVisitors", "listViewVisitors"])
  },
  methods: {
    ...mapActions(["toggleGalleryViewVisitors", "toggleListViewVisitors", "setSearchValueVisitors"]),
  }
};
</script>

<style lang="scss" scoped>
</style>