<template>
  <div class="overlay">
    <div class="overlay__inner">
      <div class="overlay__content">
        <span  class="spinner"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",

  data() {
    return {
      // show: false
    };
  },

  mounted() {
  //  setTimeout(() => {
  //   //  console.log(this.show);
  //    this.show = true;
  //         // console.log(this.show);
  //  }, 400);
  }
};
</script>

<style>

/* .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
} */

/* @keyframes spin {
  100% {
    transform: rotate(360deg);
  } */
/* } */
</style>