var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{superadmin: this.$store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editableContent: this.$store.getters.editable },attrs:{"id":"stickySection"}},[_c('header',{attrs:{"id":"membershipHeader"}},[_c('div',{staticClass:"container-lg",attrs:{"id":"headerWrapper"}},[_c('div',{attrs:{"id":"logoWrapper"}},[_c('router-link',{attrs:{"to":"/membership-area/home"}},[(!_vm.$store.getters.isMobile || _vm.premium)?_c('img',{staticClass:"logo logo-desktop",attrs:{"src":_vm.$store.getters.mainLogos.desktop}}):_c('img',{staticClass:"logo logo-mobile",attrs:{"src":_vm.$store.getters.mainLogos.mobile}})])],1),(!_vm.premium)?_c('div',{staticClass:"itemsWrapper",attrs:{"id":"navigation"}},[_c('router-link',{staticClass:"navItem",class:[
              _vm.$route.path.indexOf('/membership-area/messages') != -1 ? 'active' : ''
            ],attrs:{"to":"/membership-area/messages"}},[_c('div',{staticClass:"messages navItemWrapper"},[_c('div',{staticClass:"infoWrapper navItem-icon"},[_c('div',{staticClass:"messagesIcon icon"}),(this.$store.getters.chatNewMessages > 0 && this.$store.getters.chatNewMessages < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(this.$store.getters.chatNewMessages))]):(this.$store.getters.chatNewMessages >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerMessages navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.messages =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.messages)+" ")])])]),_c('router-link',{staticClass:"navItem",class:[
              _vm.$route.path == '/membership-area/online' ? 'active' : '',
              _vm.$route.path == '/membership-area/visitors' ? 'next' : ''
            ],attrs:{"to":"/membership-area/online"}},[(_vm.showOnlineUserMenu)?_c('div',{staticClass:"visitors navItemWrapper"},[_c('div',{staticClass:"infoWrapper navItem-icon"},[_c('div',{staticClass:"onlineIcon icon"}),(_vm.onlineUsers.length > 0 && _vm.onlineUsers.length < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.onlineUsers.length))]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerVisitors navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.onlineUsers =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale+'.keys.membershipArea.membershipHeader.onlineUsers'))+" ")])]):_vm._e()]),_c('router-link',{staticClass:"navItem",class:[
              _vm.$route.path == '/membership-area/matches' ? 'active' : '',
              _vm.$route.path.indexOf('/membership-area/messages') != -1 ? 'next' : ''
            ],attrs:{"to":"/membership-area/matches"}},[_c('div',{staticClass:"matches navItemWrapper"},[_c('div',{staticClass:"infoWrapper navItem-icon"},[_c('div',{staticClass:"matchesIcon icon"}),(_vm.$store.getters.matchesQuantity > 0 && _vm.$store.getters.matchesQuantity < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.$store.getters.matchesQuantity))]):(_vm.$store.getters.matchesQuantity >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerMatches navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.matches =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.matches)+" ")])])]),_c('router-link',{staticClass:"navItem",class:[
              _vm.$route.path == '/membership-area/visitors' ? 'active' : '',
              _vm.$route.path == '/membership-area/matches' ? 'next' : ''
            ],attrs:{"to":"/membership-area/visitors"}},[_c('div',{staticClass:"visitors navItemWrapper"},[_c('div',{staticClass:"infoWrapper navItem-icon"},[_c('div',{staticClass:"visitorsIcon icon"}),(_vm.visitorsQuantity > 0 && _vm.visitorsQuantity < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.visitorsQuantity))]):(_vm.visitorsQuantity >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerVisitors navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.visitors =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.visitors)+" ")])])]),(this.$store.getters.userRole.includes('ROLE_PREMIUM') && !_vm.totalMeetings == 0)?_c('router-link',{staticClass:"navItem",class:[
              _vm.$route.path == '/membership-area/meetings' ? 'active' : '',
              _vm.$route.path == '/membership-area/visitors' ? 'next' : ''
            ],attrs:{"to":"/membership-area/meetings"}},[_c('div',{staticClass:"meetings navItemWrapper"},[_c('div',{staticClass:"infoWrapper navItem-icon"},[_c('div',{staticClass:"meetingsIcon icon"}),(_vm.totalMeetings > 0 && _vm.totalMeetings < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.totalMeetings))]):(_vm.totalMeetings >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerHaveYouMet navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.haveYouMet =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader .haveYouMet)+" ")])])]):_vm._e(),_c('div',{class:[_vm.$route.path == '/membership-area/meetings' ? 'next' : ''],attrs:{"id":"memberPhotoWrapper"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.profilePic)?_c('img',{staticClass:"memberPhoto",attrs:{"src":_vm.profilePic}}):_vm._e(),(!_vm.profilePic && (parseInt(_vm.profileGender) == 1))?_c('div',{staticClass:"memberPhoto",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg')
              })}):_vm._e(),(!_vm.profilePic && (parseInt(_vm.profileGender) == 2))?_c('div',{staticClass:"memberPhoto",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg')
              })}):_vm._e(),_c('transition',{attrs:{"duration":{ enter: 0, leave: 300 }}},[(_vm.hover)?_c('div',{staticClass:"desktopMenuDropDown"},[_c('div',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"dropDownItem",class:[
                  _vm.$route.path == '/membership-area/home' ? 'active' : ''
                ],attrs:{"contenteditable":_vm.editable},on:{"click":function($event){_vm.$router.push('/membership-area/home'), (_vm.hover = false)},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.home =
                    $event.target.innerText}}},[_c('div',{staticClass:"homeIcon icon"}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.home)+" ")]),_c('div',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"dropDownItem",class:[
                  _vm.$route.path == '/membership-area/user-profile/profile'
                    ? 'active': ''
                ],on:{"click":function($event){_vm.$router.push('/membership-area/user-profile/profile'),(_vm.hover = false)}}},[_c('div',{staticClass:"profileIcon icon",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                      _vm.$i18n.locale
                    ).keys.membershipArea.membershipHeader.profile =
                      $event.target.innerText}}}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.profile)+" ")]),_c('div',{staticClass:"dropDownItem",class:[
                  _vm.$route.path == '/membership-area/matches-preferences'
                    ? 'active'
                    : ''
                ],on:{"click":function($event){_vm.$router.push('/membership-area/matches-preferences'),(_vm.hover = false)}}},[_c('div',{staticClass:"preferencesIcon icon",on:{"blur":function($event){_vm.$t(
                      _vm.$i18n.locale
                    ).keys.membershipArea.membershipHeader.preferences =
                      $event.target.innerText}}}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.preferences)+" ")]),_c('div',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"dropDownItem",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){_vm.$router.push('/membership-area/my-account'), (_vm.hover = false)},"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.membershipArea.membershipHeader.myAccount =
                    $event.target.innerText}}},[_c('div',{staticClass:"accountIcon icon"}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader .myAccount)+" ")]),_c('div',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"dropDownItem",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){_vm.$router.push('/logout'), (_vm.hover = false)},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.logout =
                    $event.target.innerText}}},[_c('div',{staticClass:"logoutIcon icon"}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.logout)+" ")])]):_vm._e()])],1),_c('div',{staticClass:"navItem"},[_c('div',{staticClass:"navItemWrapper navigationButton",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('span'),_c('span'),_c('span')])])],1):_vm._e()]),(_vm.isVisible && !_vm.premium)?_c('slide-up-down',{staticClass:"mobileHeaderWrapper",attrs:{"active":_vm.isVisible,"duration":1000}},[_c('div',{staticClass:"topWrapper"},[_c('div',{staticClass:"logoWrapper"},[_c('router-link',{attrs:{"to":"/membership-area/home"}},[_c('div',{staticClass:"logoMobile"},[_c('img',{attrs:{"src":_vm.$store.getters.mainLogos.mobile}})])])],1),_c('span',{staticClass:"mobileNavigationButton",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('span'),_c('span'),_c('span'),_c('span')])]),_c('div',{staticClass:"itemsWrapper"},[_c('router-link',{staticClass:"home navItem",attrs:{"to":"/membership-area/home"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"homeMobileIcon icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'home-icon.svg'),"alt":"Home icon"}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.home =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.home)+" ")])]),_c('router-link',{staticClass:"messages navItem",attrs:{"to":"/membership-area/messages"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"messageMobileIcon icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'envelope-fixed-white.svg'),"alt":"Home icon"}}),(this.$store.getters.chatNewMessages > 0 && this.$store.getters.chatNewMessages < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(this.$store.getters.chatNewMessages))]):(this.$store.getters.chatNewMessages >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.messages =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.messages)+" ")])]),_c('router-link',{staticClass:"matches navItem",attrs:{"to":"/membership-area/matches"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"matchesMobileIcon icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'puzzle_white-icon.svg'),"alt":"Matches icon"}}),(_vm.$store.getters.matchesQuantity > 0 && _vm.$store.getters.matchesQuantity < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.$store.getters.matchesQuantity))]):(_vm.$store.getters.matchesQuantity >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerMatches navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.matches =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.matches)+" ")])]),_c('router-link',{staticClass:"visitors navItem",attrs:{"to":"/membership-area/visitors"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"visitorsMobileIcon icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'eye-white-icon.svg'),"alt":"Visitors icon"}}),(_vm.visitorsQuantity > 0 && _vm.visitorsQuantity < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.visitorsQuantity))]):(_vm.visitorsQuantity >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerVisitors navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.visitors =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.visitors)+" ")])]),(this.$store.getters.userRole.includes('ROLE_PREMIUM') && !_vm.totalMeetings == 0)?_c('router-link',{staticClass:"meetings navItem",attrs:{"to":"/membership-area/meetings"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'heart-white-icon.svg'),"alt":"Meetings icon"}}),(_vm.totalMeetings > 0 && _vm.totalMeetings < 100)?_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.totalMeetings))]):(_vm.totalMeetings >= 100)?_c('div',{staticClass:"number bigNum"},[_vm._v("99+")]):_vm._e()]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerHaveYouMet navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.membershipHeader.haveYouMet =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.haveYouMet)+" ")])]):_vm._e(),_c('router-link',{staticClass:"profile navItem",attrs:{"to":"/membership-area/user-profile/profile"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'profile-icon.svg'),"alt":"Personality icon"}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.profile =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.profile)+" ")])]),_c('router-link',{staticClass:"preferences navItem",attrs:{"to":"/membership-area/matches-preferences"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'preferences-icon.svg'),"alt":"Personality icon"}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.membershipHeader.preferences =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader .preferences)+" ")])]),_c('router-link',{staticClass:"account navItem",attrs:{"to":"/membership-area/my-account"}},[_c('div',{staticClass:"navItem-icon",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'myaccount-icon.svg'),"alt":"Personality icon"}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"click":function($event){return _vm.showMobileMenu()},"blur":function($event){_vm.$t(
                  _vm.$i18n.locale
                ).keys.membershipArea.membershipHeader.myAccount =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.myAccount)+" ")])]),_c('router-link',{staticClass:"logout navItem",attrs:{"to":"/logout"}},[_c('div',{staticClass:"navItem-icon"},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'logout-icon.svg'),"alt":"Personality icon"}})]),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"navItem-text",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.logout =
                  $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.logout))])]),(false)?_c('Footer'):_vm._e(),_c('div',{staticClass:"memberPhotoWrapper"},[_c('div',{staticClass:"memberPhoto"})])],1)]):_vm._e()],1),(_vm.success)?_c('div',{staticClass:"successMessage"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Check-white.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.changeSavedSuccessfully =
            $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.changeSavedSuccessfully))])]):_vm._e(),(!this.$store.getters.userRole.includes('ROLE_PREMIUM') && !_vm.premium)?_c('div',{staticClass:"premiumButtonWrapper"},[_c('router-link',{staticClass:"premiumButton",attrs:{"to":"/premium-plans/plans?direct=1"}},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.become =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.become)+" ")]),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerPremiumMember",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.membershipHeader.premiumMember =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.premiumMember)+" ")])])],1):_vm._e(),(!_vm.premium)?_c('div',{attrs:{"id":"tour"}},[_c('b-modal',{attrs:{"id":"tourModal","hide-footer":"","hide-header":""},model:{value:(_vm.modalTourShow),callback:function ($$v) {_vm.modalTourShow=$$v},expression:"modalTourShow"}},[_c('div',{staticClass:"exit-button",on:{"click":function($event){_vm.modalTourShow = false}}},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Close-cross-White.svg'),"alt":"Exit icon"}})]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('iframe',{attrs:{"width":"1420","height":"1315","src":"https://www.youtube.com/embed/dQw4w9WgXcQ"}})])])],1):_vm._e(),(!_vm.premium)?_c('div',{attrs:{"id":"coaching"}},[_c('b-modal',{attrs:{"id":"coachingModal","hide-footer":"","hide-header":""},model:{value:(_vm.modalCoachingShow),callback:function ($$v) {_vm.modalCoachingShow=$$v},expression:"modalCoachingShow"}},[_c('div',{staticClass:"exit-button",on:{"click":function($event){_vm.modalCoachingShow = false}}},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Close-cross-Grey.svg'),"alt":"Exit icon"}})]),_c('div',{staticClass:"exit-button-mobile",on:{"click":function($event){_vm.modalCoachingShow = false}}},[_c('img',{attrs:{"src":_vm.$store.getters.mainLogos.desktop,"alt":"Logo"}}),_c('span',{staticClass:"mobileNavigationButton",on:{"click":function($event){return _vm.showMobileMenu()}}},[_c('span'),_c('span'),_c('span'),_c('span')])]),_c('Coaching')],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }