import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
    async sendImages({
        commit
    }, data) {

        try {
            const response = await axios.post(BASE_URL + '/api/picture/upload', data, handlerEnabled);
            commit('checkErrors', response.data.errors);
            if(response.data.data != null){
                commit('setImagesChanges', response.data.data);
            }
            return response;
        } catch (res) {
            return 'error';
        }
    },
   
    sendImages2({getters}, data){

        return new Promise((resolve, reject) => {
            let images = getters.getImageInfo;
            var count = 0;
            images.forEach(async (item, index) => {
                
                let key = {
                    key: item.key
                }
                const response = await axios.put(item.url, data[index].buffer, data[index].options);
                if(response.status == 200 ) {
                    await axios.post(BASE_URL + '/api/picture/create', key ).then(() => {
                        if(count == images.length-1) resolve(response);
                        else count++;
                    });
                }else{
                    reject(response);
                }
            });
        });
    },
    async fetchImages({
        commit
    }) {
        try {
            const images = await axios.get(
                BASE_URL + '/api/profile/gallery',handlerEnabled
            );
            commit('setImages', images.data.data.photos);
        } catch (res) {
            return 'error';
        }
    },
    async deleteImage({commit}, pictureid){
        try{
            await axios.delete(BASE_URL + `/api/picture/delete/${pictureid}`,handlerEnabled);
            commit('removeImage', pictureid);
        } catch (res) {
            return 'error';
        }
    },
    async chooseMainImage({commit}, pictureid){
        try {
            await axios.put(BASE_URL + `/api/picture/update/${pictureid}`, handlerEnabled);
            commit('changeProfileCardPicture');
        } catch(res) {
            return 'error';
        }
    }
}