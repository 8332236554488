export default {
  setUserInterests(state, payload){
    state.userInterests = payload;
  },
  setUserPersonality(state, payload){
    state.userPersonality = payload;
  },
  updateInterestsAnswer(state){
    state.updatedInterests++;
  }

};
