<template>
  <div id="membershipMessages">
    <header class="messagesHeader">
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <p
        class="messagesTitle"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipMeetings.messages =
            $event.target.innerText
        "
      >{{ $t($i18n.locale).keys.membershipArea.membershipMessages.messages }}</p>
      <div class="messagesDataWrapper">
        <div
          class="messagesQuantity"
          v-if="this.$store.getters.conversations"
        >{{ this.$store.getters.conversations.length }}</div>
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope-fixed-green.svg')"
          alt="Messages icon"
        />
      </div>
    </header>
    <div class="messagesWrapper" v-if="this.$store.getters.getHomeMessages.length">
      <div
        v-for="message in $store.getters.getHomeMessages"
        :key="message.id"
        class="singleMessageWrapper"
      >
        <div
          class="imageWrapper"
          :class="{
            onlineUser: message.online
          }"
          @click="
          $router.push(`/membership-area/messages/${message.id}`)
        "
        >
          <img v-if="message.public_path" :src="message.public_path" class="messageImage1" />
          <img
            v-if="!message.public_path && (parseInt(message.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="messageImage1"
          />
          <img
            v-if="!message.public_path && (parseInt(message.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="messageImage1"
          />
          <div class="imageOverlay" v-bind:class="[message.new == 1 ? 'new' : '']">
            <p
              class="newMessage"
              v-if="message.new == 1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipMeetings.new =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.membershipArea.membershipMessages.new }}</p>
          </div>
        </div>
        <div class="messagesInfoWrapper" @click="$router.push(`/membership-area/messages/${message.id}`)">
          <div class="wrapper">
            <p class="name">{{ message.firstName }}<span v-if="message.age">, {{ message.age }}</span></p>
            <!--
            <img
              v-if="parseInt(message.entertainment) == 1"
              src="../../assets/images/heart-green.svg"
              alt="Heart icon"
            />
            -->
          </div>
          <p class="messageTime">{{ formatDate(message.created_at) }}</p>
          <!--
          <p class="messageTime">{{ $d(new Date(message.created_at), 'long', locale) }}</p>
          -->
           <p
                    v-bind:class="{ newMessage: message.new == 1 }"
                    class="message"
                    v-if="!message.blurred && message.message"
                  >
                    {{ message.message }}
                  </p>
                  <router-link to="/premium-plans" v-else class="premiumLink" @click.native="buttonClicked">
                    {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
                  </router-link>

        </div>
      </div>
      <div class="membershipButtonWrapper" v-if="this.$store.getters.conversations">
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          src="../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
        <router-link to="/membership-area/messages">
          <button
            class="membershipButton"
          >{{ $t($i18n.locale).keys.membershipArea.membershipMessages.seeAll }}</button>
        </router-link>
        <input
          v-if="editable"
          v-model="$t($i18n.locale).keys.membershipArea.membershipMessages.seeAll"
          type="text"
        />
      </div>
    </div>
    <div class="noMessagesWrapper" v-if="!this.$store.getters.getHomeMessages.length">
      <div class="contentWrapper">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'no-messages-icon.svg')" alt="No meetings icon">
        <p class="noMessagesText">
          <span
          class="text1"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipMessages.noMessage1 =
                $event.target.innerText
            "
          >{{$t($i18n.locale).keys.membershipArea.membershipMessages.noMessage1}}</span><br>
          <span
          class="text2"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipMessages.noMessage2 =
                $event.target.innerText
            "
          >{{$t($i18n.locale).keys.membershipArea.membershipMessages.noMessage2}}</span>
        </p>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formattedDate from "../../helpers/dateFormatter";
export default {
  name: "MembershipMessages",
  components: {},
  data() {
    return {
      url: process.env.VUE_APP_URL,
      locale: this.$store.getters.selectedLanguage,
       };
  },
  mounted() {
    this.messageLenght();
    this.$store.dispatch("getConversations");
  },
  computed: {
    ...mapGetters(["editable"])
  },
  methods: {
    messageLenght() {
      if (window.innerWidth < 768) {
        this.$store.getters.getHomeMessages.forEach(function(value) {
          if (value.message.length > 13) {
            value.message = value.message.slice(0, 13) + "...";
          }
        });
      }
    },
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage);
    },
    buttonClicked(){
       this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Read Message Attempt',
          label: 'Home'
        });
    },
  }
};
</script>

<style lang="scss" scoped></style>
