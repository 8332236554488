import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {

  async fetchClientSetttings({
    commit
  }) {

    try {
      const response = await axios.get(
        BASE_URL + '/api/client/settings/', handlerEnabled
      );
      const data = response.data.data;
      console.log(data, 'my account')
      commit('setClientSettings', data);

    } catch (error) {
      return 'error';
    }
  },
  async updateEmail({commit, dispatch}, changedEmail){
    try {
      const response = await axios.put( BASE_URL + '/api/client/settings/update_email', changedEmail, handlerEnabled);
      console.log(response);
      if(response.status == 226) {
        commit('setTheSameEmail');
      } else {
        localStorage.setItem('token', response.data.data.token);
        commit('emailChanged');
        dispatch('showSuccessStatusMessage');
        console.log('success')
      }
      return response.data;
    } catch (error){
      return 'error';
    }
  },
  async updatePassword({commit, dispatch}, changedPassword){
    try {
      const response = await axios.put( BASE_URL + '/api/client/settings/update_password', changedPassword, handlerEnabled);
      if(response.data.errors == null){
        dispatch('showSuccessStatusMessage');
      }else{
        commit('setEmailErrors', response.data.errors.errors);
      }
        

    } catch (error) {
      return 'error'
    }
  },
  async updateAddress({commit, dispatch}, changedAddress){
    try {
      const response = await axios.put( BASE_URL + '/api/client/settings/update_address', changedAddress, handlerEnabled);
      if(response.data.errors == null){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async updateMatchingEnabled({commit, dispatch}, payload){
    try{
      const response = await axios.put( BASE_URL + '/api/client/settings/update_matching',payload, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async updateMatches({commit, dispatch}, payload){
    try{
      const response = await axios.put( BASE_URL + '/api/client/settings/update_notification_matches',payload, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async updateVisits({commit, dispatch}, payload){
    try{
      const response = await axios.put( BASE_URL + '/api/client/settings/update_notification_visits',payload, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async updateMessages({commit, dispatch}, payload) {
    try{
      const response = await axios.put( BASE_URL + '/api/client/settings/update_notification_messages', payload, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async updateMeetings({commit, dispatch}, payload) {
    console.log(payload, 'payload')
    try{
      const response = await axios.put( BASE_URL + '/api/client/settings/update_notification_meetings', payload, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      console.log(commit);
    } catch(error) {
      return 'error';
    }
  },
  async getDeletionReasons({commit}){
    try {
      const response = await axios.get( BASE_URL + '/api/client/settings/get_reasons');
      console.log(response.data.data, 'reasons');
      commit('setDeletionReasons', response.data.data)
    } catch(error){
      return 'error'
    }
  },
  async deleteAccount({commit}, payload){
    try {
      const response = await axios.put( BASE_URL + '/api/client/settings/delete_account', payload, handlerEnabled);
      if(response.data.errors){
        commit('setAccountDeletionError', response.data.errors.errors.password);
       }else{
        commit('setAccountDeletionError', '');
       }
      // console.log(response, 'deletionResponse')
    } catch(error) {
      console.log('deleteAccount catch error', error)
      commit('setAccountDeletionError', '');
      return 'error';
    }
  },
  showSuccessStatusMessage({commit}){
    commit('showSuccessMessage');
  }


};