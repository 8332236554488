<template>
  <div id="mainPanel" :class="[$store.getters.userRole.includes('ROLE_PREMIUM') ? 'isPremium' : 'notPremium']">
      <MembershipHeader />
    <div id="topPanel">
      <Loader v-if="$store.getters.getShowLoader" />
      <router-view v-if="$store.getters.getShowError == false" :style="[$store.getters.getShowLoader ? {'display': 'none'} : {'display': 'block'}]" />
      <Error v-if="$store.getters.getShowError" />
    </div>
    <MembershipFooter v-if="showFooter" />
  </div>
</template>

<script>
import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";
import MembershipFooter from "@/components/membership-area/MembershipFooter.vue";
import Loader from "@/components/generic/Loader.vue";
import Error from "@/components/generic/Error.vue";


export default {
  name: "MainPanel",
  components: {
    MembershipHeader,
    MembershipFooter,
    Error,
    Loader
  },
  data() {
    return {
      showFooter: true
    };
  },

  methods: {
    scrollFunction(){
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  },
  mounted() {

    // this.$store.dispatch("getConversations");
    // this.$store.dispatch('showLoader');
    // this.$store.dispatch('setErrorVisibility', false);
    // this.$store.dispatch('hideContent');
    this.scrollFunction();
    this.$store.dispatch("getNewMessagesNumber").then((res) => {
      this.$store.dispatch('addActionCount');
      if(this.$store.getters.getChatActionsCount == 2){
        //this.$store.dispatch('hideLoader');
      }
        if (res == "error") {
          // show error
          if(this.$store.getters.getChatActionsCount == 2){
            ////this.$store.dispatch('setErrorVisibility', true);
          }
        } else{
          if(this.$store.getters.getChatActionsCount == 2){
            this.$store.dispatch('showContent');
          }
        }
    })
    /*
    this.$store.dispatch("getMeetings").then((res) => {
      this.$store.dispatch("addMeetingsCount");
      if(this.$store.getters.getMeetingsActionsCount == 2){
        //this.$store.dispatch('hideLoader');
      }
        if(res == 'error'){
          if(this.$store.getters.getMeetingsActionsCount == 2){
            ////this.$store.dispatch('setErrorVisibility', true);
          }
        } else {
          if(this.$store.getters.getMeetingsActionsCount == 2){
            this.$store.dispatch('showContent');
            this.$store.dispatch("getConversations");
          }
        }
    })
    */



  },
  updated() {
    if(this.$route.path == '/membership-area/home' && this.$store.getters.isMobile ){
      this.showFooter = true;
    }else if (this.$store.getters.isMobile) {
      this.showFooter = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
