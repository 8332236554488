export default {
    setAgeAndHeight: (state, ageAndHeight) => state.ageAndHeight = ageAndHeight,
    setDistance: (state, distance) => state.distance = distance,
    setDesireToHaveChildren: (state, desireToHaveChildren) => state.desireToHaveChildren = desireToHaveChildren,
    setEducationAndIncome: (state, educationAndIncome) => state.educationAndIncome = educationAndIncome,
    setSmokingAndDrinkingHabits: (state, smokingAndDrinkingHabits) => state.smokingAndDrinkingHabits = smokingAndDrinkingHabits,
    ethnicityAndReligion: (state, ethnicityAndReligion) => state.ethnicityAndReligion = ethnicityAndReligion,
    updateAgeAndHeight: (state, ageAndHeight) => state.ageAndHeight = ageAndHeight,
    updateDistance: (state, distance) => state.distance = distance,
    updateDesireToHaveChildren: (state, desireToHaveChildren) => state.desireToHaveChildren = desireToHaveChildren,
    updateEducationAndIncome: (state, educationAndIncome) => state.educationAndIncome = educationAndIncome,
    updateSmokingAndDrinkingHabits: (state, smokingAndDrinkingHabits) => state.smokingAndDrinkingHabits = smokingAndDrinkingHabits,
    updateEthnicityAndReligion: (state, ethnicityAndReligion) => state.ethnicityAndReligion = ethnicityAndReligion,

};