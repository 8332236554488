<template>
  <footer
    id="membershipFooter"
    :class="{payment: payment}"
  >
    <div class="links">
      <a
        class="imprint"
        @click="modalImprintShow = !modalImprintShow"
        v-langErr
        :contenteditable="editable"
        @blur="$t($i18n.locale).keys.footer.imprint = $event.target.innerText"
        >{{ $t($i18n.locale).keys.footer.imprint }}</a
      >
      <a
        class="privacy"
        @click="modalPrivacyShow = !modalPrivacyShow"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.privacyPolicy = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.privacyPolicy }}</a
      >
      <a
        class="terms"
        @click="modalTermsShow = !modalTermsShow"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.termsConditions = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.termsConditions }}</a
      >
      <a
        :href="supportLink"
        @click.prevent="openContactForm"
        target="_blank"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.helpSupport = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.helpSupport }}</a
      >
    </div>
    <div class="address_block">
        <span>Dating Lions GmbH</span>
        <span>Anton-Melzer-Str. 7</span>
        <span>6020 Innsbruck</span>
        <span>AUSTRIA</span>
    </div>

    <b-modal id="termsModal" v-model="modalTermsShow" hide-footer hide-header>
      <div class="exit-button-terms" @click="modalTermsShow = false">
        <img src="../../assets/images/Close-cross-Grey.svg" alt="Exit icon" />
      </div>
      <div class="termsWrapper">
        <div
          v-html="termsAndPolicies_.termsOfService"
        ></div>
      </div>
    </b-modal>

    <b-modal
      id="policyModal"
      v-model="modalPrivacyShow"
      hide-footer
      hide-header
    >
      <div class="exit-button-policy" @click="modalPrivacyShow = false">
        <img src="../../assets/images/Close-cross-Grey.svg" alt="Exit icon" />
      </div>
      <div class="policyWrapper">
        <div
          v-html="termsAndPolicies_.privacyPolicy"
        ></div>
      </div>
    </b-modal>
    <b-modal
      id="imprintModal"
      v-model="modalImprintShow"
      hide-footer
      hide-header
    >
      <div class="exit-button-policy" @click="modalImprintShow = false">
        <img src="../../assets/images/Close-cross-Grey.svg" alt="Exit icon" />
      </div>
      <div class="imprintWrapper">
        <div v-html="termsAndPolicies_.imprint"></div>
      </div>
    </b-modal>
  </footer>
</template>

<script>

export default {
  name: "MembershipFooter",
  data() {
    return {
      modalTermsShow: false,
      modalPrivacyShow: false,
      modalImprintShow: false,
      termsAndPolicies_: this.$store.getters.globalTermsAndPolicies,
    };
  },
  props: {
    payment: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    //Footer pushdown
    let footerEl = document.getElementById('membershipFooter')
    footerEl.style.bottom = `-${footerEl.offsetHeight}px`
    
    console.log('FOOTER', this.$i18n.locale)

    //Load language specific contact form
    var LA_interval = setInterval(() => {

      try {
      if(typeof LiveAgent != "undefined"){
        if(this.selectedLanguage == 'de'){
          contactButton = LiveAgent.createButton('q2dm1s7e', LA_event);
        }
        else if( this.selectedLanguage == 'cz'){
          contactButton = LiveAgent.createButton('8fdq42xw', LA_event);
        }
        else if( this.selectedLanguage == 'pl'){
          contactButton = LiveAgent.createButton('1jh8x2t9', LA_event);
        }
        else if( this.selectedLanguage == 'fr'){
          contactButton = LiveAgent.createButton('he1h6bq5', LA_event);
        }
        else {
          contactButton = LiveAgent.createButton('7rubyw9g', LA_event);
        }
        //PRE-SET FORM DATA
        LiveAgent.setUserDetails(this.$store.getters.user.email, this.$store.getters.user.username);
        LiveAgent.addTicketField('country_code', this.$store.getters.getCountry);
        LiveAgent.addTicketField('product', this.$store.getters.productName);
        LiveAgent.addTicketField('language', this.lang);
        LiveAgent.addTicketField('user_id', this.$store.getters.user.user_id);
        clearInterval(LA_interval);
      }
    }catch(error){
      console.log('LA_interval', error)
    }
    }, 500);
    setTimeout(()=>{clearInterval(LA_interval);}, 10000);
  },
  computed: {
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    editable: function() {
      return this.$store.getters.editable;
    },
    supportLink: function () {

      return 'mailto:' + this.$store.getters.supportEmail;
      
    },
   
  },
  methods: {
    openContactForm(){
        try {

        contactButton.onClick()
        }catch{
        window.location.href = this.supportLink;
        }
        
    }
  },
  
};
</script>

<style lang="scss" scoped>
.extraMargin {
  margin-bottom: 30px;
}
</style>
