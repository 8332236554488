
import axios from "axios";
import store from "../../../store";

const BASE_URL = process.env.VUE_APP_URL;

const handlerEnabled= {timeout: false};

export default {

     updateSupportEmail({commit}) {

        var supportEmail = '';

        if(host == 'loveover50s' ){

            if(domain == 'nz') supportEmail = 'support-nz@loveover50s.com';
            else if(domain == 'za') supportEmail = 'support-za@loveover50s.com';
            else if(domain == 'au') supportEmail = 'support-au@loveover50s.com';
            else if(domain == 'uk') supportEmail = 'support-uk@loveover50s.com';
            else supportEmail = 'support-uk@loveover50s.com';

            commit('set_privacyEmail', "privacy@loveover50s.com"); 


        }else if(host == 'liebeuber50'){

            if(domain == 'de') supportEmail = 'kundenservice-de@liebeuber50.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeuber50.com';
            else if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeuber50.com';
            else supportEmail = 'kundenservice-de@liebeuber50.com';

            commit('set_privacyEmail', "privacy@liebeuber50.com");


        }else if(host == '60slove'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@60slove.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@60slove.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@60slove.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@60slove.com';
            else if(domain == 'uk') supportEmail = 'support-uk@60slove.com';
            else if(domain == 'au') supportEmail = 'support-au@60slove.com';
            else if(domain == 'nz') supportEmail = 'support-nz@60slove.com';
            else if(domain == 'za') supportEmail = 'support-za@60slove.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@60slove.com';
            else if(domain == 'es') supportEmail = 'support@60slove.com';
            else supportEmail = 'support@60slove.com';

            commit('set_privacyEmail', "privacy@60slove.com");

        }else if(host == '40smeet'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@40smeet.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@40smeet.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@40smeet.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@40smeet.com';
            else if(domain == 'uk') supportEmail = 'support-uk@40smeet.com';
            else if(domain == 'au') supportEmail = 'support-au@40smeet.com';
            else if(domain == 'nz') supportEmail = 'support-nz@40smeet.com';
            else if(domain == 'za') supportEmail = 'support-za@40smeet.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@40smeet.com';
            else if(domain == 'es') supportEmail = 'support-es@40smeet.com';
            else supportEmail = 'kundenservice@40smeet.com';

            commit('set_privacyEmail', "privacy@40smeet.com");

        }else if(host == 'our50s'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@our50s.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@our50s.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@our50s.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@our50s.com';
            else if(domain == 'uk') supportEmail = 'support-uk@our50s.com';
            else if(domain == 'au') supportEmail = 'support-au@our50s.com';
            else if(domain == 'nz') supportEmail = 'support-nz@our50s.com';
            else if(domain == 'za') supportEmail = 'support-za@our50s.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@our50s.com';
            else if(domain == 'es') supportEmail = 'support-es@our50s.com';
            else supportEmail = 'support@our50s.com';

            commit('set_privacyEmail', "privacy@our50s.com");

        }else if(host == 'loveat50'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@loveat50.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@loveat50.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@loveat50.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@loveat50.com';
            else if(domain == 'uk') supportEmail = 'support-uk@loveat50.com';
            else if(domain == 'au') supportEmail = 'support-au@loveat50.com';
            else if(domain == 'nz') supportEmail = 'support-nz@loveat50.com';
            else if(domain == 'za') supportEmail = 'support-za@loveat50.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@loveat50.com';
            else if(domain == 'es') supportEmail = 'support-es@loveat50.com';
            else supportEmail = 'support@loveat50.com';

            commit('set_privacyEmail', "privacy@loveat50.com");

        }else if(host == 'xn--liebe50-r2a'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeü50.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeü50.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeü50.com';
            else supportEmail = 'kundenservice@liebeü50.com';

            commit('set_privacyEmail', "privacy@liebeü50.com");

        }else if(host == '40slove'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@40slove.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@40slove.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@40slove.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@40slove.com';
            else if(domain == 'uk') supportEmail = 'support-uk@40slove.com';
            else if(domain == 'au') supportEmail = 'support-au@40slove.com';
            else if(domain == 'nz') supportEmail = 'support-nz@40slove.com';
            else if(domain == 'za') supportEmail = 'support-za@40slove.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@40slove.com';
            else if(domain == 'es') supportEmail = 'support-es@40slove.com';
            else supportEmail = 'kundenservice@40slove.com';

            commit('set_privacyEmail', "privacy@40slove.com");

        }else if(host == 'xn--liebe40-r2a'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeü40.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeü40.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeü40.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@liebeü40.com';
            else if(domain == 'uk') supportEmail = 'support-uk@liebeü40.com';
            else if(domain == 'au') supportEmail = 'support-au@liebeü40.com';
            else if(domain == 'nz') supportEmail = 'support-nz@liebeü40.com';
            else if(domain == 'za') supportEmail = 'support-za@liebeü40.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@liebeü40.com';
            else if(domain == 'es') supportEmail = 'support-es@liebeü40.com';
            else supportEmail = 'kundenservice@liebeü40.com';

            commit('set_privacyEmail', "privacy@liebeü40.com");

        }else if(host == 'liebeab40'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeab40.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeab40.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeab40.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@liebeab40.com';
            else if(domain == 'uk') supportEmail = 'support-uk@liebeab40.com';
            else if(domain == 'au') supportEmail = 'support-au@liebeab40.com';
            else if(domain == 'nz') supportEmail = 'support-nz@liebeab40.com';
            else if(domain == 'za') supportEmail = 'support-za@liebeab40.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@liebeab40.com';
            else if(domain == 'es') supportEmail = 'support-es@liebeab40.com';
            else supportEmail = 'kundenservice@liebeab40.com';

            commit('set_privacyEmail', "privacy@liebeab40.com");

        }else if(host == 'loveat40'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@loveat40.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@loveat40.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@loveat40.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@loveat40.com';
            else if(domain == 'uk') supportEmail = 'support-uk@loveat40.com';
            else if(domain == 'au') supportEmail = 'support-au@loveat40.com';
            else if(domain == 'nz') supportEmail = 'support-nz@loveat40.com';
            else if(domain == 'za') supportEmail = 'support-za@loveat40.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@loveat40.com';
            else if(domain == 'es') supportEmail = 'support-es@loveat40.com';
            else supportEmail = 'kundenservice@loveat40.com';

            commit('set_privacyEmail', "privacy@loveat40.com");

        }else if(host == 'xn--liebeber40-eeb'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeüber40.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeüber40.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeüber40.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@liebeüber40.com';
            else if(domain == 'uk') supportEmail = 'support-uk@liebeüber40.com';
            else if(domain == 'au') supportEmail = 'support-au@liebeüber40.com';
            else if(domain == 'nz') supportEmail = 'support-nz@liebeüber40.com';
            else if(domain == 'za') supportEmail = 'support-za@liebeüber40.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@liebeüber40.com';
            else supportEmail = 'support@liebeüber40.com';

            commit('set_privacyEmail', "privacy@liebeüber40.com");

        }else if(host == 'xn--liebeber50-eeb'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeüber50.net';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeüber50.net';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeüber50.net';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@liebeüber50.net';
            else if(domain == 'uk') supportEmail = 'support-uk@liebeüber50.net';
            else if(domain == 'au') supportEmail = 'support-au@liebeüber50.net';
            else if(domain == 'nz') supportEmail = 'support-nz@liebeüber50.net';
            else if(domain == 'za') supportEmail = 'support-za@liebeüber50.net';
            else if(domain == 'pl') supportEmail = 'serwisklienta@liebeüber50.net';
            else supportEmail = 'support@liebeüber50.net';

            commit('set_privacyEmail', "privacy@liebeüber50.net");

        }else if(host == 'xn--liebeber60-eeb'){

            if(domain == 'ch') supportEmail = 'kundenservice-ch@liebeüber60.com';
            else if(domain == 'at') supportEmail = 'kundenservice-at@liebeüber60.com';
            else if(domain == 'de') supportEmail = 'kundenservice-de@liebeüber60.com';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@liebeüber60.com';
            else if(domain == 'uk') supportEmail = 'support-uk@liebeüber60.com';
            else if(domain == 'au') supportEmail = 'support-au@liebeüber60.com';
            else if(domain == 'nz') supportEmail = 'support-nz@liebeüber60.com';
            else if(domain == 'za') supportEmail = 'support-za@liebeüber60.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@liebeüber60.com';
            else supportEmail = 'support@liebeüber60.com';

            commit('set_privacyEmail', "privacy@liebeüber60.com");

        }else if(host == 'amourplusde40'){

            if(domain == 'ch') supportEmail = 'serviceclient-ch@amourplusde40.com';
            else supportEmail = 'serviceclient@amourplusde40.com';

            commit('set_privacyEmail', "privacy@amourplusde40.com");

        }else if(host == 'amourplusde50'){

            if(domain == 'ch') supportEmail = 'serviceclient-ch@amourplusde50.com';
            else supportEmail = 'serviceclient@amourplusde50.com';

            commit('set_privacyEmail', "privacy@amourplusde50.com");

        }else if(host == 'amourplusde60'){

            if(domain == 'ch') supportEmail = 'serviceclient-ch@amourplusde60.com';
            else supportEmail = 'serviceclient@amourplusde60.com';

            commit('set_privacyEmail', "privacy@amourplusde60.com");

        }else{

            if(domain == 'ch') supportEmail = 'kundenservice@50slove.ch';
            else if(domain == 'at') supportEmail = 'kundenservice@50slove.at';
            else if(domain == 'de') supportEmail = 'kundenservice@50slove.de';
            else if(domain == 'cz') supportEmail = 'zakaznickapodpora@50slove.cz';
            else if(domain == 'uk') supportEmail = 'support-uk@50slove.com';
            else if(domain == 'au') supportEmail = 'support-au@50slove.com';
            else if(domain == 'nz') supportEmail = 'support-nz@50slove.com';
            else if(domain == 'za') supportEmail = 'support-za@50slove.com';
            else if(domain == 'pl') supportEmail = 'serwisklienta@50slove.com';
            else if(domain == 'es') supportEmail = 'support@50slove.com';
            else supportEmail = 'support@50slove.com';

            commit('set_privacyEmail', "privacy@50slove.com");

        }

        commit('set_supportEmail', supportEmail);
    },

    updateAlerts({commit}, message){

        var alert = {
            showAlert: true,
            alertMessage: message
        }

        commit('set_alerts', alert);
    },

    async fetchSiteConfig({commit}){
        const response = await axios.get(BASE_URL + `/api/config/site`, handlerEnabled);
        console.log('fetchSiteConfig', response);
        if(response.data.data.welcomeMessageSender) commit('set_welcomeMessageSender', response.data.data.welcomeMessageSender);
        if(response.data.data.id) commit('set_siteId', response.data.data.id);
        return true;

    },
    
    async fetchUserConfig({commit}){
        const response = await axios.get(BASE_URL + `/api/config/user`, handlerEnabled);
        console.log('fetchUserConfig', response)
        console.log('AUTH user', store.getters.getAuthUser.user_id)

        if(!response.data.data.user_id) response.data.data.user_id = store.getters.getAuthUser.user_id;
        
        commit('set_user', response.data.data);
  
        return true;
  
    },

    loadTerms({commit, dispatch, getters}, data){

        console.log('loadTerms', data )

        var termsCountry;
        if(!data.country) {
            termsCountry = domain.toUpperCase();
            if(domain == 'com') termsCountry = 'US';
        }else {
            termsCountry = data.country.toUpperCase();
        }

        var langSelect = data.lang + '_' + termsCountry;
        console.log('loadTermsJSON lang', langSelect)
        
        var termsURL = `https://cdn.aisgmbh.at/tos/json/prod/termsAndPolicies_${langSelect}.json`;
        if(getters.isDEV) termsURL = `https://cdn.aisgmbh.at/tos/json/dev/termsAndPolicies_${langSelect}.json`;


        axios.get(termsURL).then((response) => {
            console.log('loadTermsJSON', response.data);

            var terms = response.data;
            terms = Object.replaceAll(terms, '%PRODUCTNAME%', getters.productName, false)
            terms = Object.replaceAll(terms, '%PRIVACYEMAIL%', getters.privacyEmail, false)
            terms = Object.replaceAll(terms, '%SUPPORTEMAIL%', getters.supportEmail, false)
            
            terms = Object.replaceAll(terms, '%PRODUCTURL%', getters.productURL, false)
            commit('set_globalTermsAndPolicies', terms)
            
        }).catch(error => {
            console.log('loadTermsJSON error', error, data.vm.$gtm.enabled())

            //Track missing Terms in Analytics/GTM 
            var GTM_event = function() { return data.vm.$gtm.trackEvent({
                                event: 'TermsError',
                                category: 'Errors',
                                action: 'Terms not found',
                                label: `Terms ${langSelect} failed to load - ${error.response.status}`
                                });
                            }
            
            if(!data.vm.$gtm.enabled()) {
                setTimeout(()=>{
                console.log('loadTermsJSON timeout', error, data.vm.$gtm.enabled())
                GTM_event()
                },200) 
            }else{
                GTM_event()
            }
            
            if(data.retry) return false;

            //Fallback to domain-specific defaults
            switch(domain) {
                case 'de': dispatch('loadTerms', {lang:'de', country:'DE', vm:data.vm, retry: true})
                break;
                case 'at': dispatch('loadTerms', {lang:'de', country:'AT', vm:data.vm, retry: true})
                break;
                case 'ch': dispatch('loadTerms', {lang:'de', country:'CH', vm:data.vm, retry: true})
                break;
                case 'cz': dispatch('loadTerms', {lang:'cz', country:'CZ', vm:data.vm, retry: true})
                break;
                case 'pl': dispatch('loadTerms', {lang:'pl', country:'PL', vm:data.vm, retry: true})
                break;
                case 'uk': dispatch('loadTerms',  {lang:'en', country:'UK', vm:data.vm, retry: true})
                break;
                case 'za': dispatch('loadTerms',  {lang:'en', country:'ZA', vm:data.vm, retry: true})
                break;
                case 'au': dispatch('loadTerms',  {lang:'en', country:'AU', vm:data.vm, retry: true})
                break;
                case 'nz': dispatch('loadTerms',  {lang:'en', country:'NZ', vm:data.vm, retry: true})
                break;
                case 'com': dispatch('loadTerms',  {lang:'en', country:'US', vm:data.vm, retry: true})
                break;
            }

        })

        return false;

        // JS FILE LOADERS
        /*
        if(typeof lang == 'undefined') return false;
        if(domain == 'at') lang = 'at';
        else if(domain == 'ch') lang = 'ch';
        else if(domain == 'com' && (host == 'casualxdates' || host == 'illicitoasis')) lang = 'us';

        //let termsURL = `https://cdn.aisgmbh.at/tos/termsAndPolicies_${lang}.js`;
        
        dispatch('makeProductURL')
        axios.get(termsURL).then(response => {
            var terms = Function( `var termsAndPolicies_async = {};"use strict";return (${response.data});`)();
            terms = Object.replaceAll(terms, '%PRODUCTNAME%', getters.productName, false)
            terms = Object.replaceAll(terms, '%PRIVACYEMAIL%', getters.privacyEmail, false)
            terms = Object.replaceAll(terms, '%SUPPORTEMAIL%', getters.supportEmail, false)
            
            terms = Object.replaceAll(terms, '%PRODUCTURL%', getters.productURL, false)
            commit('set_globalTermsAndPolicies', terms)
            
        })
        */
    },
    makeProductURL({commit, getters}) {
        console.log('makeProductURL')
        var productURL = '';
        if(host == 'xn--liebe50-r2a') {
            productURL =  `www.liebeü50.${getters.getCountryDomainLanding}`;
        }else if(host == 'xn--liebe40-r2a'){
            productURL =  `www.liebeü40.${getters.getCountryDomainLanding}`;
        }else if(host == 'xn--liebeber40-eeb'){
            productURL =  `www.liebeüber40.${getters.getCountryDomainLanding}`;
        }else if(host == 'xn--liebeber50-eeb'){
            productURL =  `www.liebeüber50.${getters.getCountryDomainLanding}`;
        }else if(host == 'xn--liebeber60-eeb'){
            productURL =  `www.liebeüber60.${getters.getCountryDomainLanding}`;
        }else if(subdomain.indexOf('members-') != -1){
            productURL =  `${subdomain.replace('members-', '')}.${host}.${getters.getCountryDomainLanding}`;
        }
        else productURL =  'www.' + window.host +'.'+ getters.getCountryDomainLanding;

        commit('set_productURL', productURL);
      },

}