<template>
  <div id="questionNavigation" class="variant2">
    <div class="content_wrapper">
      <button class="prevButton" @click="goBack" :disabled="disablePrevButton">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        {{ $t($i18n.locale).keys.generic.backBtn }}
      </button>
      <div class="progressbar">
        <div
          class="bar"
          v-bind:style="{ width: progressData.percentage + '%' }"
        ></div>
      </div>
      <button class="nextButton" @click="sendAnswer" :disabled="nextDisabled">
        {{ $t($i18n.locale).keys.generic.continue }}
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </button>
    </div>

    <p
      v-langErr
      v-if="progressData.skippable == 1"
      @click="$emit('answerLater')"
      class="answerLaterMessage"
    >
      {{ $t($i18n.locale).keys.generic.answerLaterMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["progressData", "nextDisabled", "prevDisabled"],
  name: "QuestionNavigation",
  computed: {
    disablePrevButton: function () {
      if (this.prevDisabled || this.progressData.hideBack) return true;
      else if (this.progressData.currentQuestion == 1) return true;
      else return false;
    },
    disableNextButton: function () {
      if (this.nextDisabled || this.progressData.hideNext) return true;
      else if (
        this.progressData.currentQuestion == this.progressData.totalQuestions
      )
        return true;
      else return false;
    },
  },
  methods: {
    goBack() {
      this.scrollTop();
      this.$emit("goBack");
    },
    sendAnswer() {
      this.scrollTop();
      this.$emit("sendAnswer");
    },
    scrollTop() {
      const element = document.querySelector("#question-box-start");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
