<template>
  <div id="membershipOnlineBox">
    <header class="onlineBoxHeader">
      <p
        class="onlineBoxTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipOnlineBox.title =
            $event.target.innerText
        "
      >
        {{ $t($i18n.locale).keys.membershipArea.membershipOnlineBox.title }}
      </p>
      <div class="onlineBoxDataWrapper">
       
        <img
          src="../../assets/images/onlineUser_icon_green.svg"
          alt="icon"
        />
        
      </div>
    </header>

    <div class="onlineBoxImagesWrapper">
      <div
        @click="goToProfile(member.profile_id)"
        v-for="member in $store.getters.getHomeOnline.slice(0, this.visibleCount)"
        :key="member.id"
        class="image1Wrapper"
      >
        <img
          :src="`${member.public_path}`"
          class="matchesImage1"
          v-if="member.public_path"
        />
        <img
          src="../../assets/images/silhouette-man.svg"
          alt=""
          class="matchesImage1"
          v-if="!member.public_path && (parseInt(member.gender) == 1)"
        />
        <img
          src="../../assets/images/silhouette-woman.svg"
          alt=""
          class="matchesImage1"
          v-if="!member.public_path && (parseInt(member.gender) == 2)"
        />
        <div class="imageOverlay" v-bind:class="[member.new == 1 ? 'new' : '']">
          <p
            v-if="member.new == 1"
            class="newMessage"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipMatches.new =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipMatches.new }}
          </p>
          <div v-if="member.firstname" class="wrapper">
            <p class="matchInfo">
              <span class="matchName">{{ member.firstname }}</span>
              <span class="matchAge">, {{ member.age }}</span>
            </p>
            <!--
            <div class="insideWrapper">
              <img v-if="parseInt(match.entertainment) == 1" src="../../assets/images/heart-green.svg" alt="Heart icon">
              <p class="matchAgeLocation">, {{ match.city.length > 12 ? match.city.substring(0, 9).concat('...') : match.city }}</p>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="membershipButtonWrapper">
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <router-link to="/membership-area/online">
        <button class="membershipButton">
          {{
            $t($i18n.locale).keys.membershipArea.membershipOnlineBox.seeAllMembers
          }}
        </button>
      </router-link>
      <input
        v-if="editable"
        v-model="
          $t($i18n.locale).keys.membershipArea.membershipOnlineBox.seeAllMembers
        "
        type="text"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "MembershipOnlineBox",
  components: {},
  computed: {
    ...mapGetters(["editable","isMobile"]),
    visibleCount: function (){
      if(this.isMobile) return 4;
      else return 3;
    }
  },

  data() {
    return {
      publicPath: process.env.VUE_APP_URL
    };
  },
  methods: {
    goToProfile(profile_id) {
       if(this.lockRequests) return true;

        this.lockRequests = true;
        axios({ url: process.env.VUE_APP_URL + `/api/dashboard/view/${profile_id}`, method: "POST" })
          .then(() => {
            this.lockRequests = false;
            this.$router.push(`member-profile/profile?id=${profile_id}`);
          })
          .catch(() => {
            this.$store.dispatch('updateAlerts', this.$t(this.$i18n.locale+'.keys.generic.alerts.maxOnlineUsersReached'));
            this.lockRequests = false;
          });

    },
//$store.dispatch('updateAlerts', 'testmessage')
  }
};
</script>

<style lang="scss" scoped></style>
