<template>
  <div id="notifications" :key="componentKey" class="preferencesBox">
    <div class="notificationsWrapper preferencesContentWrapper" v-if="notifications.notification_matches">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.myAccount.notifications.notifications =$event.target.innerText"
        >{{$t($i18n.locale).keys.membershipArea.myAccount.notifications.notifications}}</h1>
      </div>
      <div class="mainWrapper">
        <div class="mainTextWrapper textWrapper">
          <h3
            class="mainText"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.membershipArea.myAccount.notifications.receiveNotifications =$event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.myAccount.notifications.receiveNotifications}}</h3>
        </div>
        <div class="filterWrapper">
          <div class="switchWrapper">
            <input
              v-model="matchesReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: isActive1 }"
              v-touch:start="changeMatchesReceivedFilterValue"
              v-touch:longtap="changeMatchesReceivedFilterValue"
              @change="changeMatchesReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_matches['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              v-model="profileVisitsSlider"
              class="switchInput"
              v-bind:class="{ active: isActive2 }"
              v-touch:start="changeProfileVisitsFilterValue"
              v-touch:longtap="changeProfileVisitsFilterValue"
              @change="changeProfileVisitsFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_visits['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              v-model="messagesReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: isActive3 }"
              v-touch:start="changeMessagesReceivedFilterValue"
              v-touch:longtap="changeMessagesReceivedFilterValue"
              @change="changeMessagesReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_messages['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              v-model="meetingsReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: isActive4 }"
              v-touch:start="changeMeetingsReceivedFilterValue"
              v-touch:longtap="changeMeetingsReceivedFilterValue"
              @change="changeMeetingsReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_meetings['text'] }}</p>
          </div>
          <!-- <div class="filterWrapper">
            <input
              v-model="photoRequestsAndProfilePicturesSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive5 }"
              @click="changePhotoRequestsAndProfilePicturesFilterValue(), photoRequestsAndProfilePicuresColouring()"
              @change="changePhotoRequestsAndProfilePicturesFilterValue(), photoRequestsAndProfilePicuresColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >Photo requests and profile pictures</p>
          </div>
          <div class="filterWrapper">
            <input
              v-model="likesReceivedSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive6 }"
              @click="changeLikesReceivedFilterValue(), likesReceivedColouring()"
              @change="changeLikesReceivedFilterValue(), likesReceivedColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >Likes received</p>
          </div>
          <div class="filterWrapper">
            <input
              v-model="commentsReceivedSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive7 }"
              @click="changeCommentsReceivedFilterValue(), commentsReceivedColouring()"
              @change="changeCommentsReceivedFilterValue(), commentsReceivedColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >Comments received</p>
          </div>
          <div class="filterWrapper">
            <input
              v-model="informationAndNewsletterSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive8 }"
              @click="changeInformationFilterValue(), informationColouring()"
              @change="changeInformationFilterValue(), informationColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >50sLove information and special offers</p>
          </div>
          <div class="filterWrapper">
            <input
              v-model="newsletterSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive9 }"
              @click="changeNewsletterFilterValue(), newsletterColouring()"
              @change="changeNewsletterFilterValue(), newsletterColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >50sLove newsletter</p>
          </div>
          <div class="filterWrapper">
            <input
              v-model="shopPromotionsSlider"
              class="filterSlider"
              v-bind:class="{ active: isActive10 }"
              @click="changeShopPromotionsFilterValue(), shopPromotionsColouring()"
              @change="changeShopPromotionsFilterValue(), shopPromotionsColouring()"
              type="range"
              min="0"
              max="1"
              value="1"
              step="1"
            />
            <p
              class="filterName"
            >Shop promotions</p>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Notifications",

  data() {
    return {
      componentKey: 0,
      matchesReceivedSlider: 0,
      isActive1: false,
      profileVisitsSlider: 0,
      isActive2: false,
      messagesReceivedSlider: 0,
      isActive3: false,
      meetingsReceivedSlider: 0,
      isActive4: false,
      notifications: []
      // photoRequestsAndProfilePicturesSlider: 0,
      // isActive5: false,
      // likesReceivedSlider: 0,
      // isActive6: false,
      // commentsReceivedSlider: 0,
      // isActive7: false,
      // informationAndNewsletterSlider: 0,
      // isActive8: false,
      // newsletterSlider: 0,
      // isActive9: false,
      // shopPromotionsSlider: 0,
      // isActive10: false
    };
  },
  computed: {
    ...mapGetters(["getClientSettings"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getClientSettings: function(newVal){
      if(!_.isEmpty(newVal)){
        this.setNotificationSettings();
      }
    }
  },
  mounted() {
  },
  methods: {
    setNotificationSettings() {
      this.notifications = this.$store.getters.getClientSettings.notifications;
      this.matchesReceivedSlider = this.notifications.notification_matches.value;
      this.matchesReceivedColouring();
      this.profileVisitsSlider = this.notifications.notification_visits.value;
      this.profileVisitsColouring();
      this.messagesReceivedSlider = this.notifications.notification_messages.value;
      this.messagesReceivedColouring();
      this.meetingsReceivedSlider = this.notifications.notification_meetings.value;
      this.meetingsReceivedColouring();
    },
    changeMatchesReceivedFilterValue() {
      if (this.matchesReceivedSlider == 0) {
        this.matchesReceivedSlider = 1;
      } else if (this.matchesReceivedSlider == 1) {
        this.matchesReceivedSlider = 0;
      }
      if (this.matchesReceivedSlider == 0) {
        this.isActive1 = false;
      } else if (this.matchesReceivedSlider == 1) {
        this.isActive1 = true;
      }
      setTimeout(() => {
        let value;
        if (this.matchesReceivedSlider == 0) {
          value = false;
        } else if (this.matchesReceivedSlider == 1) {
          value = true;
        }
        const matches = {
          value: value
        };
        this.$store.dispatch("updateMatches", matches);
      }, 1000);
    },
    matchesReceivedColouring() {
      if (this.matchesReceivedSlider == 0) {
        this.isActive1 = false;
      } else if (this.matchesReceivedSlider == 1) {
        this.isActive1 = true;
      }
    },
    changeProfileVisitsFilterValue() {
      if (this.profileVisitsSlider == 0) {
        this.profileVisitsSlider = 1;
      } else if (this.profileVisitsSlider == 1) {
        this.profileVisitsSlider = 0;
      }
      if (this.profileVisitsSlider == 0) {
        this.isActive2 = false;
      } else if (this.profileVisitsSlider == 1) {
        this.isActive2 = true;
      }
      setTimeout(() => {
        let value;
        if (this.profileVisitsSlider == 0) {
          value = false;
        } else if (this.profileVisitsSlider == 1) {
          value = true;
        }
        const visits = {
          value: value
        };
        this.$store.dispatch("updateVisits", visits);
      }, 1000);
    },
    profileVisitsColouring() {
      if (this.profileVisitsSlider == 0) {
        this.isActive2 = false;
      } else if (this.profileVisitsSlider == 1) {
        this.isActive2 = true;
      }
    },
    changeMessagesReceivedFilterValue() {
      if (this.messagesReceivedSlider == 0) {
        this.messagesReceivedSlider = 1;
      } else if (this.messagesReceivedSlider == 1) {
        this.messagesReceivedSlider = 0;
      }
      if (this.messagesReceivedSlider == 0) {
        this.isActive3 = false;
      } else if (this.messagesReceivedSlider == 1) {
        this.isActive3 = true;
      }
      setTimeout(() => {
        let value;
        if (this.messagesReceivedSlider == 0) {
          value = false;
        } else if (this.messagesReceivedSlider == 1) {
          value = true;
        }
        const messages = {
          value: value
        };
        this.$store.dispatch("updateMessages", messages);
      }, 1000);
    },
    messagesReceivedColouring() {
      if (this.messagesReceivedSlider == 0) {
        this.isActive3 = false;
      } else if (this.messagesReceivedSlider == 1) {
        this.isActive3 = true;
      }
    },
    changeMeetingsReceivedFilterValue() {
      if (this.meetingsReceivedSlider == 0) {
        this.meetingsReceivedSlider = 1;
      } else if (this.meetingsReceivedSlider == 1) {
        this.meetingsReceivedSlider = 0;
      }
      if (this.meetingsReceivedSlider == 0) {
        this.isActive4 = false;
      } else if (this.meetingsReceivedSlider == 1) {
        this.isActive4 = true;
      }
      setTimeout(() => {
        let value;
        if (this.meetingsReceivedSlider == 0) {
          value = false;
        } else if (this.meetingsReceivedSlider == 1) {
          value = true;
        }
        const meetings = {
          value: value
        };
        this.$store.dispatch("updateMeetings", meetings);
      }, 1000);
    },
    meetingsReceivedColouring() {
      if (this.meetingsReceivedSlider == 0) {
        this.isActive4 = false;
      } else if (this.meetingsReceivedSlider == 1) {
        this.isActive4 = true;
      }
      // },
      // changePhotoRequestsAndProfilePicturesFilterValue() {
      //   if (this.photoRequestsAndProfilePicturesSlider == 0) {
      //     this.photoRequestsAndProfilePicturesSlider = 1;
      //   } else if (this.photoRequestsAndProfilePicturesSlider == 1) {
      //     this.photoRequestsAndProfilePicturesSlider = 0;
      //   }
      // },
      // photoRequestsAndProfilePicuresColouring() {
      //   if (this.photoRequestsAndProfilePicturesSlider == 0) {
      //     this.isActive5 = false;
      //   } else if (this.photoRequestsAndProfilePicturesSlider == 1) {
      //     this.isActive5 = true;
      //   }
      // },
      // changeLikesReceivedFilterValue() {
      //   if (this.likesReceivedSlider == 0) {
      //     this.likesReceivedSlider = 1;
      //   } else if (this.likesReceivedSlider == 1) {
      //     this.likesReceivedSlider = 0;
      //   }
      // },
      // likesReceivedColouring() {
      //   if (this.likesReceivedSlider == 0) {
      //     this.isActive6 = false;
      //   } else if (this.likesReceivedSlider == 1) {
      //     this.isActive6 = true;
      //   }
      // },
      // changeCommentsReceivedFilterValue() {
      //   if (this.commentsReceivedSlider == 0) {
      //     this.commentsReceivedSlider = 1;
      //   } else if (this.commentsReceivedSlider == 1) {
      //     this.commentsReceivedSlider = 0;
      //   }
      // },
      // commentsReceivedColouring() {
      //   if (this.commentsReceivedSlider == 0) {
      //     this.isActive7 = false;
      //   } else if (this.commentsReceivedSlider == 1) {
      //     this.isActive7 = true;
      //   }
      // },
      // changeInformationFilterValue() {
      //   if (this.informationAndNewsletterSlider == 0) {
      //     this.informationAndNewsletterSlider = 1;
      //   } else if (this.informationAndNewsletterSlider == 1) {
      //     this.informationAndNewsletterSlider = 0;
      //   }
      // },
      // informationColouring() {
      //   if (this.informationAndNewsletterSlider == 0) {
      //     this.isActive8 = false;
      //   } else if (this.informationAndNewsletterSlider == 1) {
      //     this.isActive8 = true;
      //   }
      // },
      // changeNewsletterFilterValue() {
      //   if (this.newsletterSlider == 0) {
      //     this.newsletterSlider = 1;
      //   } else if (this.newsletterSlider == 1) {
      //     this.newsletterSlider = 0;
      //   }
      // },
      // newsletterColouring() {
      //   if (this.newsletterSlider == 0) {
      //     this.isActive9 = false;
      //   } else if (this.newsletterSlider == 1) {
      //     this.isActive9 = true;
      //   }
      // },
      // changeShopPromotionsFilterValue() {
      //   if (this.shopPromotionsSlider == 0) {
      //     this.shopPromotionsSlider = 1;
      //   } else if (this.shopPromotionsSlider == 1) {
      //     this.shopPromotionsSlider = 0;
      //   }
      // },
      // shopPromotionsColouring() {
      //   if (this.shopPromotionsSlider == 0) {
      //     this.isActive10 = false;
      //   } else if (this.shopPromotionsSlider == 1) {
      //     this.isActive10 = true;
      //   }
    },
    // changeMatches() {
    //   setTimeout(() => {
    //     let value;
    //     if (this.matchesReceivedSlider == 0) {
    //       value = false;
    //     } else if (this.matchesReceivedSlider == 1) {
    //       value = true;
    //     }
    //     const matches = {
    //       value: value
    //     };
    //     this.$store.dispatch("updateMatches", matches);
    //   }, 1000);
    // },
    // changeVisits() {
    //   setTimeout(() => {
    //     let value;
    //     if (this.profileVisitsSlider == 0) {
    //       value = false;
    //     } else if (this.profileVisitsSlider == 1) {
    //       value = true;
    //     }
    //     const visits = {
    //       value: value
    //     };
    //     this.$store.dispatch("updateVisits", visits);
    //   }, 1000);
    // },
    // changeMessages() {
    //   setTimeout(() => {
    //     let value;
    //     if (this.messagesReceivedSlider == 0) {
    //       value = false;
    //     } else if (this.messagesReceivedSlider == 1) {
    //       value = true;
    //     }
    //     const messages = {
    //       value: value
    //     };
    //     this.$store.dispatch("updateMessages", messages);
    //   }, 1000);
    // },
    // changeMeetings() {
    //   setTimeout(() => {
    //     let value;
    //     if (this.meetingsReceivedSlider == 0) {
    //       value = false;
    //     } else if (this.meetingsReceivedSlider == 1) {
    //       value = true;
    //     }
    //     const meetings = {
    //       value: value
    //     };
    //     this.$store.dispatch("updateMeetings", meetings);
    //   }, 1000);
    // }
  }
};
</script>
