<template>
  <div id="memberGallery">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.memberProfile.memberGallery.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.memberProfile.memberGallery.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>

    <div class="galleryWrapper">
      <div v-if="images" class="elementsWrapper">
        <div
          v-for="(item, index) in images"
          :key="index"
          class="photo"

        >
        <router-link to="/premium-plans" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && !item.picture_unblurred">
          <div
            class="viewButton"

          >
            {{$t($i18n.locale + '.keys.memberProfile.memberGallery.viewButton')}}
          </div>
        </router-link>
        <img class="galleryImg" :src="item.url" />
          <!--
          <div class="iconsWrapper">
             <img src="../../../../assets/images/like-green-icon.svg" alt="Like icon" />
            <img src="../../../../assets/images/message-green-icon.svg" alt="Message icon" />
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberGallery",
  props: [
    "profileId"
  ],
  data() {
    return {
      url: process.env.VUE_APP_URL,
      isLoading: true,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    images(){
      let galleryData = this.$store.getters.GalleryData;
      if(galleryData) return galleryData.photos;
      else return [];
    }
  },
  watch: {
    profileId: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep:true,
      handler (newVal, oldVal) {
        console.log('profileId', newVal, oldVal);
        if(newVal != oldVal){
          this.isLoading = true;
          this.$store.dispatch("fetchGalleryData", this.profileId).then(() => {
            this.isLoading = false;
          });
        }
      }
    }
  },
  mounted() {

  },
};
</script>

<style lang="scss"></style>
