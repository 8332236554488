<template>
<div 
    id="siteNotifications" 
    :class="{
        show_stored: showStoredNotifications && storedNotifications.length,
        mobile_chat: isMobile && $route.path.indexOf('/messages/') != -1
    }"
>
    <div class="notifications_wrapper" v-show="notifications.length  && !showStoredNotifications">
        
        <SiteNotificationElement 
        v-for="notification in notifications" 
        :key="notification.id" 
        :data-id="notification.id" 
        :ref="'notification_'+notification.id"
        @click="isMobile ? navigateTo(notification.target) : ''"
            :notification="notification"
            @navigateTo="navigateTo"
            @dismissNotification="dismissNotification"
        ></SiteNotificationElement>

        

    </div>

    <div 
        id="notficationStorage" 
        v-if="storedNotifications && storedNotifications.length"
        @click.stop.prevent="() => {return}"
    >
        
        <div class="notifications_wrapper" v-if="showStoredNotifications" v-click-outside="onClickOutside">
                <SiteNotificationElement 
                    v-for="notification in storedNotifications" 
                    :key="'stored_' + notification.id" 
                    :data-id="notification.id" 
                    :ref="'notification_'+notification.id"
                    :notification="notification"
                    @navigateTo="navigateTo"
                    @dismissNotification="dismissNotification"
                ></SiteNotificationElement>
        </div>
        
        <div class="button_wrapper" @click="showStoredNotifications = !showStoredNotifications" ref="storageButton">
            <i class="fa fa-bell"></i>
            <span>{{ storedNotifications.length }}</span>
        </div>
    </div>
    
</div>
</template>

<script>

import SiteNotificationElement from './SiteNotificationElement.vue';

export default {

    name: "SiteNotifications",
    components: {
        SiteNotificationElement
    },
    data() {
        return {
            showStoredNotifications: false
        };
    },
    computed: {
      notifications(){
        return this.$store.getters['siteNotifications/getNotfications'];
      },  
      isMobile() {
        return this.$store.getters.isMobile;
      },
      storedNotifications() {
          return this.$store.getters['siteNotifications/getStoredNotfications'];
      }
    },
    updated () {
        console.log('SiteNotifications updated', this.notifications.length, this.$refs);
        
        
        
    },
    watch: {
        notifications: function(newVal, oldVal) {
            console.log('SiteNotifications watch', newVal, oldVal);
            
        this.notifications.forEach((notification) => {
            if(!notification.timer) {
                notification.timer = setTimeout(() => {
                    //this.dismissNotification(notification.id);
                    notification.seen = true;
                    var element = document.querySelector('.notification[data-id="' + notification.id + '"]');
                    element.classList.add('removed');
                    element.classList.remove('animate');
                        //this.dismissNotification(notification.id)
                        this.$store.dispatch('siteNotifications/storeNotification', notification);

                    this.$gtm.trackEvent({
                        event: 'NOTFICATION',
                        category: 'FEATURES',
                        action: 'timeout',
                        label: notification.target
                    });

                        
                    }, 180000)
                }
            })
        },
        showStoredNotifications: function(newVal) {
            if(newVal === true){

                var userIds = this.storedNotifications.map(notification => notification.user.profileId);

                userIds = userIds.filter((value, index, self) => self.indexOf(value) === index);

                console.log('userIds', userIds)

                this.$store.dispatch('siteNotifications/loadStoredNotificationsData', userIds);
                
            }
        }
    },
    
    created() {
        console.log('SiteNotifications mounted');
        if( !this.$store.getters['siteNotifications/getSocketConnection'] ) {
            this.$store.dispatch('siteNotifications/setupWebsocket')
        }

        if(this.storedNotifications && this.storedNotifications.length) {
            this.$store.dispatch('siteNotifications/cleanupStoredNotifications')
        }
    },
    methods: {
        onClickOutside(e){
            console.log('onClickOutside', e, e.target, this.$refs.storageButton);
            if(e.target == this.$refs.storageButton || e.target.parentElement == this.$refs.storageButton) return;
            this.showStoredNotifications = false;
        },
        navigateTo(data){

            var target = data.target;
            var id = data.id;
            
            this.$gtm.trackEvent({
                event: 'NOTFICATION',
                category: 'FEATURES',
                action: 'navigate',
                label: target
            });

            if(target) this.$router.push(target).catch(() => {});
            
            this.dismissNotification({id: id});

        },
        dismissNotification(data) {

            var id = data.id;

            var element = document.querySelector('.notification[data-id="' + id + '"]');
            var notification = this.notifications.filter(notification => notification.id == id)[0] || this.storedNotifications.filter(notification => notification.id == id)[0];
            if(notification.timer) clearTimeout(notification.timer);
            
            console.log('dismissNotification', id, element, notification);
            element.classList.add('removed');

            this.$gtm.trackEvent({
                event: 'NOTFICATION',
                category: 'FEATURES',
                action: 'dismiss',
                label: notification.target
            });

            setTimeout(() => {
                this.$store.dispatch('siteNotifications/dismissNotification', id);    
            }, 500)
            
        }
    }

}

</script>