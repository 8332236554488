<template>
  <div id="userAccountMenu" :class="{premium: $store.getters.userRole.includes('ROLE_PREMIUM')}">
    <div class="userAccountMenuWrapper">
      <img v-if="editable" src="../../../assets/images/Translate-Icon.svg" alt="Translation icon" />

      <b-nav v-b-scrollspy="scrollSpyOffset">
        <b-nav-item href="#userAccountMembership" @click.stop.prevent="scrollTo('#userAccountMembership')">
          <h1
            class="menuItem userAccountMembership active"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.userAccountMenu.membership =$event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.userAccountMenu.membership}}
          </h1>
        </b-nav-item>
         <b-nav-item @click.stop.prevent="scrollTo('#personalDetails')" href="#personalDetails">
          <h1
            class="menuItem personalDetails"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.userAccountMenu.personalDetails =$event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.userAccountMenu.personalDetails}}</h1>
        </b-nav-item>
        <b-nav-item href="#matchingSettings" @click.stop.prevent="scrollTo('#matchingSettings')">
          <h1
                v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.userAccountMenu.matchingSettings =$event.target.innerText"
            class="menuItem matchingSettings"
          >{{$t($i18n.locale).keys.membershipArea.userAccountMenu.matchingSettings}}</h1>
        </b-nav-item>
        <b-nav-item href="#notifications" @click.stop.prevent="scrollTo('#notifications')">
          <h1
            class="menuItem notifications"
            v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.userAccountMenu.notifications =$event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.userAccountMenu.notifications}}</h1>
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAccountMenu",

  data() {
    return {
    scrollSpyOffset: 0

    };
  },
  computed: {

    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')) this.scrollSpyOffset = 383;
  },
  methods: {
    scrollTo: function(el){
      const target = document.querySelector(el);
      target.scrollIntoView(true);
      if(el != '#notifications'){
        window.scrollBy(0, -140);
      }
    }
  }
};
</script>

