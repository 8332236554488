import axios from "axios";
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {

  toggleGalleryView({
    commit
  }) {
    commit("toggleGalleryView");
  },
  toggleListView({
    commit
  }) {
    commit("toggleListView");
  },
  async fetchMatches({
    commit
  }, request) {
    try {

      console.log('fetchMatches data',request, store.getters.getActiveMatchesFilters);

      const filters = store.getters.getActiveMatchesFilters;
      let urlFilters = new URLSearchParams(filters).toString();

      let offset = 0;
      let limit = 10;

      if(typeof request != 'undefined' && request && request != 'autoupdate' ){
        if(request.offset) offset = request.offset;
        if(request.limit) limit = request.limit;
      }

      const response = await axios.get(
        BASE_URL + `/api/matches/?offset=${offset}&limit=${limit}&${urlFilters}`, handlerEnabled
      );
      const data = response.data.data.matches;
      console.log('matches', data);

      //Calculate and add age to matches
      data.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });

      const defaultFilters = response.data.data.defaultFilters;

      const today = new Date();
      const minBirthDate = new Date(defaultFilters.minAge);
      let minAge = today.getFullYear() - minBirthDate.getFullYear();
      const minMonth = today.getMonth() - minBirthDate.getMonth();
      if (minMonth < 0 || (minMonth === 0 && today.getDate() < minBirthDate.getDate())) {
        minAge--;
      }
      defaultFilters.minAge = minAge;

      const maxBirthDate = new Date(defaultFilters.maxAge);
      let maxAge = today.getFullYear() - maxBirthDate.getFullYear();
      const maxMonth = today.getMonth() - maxBirthDate.getMonth();
      if (maxMonth < 0 || (maxMonth === 0 && today.getDate() < maxBirthDate.getDate())) {
        maxAge--;
      }
      defaultFilters.maxAge = maxAge;


      var bookmarks = [];
      let i;
      for (i = 0; i < data.length; i++) {
        let favourites = {
          id: data[i].id,
          bookmark: data[i].bookmark
        }
        bookmarks.push(favourites);
      }

      commit('setBookmarks', bookmarks);

      if(offset != 0) commit('updateMatches', data);
      else commit('setMatches', data);

      commit('setDefaultFilters', defaultFilters)
      return response.data.data;
    } catch (error) {
      return 'error';
    }

  },
  // eslint-disable-next-line no-unused-vars
  async matchesDeleteConversation({  commit, dispatch  },matched_user_id) {
    const response = await axios.delete(BASE_URL + `/api/matches/delete/${matched_user_id}`, handlerEnabled);
    try {

      if (response) {
        dispatch("deleteConversation", matched_user_id)
        dispatch("getConversations");
        dispatch("getMessages", -1);
        dispatch("fetchMatches");
        throw 'success';
      } else {
        throw 'error';
      }
    } catch (error) {
      return 'error';
    }
  },

  filterMatches({
    commit
  }, filters) {
      commit("filterMatches", {
        //ageFrom: filters.ageFrom,
        //ageTo: filters.ageTo,
        //heightFrom: filters.heightFrom,
        //heightTo: filters.heightTo,
        newFilter: filters.newFilter,
        photoFilter: filters.photoFilter,
        favouritesFilter: filters.favouritesFilter,
        contactFilter: filters.contactFilter,
        //distanceFilter: filters.distanceFilter
      });




  },
  async matchesCount({
    commit
  }) {

    try {
      const response = await axios.get(
        BASE_URL + '/api/matches/new', handlerEnabled
      );
      commit('countMatches', response.data.data);
    } catch (error) {
      return 'error';
    }
  },
  setSearchValue({
    commit
  }, searchValue) {
    commit("setSearchValue", searchValue);
  },
  
  reRenderMatchesList({
    commit
  }) {
    commit("changeMatchesList");
  },
  addMatchCount({commit}){
    commit("addMatchCount");
  },
  setActiveMatchesFilters({commit}, filters){
    commit('setActiveMatchesFilters', filters)
  },
  removeMatchFromList({commit, dispatch}, match){
    console.log('removeMatchFromList', match);
    commit('removeMatchById', match.id)
    const filters = store.getters.getActiveMatchesFilters;
    dispatch('filterMatches', filters)
  }
};