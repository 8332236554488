<template>
<div>
  <b-modal id="bv-modal-IBQ" size="lg" hide-footer @show="loadIBQ" @hide="close" ref="modalIBQ">
      <template v-slot:modal-title>
        <img
          class="iconIBQ"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'icebreaker-green-button.svg')"
          alt="icon"
        />
      </template>
      <div class="d-block text-center">
        <h3 v-html="$t($i18n.locale + '.keys.icebreakerModal.headline')"></h3>
        <div v-if="!ibqLoader">
          <div
          v-for="question in this.currentIBQ"
          :key="question.id"
          class="icebreakerQuestion"
          :class="{active: selectedIBQId == question.id}"
          @click.stop.prevent = "selectedIBQId = question.id"
          >
          <i class="fa fa-arrow-circle-o-right"></i>
            <span>
              {{ question.question }}
            </span>
          </div>
        </div>
        <div v-else>
          Loading....
        </div>
      </div>
      <div class="actionButtons">
        <b-button
          class="my-3 loadIBQButton"
          block
          @click="shuffleIBQ"
        >
          {{ $t($i18n.locale + '.keys.icebreakerModal.loadQuestionsButton') }}
        </b-button>

        <b-button
          class="my-3 sendIBQButton"
          :disabled="!selectedIBQId"
          block
          @click="sendIBQ"
        >
          {{ $t($i18n.locale + '.keys.icebreakerModal.sendQuestionButton') }}
        </b-button>
      </div>

    </b-modal>
</div>
</template>

<script>


export default {
  name: "ModalIcebreakerQuestions",
  data() {
    return {
      ibqLoader: false,
      selectedIBQId: '',
      currentIBQ: [],
      target: '',
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    show(profile_id, target){
      this.profile_id = profile_id;
      if(typeof target != "undefined"){
        this.target = target;
      }
      this.$refs.modalIBQ.show();
    },
    close() {
      this.target = '';
    },
   loadIBQ(){
      console.log('loading icebreaker questions...');
      console.log('***************IBQ*****************', this.$store.getters.getIcebreaker);
      this.ibqLoader = true;
      this.$store.dispatch("getIcebreakerQuestions", this.profile_id).then(res => {
        if (res == "error") {
          // show error
          //this.$store.dispatch("setErrorVisibility", true);
        } else {
          console.log('***************IBQ2*****************', this.$store.getters.getIcebreaker);
          this.selectedIBQId = '';
          this.shuffleIBQ();
          this.ibqLoader = false;
        }
      });

    },
    shuffleIBQ(){
        this.currentIBQ = [];
        this.selectedIBQId = '';

        const shuffled = this.$store.getters.getIcebreaker.sort(() => 0.5 - Math.random());
        this.currentIBQ = shuffled.slice(0, 4);
    },
    sendIBQ(){
      console.log('sending icebreaker question', this.selectedIBQId);

      if(!this.selectedIBQId) return true;
      else {
        this.$store.dispatch("sendIcebreakerQuestions", {
          profile_id:this.profile_id,
          question_id: this.selectedIBQId
        }).then(res => {
          if (res == "error") {
            // show error
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            console.log('***************SEND IBQ*****************');
            if(this.target) $(this.target).remove();
            this.$bvModal.hide('bv-modal-IBQ')
          }
        });
      }
    }
  },

};
</script>

<style lang="scss" scoped>

</style>
