var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionComponent",attrs:{"id":"questionType9"}},[(_vm.variant != 2)?_c('h1',{staticClass:"questionText"},[_vm._v(" "+_vm._s(_vm.questionData.question.translations[_vm.selectedLanguage].question)+" ")]):_vm._e(),(_vm.questionData.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"skippableQuestionMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.skippableMessage)+" ")]):_vm._e(),_c('div',{class:{'chatStyleAnswer' : _vm.variant == 2}},[_c('div',{staticClass:"answerWrapper"},[_c('div',{staticClass:"sliderWrapper"},[_c('vue-slider',{staticStyle:{"width":"90%"},attrs:{"id":"distanceFilter","min":_vm.sliderMin,"max":_vm.sliderMax,"tooltip":'none',"marks":_vm.distanceMarks2,"adsorb":true,"included":true},on:{"change":function($event){return _vm.setDistanceRange()}},model:{value:(_vm.distance),callback:function ($$v) {_vm.distance=$$v},expression:"distance"}})],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"40vh","margin":"30px 0"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          gestureHandling: 'none',
          fullscreenControl: false,
          scrollwheel: false,
          draggable: false
        }}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index + 'marker',attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":{ url: require('../../assets/images/' + _vm.$store.getters.assetsPath + 'MapMarker.svg') }},on:{"click":function($event){_vm.center = m.position}}})}),_c('GmapCircle',{key:_vm.componentKey,attrs:{"center":_vm.markers[0].position,"radius":_vm.mapRadius,"visible":true,"options":{
            fillColor: '#008860',
            fillOpacity: 0.3,
            strokeColor: '#008860',
            strokeWeight: 2
          }}})],2)],1)]),(_vm.variant == 2)?_c('QuestionNavigation',{attrs:{"progressData":{
      ..._vm.navData,
      currentQuestion: _vm.questionData.question.orderId,
      skippable: _vm.questionData.question.skippable
    },"nextDisabled":_vm.distance == _vm.answerExist(),"prevDisabled":false},on:{"sendAnswer":_vm.sendAnswer,"goBack":function($event){return _vm.$emit('goBack')},"answerLater":function($event){_vm.emptyAnswers(); _vm.sendAnswer(); _vm.resendAnswer()}}}):_c('button',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"genericButton btnContinue",class:{ 'btnContinue-disabled': _vm.distance == _vm.answerExist() },attrs:{"disabled":_vm.distance == _vm.answerExist},on:{"click":function($event){_vm.sendAnswer();
      _vm.resendAnswer();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }