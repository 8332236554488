<template>
  <div id="questionType1" class="questionComponent">
    
    <h1 v-if="variant != 2" class="questionText" v-html="questionData.question.translations[selectedLanguage].question">
    </h1>
    
    <!--
    <p
      v-langErr :contenteditable="editable"
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >
      {{ $t($i18n.locale).keys.generic.skippableQuestionMessage }}
    </p>
  -->

    <div class="chatStyleAnswer">
      <div class="answerWrapper">
        <p
          v-langErr
          class="additionalMessage"
          v-if="questionData.question.id == 104"
        >
          {{ $t($i18n.locale + '.keys.questionnaire.type1.additionalMessage', {last_answer: last_answer}) }}
        </p>
        <p
          v-langErr 
          class="additionalMessage"
          v-if="questionData.question.id == 105"
        >
          {{ $t($i18n.locale).keys.questionnaire.type1.additionalMessage2 }}
        </p>
        <div class="choices" v-if="!sliderLayout">
          <button
            class="genericButton btnAnswer orange-button"
            data-toggle="modal"
            data-target="#38-quest-modal"
            type="button"
            data-dismiss="modal"
            @click="
              sendAnswer(i + 1);
              modalLoad();
              modalLoad2();
              answerSelected(i + 1);
              resendAnswer(i + 1);
              removeSelected = false;
            "
            :class="[
              { genericBtnSelected: answer === i + 1 },
              { genericBtnSelected: addAnswerClass(i + 1) && removeSelected },
              (item.translations[selectedLanguage].answer.length > 15) &&
              (item.translations[selectedLanguage].answer.length <= 65)
                ? 'buttonWider'
                : '',
                item.translations[selectedLanguage].answer.length > 65 ? 'buttonWidest' : ''
            ]"
            v-for="(item, i) in questionData.question.answerOptions"
            :key="i"
          >
            {{ item.translations[selectedLanguage].answer }}
          </button>


        </div>
        <div class="importanceSlider" v-else >
          <ul >
            <li
              v-for="(item, i) in questionData.question.answerOptions"
              :key="i"
              @click="
                sendAnswer(i + 1);
                modalLoad();
                modalLoad2();
                answerSelected(i + 1);
                resendAnswer(i + 1);
                removeSelected = false;
              "
              :class="[
                { active: answer === i + 1 },
                { active: addAnswerClass(i + 1) && removeSelected }
              ]"

              data-toggle="modal"
              data-target="#38-quest-modal"
            >
              <span class="title" v-if="(i+1) == 1 || (i+1) == 4 || (i+1) == 7">
                {{ item.translations[selectedLanguage].answer }}
              </span>
            </li>

            <!--
            <li>
              <span class="title">Unwichtig</span>
            </li>
            <li></li>
            <li class="active"></li>
            <li>
              <span class="title">Etwas wichtig</span>
            </li>
            <li></li>
            <li></li>
            <li>
              <span class="title">Sehr wichtig</span>
            </li>
            -->
          </ul>
        </div>
      </div>
    </div>

    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.orderId,
      }"
      :nextDisabled="!answer"
      :prevDisabled="false"
      
      @sendAnswer="sendSelectedAnswer"
      @goBack="$emit('goBack')"

    />

    <b-modal
      class="modal fade"
      id="38-quest-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="38-quest-modal-label"
      data-color="orange"
      hide-footer
      hide-header
    >
      <div class="modal-dialog" role="document">
        <div class="logoWrapper">
          <img :src="$store.getters.mainLogos.desktop" alt="Logo image" />
        </div>
        <div v-if="modalContentID == 38" class="modal-content">
          <div class="modal-container">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <h1 class="modal1Headline"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.type1.modal1.headline =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.questionnaire.type1.modal1.headline }}
            </h1>
            <div class="modal-message">
              <p
                v-langErr :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.questionnaire.type1.modal1.paragraph =
                    $event.target.innerText
                "
              >
                {{ $t($i18n.locale).keys.questionnaire.type1.modal1.paragraph }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="
                removeModal();
                removeModal2();
                loadNext();
              "
              class="btn btn-default btn-close modal-button"
              data-dismiss="modal"
            >
              {{ $t($i18n.locale).keys.generic.continueBtn }}
            </button>
          </div>
        </div>
        <div v-if="modalContentID == 80" class="modal-content">
          <div class="modal-container">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />

            <h1 class="modal1Headline"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.type1.modal2.headline =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.questionnaire.type1.modal2.headline }}
            </h1>
            <div class="modal-message">
              <p
                v-langErr :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.questionnaire.type1.modal2.paragraph =
                    $event.target.innerText
                "
              >
                {{ $t($i18n.locale).keys.questionnaire.type1.modal2.paragraph }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-langErr :contenteditable="editable"
              @input="
                $t($i18n.locale).keys.generic.continueBtn =
                  $event.target.innerText
              "
              type="button"
              @click="
                removeModal();
                removeModal2();
                loadNext();
              "
              class="btn btn-default btn-close modal-button"
              data-dismiss="modal"
            >
              {{ $t($i18n.locale).keys.generic.continueBtn }}
            </button>
          
          </div>
        </div>
      </div>
    </b-modal>
    <div
      @click="removeModal(); removeModal2()"
      id="modal-backdrop"
      class="modal-backdrop-transparent modal-transition"
    ></div>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  components: {
    QuestionNavigation
  },
  name: "QuestionType1",
  data: () => {
    return {
      error: null,
      answer: null,
      //modalContentID: 38,
      removeSelected: true,
      lockSend: false,
    };
  },
  watch: {

    questionData: function() {
      console.log('watch questionData', this.questionData);
      console.log('watch Question ID', this.questionData.question.id);
      if (
        this.questionData.question.id == 2

      ) {
        this.windowWidth();
      }
      console.log('questionData answered', this.questionData.answered);
      if (this.questionData.answered) {
        this.answer = this.questionData.answered.answer;
      }
    }
  },
  mounted() {

    if (this.questionData.answered) {
        this.answer = this.questionData.answered.answer;
      }

  },
  computed: {
    last_answer: function() {
      let options = this.questionData.question.answerOptions;
      let length = this.questionData.question.answerOptions.length;

      let lastOption = options[length-1];
      return lastOption.translations[this.selectedLanguage].answer;

    },
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    modalContentID: function() {
      console.log('questionData', this.questionData);
      if (
        this.questionData.question.id == 38 ||
        this.questionData.question.id == 80
      ) {
        return this.questionData.question.id;
      }else{
        return 0;
      }
    },
    sliderLayout: function(){
      console.log('Question ID', this.questionData.question.id);
      let notSliderQuestionIds = [3,4,5,11,16,60,61,62,63,64,92,93,95,96,98,99,100,105,106,107];
      if (!notSliderQuestionIds.includes(this.questionData.question.id)) {
        if (window.innerWidth > 767) {
          return true;
        } else {
          return false;
        }
      }else{
        return false;
      }
    },
    personalityTest: function() {
      return this.$store.getters.personalityTest;
    },
  },
  methods: {
    sendSelectedAnswer() {
      if(this.lockSend) return true;

      var answerId;

      if(Array.isArray(this.answer)) answerId = this.answer[0];
      else answerId = this.answer;

      if (!this.questionData.answered) {
        this.lockSend = true;
        if (
          !this.personalityTest && !this.variant == 2 &&
          (
            this.questionData.question.id == 38 ||
            this.questionData.question.id == 80
          )
        ) {
          this.$emit("only-post-answer", [answerId]);
          //this.lockSend = false;
        } else {
          this.$emit("post-answer", [answerId]);
          //this.lockSend = false;
        }
      }
      else if (this.questionData.answered) {
        this.questionData.answered.answer == null;
        if (
          !this.personalityTest && !this.variant == 2 &&
          (
            this.questionData.question.id == 38 ||
            this.questionData.question.id == 80
          )
        ) {
          console.log('ONLY PUT')
          this.$emit("only-put-answer", {
            answered: [answerId],
            customerAnswerId: this.questionData.answered.id
          });
        } else {
          console.log(' PUT AND GO')
          this.$emit("put-answer", {
            answered: [answerId],
            customerAnswerId: this.questionData.answered.id
          });
          this.$emit("transfer-skipped-array", this.questionData.skippedArray);
        }
      }
      this.scrollTop();
    },
    sendAnswer(answerId) {
      if(this.lockSend || this.variant == 2) return true;

      if (!this.questionData.answered) {
        this.lockSend = true;
        if (
          !this.personalityTest &&
          (
            this.questionData.question.id == 38 ||
            this.questionData.question.id == 80
          )
        ) {
          this.$emit("only-post-answer", [answerId]);
          //this.lockSend = false;
        } else {
          this.$emit("post-answer", [answerId]);
          //this.lockSend = false;
        }
      }
    },

    resendAnswer(answerId) {
      if(this.lockSend || this.variant == 2) return true;

      if (this.questionData.answered) {
        this.questionData.answered.answer == null;
        if (
          !this.personalityTest &&
          (
            this.questionData.question.id == 38 ||
            this.questionData.question.id == 80
          )
        ) {
          console.log('ONLY PUT')
          this.$emit("only-put-answer", {
            answered: [answerId],
            customerAnswerId: this.questionData.answered.id
          });
        } else {
          console.log(' PUT AND GO')
          this.$emit("put-answer", {
            answered: [answerId],
            customerAnswerId: this.questionData.answered.id
          });
          this.$emit("transfer-skipped-array", this.questionData.skippedArray);
        }
      }
    },
    modalLoad() {
      console.log('modal LOAD');
      if(this.personalityTest || this.variant == 2) return true;
      if (
        this.questionData.question.id == 38
      ) {
        const colorBackground = "modal-backdrop-photo";
        const modalBackdrop = document.getElementById("modal-backdrop");
        modalBackdrop.classList = colorBackground;
        this.$bvModal.show("38-quest-modal");
      }
    },
    modalLoad2() {
      console.log('modal LOAD');
      if(this.personalityTest || this.variant == 2) return true;
      if (
        this.questionData.question.id == 80
      ) {
        const colorBackground = "modal-backdrop-photo-2";
        const modalBackdrop = document.getElementById("modal-backdrop");
        modalBackdrop.classList = colorBackground;
        this.$bvModal.show("38-quest-modal");
      }
    },
    loadNext() {
      this.$emit("only-load-answer");
    },
    removeModal() {
      const colorBackground = "modal-backdrop-photo";
      const modalBackdrop = document.getElementById("modal-backdrop");
      modalBackdrop.classList.remove(colorBackground);
      this.$bvModal.hide("38-quest-modal");
    },
    removeModal2() {
      const colorBackground = "modal-backdrop-photo-2";
      const modalBackdrop = document.getElementById("modal-backdrop");
      modalBackdrop.classList.remove(colorBackground);
      this.$bvModal.hide("38-quest-modal");
    },
    answerSelected(id) {
      this.answer = id;
      this.sendSelectedAnswer();
    },
    addAnswerClass(itemId) {
      if (this.questionData.answered) {
        return itemId == this.questionData.answered.answer[0];
      } else {
        return false;
      }
    },
    scrollTop() {
      const element = document.querySelector('#question-box-start');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }
};
</script>