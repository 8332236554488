<template>
  <div id="education" :key="componentKey" class="preferencesBox">
    <div class="educationWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.educationAndIncome.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesPreferences.educationAndIncome.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="educationStatusWrapper filterWrapper">
        <div class="educationStatusTextWrapper textWrapper">
          <h3
            class="educationStatusText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.educationAndIncome.partnerEducationStatusQuestion = $event.target.innerText"
          >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.partnerEducationStatusQuestion')}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="educationStatusSelectWrapper inputWrapper">
          <select class="educationStatus">
            <option
              value="0"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.educationStatus.noMatter')}}</option>
            <option
              value="1"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.educationStatus.lower')}}</option>
            <option
              value="2"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.educationStatus.same')}}</option>
            <option
              value="3"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.educationStatus.better')}}</option>
          </select>
        </div>
      </div>
      <div class="educationImportanceWrapper filterWrapper">
        <div class="educationTextWrapper textWrapper">
          <h3
            class="educationText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.educationAndIncome.similarEducationLevelQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.educationAndIncome.similarEducationLevelQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="educationImportanceFilter"
            v-model="educationImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="educationImportanceMarks"
            :adsorb="true"
            :included="true"
          ></vue-slider>
        </div>
      </div>
      <div class="incomeStatusWrapper filterWrapper">
        <div class="incomeStatusTextWrapper textWrapper">
          <h3
            class="incomeStatusText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.educationAndIncome.partnerIncomeStatusQuestion = $event.target.innerText"
          >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.partnerIncomeStatusQuestion')}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="incomeStatusSelectWrapper inputWrapper">
          <select class="incomeStatus">
            <option
              value="0"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.incomeStatus.noMatter')}}</option>
            <option
              value="1"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.incomeStatus.lower')}}</option>
            <option
              value="2"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.incomeStatus.same')}}</option>
            <option
              value="3"
            >{{$t($i18n.locale + '.keys.matchesPreferences.educationAndIncome.incomeStatus.better')}}</option>
          </select>
        </div>
      </div>
      <div class="incomeImportanceWrapper filterWrapper">
        <div class="incomeTextWrapper textWrapper">
          <h3
            class="incomeText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.educationAndIncome.similarIncomeLevelQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.educationAndIncome.similarIncomeLevelQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="incomeImportanceFilter"
            v-model="incomeImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="incomeImportanceMarks"
            :adsorb="true"
            :included="true"
          ></vue-slider>
        </div>
      </div>
      <div class="buttonWrapper">
        <button
          @click="sendEducationAndIncomeValues()"
          class="btnSave"
        >{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          :style="{marginLeft : '20px'}"
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Education",
  data() {
    return {
      componentKey: 0,
      incomeImportanceValue: 3,
      incomeImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant,

        },
        "2": {
          label: "",

        },
        "3": {
          label: "",

        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant,

        },
        "5": {
          label: "",

        },
        "6": {
          label: "",

        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant,

        }
      },
      educationImportanceValue: 3,
      educationAndIncomeFilters: {},
      educationImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant,

        },
        "2": {
          label: "",

        },
        "3": {
          label: "",

        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant,

        },
        "5": {
          label: "",

        },
        "6": {
          label: "",

        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant,

        }
      }
    };
  },
  mounted() {

  },
  computed: {
    ...mapGetters(["getEducationAndIncome"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getEducationAndIncome: function(newVal){
      if(!_.isEmpty(newVal))  this.setEducationFilters();
    }
  },
  methods: {
    setEducationFilters() {

          this.educationImportanceValue = this.$store.getters.getEducationAndIncome.importanceEducation;
          this.incomeImportanceValue = this.$store.getters.getEducationAndIncome.importanceIncome;
          this.componentKey++;

          this.$nextTick(function(){
            document.querySelector(
              ".incomeStatus"
            ).value = this.$store.getters.getEducationAndIncome.partnerIncomeLevel;
            document.querySelector(
              ".educationStatus"
            ).value = this.$store.getters.getEducationAndIncome.partnerEducationLevel;
          });

    },
    sendEducationAndIncomeValues() {
      this.educationAndIncomeFilters = {
        importanceEducation: parseInt(this.educationImportanceValue),
        importanceIncome: parseInt(this.incomeImportanceValue),
        partnerIncomeLevel: parseInt(
          document.querySelector(".incomeStatus").value
        ),
        partnerEducationLevel: parseInt(
          document.querySelector(".educationStatus").value
        )
      };
      this.$store
        .dispatch("updateEducationAndIncome", this.educationAndIncomeFilters)
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            }).then(() => {
              document.querySelector(
                ".incomeStatus"
              ).value = this.$store.getters.getEducationAndIncome.partnerIncomeLevel;
              document.querySelector(
                ".educationStatus"
              ).value = this.$store.getters.getEducationAndIncome.partnerEducationLevel;
            });
            this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Education'
              });
            this.componentKey++;
          }
        });
    },
    
  }
};
</script>
