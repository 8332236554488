<template>
  <div id="paymentsSuccessInfo">
    <!-- <p>{{this.localStorage.getItem('token')}}</p> -->
    <MembershipHeader :premium = "true" />
    <div class="container-lg">
      
      <div class="mainInfoWrapper containerNotFound" :class="{ mobileView: isMobile }">
        <div class="messageContainer">
          <h1 class="congratulationsTitle" >
            {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.congratulations }}
          </h1>
          <p class="successMessage">
            {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.paymentsSuccess }}
          </p>
          <p class="additionalText">
            {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.enjoy }}
          </p>
          <p class="additionalText">
            <span>
              {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.accessFull }}
            </span>
            <br />
            <span>
              {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.withoutRestrictions }}
            </span>
          </p>

          <div class="advantagesWrapper">
            <div class="advantageWrapper" v-for="(benefit, index) in benefits" :key="index">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" alt="Check icon" />
              <p class="advantageText">
                {{ benefit }}
              </p>
            </div>
          </div>
          <div class="btnWrapper"  v-if="!showInfo">
            <button @click="$router.push('/membership-area/home')" class="backBtn">
              {{ $t($i18n.locale).keys.premiumPlans.paymentsSuccess.goBack }}
            </button>
          </div>
        </div>
      </div>

      <div class="premiumInfoWrapper" v-if="showInfo">
        <div class="vipActivationText">{{$t($i18n.locale + '.keys.premiumPlans.paymentsSuccess.title')}}</div>
        <div class="additionalText">
          <span class="title">{{$t($i18n.locale + '.keys.premiumPlans.paymentsSuccess.additionalTitle')}}</span>
          <span >{{$t($i18n.locale + '.keys.premiumPlans.paymentsSuccess.additionalText')}}</span>
        </div>
        <p
          v-if="addressError"
          class="emptyError"
        >{{$t($i18n.locale).keys.premiumPlans.paymentsSuccess.blankFieldsError}}</p>
        <p
          v-if="badFormatAddress"
          class="badFormatError"
         
        >{{$t($i18n.locale).keys.premiumPlans.paymentsSuccess.validFormatError}}</p>
        <div class="addressWrapper" :key="componentAddressKey">
          <div class="inputWrapper width2">
            <label
             
            >{{$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.firstName')}}</label>
            <input
              type="text"
              v-model="firstname"
              :class="{error : addressError || firstnameError}"
              :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.firstName')"
            />
          </div>
          <div class="inputWrapper width2">
            <label
              
            >{{$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.lastName')}}</label>
            <input
              type="text"
              v-model="lastname"
              :class="{error : addressError || lastnameError}"
              :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.lastName')"
            />
          </div>
          <div class="inputWrapper width3">
            <label
             
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.road}}</label>
            <input
              type="text"
              v-model="road"
              :class="{error : addressError || roadFormatError}"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.road"
            />
          </div>
          <div class="inputWrapper width1">
            <label
             
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.number}}</label>
            <input
              type="text"
              v-model="number"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.number"
              :class="{error : addressError || numberFormatError}"
            />
          </div>
          <div class="inputWrapper width1">
            <label
              
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.postalCode}}</label>
            <input
              type="text"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.postalCode"
              v-model="postalCode"
              :class="{error : addressError || postalCodeFormatError}"
            />
          </div>
          <div class="inputWrapper width3">
            <label
             
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.city}}</label>
            <input
              type="text"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.city"
              :class="{error : addressError || cityFormatError}"
              v-model="city"

            />
          </div>
          <div class="inputWrapper width2">
            <label
              
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.country}}</label>
            <input
              type="text"
              :class="{error : addressError || countryFormatError}"
              v-model="country"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.country"
            />
          </div>
          <div class="inputWrapper width3">
            <label
             
            >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.phone}}</label>
            <input
              type="text"
              :placeholder="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.phone"
              :class="{error : addressError || phoneNumberError}"
              v-model="phoneNumber"
            />
          </div>
        </div>
        <div class="buttonWrapper">
          <button
            @click="saveAddress()"
            class="btnSubmit"
          >
            {{$t($i18n.locale).keys.generic.submitBtn}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";

import jwt from "jsonwebtoken";
import { mapGetters } from "vuex";
export default {
  name: "PaymentsSuccess",
  components: {
    MembershipHeader
  },
  props:[
    "showInfo"
  ],
  data() {
    return {
      
      /* Form Data */
      firstname: "",
      lastname: "",
      road: "",
      postalCode: "",
      city: "",
      country: "",
      phoneNumber: "",
      number: "",
      /* Form Errors */ 
      lastnameError: false,
      firstnameError: false,
      roadFormatError: false,
      numberFormatError: false,
      postalCodeFormatError: false,
      countryFormatError: false,
      cityFormatError: false,
      phoneNumberError: false,
      badFormatAddress: false,
      addressError: false,
      
      BASE_URL: process.env.VUE_APP_URL,
      componentAddressKey: 0,
      userId: 0,
      paymentPlan: JSON.parse(localStorage.getItem("paymentPlan")),
      benefits: [
        this.$t(this.$i18n.locale + '.keys.premiumPlans.processPayments.detailedInsights'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.processPayments.unlimitedWriting'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.processPayments.unlimitedReading'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.processPayments.picturesUnblurred'),
      ]
    };
  },

  computed: {
    ...mapGetters(["isMobile"])
  },
  async created() {

    this.SEPAMandateHandler();

     //SEPA mandate handler
    

  },
  mounted() {

    if(this.showInfo && this.paymentPlan){
      //Call tracking functions on first purchase

      if(!this.$route.query.mandateId){
        this.$store.getters.getPrefilledData.detailsCountry == null
          ? (this.country = "")
          : (this.country = this.$store.getters.getPrefilledData.detailsCountry);
        this.$store.getters.getPrefilledData.detailsCity == null
          ? (this.city = "")
          : (this.city = this.$store.getters.getPrefilledData.detailsCity);
      }

      let token = localStorage.getItem("token");
      let decoded = jwt.decode(token, { complete: true });
      if(decoded){
        this.userId = decoded.payload.user_id;
      }

      var realPrice = this.paymentPlan.plansPrice.fullPrice;
      if(parseFloat(this.paymentPlan.plansPrice.initialFullPrice)) {
        realPrice = this.paymentPlan.plansPrice.initialFullPrice;
      }
      realPrice = (realPrice/1.2)-(realPrice*0.06);
      realPrice = Math.floor(realPrice * 100)/100

      var gtagInt = setInterval(
          function(){
        //console.log('try gtag event');
        if(typeof window.gtag == 'function'){
          //console.log('send gtag event');
          clearInterval(gtagInt);
          
          //Michael
          window.gtag('event', 'conversion', {
              'send_to': 'AW-473491805/vF1ECP2IxvIBEN3S4-EB',
              'value': realPrice,
              'currency': this.paymentPlan.plansPrice.currencySign
          });

          //seznam
          window.seznam_cId = 100074483;
          window.seznam_value = realPrice;
          this.$loadScript("https://www.seznam.cz/rs/static/rc.js");

        }
          }.bind(this),
          200
        )

        if(typeof window.fbq != 'undefined'){
          window.fbq('track', 'Purchase',{
            currency: this.paymentPlan.plansPrice.currencyISO,
            value: realPrice,
            },
            { eventID: this.userId }
          );
        }
        else{
            var FBQInterval = setInterval(() => {
              //console.log('inter FBQ');
              if(typeof window.fbq != "undefined"){
                window.fbq('track', 'Purchase',{
                currency: this.paymentPlan.plansPrice.currencyISO,
                value: realPrice,
                },
                { eventID: this.userId }
              );
                //console.log('clear FBQ');
                clearInterval(FBQInterval);
              }
            }, 100);
            setTimeout(()=>{clearInterval(FBQInterval);}, 10000);
        }

        setTimeout(()=>{
          if(typeof window.ttq != 'undefined' && this.$gtm.enabled()){
            console.log('tiktok int');
            window.ttq.track('CompletePayment',{
                content_type: 'product',
                currency: this.paymentPlan.plansPrice.currencyISO,
                value: realPrice,
                content_id: this.userId,
              }
            );
          }else {
            var tiktokint = setInterval( ()=>{
              console.log('tiktok int');
              if(typeof window.ttq != 'undefined' && this.$gtm.enabled()){
                console.log('tiktok found');
                clearInterval(tiktokint);
                window.ttq.track('CompletePayment',{
                    content_type: 'product',
                    currency: this.paymentPlan.plansPrice.currencyISO,
                    value: realPrice,
                    content_id: this.userId,
                  }
                );
              }
            }, 200)

            setTimeout(()=>{
              clearInterval(tiktokint);
            }, 10000)

          }
        }, 3000);

        if (this.$gtm.enabled()) {
          console.log('GTM ENABLED');
          window.dataLayer.push({
              event: 'payment_complete',
              currency: this.paymentPlan.plansPrice.currencyISO,
              transaction_id: this.$route.query.cid ? this.$route.query.cid:0,
              value: realPrice,
          });
        }else{
          console.log('GTM NOT ENABLED');

          setTimeout(()=>{
            window.dataLayer.push({
              event: 'payment_complete',
              currency: this.paymentPlan.plansPrice.currencyISO,
              transaction_id: this.$route.query.cid ? this.$route.query.cid:0,
              value: realPrice,
            });
          console.log('GTM NOW ENABLED');
          }, 1000);
        }


        if(this.$route.query.cid){
          let cidInt = setInterval(()=>{
            //console.log('replace CID',  this.$gtm.enabled());
            if(this.$gtm.enabled()){
              this.$router.replace({'query': null});
              clearInterval(cidInt);
            }
          },1000);
        }
    }
    else if(this.showInfo && !this.paymentPlan){
      console.log('ERROR no paymentPlan given!')
    }



/*
    if(this.planID){
      setTimeout(()=>{
        this.$gtm.trackEvent({
            event: 'payment',
            category: 'Payment',
            action: 'Success',
            label: 'Payment Success ' + (this.planID-1),
            value: this.plans[this.planID-1].plansPrice.fullPrice,
          });
      },2500);
    }
    */
  },
  methods: {
    SEPAMandateHandler() {
      if(this.$route.query.mandateId){
        let planId = this.$route.query.planId;
        let mandateId = this.$route.query.mandateId;
        let postbackUrl = this.BASE_URL + `/api/payments/SEPA/validate_mandate/${planId}`;

        this.$axios.post(
                postbackUrl,
                {
                  'mandateId': mandateId,
                }
            ).then((resp)=>{
              resp.data.data
              if(resp.data.data.detailsEntered) this.$router.push({ path: "/payments-success", query: { cid: this.$route.query.cid } });
              else {
                if(resp.data.data.detailsCountry) this.country = resp.data.data.detailsCountry;
                if(resp.data.data.detailsCity) this.city = resp.data.data.detailsCity;
              }
            });
      }
    },
    
    saveAddress() {
      this.addressError = false;
      this.badFormatAddress = false;

      const lettersNumbers = /^[0-9a-zA-ZäöüÄÖÜßçéèêëïîôöùüàâæçéèêëîïôöùûüñÁÉÍÓÚÀÂÆÇÉÈÊËÎÏÔÖÙÛÜÑĄĆĘŚŁÓŹŻąćęśłńóźż.\s-]+$/; ///^[0-9a-zA-Z]+$/;
      const letters = /^[a-zA-ZäöüÄÖÜßçéèêëïîôöùüàâæçéèêëîïôöùûüñÁÉÍÓÚÀÂÆÇÉÈÊËÎÏÔÖÙÛÜÑĄĆĘŚŁÓŹŻąćęśłńóźż.\s-]*$/g ///[a-zA-Z]+/g;
      //const numbers = /^[-+]?[0-9]+$/;
      const phone = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      if (
        (
          !this.firstname.match(letters) ||
          !this.lastname.match(letters) ||
          !this.road.match(lettersNumbers) ||
          !this.number.match(lettersNumbers) ||
          !this.postalCode.match(lettersNumbers) ||
          !this.country.match(letters) ||
          !this.city.match(letters) ||
          !this.phoneNumber.match(phone)) &&
          this.firstname != "" &&
          this.lastname != "" &&
          this.road != "" &&
          this.number != "" &&
          this.postalCode != "" &&
          this.country != "" &&
          this.city != "" &&
          this.phoneNumber != ""
      ) {
        this.badFormatAddress = true;

        !this.firstname.match(letters) ? (this.firstnameError = true) : "";
        !this.lastname.match(letters) ? (this.lastnameError = true) : "";

        !this.road.match(lettersNumbers) ? (this.roadFormatError = true) : "";
        !this.number.match(lettersNumbers) ? (this.numberFormatError = true) : "";
        !this.postalCode.match(lettersNumbers)
          ? (this.postalCodeFormatError = true)
          : "";
        !this.country.match(letters) ? (this.countryFormatError = true) : "";
        !this.city.match(letters) ? (this.cityFormatError = true) : "";
        !this.phoneNumber.match(phone) ? (this.phoneNumberError = true) : "";
      } else if (
        this.firstname == "" ||
        this.lastname == "" ||
        this.road == "" ||
        this.number == "" ||
        this.postalCode == "" ||
        this.country == "" ||
        this.city == "" ||
        this.phoneNumber == ""
      ) {
        this.addressError = true;
      } else {
        const newAddress = {
          firstname: this.firstname,
          lastname: this.lastname,
          road: this.road,
          number: this.number,
          postalCode: this.postalCode,
          country: this.country,
          city: this.city,
          phone: this.phoneNumber
        };
        this.$store.dispatch("updateAddress", newAddress).then(() => {
          this.addressError = false;
          this.phoneNumberError = '';
          this.cityFormatError = '';
          this.countryFormatError = '';
          this.postalCodeFormatError = '';
          this.numberFormatError = '';
          this.roadFormatError = '';
          this.lastnameError = '';
          this.firstnameError = '';
          this.$router.push("/membership-area/home");
        });
      }
    }
  }
};
</script>
