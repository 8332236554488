<template>
  <div id="visitorsFilters" :key="componentKey">
    <img
      v-if="editable"
      :style="{marginLeft : 'auto', display: 'block', marginRight: '10px', paddingTop: '10px'}"
      src="../../../assets/images/Translate-Icon.svg"
      alt="Translation icon"
    />
    <div class="titleWrapper">
      <h1
        class="filtersTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.membershipVisitors.filter.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.membershipVisitors.filter.title}}</h1>
    </div>
    <div class="mainFiltersWrapper">
      <div class="filterWrapper">
        <div class="filterSwitch"
          v-bind:class="{ active: isActive1 }"
          @click="changeNewFilterValue()"
          >
          <input
            class="filterSlider"
            type="checkbox"
            :checked="newFilterSlider"
          />
          <span class="slider"></span>
        </div>
        <img
          class="filterIcon"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'new-filters-icon.svg')"
          alt="New filter icon"
        />
        <p
          class="filterName"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.new = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.new}}</p>
      </div>
      <div class="filterWrapper">
        <div class="filterSwitch"
          v-bind:class="{ active: isActive2 }"
          @click="changePhotoFilterValue()"
          >
          <input
          :checked="photoFilterSlider"
            class="filterSlider"
            type="checkbox"
          />
          <span class="slider"></span>
        </div>
        <img
          class="filterIcon"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'photo-filters-icon.svg')"
          alt="Photo filter icon"
        />
        <p
          class="filterName"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.withPhoto = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.withPhoto}}</p>
      </div>
      <div class="filterWrapper">
        <div class="filterSwitch"
          v-bind:class="{ active: isActive3 }"
          @click="changeContactFilterValue()"
          >
          <input
            :checked="contactFilterSlider"
            class="filterSlider"
            type="checkbox"
          />
          <span class="slider"></span>
        </div>
        <img
          class="filterIcon"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'contact-filters-icon.svg')"
          alt="Contact filter icon"
        />
        <p
          class="filterName"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.inContact = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.inContact}}</p>
      </div>
      <div class="filterWrapper">
        <div class="filterSwitch"
          v-bind:class="{ active: isActive5 }"
          @click.prevent="changeFavouritesFilterValue()"
        >
          <input
            :checked="favouritesFilterSlider"
            class="filterSlider"
            type="checkbox"
          />
          <span class="slider"></span>
        </div>
        <img
          class="filterIcon"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'favourites-filters-icon.svg')"
          alt="Favourites filter icon"
        />
        <p
          class="filterName"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.favourites = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.favourites}}</p>
      </div>
    </div>
    <!--
    <div class="ageFilterWrapper">
      <div class="ageTextWrapper">
        <h1
          class="ageText"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.age = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.age}}</h1>
      </div>
      <div class="sliderWrapper">
        <vue-slider
          id="ageFilter"
          style="width: 100%"
          v-model="ageFilterValue"
          :min="ageFilterMin"
          :max="ageFilterMax"
          :tooltip="'always'"
          :tooltip-placement="'bottom'"
          :enable-cross="false"
        ></vue-slider>
      </div>
    </div>
    <div class="heightFilterWrapper">
      <div class="heightTextWrapper">
        <h1
          class="heightText"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.height = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.height}}</h1>
      </div>
      <div class="sliderWrapper">
        <vue-slider
          id="heightFilter"
          style="width: 100%"
          v-model="heightFilterValue"
          :min="heightFilterMin"
          :max="heightFilterMax"
          :tooltip="'always'"
          :tooltip-placement="'bottom'"
          :enable-cross="false"
        ></vue-slider>
      </div>
    </div>
    <div class="distanceFilterWrapper">
      <div class="distanceTextWrapper">
        <h1
          class="distanceText"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipVisitors.filter.names.distance = $event.target.innerText"
        >{{$t($i18n.locale).keys.membershipVisitors.filter.names.distance}}</h1>
      </div>
      <div class="sliderWrapper">
        <vue-slider
          id="distanceFilter"
          :class="{ hideTooltip : distanceFilterValue > distanceMaxValue - 100 }"
          style="width: 100%"
          v-model="distanceFilterValue"
          :min="0"
          :max="distanceMaxValue"
          :tooltip="'always'"
          :tooltip-placement="'bottom'"
          :marks="mark"
        ></vue-slider>
      </div>
    </div>
    <div class="resetFilterWrapper" @click="resetFilters()">
      <h1
        class="resetFilterTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.membershipVisitors.filter.resetFilters = $event.target.innerText"
      >{{$t($i18n.locale).keys.membershipVisitors.filter.resetFilters}}</h1>
    </div>
    -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VisitorsFilters",
  components: {},
  data() {
    return {
      componentKey: 0,
      isActive1: false,
      newFilterSlider: 0,
      isActive2: false,
      photoFilterSlider: 0,
      contactFilterSlider: 0,
      isActive3: false,
      favouritesFilterSlider: 0,
      isActive5: false,
      ageFilterValue: [],
      enableAllFilters: false,
      ageFilterMin: 40,
      ageFilterMax: 100,
      minAgeFilterValue: 40,
      maxAgeFilterValue: 100,
      distanceFilterValue: 1,
      distanceMaxValue: 200,
      mark: {},
      heightFilterValue: [],
      heightFilterMin: 140,
      heightFilterMax: 200,
      minHeightFilterValue: 140,
      maxHeightFilterValue: 200,
      backgroundGradient5: ""
    };
  },
  watch: {
    newFilterSlider: function(newFilterValue, oldVal) {
      if (this.newFilterSlider == 1) {
        this.isActive1 = true;
      } else {
        this.isActive1 = false;
      }
      console.log('dispatchFilter newFilterSlider watch', newFilterValue, oldVal)
      if(newFilterValue != oldVal) this.dispatchFilter();

      localStorage.newFilterValue = newFilterValue;
    },
    photoFilterSlider: function(photoFilterValue, oldVal) {
      if (this.photoFilterSlider == 1) {
        this.isActive2 = true;
      } else {
        this.isActive2 = false;
      }
      console.log('dispatchFilter photoFilterSlider watch', photoFilterValue, oldVal)
      if(photoFilterValue != oldVal) this.dispatchFilter();

      localStorage.photoFilterValue = photoFilterValue;
    },
    contactFilterSlider: function(contactFilterValue, oldVal) {
      if (this.contactFilterSlider == 1) {
        this.isActive3 = true;
      } else {
        this.isActive3 = false;
      }
      console.log('dispatchFilter contactFilterSlider watch', contactFilterValue, oldVal)
      if(contactFilterValue != oldVal) this.dispatchFilter();

      localStorage.contactFilterValue = contactFilterValue;
    },
    favouritesFilterSlider: function(favouritesFilterValue, oldVal) {
      if (this.favouritesFilterSlider == 1) {
        this.isActive5 = true;
      } else {
        this.isActive5 = false;
      }
      console.log('dispatchFilter favouritesFilterSlider watch', favouritesFilterValue, oldVal)
      if(favouritesFilterValue != oldVal)  this.dispatchFilter();


      localStorage.favouritesFilterValue = favouritesFilterValue;
    },
    ageFilterValue: function(ageFilterValue) {
      this.distanceFilterValue > 200 ? this.distanceToSend = 5000 : this.distanceToSend = this.distanceFilterValue;

      console.log('dispatchFilter ageFilterValue watch')
      this.dispatchFilter();

      localStorage.ageFilterValueFromVisitors = ageFilterValue[0];
      localStorage.ageFilterValueToVisitors = ageFilterValue[1];
      this.enableAllFilters = true;
    },
    heightFilterValue: function(heightFilterValue) {
      
      console.log('dispatchFilter heightFilterValue watch')
      this.dispatchFilter();

      localStorage.heightFilterValueFromVisitors = heightFilterValue[0];
      localStorage.heightFilterValueToVisitors = heightFilterValue[1];
      this.enableAllFilters = true;
    },
    distanceFilterValue: function(distanceFilterValue) {
      this.distanceFilterValue > 200 ? this.distanceToSend = 5000 : this.distanceToSend = this.distanceFilterValue;

      console.log('dispatchFilter distanceFilterValue watch')
      this.dispatchFilter();

      if (this.distanceFilterValue > 200) {
        localStorage.distanceFilterValueVisitors = 300;
      } else if (this.distanceFilterValue <= 200) {
        localStorage.distanceFilterValueVisitors = distanceFilterValue;
      }
      this.enableAllFilters = true;
    },

    searchValueVisitors: function() {
      this.distanceFilterValue > 200 ? this.distanceToSend = 5000 : this.distanceToSend = this.distanceFilterValue;

      console.log('dispatchFilter searchValue watch')
      this.dispatchFilter();
    },
  },
  computed: {
    ...mapGetters([
      "getDefaultFiltersVisitors",
      "searchValueVisitors",
      "allVisitors",
      "getActiveVisitorsFilters"
    ]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {

    this.newFilterSlider = this.getActiveVisitorsFilters.newFilter
    this.photoFilterSlider = this.getActiveVisitorsFilters.photoFilter
    this.favouritesFilterSlider = this.getActiveVisitorsFilters.favouritesFilter
    this.contactFilterSlider = this.getActiveVisitorsFilters.contactFilter

    this.dispatchFilter();

    /*
    if (localStorage.newFilterValueVisitors) {
      this.newFilterSlider = localStorage.newFilterValueVisitors;
    }
    if (localStorage.photoFilterValueVisitors) {
      this.photoFilterSlider = localStorage.photoFilterValueVisitors;
    }
    if (localStorage.contactFilterValueVisitors) {
      this.contactFilterSlider = localStorage.contactFilterValueVisitors;
    }
    if (localStorage.favouritesFilterValueVisitors) {
      this.favouritesFilterSlider = localStorage.favouritesFilterValueVisitors;
    }
    */

    return true;

  },
  methods: {
    dispatchFilter(){

      console.log('dispatchFilter', this.enableAllFilters)

      //Check for correct distance value
      this.distanceFilterValue > 200 ? this.distanceToSend = 5000 : this.distanceToSend = this.distanceFilterValue;

      //Set up filters 
      var filters = {}; 
      if(!this.enableAllFilters) {
        filters = {
            //ageFrom: this.ageFilterMin,
            //ageTo: this.ageFilterMax,
            //heightFrom: this.heightFilterMin,
            //heightTo: this.heightFilterMax,
            newFilter: this.newFilterSlider,
            photoFilter: this.photoFilterSlider,
            favouritesFilter: this.favouritesFilterSlider,
            contactFilter: this.contactFilterSlider,
            //distanceFilter: 5000
          }
      }else{
        filters = {
          //ageFrom: this.ageFilterValue[0],
          //ageTo: this.ageFilterValue[1],
          //heightFrom: this.heightFilterValue[0],
          //heightTo: this.heightFilterValue[1],
          newFilter: this.newFilterSlider,
          photoFilter: this.photoFilterSlider,
          favouritesFilter: this.favouritesFilterSlider,
          contactFilter: this.contactFilterSlider,
          //distanceFilter: this.distanceToSend
        }
      }

      //Set active filters before loading new matches
      this.$store.dispatch("setActiveVisitorsFilters", filters);

      //Load matches with filter
      this.$store.dispatch("fetchVisitors").then((res) => {
        console.log('dispatchFilter then', res)
        this.$store.dispatch("filterVisitors", filters);
        this.$emit('filterResetList');
      })
        
    },
    changeNewFilterValue() {
      if (this.newFilterSlider == 0) {
        this.newFilterSlider = 1;
      } else if (this.newFilterSlider == 1) {
        this.newFilterSlider = 0;
      }
    },
    changePhotoFilterValue() {
      if (this.photoFilterSlider == 0) {
        this.photoFilterSlider = 1;
      } else if (this.photoFilterSlider == 1) {
        this.photoFilterSlider = 0;
      }
    },
    changeContactFilterValue() {
      if (this.contactFilterSlider == 0) {
        this.contactFilterSlider = 1;
      } else if (this.contactFilterSlider == 1) {
        this.contactFilterSlider = 0;
      }
    },
    changeFavouritesFilterValue() {
      if (this.favouritesFilterSlider == 0) {
        this.favouritesFilterSlider = 1;
      } else if (this.favouritesFilterSlider == 1) {
        this.favouritesFilterSlider = 0;
      }
    },
    resetFilters() {
      if(this.$store.getters.getDefaultFiltersVisitors.minAge < 40){
        this.ageFilterMin = this.$store.getters.getDefaultFiltersVisitors.minAge;
      }
      this.ageFilterValue[0] = this.$store.getters.getDefaultFiltersVisitors.minAge;
      if(this.$store.getters.getDefaultFiltersVisitors.maxAge > 100){
        this.ageFilterMax = this.$store.getters.getDefaultFiltersVisitors.maxAge;
      }
      this.ageFilterValue[1] = this.$store.getters.getDefaultFiltersVisitors.maxAge;
      if(this.$store.getters.getDefaultFiltersVisitors.minHeight < 140){
        this.heightFilterMin = this.$store.getters.getDefaultFiltersVisitors.minHeight;
      }
      this.heightFilterValue[0] = this.$store.getters.getDefaultFiltersVisitors.minHeight;
      if(this.$store.getters.getDefaultFiltersVisitors.maxHeight > 200){
        this.heightFilterMax = this.$store.getters.getDefaultFiltersVisitors.maxHeight;
      }
      this.heightFilterValue[1] = this.$store.getters.getDefaultFiltersVisitors.maxHeight;

      this.distanceMaxValue = 300;

      if (this.$store.getters.getDefaultFiltersVisitors.maxDistance != 5000) {
        this.distanceFilterValue = this.$store.getters.getDefaultFiltersVisitors.maxDistance;
      } else {
        this.distanceFilterValue = 300;
      }
      this.$store.dispatch('setAdditionalTranslations').then(() => {
        this.mark[this.distanceMaxValue] = `${this.$store.getters.getAdditionalTranslations.filters[0].i_dont_mind}`;
        this.componentKey++;
      })
      // this.$store.dispatch("reRenderVisitorsList");
      this.newFilterSlider = 0;
      this.photoFilterSlider = 0;
      this.contactFilterSlider = 0;
      this.favouritesFilterSlider = 0;
      this.$store.dispatch("fetchVisitors").then(() => {
            this.$store.dispatch("filterVisitors", {
              ageFrom: 40,
              ageTo: 100,
              heightFrom: 140,
              heightTo: 200,
              newFilter: 0,
              photoFilter: 0,
              favouritesFilter: 0,
              contactFilter: 0,
              distanceFilter: 5000
          });
        })
      this.componentKey++;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>