<template>
  <div id="memberQuestionsAnswers" :key="componentKey + '3'">
    <!-- normal questions -->
    <div class="sectionTitleWrapper" v-if="boxBlurred || MemberData.answers.length">
      <h1
        class="sectionTitle"
        :contenteditable="editable"
        v-langErr
        @blur="
          $t(
            $i18n.locale
          ).keys.memberProfile.userProfile.questionsAndAnswers.title =
            $event.target.innerText
        "
      >
        {{
          $t($i18n.locale).keys.memberProfile.userProfile.questionsAndAnswers
            .title
        }}
      </h1>
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <template v-if="!boxBlurred && (MemberData.answers || MemberData.answersRandom)">
    <div class="questionsToEditWrapper" v-if="MemberData.answers.length">
      <div v-if="MemberData.answers" class="knownEditableQuestionsWrapper">
        <div v-if="!MemberData.answers.length > 0" id="EmptyQuestionAnswers">
          <h1>
            {{ MemberData.profileCard.name }}
            <span>
              {{
                $t($i18n.locale).keys.memberProfile.userProfile
                  .questionsAndAnswers.didNotAnswer
              }}</span
            >
          </h1>
          <p
            v-langErr
            :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.memberProfile.userProfile.questionsAndAnswers.alwaysAsk =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.memberProfile.userProfile
                .questionsAndAnswers.alwaysAsk
            }}
          </p>

          <img
            @click="initiateConversation(MemberData)"
            class="sendMessage-empty"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
            alt="Message icon"
          />
        </div>

        <div class="row">
          <div class="column" :key="componentKey + '8'">
            <div v-for="(question, index) in MemberData.answers" :key="index" :qid="question.question.id">
              <div v-if="index % 2 !== 1" class="leftContainer">
                <!-- <p>{{ question.answerLikes }} answerLikes</p> -->
                <div class="editableContentWrapper">
                  <div class="editableQuestionWrapper">
                        <h1 class="editableQuestion">
                      {{
                        question.question.translations[selectedLanguage].question
                      }}
                    </h1>
                  </div>
                  <!-- zou -->
                 <span v-if="question.question.type == 3">
                    <p class="answerType2">{{ question.answer[0] }}</p>
                  </span>

                  <span v-if="question.question.type == 2">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                      <span v-if="question.question.answerOptions[item]">
                      {{
                        question.question.answerOptions[item].translations[
                          selectedLanguage
                        ].answer
                      }}
                      </span>
                      <span v-else>{{item}}</span>
                    </p>
                  </span>
                  <!-- <span v-if="question.question.type == 3">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span> -->
                  <span v-if="question.question.type == 4">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span>
                  <div class="iconsWrapper" :key="componentKey">
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      @click="likeAnswer(question.id)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      @click="showModal(index)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                      alt="Message icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      :src="require('@/assets/images/like-grey-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      :src="require('@/assets/images/Message_grey-icon.svg')"
                      class="sendComment"
                      alt="Message icon"
                    />
                    <div class="commentInputWrapper">
                      <!-- <h1> {{index}}</h1> -->
                      <div
                        class="triangle-with-shadow"
                        v-if="form.comment_modals[index]"
                      ></div>
                      <div
                        class="commentInput"
                        v-if="form.comment_modals[index]"
                      >
                        <textarea
                          v-model="form.question_comments[index]"
                          class="commentTextArea"
                          :placeholder="$t($i18n.locale).keys.generic.typeHere"
                          maxlength="100"
                        ></textarea>
                        <!-- <input  >  -->
                        <img
                          @click="showModal(index)"
                          class="closeBtn"
                          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                          alt=""
                        />
                        <button
                          v-langErr
                          @click="
                            commentAnswer({
                              answerID: question.id,
                              comment: form.question_comments[index],
                            })
                          "
                          class="sendBtn"
                        >
                          {{
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          }}
                        </button>
                        <input
                          v-if="editable"
                          v-model="
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          "
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column" :key="componentKey + '5'">
            <div v-for="(question, index) in MemberData.answers" :key="index">
              <div v-if="index % 2 == 1" class="leftContainer">
                <!-- <p>{{ question.answerLikes }} answerLikes</p> -->
                <div class="editableContentWrapper">
                  <div class="editableQuestionWrapper">
                    <h1 class="editableQuestion">
                      {{
                        question.question.translations[selectedLanguage].question
                      }}:
                    </h1>
                  </div>
                  <span v-if="question.question.type == 3">
                    <p class="answerType1">{{ question.answer[0] }}</p>
                  </span>

                  <span v-if="question.question.type == 2">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                      {{
                        question.question.answerOptions[item].translations[
                          selectedLanguage
                        ].answer
                      }}
                    </p>
                  </span>
                  <!-- <span v-if="question.question.type == 3">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span> -->
                  <span v-if="question.question.type == 4">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span>
                  <div class="iconsWrapper" :key="componentKey + '2'">
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      @click="likeAnswer(question.id)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      @click="showModal(index)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                      alt="Message icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      :src="require('@/assets/images/like-grey-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      :src="require('@/assets/images/Message_grey-icon.svg')"
                      alt="Message icon"
                    />
                    <div class="commentInputWrapper">
                      <div
                        class="triangle-with-shadow"
                        v-if="form.comment_modals[index]"
                      ></div>
                      <div
                        class="commentInput"
                        v-if="form.comment_modals[index]"
                      >
                        <textarea
                          v-model="form.question_comments[index]"
                          class="commentTextArea"
                          :placeholder="$t($i18n.locale).keys.generic.typeHere"
                          maxlength="100"
                        ></textarea>
                        <!-- <input  >  -->
                        <img
                          @click="showModal(index)"
                          class="closeBtn"
                          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                          alt=""
                        />
                        <button
                          v-langErr
                          @click="
                            commentAnswer({
                              answerID: question.id,
                              comment: form.question_comments[index],
                            })
                          "
                          class="sendBtn"
                        >
                          {{
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          }}
                        </button>
                        <input
                          v-if="editable"
                          v-model="
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          "
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- normal questions end -->
    <!-- random questions -->
    <div class="sectionTitleWrapper" v-if="MemberData.answersRandom">
      <h1
        class="sectionTitle"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t(
            $i18n.locale
          ).keys.memberProfile.userProfile.questionsAndAnswers.title2 =
            $event.target.innerText
        "
      >
        {{
          $t($i18n.locale).keys.memberProfile.userProfile.questionsAndAnswers
            .title2
        }}
        <!-- RANDOM QUESTIONS AND ANSWERS -->
      </h1>
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <div class="questionsToEditWrapper" v-if="MemberData.answersRandom">
      <div
        v-if="MemberData.answersRandom"
        class="knownEditableQuestionsWrapper"
      >
        <div
          v-if="!MemberData.answersRandom.length > 0"
          id="EmptyQuestionAnswers"
        >
          <h1>
            {{ MemberData.profileCard.name }}
            <span>
              {{
                $t($i18n.locale).keys.memberProfile.userProfile
                  .questionsAndAnswers.didNotAnswer
              }}</span
            >
          </h1>
          <p
            v-langErr
            :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.memberProfile.userProfile.questionsAndAnswers.alwaysAsk =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.memberProfile.userProfile
                .questionsAndAnswers.alwaysAsk
            }}
          </p>

          <img
            @click="initiateConversation(MemberData)"
            class="sendMessage-empty"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
            alt="Message icon"
          />
        </div>

        <div class="row">
          <div class="column" :key="componentKey + '8'">
            <div
              v-for="(question, index) in MemberData.answersRandom"
              :key="index"
            >
              <div v-if="index % 2 !== 1" class="leftContainer">
                <!-- <p>{{ question.answerLikes }} answerLikes</p> -->
                <div class="editableContentWrapper">
                  <div class="editableQuestionWrapper">
                    <h1 class="editableQuestion">
                      {{
                        question.question.translations[selectedLanguage].question
                      }}
                    </h1>
                  </div>
          <span v-if="question.question.type == 3">
                    <p class="answerType1">{{ question.answer[0] }}</p>
                  </span>

                  <span v-if="question.question.type == 2">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                      {{
                        question.question.answerOptions[item].translations[
                          selectedLanguage
                        ].answer
                      }}
                    </p>
                  </span>
                  <!-- <span v-if="question.question.type == 3">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span> -->
                  <span v-if="question.question.type == 4">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span>
                  <div class="iconsWrapper" :key="componentKey">
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      @click="likeAnswer(question.id)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      @click="showModalRandom(index)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                      alt="Message icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      :src="require('@/assets/images/like-grey-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      :src="require('@/assets/images/Message_grey-icon.svg')"
                      class="sendComment"
                      alt="Message icon"
                    />
                    <div class="commentInputWrapper">
                      <!-- <h1> {{index}}</h1> -->
                      <div
                        class="triangle-with-shadow"
                        v-if="formRandom.comment_modals[index]"
                      ></div>
                      <div
                        class="commentInput"
                        v-if="formRandom.comment_modals[index]"
                      >
                        <textarea
                          v-model="formRandom.question_comments[index]"
                          class="commentTextArea"
                          :placeholder="$t($i18n.locale).keys.generic.typeHere"
                          maxlength="100"
                        ></textarea>
                        <!-- <input  >  -->
                        <img
                          @click="showModalRandom(index)"
                          class="closeBtn"
                          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                          alt=""
                        />
                        <button
                          v-langErr
                          @click="
                            commentAnswer({
                              answerID: question.id,
                              comment: formRandom.question_comments[index],
                            })
                          "
                          :cmtid='index'
                          class="sendBtn"
                        >
                          {{
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          }}
                        </button>
                        <input
                          v-if="editable"
                          v-model="
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          "
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column" :key="componentKey + '5'">
            <div
              v-for="(question, index) in MemberData.answersRandom"
              :key="index"
            >
              <div v-if="index % 2 == 1" class="leftContainer">
                <!-- <p>{{ question.answerLikes }} answerLikes</p> -->
                <div class="editableContentWrapper">
                  <div class="editableQuestionWrapper">
                    <h1 class="editableQuestion">
                      {{
                        question.question.translations[selectedLanguage].question
                      }}
                    </h1>
                  </div>
          <span v-if="question.question.type == 3">
                    <p class="answerType1">{{ question.answer[0] }}</p>
                  </span>

                  <span v-if="question.question.type == 2">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                      {{
                        question.question.answerOptions[item].translations[
                          selectedLanguage
                        ].answer
                      }}
                    </p>
                  </span>
                  <!-- <span v-if="question.question.type == 3">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span> -->
                  <span v-if="question.question.type == 4">
                    <p
                      class="answerType2"
                      v-for="item in question.answer"
                      :key="item.message"
                    >
                    {{item}}

                    </p>
                  </span>
                  <div class="iconsWrapper" :key="componentKey + '2'">
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      @click="likeAnswer(question.id)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      @click="showModalRandom(index)"
                      :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                      alt="Message icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ smileSent: !!question.answerLikes.length }"
                      class="sendLike"
                      :src="require('@/assets/images/like-grey-icon.svg')"
                      alt="Like icon"
                    />
                    <img
                      v-if="!!question.answerLikes.length"
                      :class="{ commentSent: !!question.answerLikes.length }"
                      class="sendComment"
                      :src="require('@/assets/images/Message_grey-icon.svg')"
                      alt="Message icon"
                    />
                    <div class="commentInputWrapper">
                      <div
                        class="triangle-with-shadow"
                        v-if="formRandom.comment_modals[index]"
                      ></div>
                      <div
                        class="commentInput"
                        v-if="formRandom.comment_modals[index]"
                      >
                        <textarea
                          v-model="formRandom.question_comments[index]"
                          class="commentTextArea"
                          :placeholder="$t($i18n.locale).keys.generic.typeHere"
                          maxlength="100"
                        ></textarea>
                        <!-- <input  >  -->
                        <img
                          @click="showModalRandom(index)"
                          class="closeBtn"
                          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                          alt=""
                        />
                        <button
                          v-langErr
                          @click="
                            commentAnswer({
                              answerID: question.id,
                              comment: formRandom.question_comments[index],
                            })
                          "
                          class="sendBtn"
                          :cmtid="index"
                        >
                          {{
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          }}
                        </button>
                        <input
                          v-if="editable"
                          v-model="
                            $t($i18n.locale).keys.memberProfile.userProfile
                              .questionsAndAnswers.send
                          "
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- random questions end -->
  </template>
  <ProfileBlurBanner v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileBlurBanner from "@/components/generic/ProfileBlurBanner.vue";
// import EmptyQuestionAnswers from "./EmptyQuestionAnswers";
export default {
  name: "QuestionsAnswers",
  components: {
    ProfileBlurBanner
  },
  computed: {
    ...mapGetters(["MemberData", "selectedLanguage", "user"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    boxBlurred: function(){
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 4;
    },
  },
  created() {
    // for (let x in this.MemberData.answers) {
    //   this.form.comment_modals[x] = false;
    // }
    this.hideAllCommentModals();
  },
  data() {
    return {
      componentKey: 0,
      form: {
        question_comments: [],
        comment_modals: [],
      },
      formRandom: {
        question_comments: [],
        comment_modals: [],
      },
      lockRequests: false
    };
  },

  methods: {
    initiateConversation(match) {
      if(match.blockSend){
      this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });
      }
      var data = {
        id: match.match_id,
        blockSend: match.blockSend
      };
      this.$store.dispatch("initiateConversation", data).then((res) => {
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          this.$store.dispatch("hideContent");
        }
      });
    },
    fetchUserData() {
      var data = {
        memberID: this.MemberData.profile_id,
        reset: true
      }
      this.$store.dispatch("fetchMemberData",data);
      this.componentKey += 1;
    },

    showModal(index) {
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){

        console.log('showModal', index);

        this.form.comment_modals[index] = !this.form.comment_modals[index];
        this.form.comment_modals = [...this.form.comment_modals];
      }else{
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
    showModalRandom(index) {
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){
        console.log("showModalRandom", index);
        this.formRandom.comment_modals[index] = !this.formRandom.comment_modals[
          index
        ];
        this.formRandom.comment_modals = [...this.formRandom.comment_modals];
      }else{
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },

    likeAnswer(answerID) {
      if(this.lockRequests) return false;
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){
        this.lockRequests = true;
        this.$store.dispatch("likeAnswer", answerID).then(() => {
          this.lockRequests = false;
          this.componentKey += 1;
          this.fetchUserData();
        });
      }else{

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Like'
        });

        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },

    hideAllCommentModals() {
      for (let x in this.MemberData.answers) {
        this.form.comment_modals[x] = false;
      }
      for (let x in this.MemberData.answers) {
        this.formRandom.comment_modals[x] = false;
      }
    },

    commentAnswer(commentData) {
      let data = {
        answerID: commentData.answerID,
        comment: commentData.comment,
      };
      console.log(commentData);
      console.log('commentAnswer', data);
      if(data.comment && data.comment != ''){
        if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
          this.$store.dispatch("commentAnswer", data).then(()=>{
            this.fetchUserData();
            this.componentKey += 1;
          });
        } else {

          this.$gtm.trackEvent({
            event: 'NPM',
            category: 'NPM',
            action: 'Send Message Attempt',
            label: 'Comment'
          });

          localStorage.setItem("premium", 'false');
          this.$router.push(`/premium-plans`);
        }

      }
      this.hideAllCommentModals();
      this.form.comment_modals = [...this.form.comment_modals];
      this.formRandom.comment_modals = [...this.form.comment_modals];

    },
  },
};
</script>

<style lang="scss" scoped>
#EmptyQuestionAnswers {
  border: 1px solid #dadde4;
  padding: 15px;
  padding-bottom: 25px;
  width: 100%;
  margin-bottom: 15px;
  .sendMessage-empty {
    cursor: pointer;
  }
  h1 {
    font-size: 22px;
    font-weight: bold;
  }
  p {
    font-size: 22px;
  }
}

.smileSent {
  pointer-events: none;
  // background: url("/src/assets/images/Close-cross-White.svg");
}
/*
.smileSent:hover {
}
*/

.commentSent {
  pointer-events: none;
  // background: url("/src/assets/images/Close-cross-White.svg");
}
.commentSent:hover {
  cursor: default;
}

.commentInputWrapper {
  position: relative;
  width: 100%;

  .sendBtn {
    right: 25px;
    background: #ffffff;
    position: absolute;
    bottom: 15px;
    width: 100px;
    height: 30px;
    color: #008860;
    box-shadow: none;
    text-transform: uppercase;
    color: #008860;
    border: 1px solid #008860;
    border-radius: 5px;
    // padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .closeBtn {
    height: 15px;
    width: 15px;
    position: absolute;
    margin: 0;
    right: 0px;
    top: 10px;
  }
  .commentTextArea {
    left: 0px;
    position: absolute;
    height: 98%;
    width: 75%;
    resize: none;
    border: none;
    padding: 20px;
    font-size: 20px;
  }

  .triangle-with-shadow {
    width: 100%;
    height: 80px;
    position: absolute;
    overflow: hidden;
    top: -79px;
    z-index: 5;
    transform: translateX(-25px);
  }
  .triangle-with-shadow:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 75px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    left: 50%;
  }

  .commentInput {
    border: solid 1px #dadde4;
    height: 121.6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    width: 100%;
    background-color: #ffffff;
  }
  // .commentInput:after {
  //   border: solid transparent;
  //   content: " ";
  //   height: 0;
  //   width: 0;
  //   pointer-events: none;
  //   border-width: 20px;
  //   top: -30%;
  //   border-bottom-color: #ffffff !important;
  //   position: absolute;
  //   left: 50%;
  //   -webkit-transform: translateX(-50%);
  //   -ms-transform: translateX(-50%);
  //   transform: translateX(-50%);
  //   z-index: 99;
  // }
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 11px;
  width: 100%;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
</style>
