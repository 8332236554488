export default {
    galleryView: false,
    listView: true,
    matches: [],
    allMatchesCount: 0,
    filteredMatches: [],
    alternativeMatches: [],
    matchesQuantity: 0,
    matchesSearchValue: "",
    bookmarks: [],
    defaultFilters: [],
    matchesList: 0,
    matchesActionsCount: 0,
    activeMatchesFilters: {
        contactFilter: 0,
        favouritesFilter: 0,
        newFilter: 0,
        photoFilter: 0,
    },
};