<template>
  <div id="uploadImage">
    <div class="simpleHeader">
      <div class="container topnavWrapper">
        <div class="row justify-content-center">
          <div class="logoWrapper col-12 col-md-10">
            <img :src="$store.getters.mainLogos.desktop" alt="Logo" />
          </div>
        </div>
      </div>
    </div>

    <div class="container mainQuestionBoxWrapper">
      <div class="questionBoxWrapper" v-if="desktopView">
        <h1 class="personalityAdditionalHeadline">
        {{ $t($i18n.locale + '.keys.questionnaire.additionalHeadline') }}
        </h1>
        <div class="questionBoxHeader">
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
          <div
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.headerTitle =
                $event.target.innerText
            "
            class="headerTitle"
          >{{ $t($i18n.locale).keys.questionnaire.uploadImage.headerTitle }}</div>
          <!--
          <div class="progressBarWrapper">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <div
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.questionnaire.uploadImage.progressBarHeading =
                  $event.target.innerText
              "
              class="progressBarHeading"
            >
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage
              .progressBarHeading
              }}
            </div>
            <div class="progressBar"></div>
          </div>
          -->
        </div>
        <div class="questionBoxMainMessage">
          <div class="imageWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'profile-picture.svg')" alt="Image icon" />
          </div>
          <div class="messageWrapper">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <div
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.mainMessage =
                  $event.target.innerText
              "
              class="mainMessage"
            >{{ $t($i18n.locale).keys.questionnaire.uploadImage.mainMessage }}</div>
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <!-- <div
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.questionnaire.uploadImage.additionalMessage =
                  $event.target.innerText
              "
              class="additionalMessage"
            >
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage
              .additionalMessage
              }}
            </div> -->
          </div>
        </div>
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
        <div
          class="privacyMessage"
          v-langErr
          :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.questionnaire.uploadImage.privacyMessage =
              $event.target.innerText
          "
        >{{ $t($i18n.locale).keys.questionnaire.uploadImage.privacyMessage }}</div>
        <div class="imageUploadWrapper">
          <div class="leftContainer">

            <div class="uploadFileWrapper">
              <!-- <router-link to="/membership-area/user-profile/gallery"> -->
              <label :for="idUpload" class="uploadNewWrapper">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Upload_Pic-button.svg')" alt="Upload icon" />
                <div
                  class="uploadText"
                  v-langErr
                  :contenteditable="editable"
                  @blur="
                      $t($i18n.locale).keys.questionnaire.uploadImage.uploadText =
                        $event.target.innerText
                    "
                >
                  {{
                  $t($i18n.locale).keys.questionnaire.uploadImage.uploadText
                  }}
                </div>
              </label>
              <div style="display: none" class="file-upload-form">
                <input
                  type="file"
                  :id="idUpload"
                  @change="uploadImage($event)"
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>
              <!-- </router-link> -->
              <!-- <b-modal id="crop-image" hide-footer>
                <clipper-basic ref="clipper" :src="imageToCrop" :ratio="1" :touch-create="true"></clipper-basic>
                <div class="buttonWrapper">
                  <button
                    class="btnImage"
                    @click="cropImage()"
                  >{{$t($i18n.locale).keys.generic.cropImage}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cropImage"
                    type="text"
                  />
                </div>
              </b-modal> -->
              <b-modal id="failed-upload" hide-footer>
                <template v-slot:modal-title>
                  {{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title}}
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title"
                    type="text"
                  />
                </template>
                <div class="d-block">
                  <img
                    v-if="editable"
                    :style="{marginLeft : '20px'}"
                    src="../../../assets/images/Translate-Icon.svg"
                    alt="Translation icon"
                  />
                  <h1
                    class="message1"
                    v-langErr
                    :contenteditable="editable"
                    @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1"
                  >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1}}</h1>
                  <h1
                    class="message2"
                    v-langErr
                    :contenteditable="editable"
                    @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2"
                  >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2}}</h1>
                  <div class="btnWrapper">
                    <button
                      @click="$bvModal.hide('failed-upload')"
                    >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing}}</button>
                    <input
                      :style="{marginLeft : '20px'}"
                      v-if="editable"
                      v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing"
                      type="text"
                    />
                  </div>
                </div>
              </b-modal>
              <div class="restrictions">
                <ul>
                  <img
                    v-if="editable"
                    :style="{ marginLeft: '20px' }"
                    src="../../../assets/images/Translate-Icon.svg"
                    alt="Translation icon"
                  />
                  <li
                    v-langErr
                    :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions1 =
                        $event.target.innerText
                    "
                  >
                    {{
                    $t($i18n.locale).keys.questionnaire.uploadImage
                    .restrictions1
                    }}
                  </li>
                  <!-- <li
                    v-langErr :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions2 =
                        $event.target.innerText
                    "
                  >
                    {{
                      $t($i18n.locale).keys.questionnaire.uploadImage
                        .restrictions2
                    }}
                  </li>-->
                  <li
                    v-langErr
                    :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions3 =
                        $event.target.innerText
                    "
                  >
                    {{
                    $t($i18n.locale).keys.questionnaire.uploadImage
                    .restrictions3
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="rightContainer">
            <!-- <div class="importFileWrapper">
              <router-link
                to="/membership-area/user-profile/gallery"
                class="importImageWrapper"
              >
                <img
                  src="../../../assets/images/facebook_icon.svg"
                  alt="Upload icon"
                />
                <div
                  class="importText"
                  v-langErr :contenteditable="editable"
                  @blur="
                    $t($i18n.locale).keys.questionnaire.uploadImage.importText =
                      $event.target.innerText
                  "
                >
                  {{
                    $t($i18n.locale).keys.questionnaire.uploadImage.importText
                  }}
                </div>
              </router-link>
              <div class="rules">
                <img
                  v-if="editable"
                  :style="{ marginLeft: '20px' }"
                  src="../../../assets/images/Translate-Icon.svg"
                  alt="Translation icon"
                />
                <ul>
                  <li
                    v-langErr :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions1 =
                        $event.target.innerText
                    "
                  >
                    {{
                      $t($i18n.locale).keys.questionnaire.uploadImage
                        .restrictions1
                    }}
                  </li>
                  <li
                    v-langErr :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions2 =
                        $event.target.innerText
                    "
                  >
                    {{
                      $t($i18n.locale).keys.questionnaire.uploadImage
                        .restrictions2
                    }}
                  </li>
                  <li
                    v-langErr :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.uploadImage.restrictions3 =
                        $event.target.innerText
                    "
                  >
                    {{
                      $t($i18n.locale).keys.questionnaire.uploadImage
                        .restrictions3
                    }}
                  </li>
                </ul>
              </div>
            </div>-->
            <div class="imagesWrapper">
              <div class="imageWrapper" v-for="(image, index) in imageData" :key="'images' + index">
                <div class="wrapper">
                  <img class="imageToShow" :src="image" />
                  <button @click="removeFile(index)">
                    <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'close-cross-transparent.svg')" alt="Delete icon" />
                  </button>
                </div>
              </div>
            </div>
            <div class="btnWrapper" v-if="imageData.length > 0">
              <div
                @click="sendImages()"
                class="btnSaveContinue"
                :class="{ disabled : lockSendImages  }"
              >{{$t($i18n.locale).keys.questionnaire.uploadImage.saveAndContinue}}</div>
              <input
                v-if="editable"
                v-model="
                    $t($i18n.locale).keys.questionnaire.uploadImage.saveAndContinue
                  "
                type="text"
              />
            </div>
          </div>
        </div>
        <router-link :to="nextLink" class="skippWrapper">
          <div
            class="skippText"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.addPhotosLater =
                $event.target.innerText
            "
          >{{ $t($i18n.locale).keys.questionnaire.uploadImage.addPhotosLater }}</div>
        </router-link>
        <!--
        <div class="additionalMessages">
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
          <div class="message1">
            <span
              class="messageSpan1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.message1 =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.questionnaire.uploadImage.message1 }}</span>
            <span
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.message1Span =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.message1Span
              }}
            </span>
          </div>
          <div
            class="message2"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.message2 =
                $event.target.innerText
            "
          >
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            {{ $t($i18n.locale).keys.questionnaire.uploadImage.message2 }}
          </div>
          <div class="message3">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <span
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.message3 =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.message3
              }}
            </span>

            <span
              class="highlightedSpan"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.message3Span =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.questionnaire.uploadImage.message3Span }}</span>

            <span
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.message3Span2 =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.message3Span2
              }}
            </span>
          </div>
        </div>
        -->
      </div>

      <div class="mobileWrapper" v-if="!desktopView">
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
        <div class="titleWrapper">
          <div
            class="title"
            v-langErr
            :contenteditable="editable"
            @blur="
            $t($i18n.locale).keys.questionnaire.uploadImage.titleWrapper =
              $event.target.innerText
          "
          >{{ $t($i18n.locale).keys.questionnaire.uploadImage.titleWrapper }}</div>
        </div>
        <div class="infoTextWrapper">
          <div
            class="infoText"
            v-langErr
            :contenteditable="editable"
            @blur="
            $t($i18n.locale).keys.questionnaire.uploadImage.infoTextWrapper =
              $event.target.innerText
          "
          >{{ $t($i18n.locale).keys.questionnaire.uploadImage.infoTextWrapper }}</div>
        </div>
        <div class="advantagesWrapper">
          <div class="advatageWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" alt="Check icon" />
            <div
              class="advantageText"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper1 =
                $event.target.innerText
            "
            >
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper1
              }}
            </div>
          </div>
          <div class="advatageWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" alt="Check icon" />
            <div
              class="advantageText"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper2 =
                $event.target.innerText
            "
            >
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper2
              }}
            </div>
          </div>
          <div class="advatageWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" alt="Check icon" />
            <div
              class="advantageText"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper3 =
                $event.target.innerText
            "
            >
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              {{
              $t($i18n.locale).keys.questionnaire.uploadImage.advatageWrapper3
              }}
            </div>
          </div>
          <div class="buttonWrapper">
            <button
              class="btnUploadImage"
              @click="$bvModal.show('bv-modal-upload-image-mobile')"
            >{{ $t($i18n.locale).keys.questionnaire.uploadImage.btnUploadImage }}</button>
            <input
              v-if="editable"
              v-model="
              $t($i18n.locale).keys.questionnaire.uploadImage.btnUploadImage
            "
              type="text"
            />
            <b-modal id="bv-modal-upload-image-mobile" hide-footer>
              <div class="d-block text-center">
                <div class="imagesWrapper">
                  <div
                    class="imageWrapper"
                    v-for="(image, index) in imageData"
                    :key="'images' + index"
                  >
                    <div class="wrapper">
                      <img class="imageToShow" :src="image" />
                      <button @click="removeFile(index)">
                        <img
                          :src="require('@/assets/images/' + $store.getters.assetsPath + 'close-cross-transparent.svg')"
                          alt="Delete icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="btnWrapper" v-if="imageData.length > 0">
                  <div
                    @click="sendImages()"
                    class="btnSaveContinue"
                    :class="{ disabled:lockSendImages }"
                  >{{$t($i18n.locale).keys.questionnaire.uploadImage.saveAndContinue}}</div>
                  <input
                    v-if="editable"
                    v-model="
                    $t($i18n.locale).keys.questionnaire.uploadImage.saveAndContinue
                  "
                    type="text"
                  />
                </div>
                <div class="elementsWrapper">
                  <div class="addPhoto">
                    <!-- <div class="importFromFacebook" @click="getFacebookPhotos()">
                      <span class="icon icon-facebook"></span>
                      <p
                        v-langErr
                        :contenteditable="editable"
                        @blur="
                        $t(
                          $i18n.locale
                        ).keys.questionnaire.uploadImage.addFromFacebook =
                          $event.target.innerText
                      "
                        class="userGalleryImportFromFacebook"
                      >
                        {{
                        $t($i18n.locale).keys.questionnaire.uploadImage
                        .addFromFacebook
                        }}
                      </p>

                      <input
                        :style="{ marginLeft: '20px' }"
                        v-if="editable"
                        v-model="
                        $t($i18n.locale).keys.currentUserProfile.userGallery
                          .importFromFacebook
                      "
                        type="text"
                      />
                    </div>-->
                    <label class="uploadNew" :for="idUpload">
                      <span class="icon icon-uploadNew"></span>
                      <p
                        v-langErr
                        :contenteditable="editable"
                        @blur="
                        $t(
                          $i18n.locale
                        ).keys.questionnaire.uploadImage.addFromGallery =
                          $event.target.innerText
                      "
                        class="userGalleryUploadNew"
                      >
                        {{
                        $t($i18n.locale).keys.questionnaire.uploadImage
                        .addFromGallery
                        }}
                      </p>
                      <input
                        :style="{ marginLeft: '20px' }"
                        v-if="editable"
                        v-model="
                        $t($i18n.locale).keys.currentUserProfile.userGallery
                          .uploadNew
                      "
                        type="text"
                      />
                    </label>
                    <div style="display: none" class="file-upload-form">
                      <input
                        type="file"
                        :id="idUpload"
                        @change="uploadImage($event)"
                        accept="image/png, image/jpg, image/jpeg"
                      />
                    </div>
                    <!-- <b-modal id="crop-image" hide-footer>
                    <div class="clipping-area">
                      <clipper-fixed ref="clipper" :src="imageToCrop" :ratio="1" :touch-create="true"></clipper-fixed>
                    </div>
                      <div class="buttonWrapper">
                        <button
                          class="btnImage"
                          @click="cropImage()"
                        >{{ $t($i18n.locale).keys.generic.cropImage }}</button>
                        <input
                          :style="{ marginLeft: '20px' }"
                          v-if="editable"
                          v-model="$t($i18n.locale).keys.generic.cropImage"
                          type="text"
                        />
                      </div>
                    </b-modal> -->
                    <b-modal id="failed-upload" hide-footer>
                      <template v-slot:modal-title>
                        {{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title}}
                        <input
                          :style="{marginLeft : '20px'}"
                          v-if="editable"
                          v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title"
                          type="text"
                        />
                      </template>
                      <div class="d-block">
                        <img
                          v-if="editable"
                          :style="{marginLeft : '20px'}"
                          src="../../../assets/images/Translate-Icon.svg"
                          alt="Translation icon"
                        />
                        <h1
                          class="message1"
                          v-langErr
                          :contenteditable="editable"
                          @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1"
                        >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1}}</h1>
                        <h1
                          class="message2"
                          v-langErr
                          :contenteditable="editable"
                          @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2"
                        >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2}}</h1>
                        <div class="btnWrapper">
                          <button
                            @click="$bvModal.hide('failed-upload')"
                          >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing}}</button>
                          <input
                            :style="{marginLeft : '20px'}"
                            v-if="editable"
                            v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing"
                            type="text"
                          />
                        </div>
                      </div>
                    </b-modal>
                    <!--
                    <div class="takeNew" v-b-modal.modal-imageUpload>
                      <span class="icon icon-takeNew"></span>
                      <p
                        v-langErr
                        :contenteditable="editable"
                        @blur="
                        $t(
                          $i18n.locale
                        ).keys.questionnaire.uploadImage.takeAphoto =
                          $event.target.innerText
                      "
                        class="cameraTakeNew"
                      >
                        {{
                        $t($i18n.locale).keys.questionnaire.uploadImage
                        .takeAphoto
                        }}
                      </p>
                    </div>
                    -->
                    <input
                      :style="{ marginLeft: '20px' }"
                      v-if="editable"
                      v-model="
                      $t($i18n.locale).keys.currentUserProfile.userGallery
                        .camera.takeNew
                    "
                      type="text"
                    />
                    <b-modal
                      id="modal-imageUpload"
                      hide-footer
                      @hidden="resetImage()"
                      @shown="onStart()"
                    >
                      <template
                        v-slot:modal-title
                      >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.takeApicture}}</template>
                      <code v-if="device" class="d-flex justify-content-center">{{ device.label }}</code>
                      <div class="webcamWrapper">
                        <vue-web-cam
                          ref="webcam"
                          class="webcam"
                          :device-id="deviceId"
                          width="100%"
                          height="240"
                          @cameras="onCameras"
                          @error="onError"
                          @camera-change="onCameraChange"
                        />
                        <!-- @started="onStarted" -->
                        <!-- @stopped="onStopped" -->
                        <!-- <figure class="figure">
                <img :src="croppedImage" class="img-responsive" />
                        </figure>-->
                      </div>
                      <div class="chooseDeviceWrapper">
                        <select class="chooseDevice" v-model="camera">
                          <option>{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.selectDevice}}</option>
                          <option
                            v-for="(device, index) in devices"
                            :key="device.deviceId"
                            :value="device.deviceId"
                          >{{ device.label != '' ? device.label : 'Camera' + (index+1) }}</option>
                        </select>
                      </div>
                      <div class="buttonsWrapper">
                        <button
                          class="btnCamera"
                          @click="onCapture"
                        >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.capturePhoto}}</button>
                        <input
                          :style="{marginLeft : '20px'}"
                          v-if="editable"
                          v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.capturePhoto"
                          type="text"
                        />
                        <!-- <button
                class="btnCamera"
                @click="removeCapturedPhoto"
                :disabled="!croppedImage"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.removePhoto}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.removePhoto"
                type="text"
              />
              <button
                class="btnCamera"
                @click="onStop"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.stopCamera}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.stopCamera"
                type="text"
              />
              <button
                class="btnCamera"
                @click="onStart"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.startCamera}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.startCamera"
                type="text"
                        />-->
                      </div>
                    </b-modal>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
          <div class="laterWrapper">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <div
              class="laterMessage"
              @click="$bvModal.show('bv-modal-skip-upload')"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.questionnaire.uploadImage.laterMessage =
                $event.target.innerText
            "
            >{{ $t($i18n.locale).keys.questionnaire.uploadImage.laterMessage }}</div>
            <b-modal id="bv-modal-skip-upload" hide-footer>
              <template
                v-slot:modal-title
                v-langErr
                :contenteditable="editable"
                @blur="
                $t($i18n.locale).keys.questionnaire.uploadImage.areYouSure =
                  $event.target.innerText
              "
              >{{ $t($i18n.locale).keys.questionnaire.uploadImage.areYouSure }}</template>
              <div class="d-block">
                <p
                  class="noPhotoMessage"
                  v-langErr
                  :contenteditable="editable"
                  @blur="
                  $t($i18n.locale).keys.questionnaire.uploadImage.noPhoto =
                    $event.target.innerText
                "
                >{{ $t($i18n.locale).keys.questionnaire.uploadImage.noPhoto }}</p>
                <p
                  class="addPhotoMessage"
                  v-langErr
                  :contenteditable="editable"
                  @blur="
                  $t($i18n.locale).keys.questionnaire.uploadImage.strugglingToFind =
                    $event.target.innerText
                "
                >
                  {{
                  $t($i18n.locale).keys.questionnaire.uploadImage
                  .strugglingToFind
                  }}
                </p>
              </div>
              <router-link :to="nextLink">
                <button class="skipBtn">{{ $t($i18n.locale).keys.questionnaire.uploadImage.skip }}</button>
                <input
                  v-if="editable"
                  v-model="
              $t($i18n.locale).keys.questionnaire.uploadImage.skip
            "
                  type="text"
                />
              </router-link>
              <button
                @click="
                $bvModal.hide('bv-modal-skip-upload');
                $bvModal.show('bv-modal-upload-image-mobile');
              "
                class="addPhotoBtn"
              >{{ $t($i18n.locale).keys.questionnaire.uploadImage.addAphoto }}</button>
              <input
                v-if="editable"
                v-model="
              $$t($i18n.locale).keys.questionnaire.uploadImage.addAphoto
            "
                type="text"
              />
            </b-modal>
          </div>
        </div>
        <!-- <div class="buttonWrapper">
        <button
          class="btnUploadImage"
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.questionnaire.uploadImage.btnUploadImage =
              $event.target.innerText
          "
        >
          {{ $t($i18n.locale).keys.questionnaire.uploadImage.btnUploadImage }}
        </button>
        <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.questionnaire.uploadImage.btnUploadImage
          "
          type="text"
        />
        </div>-->
        <!-- <div class="laterWrapper">
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
        <div
          class="laterMessage"
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.questionnaire.uploadImage.laterWrapper =
              $event.target.innerText
          "
        >
          {{ $t($i18n.locale).keys.questionnaire.uploadImage.laterWrapper }}
        </div>
        </div>-->
      </div>
      <div v-if="!desktopView" class="container-main delayMessageWrapper">
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
        <div
          class="delayMessage"
          v-langErr
          :contenteditable="editable"
          @blur="
          $t($i18n.locale).keys.questionnaire.uploadImage.delayMessage =
            $event.target.innerText
        "
        >
          {{ $t($i18n.locale).keys.questionnaire.uploadImage.delayMessage }}

        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div>-->
</template>

<script>
import { forEach } from "lodash";
import { WebCam } from "vue-web-cam";
// import { clipperBasic } from "vuejs-clipper";
// import { clipperFixed } from "vuejs-clipper";
// import FbAllPhotos from "facebook-javascript-all-photos";


export default {
  name: "UploadImage",
  components: {
    "vue-web-cam": WebCam,
    // clipperBasic,
    // clipperFixed
  },
  data() {
    return {
      desktopView: true,
      publicPath: process.env.BASE_URL,
      idUpload: "imageUpload",
      imageData: [],
      camera: null,
      deviceId: null,
      devices: [],
      capturedImage: null,
      fetchedImages: [],
      imageToCrop: "",
      croppedImage: "",
      webcamUpload: false,
      bufferData: [],
      componentFetchedKey: 0,
      deletionId: 0,
      lockSendImages: false,
      nextLink: "/membership-area/matches?reg=1"
    };
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  created() {
    this.$store.dispatch("getEmailConfirmed");
  },
  mounted() {
    this.scrollFunction();
    this.viewSizeCheck();
    this.$store.dispatch("fetchImages").then(res => {
      //this.$store.dispatch("hideLoader");
      if (res == "error") {
        //this.$store.dispatch("setErrorVisibility", true);
      } else {
        this.$store.dispatch("showContent");
        this.fetchedImages = this.$store.getters.getImages.photos;
      }
    });

  },

  methods: {
    scrollFunction() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    viewSizeCheck() {
      if (window.innerWidth > 768) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
        this.$store.dispatch('generateMatches');
      }
    },
    /*
    getFacebookPhotos() {
      const fbAllPhotos = new FbAllPhotos();
      fbAllPhotos
        .getAlbums(5)
        .then(fullObj => {
          console.log(fullObj);
        })
        .catch(errorMsg => {
          if (errorMsg === "fbError") {
            console.log(fbAllPhotos.errorObj.message);
          } else if (errorMsg === "noAlbums") {
            console.log("No albums");
          }
        });
    },
    */
    // cropImage() {
    //   const canvas = this.$refs.clipper.clip({ wPixel: 300 });
    //   const img = canvas.toDataURL();
    //   if (this.webcamUpload) {
    //     this.croppedImage = img;
    //   }
    //   console.log(img);
    //   this.imageData.push(img);
    //   // this.$refs.webcam.stop();
    //   this.$bvModal.hide("crop-image");
    //   this.$bvModal.hide("modal-imageUpload");
    // },
    uploadImage(event) {
      let files = event.target.files || event.dataTransfer.files;

      // if (this.imageData.length > 2) {
      //   return false;
      // }
      // console.log(this.imageData, 'img data 1')
      var reader = new FileReader();
      let formData = new FormData();
      // console.log(files, 'files');
      if (files) {
        forEach(files, (value, index) => {
          formData.append("file" + index, value);
          // console.log(formData, 'formData')
          console.log(value.type, "value type");
          const longFormat = value.type;
          const format = longFormat.split("/")[1];
          // const shortFormat = format.substring(0, 3);
          const formatObj = {
            format: "." + format
          };
          this.$store.dispatch("sendImages", formatObj).then(() => {
            //       this.files(value).then((resp) => {
            // this.$store.dispatch("sendImages2", resp)

            //     })
            if (this.$store.getters.getErrors == null) {
              reader.onload = e => {
                this.imageData.push(e.target.result);
                // this.imageToCrop = e.target.result;
                // console.log(this.imageData, 'img data 2');
                this.webcamUpload = false;
                document.getElementById(this.idUpload).value = "";
                this.$bvModal.hide("modal-imageUpload");

                // this.$bvModal.show("crop-image");
              };
              // reader.readAsBinaryString(value);
              reader.readAsDataURL(value);
              // console.log(formData, 'formData 2')
            } else {
              this.$bvModal.show("failed-upload");
            }
          });
        });
      }
    },
    removeFile(index) {
      this.imageData.splice(index, 1);
      document.getElementById(this.idUpload).value = "";
    },
    sendImages() {
      if(this.lockSendImages) return false;
      this.lockSendImages = true;
      // this.blobData
      //console.log(this.imageData, "content type");
      // this.imageData.forEach(item => {
      //   const contentType = item.split(';')[0].split(':')[1];
      //   const b64Data = item.split(',')[1];
      //   const blob = base64StringToBlob(b64Data, contentType);
      //   this.blobData.push(blob);
      // });
      // console.log(this.blobData, 'blob data')

      this.bufferData = this.imageData.map(image => {
        const imageSplit = image.split(";base64,").pop();
        const buffer = Buffer.from(imageSplit, "base64");
        const options = {
          body: buffer
        };
        const returnedBuffer = {
          buffer: buffer,
          options: options
        };
        return returnedBuffer;
      });
      // this.imageData.forEach(item => {
      //   fetch(item)
      //     .then(res => res.blob())
      //     .then(blob => {
      //       this.binaryImages.push(blob.stream())
      //       // console.log("here is your binary: ", blob)
      //     })
      // });
      // console.log(this.binaryImages, 'binar im')

      // this.$store.dispatch("sendImages2", this.binaryImages);
      this.$store.dispatch("sendImages2", this.bufferData).then(() => {
        console.log('upload finished OK');
        //location.reload();
        //this.$router.push("/premium-plans?signup=1");
        this.$router.push(this.nextLink);
      }, error => {
        console.log('UPLOAD ERROR');
        console.log(error);
        //this.$router.push("/premium-plans?signup=1");
        this.$router.push(this.nextLink);
      });

      // .then(res => {
      //   // //this.$store.dispatch("hideLoader");
      //   // if (res == "error") {
      //   //   //this.$store.dispatch("setErrorVisibility", true);
      //   // } else {
      //   //   this.$store.dispatch("showContent");
      //   // }
      // });
    },
    onCapture() {
      this.capturedImage = this.$refs.webcam.capture();
      // this.imageToCrop = this.capturedImage;
      const format = this.capturedImage.split("/")[1].split(";")[0];

      const formatObj = {
        format: "." + format
      };
      this.$store.dispatch("sendImages", formatObj).then(() => {
        if (this.$store.getters.getErrors == null) {
          this.webcamUpload = true;
          this.$bvModal.hide("modal-imageUpload");

          // this.$bvModal.show("crop-image");
        } else {
          this.$bvModal.show("failed-upload");
        }
      });

      // if (this.imageData.length < 3) {
      //   this.imageData.push(this.capturedImage);
      // }
    },
    // onStarted(stream) {
    //   console.log("On Started Event", stream);
    // },
    // onStopped(stream) {
    //   console.log("On Stopped Event", stream);
    // },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      // console.log("On Camera Change Event", deviceId);
    },
    removeCapturedPhoto() {
      this.capturedImage = null;
      this.croppedImage = null;
      this.imageData.pop();
    },
    resetImage() {
      this.capturedImage = null;
      this.croppedImage = null;
    }
  }
};
</script>

<style lang="stylus" scoped></style>
