var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'premiumTest': _vm.premiumTest, 'variant2': _vm.variant == 2 },attrs:{"id":"personalityTest"}},[(!_vm.premiumTest && _vm.variant != 2)?_c('div',{staticClass:"simpleHeader"},[_c('div',{staticClass:"container topnavWrapper"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"logoWrapper col-12 col-md-10"},[_c('img',{attrs:{"src":_vm.$store.getters.mainLogos.desktop,"alt":"Logo"}})])])])]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"questionWrapper",class:{
          'col-12 col-md-10': _vm.variant != 2
        }},[(_vm.variant != 2 && _vm.question)?_c('ProgressBar',{staticClass:"ProgressBar",attrs:{"progressData":{
            percentage: _vm.percentage,
            currentQuestion: _vm.question.orderId,
            totalQuestions: _vm.totalQuestions
          }}}):_vm._e(),(_vm.variant == 2)?_c('div',{staticClass:"questionWrapperHeader"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.$store.getters.mainLogos.desktop,"alt":"Logo"}}),_c('p',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.headerSubline)+" ")])]):_vm._e(),(!_vm.loadingQuestion && !_vm.hideBack && _vm.variant != 2 && _vm.question)?_c('div',{staticClass:"changeQuestion goBack",attrs:{"hidden":_vm.question.orderId <= 1,"disabled":_vm.disableBack},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),(!_vm.loadingQuestion && !_vm.hideForward && _vm.variant != 2 && _vm.question)?_c('div',{staticClass:"changeQuestion goForward",attrs:{"hidden":_vm.question.orderId > _vm.steps_finished,"disabled":_vm.disableForward},on:{"click":function($event){return _vm.goForward()}}}):_vm._e(),_c('div',{staticClass:"questionBox",class:[
            _vm.type == 1 ? 'questionBox1' : '',
            _vm.type == 2 ? 'questionBox2' : '',
            _vm.type == 3 ? 'questionBox3' : '',
            _vm.type == 4 ? 'questionBox4' : '',
            _vm.type == 5 ? 'questionBox5' : '',
            _vm.type == 6 ? 'questionBox6' : '',
            _vm.type == 7 ? 'questionBox7' : '',
            _vm.type == 8 ? 'questionBox8' : '',
            _vm.type == 9 ? 'questionBox9' : '',
            _vm.type == 10 ? 'questionBox10' : ''
          ]},[(_vm.loadingQuestion)?_c('Spinner'):_vm._e(),(!_vm.loadingQuestion)?[_c('div',{attrs:{"id":"question-box-start"}}),(_vm.questionError)?_c('div',{staticClass:"questionError global"},_vm._l((_vm.questionError),function(error,index){return _c('p',{key:index},[_vm._v(_vm._s(error))])}),0):_vm._e(),(_vm.variant == 2 && _vm.question)?_c('div',{staticClass:"chatStyleQuestion"},[_c('img',{staticClass:"chatAvatar",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + '50sloveAvatar.jpg')}}),_c('p',{staticClass:"chatQuestion"},[_c('span',{staticClass:"chatQuestionText",domProps:{"innerHTML":_vm._s(_vm.question.translations[_vm.$store.getters.selectedLanguage].question)}}),_vm._m(0)])]):_vm._e(),(_vm.type == 1)?_c('QuestionType1',{key:_vm.componentKey,staticClass:"questiontype1",attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"only-post-answer":_vm.onlyPostAnswer,"only-load-answer":_vm.onlyLoadQuestion,"only-put-answer":_vm.onlyPutAnswer,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 2)?_c('QuestionType2',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 3)?_c('QuestionType3',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 4)?_c('QuestionType4',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 5)?_c('QuestionType5',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 6)?_c('QuestionType6',{key:_vm.componentKey,attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 7)?_c('QuestionType7',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 8)?_c('QuestionType8',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 9)?_c('QuestionType9',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable,
                questionData: _vm.questionData
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e(),(_vm.type == 10)?_c('QuestionType10',{attrs:{"questionData":{
                question: _vm.question,
                answered: _vm.answered,
                skippable: _vm.skippable,
                questionData: _vm.questionData
              },"variant":_vm.variant,"navData":{
                hideBack: _vm.hideBack,
                hideNext: _vm.hideForward,
                totalQuestions: _vm.totalQuestions,
                percentage: _vm.percentage
              }},on:{"post-answer":_vm.postAnswer,"put-answer":_vm.putAnswer,"load-next-question":_vm.loadNextQuestion,"goBack":_vm.goBack}}):_vm._e()]:_vm._e()],2)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"chatQuestionDots"},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"})])
}]

export { render, staticRenderFns }