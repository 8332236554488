<template>

  <div id="userPersonality">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.currentUserProfile.userPersonality.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.currentUserProfile.userPersonality.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>

    <div id="personalityPremiumInfo" v-if="showPremiumInfo">
      <div class="infoText">
        {{ $t($i18n.locale + '.keys.currentUserProfile.userPersonality.personaltiyPremiumInfoText') }}
      </div>
       <router-link to="/premium-plans">
        <div class="buttonWrapper">
            <button class="btnContinue">
              {{ $t($i18n.locale + '.keys.currentUserProfile.userPersonality.personaltiyPremiumInfoButton') }}
            </button>
        </div>
       </router-link>
    </div>


    <PersonalityTest v-if="showPersonalityTest" :premiumTest="true" @finished="setPremiumTestState" />
    <div id="personalityTestInfo" v-else-if="showPersonalityTestInfo">
      <div class="infoText">
        {{ $t($i18n.locale + '.keys.currentUserProfile.userPersonality.personaltiyTestInfoText') }}
      </div>
      <div class="buttonWrapper">
          <button class="btnContinue" @click="showPersonalityTest = true;showPersonalityTestInfo =false;">
            {{ $t($i18n.locale + '.keys.currentUserProfile.userPersonality.personaltiyTestInfoButton') }}
          </button>
      </div>
    </div>
    <div v-else-if="getUserPersonality && !isLoading" class="personalityWrapper">
      <div v-for="(item, index) in getUserPersonality.scores" :key="index" class="personalityTraitWrapper">
        <div class="personalityTraitTitle">{{ $t($i18n.locale).keys.personality[index] }}</div>
        <div v-if="getUserPersonality.texts[index][(item-1)]" class="personalityTraitRendering">
          <p
            class="personalityCharacteristic"
            v-bind:style="{ marginLeft: getUserPersonality.texts[index][(item-1)].left + '%' }"
          >{{ getUserPersonality.texts[index][(item-1)].characteristic }}</p>
          <div class="personalityPercentageWrapper">
            <div class="personalityPercentage"></div>
            <div
              v-bind:style="{ left: getUserPersonality.texts[index][(item-1)].left + '%' }"
              class="personalityPercentageActive"
            ></div>
          </div>
          <div class="comparisonToPopulationMessages">
            <p class="leftMessage">{{ getUserPersonality.texts[index][(item-1)].percentageText1 }}</p>
            <p class="rightMessage">{{ getUserPersonality.texts[index][(item-1)].percentageText2 }}</p>
          </div>
          <div class="personalityTraitDescriptionWrapper">
            <p class="personalityDescription">{{ getUserPersonality.texts[index][(item-1)].traitDescription }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PersonalityTest from "@/components/personality/PersonalityTest.vue";

export default {
  name: "UserPersonality",
  components: {
    PersonalityTest
  },
  data() {
    return {
      isLoading: true,
      showPersonalityTest: false,
      showPersonalityTestInfo: false,
      showPremiumInfo:false,

    }
  },
  computed: {
    ...mapGetters(["MemberData", "getUserPersonality", "CurrentProfileID"]),
    memberName() {
      return this.$store.getters.getProfileCard.name.answer[0];
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    console.log('personalityTest', this.$store.getters.personalityTest);
    if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')){
      this.showPremiumInfo = true;
      this.isLoading = false;
      return true;
    }
    if(this.$store.getters.personalityTest){
    this.$store
            .dispatch("checkFinished")
            .then((res) => {
                console.log('check_finished', res);
                if(!res.completed || res.steps_finished != res.total) {
                  if(res.steps_finished == 0){
                    this.showPersonalityTestInfo = true;
                  }else{
                    this.showPersonalityTest = true;
                  }
                }else{
                  this.$store.dispatch("fetchUserPersonality", this.selectedLanguage)
                  .then(() => {
                    this.isLoading = false;
                  });
                }
                this.isLoading = false;
            });

    }else{
      this.$store.dispatch("fetchUserPersonality", this.selectedLanguage)
      .then(() => {
        this.isLoading = false;
      });
    }
  },
  methods: {
    setPremiumTestState(state) {
      if(state){
        this.$store.dispatch("fetchUserPersonality", this.selectedLanguage)
        .then(() => {
          this.showPersonalityTest = false;
        });
      }
      else this.showPersonalityTest = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.personalityTraitWrapper {
  margin-bottom: 15px;
}
.personalityTraitTitle {
  text-transform: capitalize;
}

</style>
