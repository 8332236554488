<template>
  <div id="completionMessage">
    <Loader v-if="$store.getters.getShowLoader" />
    <div class="completionMessageWrapper" :style="[$store.getters.getShowLoader ? {'display': 'none'} : {'display': 'flex'}]">
      <div class="mainContainerWrapper">
        <div class="logoWrapper">
          <img :src="$store.getters.mainLogos.desktop" alt="Logo" />
        </div>
        <div class="mainContainer">
          <div class="titleWrapper">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <div
              class="title"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.completionMessage.title =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.questionnaire.completionMessage.title }}</div>
          </div>
          <div class="mainTextWrapper">
            <div class="mainText">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              <span class="editable"
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.questionnaire.completionMessage.mainText =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.questionnaire.completionMessage.mainText
                }}
              </span>
              <br />
              <span v-langErr :contenteditable="editable"
                @blur="
                $t($i18n.locale).keys.questionnaire.completionMessage.mainText2 =
                  $event.target.innerText
              ">
              {{
              $t($i18n.locale).keys.questionnaire.completionMessage.mainText2
              }}
              </span>
            </div>
          </div>
          <div class="buttonWrapper">
            <a href="" v-if="!desktopView" @click.stop.prevent="continueButton('/upload-image')">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              <button
                class="btnContinue"
                v-langErr :contenteditable="editable"
              >{{ $t($i18n.locale).keys.generic.continueBtn }}</button>
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.continueBtn"
                type="text"
              />
            </a>
            <a href="" v-if="desktopView && skipAdditionalQuestions" @click.stop.prevent="continueButton('/membership-area/home')">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              <button
                class="btnContinue"
                v-langErr :contenteditable="editable"
              >{{ $t($i18n.locale).keys.generic.continueBtn }}</button>
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.continueBtn"
                type="text"
              />
            </a>
           <a href="" v-if="desktopView && !skipAdditionalQuestions" @click.stop.prevent="continueButton('/additional-question')">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
              <button
                class="btnContinue"
                v-langErr :contenteditable="editable"
              >{{ $t($i18n.locale).keys.generic.continueBtn }}</button>
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.continueBtn"
                type="text"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/generic/Loader.vue";

export default {
  name: "CompletionMessage",
  components: {
    Loader
  },
    computed: {
      editable: function() {
        return this.$store.getters.editable;
      },
    },
    watch: {
    },
  data() {
    return {
      desktopView: true,
      additionalQuestions: [],
      skipAdditionalQuestions: false
    };
  },
  mounted() {
    this.$store.dispatch("showLoader");
    this.$store.dispatch("checkCompleted").then(() => {

      this.viewSizeCheck();
      this.scrollFunction();

      if(this.$store.getters.personalityTest){
        this.$store.dispatch("getEmailConfirmed").then(res =>{
          console.log('completion getEmailConfirmed',res)
          if(!res.data) return false;
          if(this.desktopView){
            if(this.skipAdditionalQuestions){
              this.$router.push('/membership-area/home');
            }else{
              this.$router.push('/additional-question');
            }
          }else{
            this.$router.push('/upload-image');
          }
        });
      }else{
        this.$store.dispatch("hideLoader");
      }

    });

    /*
    var gtagInt = setInterval(
      function(){
      console.log('try gtag event');
        if(typeof window.gtag == 'function'){
          console.log('send gtag event');
          clearInterval(gtagInt);
          window.gtag('event', 'conversion', {'send_to': 'AW-1037950593/TJrQCK_l7vQBEIG99-4D'});
        }
      },
      200
    );
    */
  },
  methods: {
    continueButton(link){
      this.$store.dispatch("getEmailConfirmed").then(res =>{
        console.log('continueButton getEmailConfirmed',res)
        if(!res.data) return false;
        this.$router.push(link);
      });
    },
    scrollFunction() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    checkAdditionalQuestions(){
       this.$store.dispatch("fetchAdditionalQuestions").then(res => {
        //this.$store.dispatch("hideLoader");
         if (res == "error") {
           //this.$store.dispatch("setErrorVisibility", true);
         }
         else {
            this.$store.dispatch("showContent");
            this.additionalQuestions = this.$store.getters.getAdditionalQuestions;
            if(this.additionalQuestions.length <= 7){
              this.skipAdditionalQuestions = true;
            } else {
              this.skipAdditionalQuestions = false;
            }
         }
       })
    },
    viewSizeCheck() {
      if (window.innerWidth > 768) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
      }
    }
  }
};
</script>
