<template>
<div>

</div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout")
    }
  },
  beforeMount() {
    this.logout();
  }
};
</script>