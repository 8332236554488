<template >
  <div id="userInterests">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.currentUserProfile.userInterests.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <div class="interestsWrapper">
      <div class="leftContainer">
        <div
          class="editabelHobbiesWrapper"
          :class="{editBorder: editHobbiesAnswers}"
          :key="componentHobbiesKey"
        >
          <div class="editableTitleWrapper">
            <h1
              class="editableTitle"
              v-langErr :contenteditable="editable"
              @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.hobbies.title = $event.target.innerText"
            >{{$t($i18n.locale).keys.currentUserProfile.userInterests.hobbies.title}}</h1>
            <img
              v-if="editable"
              :style="{marginLeft : 'auto', display: 'block', marginRight: '20px'}"
              src="../../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <img
              v-if="!editHobbiesAnswers"
              @click="editHobbiesAnswers = !editHobbiesAnswers"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
              alt="Edit icon"
            />
          </div>
          <p
            v-if="!editHobbiesAnswers && hobbiesChosenAnswers == null"
            class="editableAnswer"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage = $event.target.innerText"
          >{{$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage}}</p>
          <ol v-if="!editHobbiesAnswers && hobbiesChosenAnswers != null">
            <span
              v-for="(answer, index) in hobbiesChosenAnswers"
              :key="index + 'answer'"
              class="editableAnswer"
            ><li v-if="Number.isInteger(answer)">{{allAnswers.questions.hobbiesQuestion.answerOptions[answer - 1].translations[$store.getters.selectedLanguage].answer}}</li> </span>
          </ol>
          <div v-if="editHobbiesAnswers" class="buttonWrapper">
            <div class="answerBtnWrapper">
              <button
                class="answerBtn"
                v-for="(answerOption, index) in  allAnswers.questions.hobbiesQuestion.answerOptions"
                @click="addAnswer(index + 1)"
                :key="index + 'answerOption'"
                :class="{active: hobbiesChosenAnswers.includes(answerOption.id - 229)}"
              >{{answerOption.translations[$store.getters.selectedLanguage].answer}}</button>
            </div>
            <button
              @click="editHobbiesAnswers = !editHobbiesAnswers; resetHobbiesAnswers()"
              class="btnCancel"
            >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.cancelBtn"
              type="text"
            />
            <button
              @click="editHobbiesAnswers = !editHobbiesAnswers; updateHobbiesAnswer(); sendHobbiesAnswer(hobbiesChosenAnswers)"
              class="btnSave"
            >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.saveBtn"
              type="text"
            />
          </div>
        </div>
        <div
          class="editabelSportsWrapper"
          :class="{editBorder: editSportsAnswers}"
          :key="componentSportsKey"
        >
          <div class="editableTitleWrapper">
            <h1
              class="editableTitle"
              v-langErr :contenteditable="editable"
              @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.sports.title = $event.target.innerText"
            >{{$t($i18n.locale).keys.currentUserProfile.userInterests.sports.title}}</h1>
            <img
              v-if="editable"
              :style="{marginLeft : 'auto', display: 'block', marginRight: '20px'}"
              src="../../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <img
              v-if="!editSportsAnswers"
              @click="editSportsAnswers = !editSportsAnswers"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
              alt="Edit icon"
            />
          </div>
          <p
            v-if="!editSportsAnswers && sportsChosenAnswers == null"
            class="editableAnswerNone"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage = $event.target.innerText"
          >{{$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage}}</p>
          <ol v-if="!editSportsAnswers && sportsChosenAnswers != null">
            <span
              v-for="(answer, index) in sportsChosenAnswers"
              :key="index + 'answer'"
              class="editableAnswer"
            ><li v-if="Number.isInteger(answer)">{{allAnswers.questions.sportsQuestion.answerOptions[answer - 1].translations[$store.getters.selectedLanguage].answer}}</li></span>
          </ol>
          <div v-if="editSportsAnswers" class="buttonWrapper">
            <div class="answerBtnWrapper">
              <button
                class="answerBtn"
                v-for="(answerOption, index) in  allAnswers.questions.sportsQuestion.answerOptions"
                @click="addSportsAnswer(index + 1)"
                :key="index + 'answerOption'"
                :class="{active: sportsChosenAnswers.includes(answerOption.id - 339)}"
              >{{answerOption.translations[$store.getters.selectedLanguage].answer}}</button>
            </div>
            <button
              @click="editSportsAnswers = !editSportsAnswers; resetSportsAnswer()"
              class="btnCancel"
            >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.cancelBtn"
              type="text"
            />
            <button
              @click="editSportsAnswers = !editSportsAnswers; updateSportsAnswer(); sendSportsAnswer(sportsChosenAnswers)"
              class="btnSave"
            >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.saveBtn"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="rightContainer">
        <div
          class="editableMusicWrapper"
          :class="{editBorder: editMusicAnswers}"
          :key="componentMusicKey"
        >
          <div class="editableTitleWrapper">
            <h1
              class="editableTitle"
              v-langErr :contenteditable="editable"
              @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.music.title = $event.target.innerText"
            >{{$t($i18n.locale).keys.currentUserProfile.userInterests.music.title}}</h1>
            <img
              v-if="editable"
              :style="{marginLeft : 'auto', display: 'block', marginRight: '20px'}"
              src="../../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
            <img
              v-if="!editMusicAnswers"
              @click="editMusicAnswers = !editMusicAnswers"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
              alt="Edit icon"
            />
          </div>
          <p
            v-if="!editSportsAnswers && musicChosenAnswers == null"
            class="editableAnswerNone"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage = $event.target.innerText"
          >{{$t($i18n.locale).keys.currentUserProfile.userInterests.noInterestsMessage}}</p>
          <ol v-if="!editMusicAnswers && musicChosenAnswers != null">
            <span
              v-for="(answer, index) in musicChosenAnswers"
              :key="index + 'musicAnswer'"
              class="editableAnswer"
            ><li v-if="Number.isInteger(answer)">{{allAnswers.questions.musicQuestion.answerOptions[answer - 1].translations[$store.getters.selectedLanguage].answer}}</li></span>
          </ol>
          <div v-if="editMusicAnswers" class="buttonWrapper">
            <div class="answerBtnWrapper">
              <button
                class="answerBtn"
                v-for="(answerOption, index) in  allAnswers.questions.musicQuestion.answerOptions"
                @click="addMusicAnswer(index + 1)"
                :key="index + 'answerOption'"
                :class="{active: musicChosenAnswers.includes(answerOption.id - 372)}"
              >{{answerOption.translations[$store.getters.selectedLanguage].answer}}</button>
            </div>
            <button
              @click="editMusicAnswers = !editMusicAnswers; resetMusicAnswers()"
              class="btnCancel"
            >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.cancelBtn"
              type="text"
            />
            <button
              @click="editMusicAnswers = !editMusicAnswers; updateMusicAnswer(); sendMusicAnswer(musicChosenAnswers)"
              class="btnSave"
            >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.generic.saveBtn"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserInterests",
  data() {
    return {
      allAnswers: [],
      hobbiesChosenAnswers: [],
      savedHobbiesAnswers: [],
      componentHobbiesKey: 0,
      musicChosenAnswers: [],
      savedMusicAnswers: [],
      componentMusicKey: 0,
      sportsChosenAnswers: [],
      editHobbiesAnswers: false,
      editSportsAnswers: false,
      editMusicAnswers: false,
      show: false,
      componentSportsKey: 10,
      isLoading: true,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");

    this.$store.dispatch("fetchUserInterests").then(res => {
      //this.$store.dispatch("hideLoader");
      if (res == "error") {
        //this.$store.dispatch("setErrorVisibility", true);
      } else {
        this.allAnswers = this.$store.getters.getUserInterests;

        if(this.$store.getters.getUserInterests.answers.answerHobbies){
          this.$store.getters.getUserInterests.answers.answerHobbies.answer.forEach(
            answer => {
              this.hobbiesChosenAnswers.push(answer);
            }
          );
        }
        if(this.$store.getters.getUserInterests.answers.answerMusic){
          this.$store.getters.getUserInterests.answers.answerMusic.answer.forEach(
            answer => {
              this.musicChosenAnswers.push(answer);
            }
          );
        }
        if(this.$store.getters.getUserInterests.answers.answerSports){
          this.$store.getters.getUserInterests.answers.answerSports.answer.forEach(
            answer => {
              this.sportsChosenAnswers.push(answer);
            }
          );
        }
        this.savedHobbiesAnswers = [...this.hobbiesChosenAnswers];
        this.savedMusicAnswers = [...this.musicChosenAnswers];
        this.savedSportsAnswers = [...this.sportsChosenAnswers];

        this.isLoading = false;
      }

    });
  },
  methods: {
    addAnswer(id) {
      if (this.hobbiesChosenAnswers.includes(id)) {
        let index = this.hobbiesChosenAnswers.indexOf(id);
        this.hobbiesChosenAnswers.splice(index, 1);
      } else {
        //if (this.hobbiesChosenAnswers.length <= 4) {
          this.hobbiesChosenAnswers.push(id);
        //}
      }
    },
    resetHobbiesAnswers() {
      this.hobbiesChosenAnswers = [...this.savedHobbiesAnswers];
    },
    updateHobbiesAnswer() {
      this.savedHobbiesAnswers = [...this.hobbiesChosenAnswers];
      this.componentHobbiesKey++;
    },
    sendHobbiesAnswer(answer) {
      
      if(this.allAnswers.answers.answerHobbies){
        var answerId = this.allAnswers.answers.answerHobbies.id
        const data = {
          customerAnswerId: answerId,
          answer: [...answer]
        };
        this.$store.dispatch("updateInterestsAnswer", data);
      }else {
        const data = {
          question: this.allAnswers.questions.hobbiesQuestion.id,
          answer: [...answer],
          stepId: 107,
        };
        this.$store.dispatch("sendInterestsAnswer", data);
      }

    },
    addMusicAnswer(id) {
      if (this.musicChosenAnswers.includes(id)) {
        let index = this.musicChosenAnswers.indexOf(id);
        this.musicChosenAnswers.splice(index, 1);
      } else {
        //if (this.musicChosenAnswers.length <= 4) {
          this.musicChosenAnswers.push(id);
        //}
      }
    },
    resetMusicAnswers() {
      this.musicChosenAnswers = [...this.savedMusicAnswers];
    },
    updateMusicAnswer() {
      this.savedMusicAnswers = [...this.musicChosenAnswers];
      this.componentMusicKey++;
    },
    sendMusicAnswer(answer) {

      if(this.allAnswers.answers.answerMusic){
        var answerId = this.allAnswers.answers.answerMusic.id
        const data = {
          customerAnswerId: answerId,
          answer: [...answer]
        };
        this.$store.dispatch("updateInterestsAnswer", data);
      }else {
        const data = {
          question: this.allAnswers.questions.musicQuestion.id,
          answer: [...answer],
          stepId: 107,
        };
        this.$store.dispatch("sendInterestsAnswer", data);
      }

    },
    addSportsAnswer(id) {
      if (this.sportsChosenAnswers.includes(id)) {
        let index = this.sportsChosenAnswers.indexOf(id);
        this.sportsChosenAnswers.splice(index, 1);
      } else {
        //if (this.sportsChosenAnswers.length <= 4) {
          this.sportsChosenAnswers.push(id);
        //}
      }
    },
    resetSportsAnswer() {
      this.sportsChosenAnswers = [...this.savedSportsAnswers];
    },
    updateSportsAnswer() {
      this.savedSportsAnswers = [...this.sportsChosenAnswers];
      this.componentSportsKey++;
    },
    sendSportsAnswer(answer) {
      if(this.allAnswers.answers.answerSports){
        var answerId = this.allAnswers.answers.answerSports.id
        const data = {
          customerAnswerId: answerId,
          answer: [...answer]
        };
        this.$store.dispatch("updateInterestsAnswer", data);
      }else {
        const data = {
          question: this.allAnswers.questions.sportsQuestion.id,
          answer: [...answer],
          stepId: 107,
        };
        this.$store.dispatch("sendInterestsAnswer", data);
      }
    }
  }
};
</script>
