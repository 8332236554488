export default {
  set_completed(state, completed) {
    state.completed = completed;
  },
  lastCompleted(state, lastNumber) {
    state.lastCompleted = lastNumber;
  },
  skippedQuestions(state, skippedQuestions) {
    state.skippedQuestions = skippedQuestions;
  },
  removeSkippedQuestion: state => {
    state.skippedQuestions.shift();
  },
  setSkippedMode: state => {
    state.skippedMode = true;
  },
  setAdditionalQuestions (state, payload) {
    state.additionalQuestions = payload;
  },
  removeAdditionalQuestion(state){
    // console.log(state.additionalQuestions, '1');
      state.additionalQuestions.shift();
    // console.log(state.additionalQuestions, '2');
  },
  answerAdditionalQuestion(state){
    state.answered++;
  },
  questionnaireComplete(state){
    state.questionnaireCompleted = true;
  },
  additionalQuestionsAnswered(state, payload){
    state.additionalQuestionsAnswered = payload;
  },
  setRandomNames(state, randomNames){
    state.randomNames = randomNames;
  },
  hideFirstMessage(state){
    state.hideFirstMessage = true;
  },
  set_finished(state, finished) {
    state.finished = finished;
  },
  set_personalityTest(state, personalityTest) {
    state.personalityTest = personalityTest;
  },
  set_variant(state, variant) {
    state.personalityTestVariant = variant;
  }
};
