<template>
  <div id="domainMoveModal" >
      <div class="modal_backdrop" @click.stop.prevent="modalClose"></div>
      <div class="modal_wrapper">
          <div class="modal_graphic">
              <img class="icon" :src="require('@/assets/images/domain_move_icon.svg')" />
          </div>
          <div class="modal_content">
              <h3>{{translations.domainMoveModal.headline}}</h3>
              <p v-html="translations.domainMoveModal.content">
              </p>
              <button @click="modalClose">
                  {{translations.domainMoveModal.button}}
              </button>
          </div>
      </div>    
  </div>
</template>

<script>
export default {
  name: "DomainMoveModal",
  props: [],
  data() {
    return {
      translations: {
        domainMoveModal: {
          headline: 'We have moved!',
          content: `We appreciate your visit to our website.<br>
                    Please note that we have recently moved to a new location.<br>
                    You have been automatically redirected to our new URL.<br>
                    Please update your bookmarks.<br><br>
                    Thank you,<br>
                    your Loveat50 Team`,
          button: 'Continue'
        }
      }
    };
  },
  mounted() {
  },
  methods: {
    modalClose: function(){
                  this.$emit('close');
              }
  }
};
</script>

<style lang="scss" scoped>

  #domainMoveModal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background-color: rgba(16, 31, 40, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      & * {
        box-sizing: content-box;
      }
      
      .modal_backdrop {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background-color: rgba(16, 31, 40, 0.5);
      }
      .modal_wrapper {
          position: relative;
          z-index:99;
          display: flex;
          height: 345px;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          @media (max-width: 960px) {
              width:100%;
              height: auto;
              flex-direction: column;
              margin: 32px;
          }
          h3, p {
              margin: 0;
          }
          .modal_graphic {
              display: flex;
              width: 240px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              align-self: stretch;
              background: radial-gradient(51.42% 78.33% at 50% 50%, #E0E9EE 0%, #B1C7D4 100%);
              @media (max-width: 960px) {
                  width:100%;
                  height: 196px;
              }
              .icon {
                  width: 160px;
              }

          }
          .modal_content {
              display: flex;
              width: auto;
              padding: 36px 44px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 16px;
              align-self: stretch;
              h3 {
                font-size: 1.17em;
                font-weight: bold;
              }
              @media (max-width: 960px) {
                  width:auto;
              }
              button {
                  width: 232px;
                  height: 50px;
                  flex-shrink: 0;
                  border-radius: 8px;
                  border: 1px solid #72151A;
                  background: #30698D;
                  font-size: 16px;
                  color: #ffffff;
                  cursor: pointer;
                  &:hover {
                      background:  #3a7ba4;
                  }
              }
          }
      }
  }

</style>