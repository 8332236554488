<template>
  <div>
  <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/generic/Loader.vue";
export default {
  components: {
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      mobileView: false,
    };
  },

  methods: {
    windowWidth() {
      if (window.innerWidth < 576) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    checkCompleted() {
      const URL = `${this.url}/api/personality/check_completed`;
      axios.get(URL).then((response) => {
        if (response.data.data.additionalQuestionsShown !== true) {
          // if (this.mobileView) {
          //   this.$router.push("/upload-image");
          // } else {
            this.$router.push("/additional-question");
          // }
        } else {
          this.$router.push("/membership-area/home");
        }
      });
    },

    loginAuth() {
      this.$store
        .dispatch("tokenAuth", this.$route.params.token)
        .then(() => {

          this.$store.dispatch("setAdditionalTranslations");
          this.$store.dispatch("checkAnsweredQuestionaire").then((res) => {
            if (this.$route.query.showModal == 1) {
              return;
            }

            if (res == "error") {
              console.log("error");
            } else {
              console.log('this.$route.query',this.$route.query);
              if(this.$route.query.confirm == 1){
                this.$router.push("/additional-question");
                //GA EVENT
                this.$gtm.trackEvent({
                  event: 'email-confirm-finished'
                });
                return true;
              }
              if(this.$route.query.target){
                if(this.$route.query.target == 'premium') this.$router.push("/premium-plans");
                else if(this.$route.query.target == 'messages') this.$router.push("/membership-area/messages");
                else if(this.$route.query.target == 'visitors') this.$router.push("/membership-area/visitors");
                else if(this.$route.query.target == 'matches') this.$router.push("/membership-area/matches");
                else if(this.$route.query.target == 'gallery') this.$router.push("/membership-area/user-profile/gallery");
                else if(this.$route.query.target != '') this.$router.push(this.$route.query.target);
                else this.$router.push("/membership-area/home");
                return true;
              }
              if (
                this.$store.getters.getCompletedQuestionnaire
              ) {
                this.$store.dispatch("getEmailConfirmed");
                this.$router.push("/membership-area/home");
              } else {
                //First Login after Signup
                if (!this.$store.getters.hideFirstMessage) {
                  this.$router.push("/welcome-message");
                } else {
                  this.$router.push("/personality-test");
                }
              }
            }
          });
        })
        .catch((err) => console.log(err));
    },
    updatePap() {

      const URL = `${this.url}/api/personality/update/pap`;
      const a_aid = this.$route.query.a_aid;
      const a_bid = this.$route.query.a_bid;
      const data1 = this.$route.query.data1;
      const data2 = this.$route.query.data2;

      var request = {};

      if(a_aid) request.a_aid = a_aid;
      if(a_bid) request.a_bid = a_bid;
      if(data1) request.data1 = data1;
      if(data2) request.data2 = data2;

      axios.get(URL, request)


    },
  },
  beforeMount() {
    this.windowWidth();
    if(this.$route.query.a_aid) {
      this.updatePap()
    }
    this.loginAuth();
  },
};
</script>
