<template>
  <div id="matchesPreferences">
    <!--
    <MembershipMisc />
    -->
    <div class="container-lg preferencesContainer">
      <div class="leftContainer">
        <PreferencesMenu />
      </div>
      <div class="rightContainer">
        <AgeAndHeight />
        <Distance />
        <Children />
        <Education />
        <SmokingAndDrinkingHabits />
        <EthnicityAndReligion />
      </div>
    </div>
  </div>
</template>

<script>
import PreferencesMenu from "@/components/membership-area/matches-preferences/PreferencesMenu.vue";
import AgeAndHeight from "@/components/membership-area/matches-preferences/AgeAndHeight.vue";
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import Distance from "@/components/membership-area/matches-preferences/Distance.vue";
import Children from "@/components/membership-area/matches-preferences/Children.vue";
import Education from "@/components/membership-area/matches-preferences/Education.vue";
import SmokingAndDrinkingHabits from "@/components/membership-area/matches-preferences/SmokingAndDrinkingHabits.vue";
import EthnicityAndReligion from "@/components/membership-area/matches-preferences/EthnicityAndReligion.vue";

export default {
  name: "MatchesPreferences",
  components: {
    PreferencesMenu,
    AgeAndHeight,
    //MembershipMisc,
    Distance,
    Children,
    Education,
    SmokingAndDrinkingHabits,
    EthnicityAndReligion,
  },

  data() {
    return {
      idBlocks: [
        "ageAndHeight",
        "distance",
        "children",
        "education",
        "smokingAndDrinkingHabits",
        "ethnicityAndReligion",
      ],
      // activeBlock: "ageAndHeight"
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.idBlocks = [
      "ageAndHeight",
      "distance",
      "children",
      "education",
      "smokingAndDrinkingHabits",
      "ethnicityAndReligion",
    ];
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchPreferences").then(() => {
      this.$store.dispatch("hideLoader");
    });
  },

  methods: {
    handleScroll() {
      let ids = this.idBlocks.filter((number) => {
        const el = document.getElementById(number);
        if (el != null) {
          const rect = el.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
          const vertInView =
            rect.top <= windowHeight && rect.top + rect.height - 175 >= 0;
          return vertInView ? number : "";
        }
      });

      let menuItems = Array.prototype.slice.call(
        document.querySelectorAll(".menuItem")
      );
      menuItems.forEach((item) => {
        item.classList.remove("active");
      });
      document.querySelector("." + ids[0] + "").classList.add("active");
    },
  },
};
</script>
