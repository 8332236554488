<template>
  <div id="plansChoices" :class="{ mobile: isMobile, variant2: desktopPlansVariant == 2 }">

    <div class="plansMainSection container-lg" >
     
          <template v-if="!isMobile">
          <!-- Desktop Plans -->
          <DesktopPlansVariant1 v-if="desktopPlansVariant == 1"
            :comfort = "comfort"
            :classic = "classic"
            :light = "light"
            :selectedLanguage = "selectedLanguage"
            :domain = "domain"
            @selectPlan = "selectPlan"
          />

          <DesktopPlansVariant2 v-if="desktopPlansVariant == 2"
            :comfort = "comfort"
            :classic = "classic"
            :light = "light"
            :selectedLanguage = "selectedLanguage"
            :domain = "domain"
            @selectPlan = "selectPlan"
          />

          
          <!-- End Desktop Plans -->
        </template>

        <template v-else>
          <!-- Mobile Plans -->
          <MobilePlansVariant1 v-if="mobilePlansVariant == 1"
            :comfort = "comfort"
            :classic = "classic"
            :light = "light"
            :selectedLanguage = "selectedLanguage"
            @selectPlan = "selectPlan"
            :domain = "domain"
           />

          <MobilePlansVariant2 v-if="mobilePlansVariant == 2"
            :comfort = "comfort"
            :classic = "classic"
            :light = "light"
            :selectedLanguage = "selectedLanguage"
            @selectPlan = "selectPlan"
            :domain = "domain"
           />
          <!-- End Mobile Plans -->
        </template>
        
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DesktopPlansVariant1 from "@/components/membership-area/premium-plans/plansVariants/DesktopPlans_variant1.vue";
import DesktopPlansVariant2 from "@/components/membership-area/premium-plans/plansVariants/DesktopPlans_variant2.vue";
import MobilePlansVariant1 from "@/components/membership-area/premium-plans/plansVariants/MobilePlans_variant1.vue";
import MobilePlansVariant2 from "@/components/membership-area/premium-plans/plansVariants/MobilePlans_variant2.vue";

export default {
  name: "PlansChoices",
  components: {
    MobilePlansVariant1,
    MobilePlansVariant2,
    DesktopPlansVariant2,
    DesktopPlansVariant1,
  },
  props: [
    "desktopPlansVariant",
    "mobilePlansVariant"
  ],
  data() {
    return {
      BASE_URL: process.env.VUE_APP_URL,
      domain: window.domain,
    };
  },
  
  mounted() {
   
   if(this.isMobile){
    document.addEventListener('focusout', this.focusoutHandler);
   }
  },
  beforeDestroy() {
    document.removeEventListener('focusout', this.focusoutHandler);
  },
  computed: {
    ...mapGetters(["getPlans", "selectedLanguage", "user", "getActivePlan", "isMobile"]),

    light: function() {
      
      if(this.getPlans[0].plansPrice.discount && this.isMobile && domain != 'ch'){
        return this.getPlans[1];
      }else{
        return this.getPlans[0];
      }
    },
    classic: function() {
      
      if(this.getPlans[0].plansPrice.discount && this.isMobile && domain != 'ch'){
        return this.getPlans[0];
      }else{
        return this.getPlans[1];
      }
    },
    comfort: function() {
      return this.getPlans[2];
    },
    
    
  },
  methods: {
    selectPlan(planId){
      //Set selected plan
      let plan = this.getPlans.find(el => el.id == planId)
      console.log('selectPlan', {planId: planId, plan: plan})
      this.$store.dispatch('setPlan', {planId: planId, plan: plan})
      //Go to payment form
      //this.nextTab(plan.plansPrice.planId)
      this.$emit('nextTab');

    },
    focusoutHandler(){
      window.scrollTo(0, 0)
      console.log('SCROLLED TOP')
    },
    
  }
    
};
</script>
