<template>
  <div id="altMatchesList" :key="componentKey">
    <div
      class="matchesWrapper"
      v-bind:class="[
        galleryView ? 'galleryView' : '',
        listView ? 'listView' : '',
        isMobile ? 'mobileView' : ''
      ]"
    >
      <div
        v-if="listView && !isMobile"
        class="matchWrapper"
        :class="{
          onlineUser: match.online
        }"
        v-for="(match, i) in filteredMatchesList"
        :key="i + match.id + match.firstname + 'list'"
      >
        <div class="imageWrapper" @click="goToProfile(match)">
          <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="matchesImage"
          />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="matchesImage"
          />
          <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
            <p
              class="newMessage"
              v-if="match.new == 1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipMatches.matchesList.new =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
          </div>

          <div
            v-if="!match.public_path && match.photo_request"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest(match.matched_user_id, $event)"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
            </div>
          <Countdown
            v-if="match.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
            :end-date="new Date(match.expires*1000)" 
            @timer-ended="countdownEnded(match)"
            :timertype="'inline_timer'"
          />
        </div>
        <div class="infoWrapper">
          <div class="nameAgeWrapper">
            <!--
            <img
              v-if="parseInt(match.entertainment) == 1"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')"
              alt="Heart icon"
            />
            -->
            <div class="nameAge">{{ match.firstname }}, {{ getAge(match.birthday) }}</div>
          </div>
          <div class="basicInfoWrapper">
            <div class="workWrapper">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'job-icon.svg')" alt="Job icon" />
              <p class="workText">{{ match.profession }}</p>
            </div>
            <div class="locationWrapper">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'house-icon.svg')" alt="Location icon" />
              <p class="locationText">{{ match.city }}</p>
            </div>
            <div class="matchWrapper">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidblue-icon.svg')" alt="Matches icon" />
              <p class="matchesPercentage">{{ match.accuracy }}</p>
            </div>
          </div>
          <div class="actionsWrapper">
            <button @click="initiateConversation(match)" class="sendMessageButton">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope-white-icon.svg')" alt="Envelope icon" />
              {{$t($i18n.locale).keys.membershipArea.membershipMatches.sendMessage}}
            </button>
            <div @click="initiateConversation(match)" class="iconChat icons"></div>

            <div
              v-if="match.icebreaker_question"
              class="iconIBQ icons"
              @click="showIBQ(match.matched_user_id, $event)"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
            ></div>
            
            <div
              @click="smileSent(match, $event)"
              :id="'match'+match.id"
              class="iconSmile icons"
              :class="{ smileSent: match.smile_sent == 1 }"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.Smile_button')"
            ></div>
            <div
              class="iconFavourites icons"
              @click="toggleBookmark(match.id, $event)"
              :id="'bookmark'+match.id"
              :class="[match.bookmark == 1 ? 'added' : '']"
              v-b-tooltip.hover
              :title="[match.bookmark == 1 ? $t($i18n.locale + '.keys.tooltips.FavRemove_button') : $t($i18n.locale + '.keys.tooltips.Fav_button')]"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-if="galleryView && !isMobile"
        @click="goToProfile(match)"
        class="matchWrapperGallery"
        :class="{
          onlineUser: match.online
        }"
        v-for="(match, i) in filteredMatchesGallery"
        :key="i + match.id + match.firstname + 'gallery'"
      >
        <div class="imageWrapper">
          <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="matchesImage"
          />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="matchesImage"
          />
          <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
            <p
              class="newMessage"
              v-if="match.new == 1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipMatches.matchesList.new =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
            <Countdown
              v-if="match.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
              :end-date="new Date(match.expires*1000)" 
              @timer-ended="countdownEnded(match)"
              :timertype="'inline_timer'"
            />
            <div class="bottomTextWrapper">
              <div class="nameWrapper">
                <div
                  class="nameAgeText"
                >{{ match.firstname }}</div>
              </div>
              <div class="wrapper">
                <div class="nameAgeTextWrapper">
                  <!--
                  <img
                    v-if="parseInt(match.entertainment) == 1"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')"
                    alt="Heart icon"
                  />
                  -->
                  <div class="nameAgeText">{{ getAge(match.birthday) }}, {{ match.city }}</div>
                </div>
                <div class="matchedPercentageWrapper">
                  <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidwhite-icon.svg')" alt="Matches icon" />
                  <p class="matchedPercentage">{{ match.accuracy }}%</p>
                </div>
              </div>
            </div>
            <div class="overlayWrapper">
              <div class="darkOverlay"></div>
              <div class="basicInfoWrapper">
                <div class="nameAgeTextWrapper">
                  <!--
                  <img
                    v-if="parseInt(match.entertainment) == 1"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')"
                    alt="Heart green"
                  />
                  -->
                  <div
                    class="nameText"
                  >{{ match.firstname }}</div>
                </div>
                <div class="locationAgeText">{{ getAge(match.birthday) }}, {{ match.city }}</div>
                <div class="jobText">{{ match.profession }}</div>
                <div
                  v-if="!match.public_path && true"
                  class="photoRequestButton"
                  @click.stop.prevent = "sendPhotoRequest(match.matched_user_id, $event)"
                  >
                  {{
                    $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
                  }}
                </div>
              </div>
              <div class="matchedPercentageWrapper">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidwhite-icon.svg')" alt="Matches icon" />
                <p class="matchedPercentage">{{ match.accuracy }}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isMobile"
        class="matchWrapperMobile"
        :class="{
          onlineUser: match.online
        }"
        v-for="(match, i) in filteredMatchesMobile"
        :key="i + match.id + match.firstname + 'mobile'"
      >
        <div class="imageWrapper" @click="goToProfile(match)">
          <img v-if="match.public_path" :src="match.public_path" class="matchesImage" />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="matchesImage"
          />
          <img
            v-else-if="!match.public_path && (parseInt(match.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="matchesImage"
          />
          <img
            v-else
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="matchesImage"
          />

          <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
            <p
              class="newMessage"
              v-if="match.new == 1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipMatches.matchesList.new =
                  $event.target.innerText
              "
            >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
          </div>
          <div
            v-if="!match.public_path && match.photo_request"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest(match.matched_user_id, $event)"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
          </div>
          <Countdown
              v-if="match.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
              :end-date="new Date(match.expires*1000)" 
              @timer-ended="countdownEnded(match)"
              :timertype="'inline_timer'"
            />
        </div>
        <div class="infoWrapper">
          <div class="nameAgeText">
            <!--
            <img
              v-if="parseInt(match.entertainment) == 1"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')"
              alt="Heart icon"
            />
            -->
            <span class="matchesUsername">{{ match.firstname > 8 ? match.firstname.substring(0, 7).concat('...') : match.firstname}}</span>, {{ getAge(match.birthday) }}
          </div>
          <div class="locationText">{{ match.city }}</div>
          <div class="iconsWrapper">
            <div @click="initiateConversation(match)" class="iconChat"></div>
            <div v-if="match.icebreaker_question" @click="showIBQ(match.matched_user_id, $event)" class="iconIBQ"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
            ></div>
            <div
              v-on="
                match.smile_sent == null || match.smile_sent == 0
                  ? { click: () => (smileSent(match), addClass(match.id)) }
                  : {}
              "
              :id="'match'+match.id"
              :class="{ smileSent: match.smile_sent == 1 }"
              class="iconSmile"
            ></div>
            <div
              @click.stop.prevent="toggleBookmark(match.id, $event)"
              :id="'bookmark'+match.id"
              v-bind:class="[match.bookmark == 1 ? 'added' : '']"
              class="iconFavourites"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <ModalIcebreakerQuestions ref="modalIBQ" />
  </div>
</template>

<script>
import ModalIcebreakerQuestions from "@/components/membership-area/ModalIcebreakerQuestions.vue";
import Countdown from "@/components/generic/Countdown.vue";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "AltMatchesList",
  components: {
    ModalIcebreakerQuestions,
    Countdown
  },
  data() {
    return {
      componentKey: 0,
      url: process.env.VUE_APP_URL,
      filteredMatchesList: [],
      filteredMatchesGallery: [],
      filteredMatchesMobile: [],
      listItems: 0,
      galleryItems: 0,
      mobileItems: 0
    };
  },

  watch: {
    allAlternativeMatches: function() {
      this.setFilteredMatches();
      $(window).trigger('scroll');
    }
  },
  computed: {
    ...mapGetters([
      "galleryView",
      "listView",
      "allFilteredMatches",
      "allAlternativeMatches",
      "getBookmarks",
      "allMatches",
      "isMobile"
    ]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    
    this.setFilteredMatches();
    this.scroll();
    //window.scrollTo(0, 0);
    $(window).resize(function(){
      console.log('resize');
      if($('body').get(0).scrollHeight <= $('body').get(0).offsetHeight && this.$store.getters.allAlternativeMatches.length > 3){
        console.log('scroll triggered');
        $(window).trigger('scroll');
      }
    }.bind(this));

    //disable tooltips on mobile
    setTimeout(() => {
      if(this.isMobile) this.$root.$emit('bv::disable::tooltip');
    }, 100);

},
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  updated() {
    //$(window).trigger('scroll');
    if($('body').get(0).scrollHeight <= $('body').get(0).offsetHeight && this.$store.getters.allAlternativeMatches.length > 3){
      console.log('scroll triggered');
      $(window).trigger('scroll');
    }


  },
  methods: {
    countdownEnded(match){
      this.$store.dispatch("removeMatchFromList", match).then(res => {
       console.log('countdownEnded res', res)
      });
    },
    smileClick(match){
      if(match.smile_sent == null || match.smile_sent == 0){
        this.smileSent(match);
        this.addClass(match.id);
      }
    },
    showIBQ(profile_id, $event){
      this.$refs.modalIBQ.show(profile_id, $event.currentTarget);
    },
    removeDuplicates(arr) {
      let filtered = arr.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filtered;
    },
    ...mapActions(["toggleBookmark", "reRenderMatchesList"]),
    scroll() {
      return false;
      /*
      // window.pageYOffset = 0;
      window.onscroll = () => {

        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 10;
        // console.log(bottomOfWindow)
        if (bottomOfWindow) {
          let fromList = this.listItems - 1;
          let toList = this.listItems;
          this.filteredMatchesList.push(
            ...this.$store.getters.allAlternativeMatches.slice(fromList, toList)
          );
          this.filteredMatchesList = this.removeDuplicates(
            this.filteredMatchesList
          ); // removing duplicates when adding new filtered matches
          // console.log(this.filteredMatchesList)
          this.listItems++;
          let from = this.galleryItems;
          let to = this.galleryItems + 2;
          this.filteredMatchesGallery.push(
            ...this.$store.getters.allAlternativeMatches.slice(from, to)
          );
          this.filteredMatchesGallery = this.removeDuplicates(
            this.filteredMatchesGallery
          ); // removing duplicates from gallery
          this.galleryItems += 2;

          let fromMobile = this.mobileItems;
          let toMobile = this.mobileItems + 2;
          this.filteredMatchesMobile.push(
            ...this.$store.getters.allAlternativeMatches.slice(
              fromMobile,
              toMobile
            )
          );

          this.filteredMatchesMobile = this.removeDuplicates(
            this.filteredMatchesMobile
          ); // removing duplicates from gallery mobile
          this.mobileItems += 2;
        }
      };
      */
    },
    setFilteredMatches() {

      const matchesList = this.$store.getters.allAlternativeMatches;//.slice(0, 3);
      this.filteredMatchesList = []; // emptying array before
      this.filteredMatchesList.push(...matchesList);
      this.listItems = matchesList.length;
      const matchesGallery = this.$store.getters.allAlternativeMatches;//.slice(0, 6);
      this.filteredMatchesGallery = []; //
      this.filteredMatchesGallery.push(...matchesGallery);
      this.galleryItems = matchesGallery.length;
      const matchesMobile = this.$store.getters.allAlternativeMatches;//.slice(0, 2);
      this.filteredMatchesMobile = []; //
      this.filteredMatchesMobile.push(...matchesMobile);
      this.mobileItems = matchesMobile.length;
    },
    initiateConversation(match) {
      console.log('initiateConversation', match);
      if(match.blockSend){
      this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });
      }
      this.$store.dispatch("initiateConversation", match).then(res => {
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          this.$store.dispatch("hideContent");
        }
      });
    },
    goToProfile(match) {
      this.$router.push(`member-profile/profile?id=${match.matched_user_id}`);
    },

    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toggleBookmark(match_id, $event) {
      console.log('toggleBookmark', $event);
      var button = $event.target;
      button.classList.add('loading');

      this.$store.dispatch("toggleBookmark", match_id).then(res => {
        console.log('toggleBookmark res', res);
        button.classList.remove('loading');
        //this.$store.dispatch("hideLoader");
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
        } else {
          this.$store.dispatch("showContent");
        }
      });
    },
    smileSent(match, $event) {
      console.log('smileSent', $event);
      if(match.smile_sent == "1") return false;
      
      //if(true){

        var button = $event.target;
        button.classList.add('loading');

      this.$store.dispatch("sendSmile", match.id).then(res => {
          console.log('smileSent res', res);
          button.classList.remove('loading');
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          //this.$store.dispatch("hideContent");
        }
      });
      //this.$store.dispatch("reRenderMatchesList");
      // this.componentKey++;
      /*
      } else {
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Smile'
        });
        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }
      */
    },
    addClass(id) {
      document.getElementById("match" + id).classList.add("smileSent");
      document.getElementById("mobile_match" + id).classList.add("smileSent");
    },
    addClassBookmark(id) {
      const currentId = "bookmark" + id;

      !document.getElementById(currentId).classList.contains("added")
        ? document.getElementById(currentId).classList.add("added")
        : "";
    },
    sendPhotoRequest(id, $event) {
      console.log('sendPhotoRequest method', $event);
      this.$store.dispatch("sendPhotoRequest", id).then(res => {
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          //this.$store.dispatch("hideContent");
        } else {
            //remove photo button here
            $($event.target).remove();
        }
      });

    },
  }
};
</script>

<style lang="scss" scoped></style>
