export default {
    load_conversations(state, payload) {
        if(payload.conversations.length > 0) {
            state.conversations = payload.conversations;
        }

    },
    load_messages(state, payload) {
        console.log('load_messages', payload);
        state.messages = payload;
    },
    load_info(state, payload) {
        state.info = payload;
    },
    new_messages(state, payload) {
        state.chatNewMessages = payload;
    },
    addActionCount(state){
        state.chatActionsCount++;
    },
    set_icebreaker(state, payload){
        state.icebreaker = payload;
    },
    update_messages(state, payload){
        //console.log('update_messages', payload);
        var messages = state.messages;
        var newMessages = [...payload.messages];

        var processedMessages = [];

        if(payload.direction == 'after'){
            processedMessages = [...messages,...newMessages];
        }else{
            processedMessages = [...newMessages,...messages];
        }

        //Filter duplicate messages from array
        processedMessages = processedMessages.filter((obj, index, self) => {
            console.log('processedMessages', index, obj,self)
            return index === self.findIndex((t) => t.messageId === obj.messageId);
        });
          
        state.messages = processedMessages;
    },
    update_conversations(state,payload){
        console.log('update_conversations', payload);

        let updatedConversations = [];

        if(payload.direction == 'before'){
            updatedConversations = payload.conversations.concat(state.conversations);
        }else if(payload.direction == 'replace'){
            updatedConversations = payload.conversations;
        }else{
            updatedConversations = state.conversations.concat(payload.conversations);
        }
        //Clean array from double entries
        console.log('updatedConversations before filter', updatedConversations);
        updatedConversations = updatedConversations.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
        )

        console.log('updatedConversations after filter', updatedConversations);

        /*
        console.log('updatedConversations 1', updatedConversations)
        updatedConversations.filter((v,i,a)=> {
            console.log('updatedConversations filter', v,i,a);
            return a.findIndex(v2=>(v2.id == v.id)) == i
        });
        console.log('updatedConversations 2', updatedConversations)
        */

        if(payload.conversations.length > 0) {
            state.conversations = updatedConversations
        }
    },
    delete_conversation(state,payload){
        var filteredConversations = state.conversations.filter((e)=>(e.matched_user_id != payload.match_id))
        state.conversations = filteredConversations;
    }
};