<template>
  <div id="userTabs">
    <router-link
      to="/membership-area/user-profile/profile"
      @click.native="onClickNavigation(0)"
      class="profile"
      :class="[$route.path == '/membership-area/user-profile/profile' ? 'profile-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p class="memberTabsProfile"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.profile = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.profile}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <router-link
      to="/membership-area/user-profile/gallery"
      @click.native="onClickNavigation(1)"
      class="gallery"
      :class="[$route.path == '/membership-area/user-profile/gallery' ? 'gallery-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p lass="memberTabsGallery"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.gallery = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.gallery}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <router-link
      v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM') || this.$store.getters.personalityTest"
      to="/membership-area/user-profile/personality"
      @click.native="onClickNavigation(2)"
      class="personality"
      :class="[
        $route.path == '/membership-area/user-profile/personality' ? 'personality-active' : ''
      ]"
    >
      <div class="image"></div>
      <div class="wrap">
        <p  class="memberTabsPersonality"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.personality = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.personality}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
     <router-link
      v-else-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')"
      to="/premium-plans"
      @click="forPremiums()"
      class="personality"
      :class="[$route.path == '/membership-area/user-profile/personality' ? 'personality-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p  class="memberTabsPersonality"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.personality = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.personality}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <router-link
      to="/membership-area/user-profile/interests"
      @click.native="onClickNavigation(3)"
      class="interests"
      :class="[$route.path == '/membership-area/user-profile/interests' ? 'interests-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p lass="memberTabsInterests"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.interests = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.interests}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "UserTabs",
  methods: {
    onClickNavigation(value) {
      this.$emit("clicked", value);
    },
    forPremiums(){
      localStorage.setItem("premium", 'false');
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  }
};
</script>

<style lang="sccs">
</style>
