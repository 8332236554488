<template>
  <div id="membershipMisc" :class="{admin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editingMode: $store.getters.editable}" >

    <div
      class="profilePhotoWrapper"
      v-if="MemberData.profileCard.profileBackground"
      :style="{ backgroundImage: mainBackground }"
    >
      <div class="container-lg photoContainer">
        <div class="profileWrapper">
          <div class="photoWrapper">
            <div
              v-if="profilePicture"
              class="profilePhoto"
              v-bind:style="{
                backgroundImage: 'url(' + profilePicture + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }"
            >
              <router-link to="/premium-plans" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && !MemberData.profileCard.picture_unblurred">
                <div class="viewButton">
                  {{$t($i18n.locale + '.keys.memberProfile.memberGallery.viewButton')}}
                </div>
              </router-link>
            </div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 1)"
              class="profilePhoto"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')
              }"
            ></div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 2)"
              class="profilePhoto"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')
              }"
            ></div>
            <div
            v-if="!profilePicture && requestPhoto"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
            </div>
            <i v-if="parseInt(MemberData.profileCard.entertainment) == 1 && showHearts" class="fa fa-heart"></i>
          </div>

          <div class="userInfoWrapper">
            <div class="infoWrapper">
              <div class="nameAgeTextWrapper">
                <!--
                <img 
                  v-if="parseInt(MemberData.profileCard.entertainment) == 1"  
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')"
                  alt="Heart icon"
                >
                -->
                <h1
                  v-if="this.MemberData.profileCard.name"
                  class="nameAgeText"
                >
                <span class="username">{{ this.MemberData.profileCard.name }}</span>
                <span>,
                  <!-- {{ this.MemberData.profileCard.name.length > 9 ? this.MemberData.profileCard.name.substring(0, 8).concat('...') : this.MemberData.profileCard.name }}, -->
                  {{ getUserAge(this.MemberData.profileCard.age) }}
                </span>
                </h1>
              </div>
              <div class="jobWrapper" :key="componentKey">
                <div class="jobInfoWrapper">
                  <img
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'job-icon.svg')"
                    alt="Job icon"
                  />
                  <p
                    v-if="this.MemberData.profileCard.name"
                    class="jobText"
                    :class="{ inReview: !jobStatus }"
                  >
                    <span class="jobDesc">{{this.MemberData.profileCard.job.answer[0]}}</span>
                    <!-- {{ this.MemberData.profileCard.job.answer[0].length > 12 ? this.MemberData.profileCard.job.answer[0].substring(0,12).concat('...') : this.MemberData.profileCard.job.answer[0] }} -->
                  </p>
                </div>

                <img
                  v-if="!jobStatus"
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                  alt="Review icon"
                />
              </div>

              <div class="locationWrapper">
                <img
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'house-icon.svg')"
                  alt="Location icon"
                />
                <p
                  v-if="this.MemberData.profileCard.name"
                  class="locationText"
                >
                  {{ this.MemberData.profileCard.location }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Countdown
          v-if="MemberData.profileCard.expires && !$store.getters.userRole.includes('ROLE_PREMIUM')"
          :end-date="new Date(MemberData.profileCard.expires*1000)" 
          @timer-ended="countdownEnded()"
          :timertype="'timer_box'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import getUserAge from "../../../helpers/userAge";
import Countdown from "@/components/generic/Countdown.vue";

export default {
  name: "MemberMisc",
  components: {
    Countdown
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      componentKey: 0,
      userJob: "",
      showEditJob: false,
      profileAge: 0,
      profileJob: "",
      profileLocation: "",
      // mainBackground: "",
      backgroundSet1: false,
      backgroundSet2: false,
      backgroundSet3: false,
      backgroundSet4: false,
      backgroundSet5: false,
      backgroundSet6: false,
      backgroundSet7: false,
      backgroundSet8: false,
      jobStatus: false
    };
  },
  computed: {
    profilePicture() {
      return this.MemberData.profileCard.profilePicture;
    },
    profileGender() {
      return this.MemberData.profileCard.gender;
    },
    requestPhoto() {
      return this.MemberData.photo_request;
    },
    mainBackground() {
      return `url(${require(`@/assets/images/${this.$store.getters.assetsPath}BG${this.MemberData.profileCard.profileBackground}.jpg`)})`;
    },
    MemberData() {
      return this.$store.getters.MemberData;
    },
    //...mapGetters(["MemberData"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    showHearts: function (){
      //Auftrag von "Markus Haas" per Whatsapp am 29.3.2024
        if(
            domain != 'at'
        ){
            return true;
        }
        else return false;
    }
  },
  watch:{
    MemberData: {
      immediate:true,
      deep:false,
      handler(newValue, oldValue){
        console.log(newValue,oldValue);
        if(!newValue) return [];
      }
    }
  },
  mounted() {
    // this.profilePicture = this.MemberData.profileCard.profilePicture;
    // const backgroundID = ;

    // this.mainBackground = this.background2;
    this.profileAge = 0;
    this.profileJob = "unemployed";
    this.profileLocation = "nowhere";
    this.jobStatus = true;
  },
  created() {
    console.log("Lifecycle CREATED");
  },
  update() {
   console.log("Lifecycle UPDATED");
  },
  destroyed() {
    console.log("Lifecycle DESTROYED");
    //this.MemberData = {};
  },
  methods: {
    countdownEnded(){
      console.log('countdownEnded')
      //Go to Dashboard
      this.$router.push('/membership-area/home')
    },
    // setBackground(background) {
    //   localStorage.setItem("mainBackground", `${background}`);
    // },
    getUserAge(birthday) {
      return getUserAge(birthday);
    },
    sendPhotoRequest() {
      console.log('sendPhotoRequest method');
      this.$store.dispatch("sendPhotoRequest", this.MemberData.profile_id).then(res => {
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          //this.$store.dispatch("hideContent");
        } else {
          this.$store.dispatch("fetchMemberData", {memberID: this.MemberData.profile_id, reset: true});
        }
      });

    }
  }
};
</script>

<style lang="scss" scoped></style>
