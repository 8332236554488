
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

const BASE_URL = process.env.VUE_APP_URL;


const Shift4_helpers = {

    _initialze(data, context){
      console.log('initSecurionPay PARENT',  {prm: context.securionPrimaryKey, sec: context.securionFallbackKey});
      Vue.loadScript('https://securionpay.com/js/securionpay.js').then(()=>{
        SecurionPay.setPublicKey(context.securionPrimaryKey);
      });
    },

    _validation(data, context){
      context.cardErrors = {};
        let valid = true;
        if (!context.$cardFormat.validateCardCVC(context.cardCvc)) {
          context.cardErrors.cardCvc = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardCVC');
          valid = false;
        }
        if  (
              !context.$cardFormat.validateCardExpiry(context.shift4Data.expireDate)
              || context.shift4Data.expireMonth == "" || context.shift4Data.expireYear == ""
            )
        {
          context.cardErrors.cardExpiry = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardExpiry');
          valid = false;
        }
        if(!context.$cardFormat.validateCardNumber(context.cardNumber)){
          context.cardErrors.cardNumber = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardNumber');
          valid = false;
        }
        return valid;
    },

    _submitData(data, context){
      console.log('submitSecurionPay CHILD');
  
      if(data.isSummary){

        console.log('submitSecurionPay SUMMARY');

        $('#mobileSubmitBtn').prop('disabled', true);
        // Send card data to SecurionPay
        SecurionPay.createCardToken(context.secForm, (token) => {
          Shift4_helpers._tokenCallback({token:token}, context)
        });

      }else {

        if(!Shift4_helpers._validation({}, context)) return false;
        var form = data.form;
        
        // Disable form submit button to prevent repeatable submits
        context.formLocked = true;
  
        if(context.$store.getters.isMobile){
          context.$emit('showSummary', {
            checkoutId: context.checkoutId, 
            processor: context.processor, 
            securionPrimaryKey: context.securionPrimaryKey,
            securionFallbackKey: context.securionFallbackKey,
            countPaymentAttempt: context.countPaymentAttempt,
            form: form
          });
        }else{
          //context.$emit('submitSecurionPay', {form: form})

          // Send card data to SecurionPay
          SecurionPay.createCardToken(form, (token) => {
            Shift4_helpers._tokenCallback({token:token}, context)
          });

        }
      
      }
    },

    _tokenCallback(data, context){

      var token = data.token; 
      console.log("_tokenCallback PARENT", token);
  
      // Check for errors
      if (token.error) {
        // Display error message
        context.paymentErrors = token.error.message;
        // Re-enable form submit button
        if(!context.$store.getters.isMobile) {
          context.formLocked = false;
  
        }
      } else {

        var amount = context.getActivePlan.plansPrice.initialFullPrice > 0 ? context.getActivePlan.plansPrice.initialFullPrice * 100 : context.getActivePlan.plansPrice.fullPrice * 100;

        //Server-Set Payment settings
        if(context.getActivePlan.plansPrice.tariffMap){
          //First Try 
          if(!context.retrySecurion && !context.securionFallback){
            console.log('ServerPaymentSettings - FIRST TRY')
            //Check for 3DS setting
            if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary['3DS']){
              //Send payment with 3DS
              console.log('ServerPaymentSettings - verify 3DS');
              SecurionPay.verifyThreeDSecure({
                amount: parseInt(amount),
                currency: context.getActivePlan.plansPrice.currencyISO,
                card: token.id
              }, (token) => {
                Shift4_helpers._finalizePayment({token:token}, context)
              });
            }else{
              //Send payment without 3DS
              console.log('ServerPaymentSettings - skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context) 
            }
          }
          //First Retry
          else if (context.retrySecurion && !context.securionFallback){
            console.log('ServerPaymentSettings - FIRST RETRY')
            //Check for 3DS setting
            if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary['3DS']){
              //Send payment without 3DS
              console.log('ServerPaymentSettings - skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context) 
            }else{
              //Send payment with 3DS
              console.log('ServerPaymentSettings - verify 3DS');
              SecurionPay.verifyThreeDSecure({
                amount: parseInt(amount),
                currency: context.getActivePlan.plansPrice.currencyISO,
                card: token.id
              }, (token) => {
                Shift4_helpers._finalizePayment({token:token}, context)
              });
            }
          }
          //Second Try (with fallback)
          else if(!context.retrySecurion && context.securionFallback){
            console.log('ServerPaymentSettings - SECOND TRY');
            //Check for 3DS setting
            if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback['3DS']){
              //Send payment with 3DS
              console.log('ServerPaymentSettings - verify 3DS');
              SecurionPay.verifyThreeDSecure({
                amount: parseInt(amount),
                currency: context.getActivePlan.plansPrice.currencyISO,
                card: token.id
              }, (token) => {
                Shift4_helpers._finalizePayment({token:token}, context)
              });
            }else{
              //Send payment without 3DS
              console.log('ServerPaymentSettings - skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context) 
            }
          }
          //Fallback Retry
          else if (context.retrySecurion && context.securionFallback){
            console.log('ServerPaymentSettings - SECOND RETRY');
            //Check for 3DS setting
            if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback['3DS']){
              //Send payment without 3DS
              console.log('ServerPaymentSettings - skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context) 
            }else{
              //Send payment with 3DS
              console.log('ServerPaymentSettings - verify 3DS');
              SecurionPay.verifyThreeDSecure({
                amount: parseInt(amount),
                currency: context.getActivePlan.plansPrice.currencyISO,
                card: token.id
              }, (token) => {
                Shift4_helpers._finalizePayment({token:token}, context)
              });
            }
          }
          //Final return to prevent further processing
          return true;
        }

        //Country Specific Payment Settings
        switch(domain){
          case 'au': 
            //AU Settings
            console.log('skip 3DS');
            Shift4_helpers._finalizePayment({token:token}, context)

          break;
          case 'nz':
            //NZ Settings
            if(!context.retrySecurion){
              console.log('skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context)
            }else{
              console.log('verify 3DS');
              SecurionPay.verifyThreeDSecure({
                amount: parseInt(amount),
                currency: context.getActivePlan.plansPrice.currencyISO,
                card: token.id
              }, (token) => {
                Shift4_helpers._finalizePayment({token:token}, context)
              });
            }
          
          break;
          case 'uk':
            //UK Settings
            console.log('verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          
          break;
          case 'ch':
            //CH Settings
            if(context.getActivePlan.plansPrice.tariffIds.Magnius){
              console.log('skip 3DS');
              Shift4_helpers._finalizePayment({token:token}, context)    
              return true;
            }
            
              if(!context.retrySecurion && !context.securionFallback){
                console.log('verify 3DS');
                SecurionPay.verifyThreeDSecure({
                  amount: parseInt(amount),
                  currency: context.getActivePlan.plansPrice.currencyISO,
                  card: token.id
                }, (token) => {
                  Shift4_helpers._finalizePayment({token:token}, context)
                });
              }else {
                console.log('skip 3DS');
                Shift4_helpers._finalizePayment({token:token}, context)    
              }
            
          
          break;
          case 'za': 
            //ZA Settings
            console.log('skip 3DS');
            Shift4_helpers._finalizePayment({token:token}, context)

          break;
          case 'de':
            //DE Settings
            console.log('verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          
          break;
          case 'at':
            //AT Settings
            console.log('verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          
          break;
          case 'pl':
            //PL Settings
            console.log('verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          
          break;
          case 'cz':
            //CZ Settings
            console.log('verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          
          break;
          default:
            //No country found
            console.log('No country found - verify 3DS');
            SecurionPay.verifyThreeDSecure({
              amount: parseInt(amount),
              currency: context.getActivePlan.plansPrice.currencyISO,
              card: token.id
            }, (token) => {
              Shift4_helpers._finalizePayment({token:token}, context)
            });
          break;
        }

      }

    },

    _finalizePayment(data, context){

      var token = data.token;

      console.log('_finalizePayment', context.securionPrimaryKey, context.securionFallbackKey);
        // Check for errors
        if (token.error) {
          // Display error message
          context.paymentErrors = token.error.message;
          // Re-enable form submit button
          if(!context.$store.getters.isMobile) {
            context.formLocked = false;
          }
        } else {
          try {
  
            let SecData = {};
            //Send with secondary key if fallback
            if(context.securionFallback){
                SecData = {
                  'token': token.id,
                  'tariffId': context.getActivePlan.plansPrice.tariffIds.SecurionPay,
                  'pk': context.securionFallbackKey,
                  //Don't count payment tries if nocount query parameter is set
                  'count': (context.$route.query.nocount == 1) ? 0 : context.countPaymentAttempt,
                };
                console.log('Resend subscribe');
            }
            //Send with primary key by default
            else{
  
              SecData = {
                  'token': token.id,
                  'tariffId': context.getActivePlan.plansPrice.tariffIds.SecurionPay,
                  'pk': context.securionPrimaryKey,
                  //Don't count payment tries if nocount query parameter is set
                  'count': (context.$route.query.nocount == 1) ? 0 : context.countPaymentAttempt,
                };
            }
  
            context.$axios.post(
                BASE_URL + `/api/payments/SecurionPay/subscribe/${context.getActivePlan.plansPrice.planId}`,
                SecData
            ).then((resp)=>{
              if (resp.data.data.success == true && resp.data.data.token) {
              localStorage.setItem("token", resp.data.data.token);
              if(!context.$store.getters.isMobile) {
                context.formLocked = false;
              }
              
                if(resp.data.data.detailsEntered == true){
                  context.$router.push({ path: "/payments-success", query: { cid: context.$route.query.id } });
                } else if(resp.data.data.detailsEntered == false) {
                  context.$router.push({ path: "/payments-success-info", query: { cid: context.$route.query.id } });
                }
  
              } else {
                  context.$router.push("/payments-error");
              }
            }).catch((error) => {
              console.log('_finalizePayment - ERROR', error)
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
  
              //Max payment attempts reached
              if(error.response.data.data.error == "payment attempt not allowed"){
  
                //reset attempt counter
                context.countPaymentAttempt = 1;
  
                // Display error message
                var errorMessage = context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.errorMaxAttempts');
                context.paymentErrors = errorMessage;
                // Re-enable form submit button
                if(!context.$store.getters.isMobile) {
                  context.formLocked = false;
                }
  
                return false;
              } 
           
               //Don't count retries as payment attempts
               context.countPaymentAttempt = 0;

              //Server-Set Payment settings
              if(context.getActivePlan.plansPrice.tariffMap){
                //Check for retry enabling on primary key 
                if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary.retry && !context.retrySecurion && !context.securionFallback){
                  console.log('ServerPaymentSettings - retry on primary key');
                  //Set retry var
                  context.retrySecurion = true;

                  //Resubmit Securion Form
                  context.submitSecurionPay();
                }
                //Check for fallback enabling 
                else if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback && !context.securionFallback){
                  console.log('ServerPaymentSettings - set fallback key');

                  //Set fallback var
                  context.securionFallback = true;

                  //Reset retry var
                  context.retrySecurion = false;

                  //Set new Public Key to fallback
                  SecurionPay.setPublicKey(context.securionFallbackKey);

                  //Resubmit Securion Form
                  context.submitSecurionPay();
                }
                //Check for retry enabling on fallback key 
                else if(context.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback?.retry && !context.retrySecurion){
                  console.log('ServerPaymentSettings - retry on fallback key');

                  //Set retry var
                  context.retrySecurion = true;

                  //Resubmit Securion Form
                  context.submitSecurionPay();
                }else {
                  console.log('ServerPaymentSettings - all tries failed');

                  //All tries failed
                  console.log('FALLBACK ALSO FAILED');
                  //Reset attempt counter
                  context.countPaymentAttempt = 1;
                  //Final error
                  context.$router.push("/payments-error");
                  return false;
                }
                //Final return to prevent further processing
                return true;

              }



  
               //New Payment Settings Handling
              if(!context.retrySecurion && domain == 'nz'){
                //Set retry var
                context.retrySecurion = true;
  
                //Resend form
                context.submitSecurionPay();
              }
              else if(domain == 'ch'){
                
                if(!context.retrySecurion && !context.getActivePlan.plansPrice.tariffIds.Magnius){
                  //Set retry var
                  context.retrySecurion = true;

                }else if(!context.securionFallback && context.securionFallbackKey){
                  //Set fallback var
                  context.securionFallback = true;

                  //Set new Public Key to fallback
                  SecurionPay.setPublicKey(context.securionFallbackKey);

                }else {
                  //All tries failed
                  console.log('FALLBACK ALSO FAILED');
                  //Reset attempt counter
                  context.countPaymentAttempt = 1;
                  //Final error
                  context.$router.push("/payments-error");
                  return false;
                }

                //Resend form
                context.submitSecurionPay();
              }
              else {
                console.log('FALLBACK ALSO FAILED');
                //Reset attempt counter
                context.countPaymentAttempt = 1;
                //Final error
                context.$router.push("/payments-error");
                return false;
              }
            
  
            });
            
          } catch (error) {
            console.error(error);
          }
        }
    }
    
}

export default Shift4_helpers