<template>
  <div id="memberDescription">
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.memberProfile.userProfile.personalDescription.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.memberProfile.userProfile.personalDescription.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <div class="sectionItemsWrapper" v-if="!profileBlurred">
      <div class="leftContainer">
        <div v-if="this.MemberData.description" class="infoWrapper">
          <div class="heightWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'height-icon.svg')" alt="Height icon" />
            <p class="height">{{ this.formattedHeight }}</p>
          </div>
          <div class="raceWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'race-icon.svg')" alt="Race icon" />
            <p class="race">
              {{
              this.MemberData.description.ethnicy.question.answerOptions[this.MemberData.description.ethnicy.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
          <div class="religionWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'religion-icon.svg')" alt="Religion icon" />
            <p class="religion">
              {{
              this.MemberData.description.religion.question.answerOptions[this.MemberData.description.religion.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="this.MemberData.description" class="rightContainer">
        <div class="infoWrapper">
          <div class="smokingWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'smoke-icon.svg')" alt="Smoking icon" />
            <p class="smoking">
              {{
              this.MemberData.description.smoking.question.answerOptions[this.MemberData.description.smoking.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
          <div class="educationWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'studies-icon.svg')" alt="Education icon" />
            <p class="education">
              {{
              this.MemberData.description.degree.question.answerOptions[this.MemberData.description.degree.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
          <!--
          <div class="languageWrapper">
            <img src="../../../../assets/images/language-icon.svg" alt="Language icon" />
            <p class="language">
              <span v-for="(item, index) in this.MemberData.description.languages" :key="index">

                {{ item
                }}
                <span v-if="index < MemberData.description.languages.length - 1">,</span>
              </span>
            </p>
          </div>
          -->
        </div>
      </div>
    </div>
    <ProfileBlurBanner v-else />
    <div class="separatorBox"></div>

    <QuestionsAnswers />
  </div>
</template>

<script>

import ProfileBlurBanner from "@/components/generic/ProfileBlurBanner.vue";
import QuestionsAnswers from "./QuestionsAnswers";
import { mapGetters } from "vuex";

export default {
  name: "PersonalDescription",
  components: {
    QuestionsAnswers,
    ProfileBlurBanner

  },
  computed: {
    ...mapGetters(["MemberData","selectedLanguage","getCountry", "user"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      return (this.getCountry != 'uk');
    },
    formattedHeight: function(){
      if(!this.metricUnits){
        //set values to foot

        var measurement = this.MemberData.description.height.answer[0];
        var feet = parseInt(measurement);
        var fraction = measurement - feet;
        var inches = parseInt((12.0 * fraction));

        return feet + 'ft ' + inches + 'in';
      }else{
        return this.MemberData.description.height.answer[0] + "cm";
      }
    },
    profileBlurred: function(){
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 2;
    },
  },
  data() {
    return {
      language: "English"
    };
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>
