<template>
  <div id="memberTabs"
    :class="{'hidePersonality':!showPersonality}"
  >
    <router-link
      class="profile"
      :class="[
        $route.path == '/membership-area/member-profile/profile' ? 'profile-active' : ''
      ]"
      :to="'/membership-area/member-profile/profile' + profileAdd" @click.native="onClickNavigation(0)"
    >

        <div class="image"></div>
        <div class="wrapper">
          <p class="memberTabsProfile"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.memberProfile.memberTabs.profile = $event.target.innerText"
          >{{$t($i18n.locale).keys.memberProfile.memberTabs.profile}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
    </router-link>
    <router-link
      class="gallery"
      :class="[$route.path == '/membership-area/member-profile/gallery' ? 'gallery-active' : '']"
      :to="'/membership-area/member-profile/gallery' + profileAdd" @click.native="onClickNavigation(1)"
    >
        <div class="image"></div>
        <div class="wrapper">
          <p class="memberTabsGallery"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.memberProfile.memberTabs.gallery = $event.target.innerText"
          >{{$t($i18n.locale).keys.memberProfile.memberTabs.gallery}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
    </router-link>
    <router-link
      v-if="showPersonality"
      :to="'/membership-area/member-profile/personality' + profileAdd"
      @click.native="onClickNavigation(2)"
      class="personality"
        :class="[
          $route.path == '/membership-area/member-profile/personality' ? 'personality-active' : ''
        ]"
    >
        <div class="image"></div>
        <div class="wrapper">
          <p  class="memberTabsPersonality"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.memberProfile.memberTabs.personality = $event.target.innerText"
          >{{$t($i18n.locale).keys.memberProfile.memberTabs.personality}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
    </router-link>
    <router-link
      class="interests"
      :class="[
        $route.path == '/membership-area/member-profile/interests' ? 'interests-active' : ''
      ]"
      :to="'/membership-area/member-profile/interests' + profileAdd"
      @click.native="onClickNavigation(3)"
    >
      <div class="image"></div>
      <div class="wrapper">
        <p class="memberTabsInterests"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.interests = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.interests}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MemberTabs",
  props: [
    "profileID",
    "showPersonality"
  ],
  computed: {
    profileAdd: function(){
      return (this.profileID) ? '?id=' + this.profileID : '';
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {
    onClickNavigation(value) {
      this.$emit("clicked", value);
    }
  }
};
</script>

<style lang="sccs">
</style>


















