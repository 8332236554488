<template>
  <div id="questionType6" class="questionComponent">
    
    <h1 class="questionText" v-if="variant != 2">
      {{ questionData.question.translations[selectedLanguage].question }}
    </h1>
    <img
      v-if="editable"
      :style="{ marginLeft: '20px' }"
      src="../../assets/images/Translate-Icon.svg"
      alt="Translation icon"
    />
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.skippableQuestionMessage =
          $event.target.innerText
      "
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >
      {{ $t($i18n.locale).keys.generic.skippableQuestionMessage }}
    </p>

    <div :class="{'chatStyleAnswer' : variant == 2}">
      <div class="answerWrapper">
        <div
          v-if="variant == 2" 
          class="images-container variant2"
        >
          <div
            @click="clickHandler(i + 1);"
            class="imageWrapper"
            :class="{'selected' : i + 1 === activeItem,
                      'fullheight': imagesArray.length <= 2
                    }"

            v-for="(picture, i) in imagesArray"
            :key="i"
          >
            <img class="selection_img" :src="picture" />
            <div class="selection_overlay">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="images-container" v-else>
          <div
            @click="
              clickHandler(i + 1);
            "
            class="image"
            v-for="(picture, i) in imagesArray"
            :key="i"
            v-bind:style="{
              'background-image':
                'url(' + picture + ')'
            }"
            v-bind:class="[i + 1 === activeItem ? 'imageChosen' : '']"
          ></div>
        </div>
      </div>
    </div>

    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="!answer"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />
   
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType6",
  components: {
    QuestionNavigation
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.answer = "";
      } else {
        if (this.questionData.answered) {
          this.answer = this.questionData.answered.answer[0];
        }
      }
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_ASSETS,
      answer: null,
      activeItem: -1,
      imagesArray: [],
      lockSend: false,
    };
  },
  methods: {
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [this.activeItem]);
      }
      else if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: [this.activeItem],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: [this.activeItem],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    clickHandler(idx) {
      this.activeItem = idx;
      this.answer = idx;
      if(this.variant != 2){
        this.sendAnswer(); // instead of countinue btn
        this.resendAnswer();//instead of countinue btn
      }else {
        //Continue without button 
        this.sendAnswer();
      }
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.activeItem = this.questionData.answered.answer[0];
      }
    },
    disableButton() {
      if (this.questionData.answered) {
        if (this.activeItem == this.questionData.answered.answer) {
          return true;
        } else {
          return false;
        }
      } else {
        false;
      }
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },

    answerSelected() {
      return this.answer == null;
    }
  },
  mounted() {
    this.loadAnswers();
    if(this.questionData.question.id == 9){
      const image1 = require(`./../../assets/images/questionnaire-pics/9/pic1.jpg`);
      const image2 = require(`./../../assets/images/questionnaire-pics/9/pic2.jpg`);
      this.imagesArray.push(image1, image2)
    } else if(this.questionData.question.id == 10){
      const image1 = require(`./../../assets/images/questionnaire-pics/10/pic1.jpg`);
      const image2 = require(`./../../assets/images/questionnaire-pics/10/pic2.jpg`);
      const image3 = require(`./../../assets/images/questionnaire-pics/10/pic3.jpg`);
      const image4 = require(`./../../assets/images/questionnaire-pics/10/pic4.jpg`);
      this.imagesArray.push(image2, image3, image4, image1)
    } else if(this.questionData.question.id == 33){
      const image1 = require(`./../../assets/images/questionnaire-pics/33/pic1.jpg`);
      const image2 = require(`./../../assets/images/questionnaire-pics/33/pic2.jpg`);
      const image3 = require(`./../../assets/images/questionnaire-pics/33/pic3.jpg`);
      const image4 = require(`./../../assets/images/questionnaire-pics/33/pic4.jpg`);
      this.imagesArray.push(image1, image2, image3, image4)
    } else if(this.questionData.question.id == 34){
      const image1 = require(`./../../assets/images/questionnaire-pics/34/pic1.jpg`);
      const image2 = require(`./../../assets/images/questionnaire-pics/34/pic2.jpg`);
      this.imagesArray.push(image1, image2)
    }
      // this.imagesArray = require(`./../../assets/images/questionnaire-pics/9/pic1.jpg`);
      // console.log(this.imagesArray, 'imagesArray')
    // this.imagesArray
  }
};
</script>

<style lang="scss" scoped>
</style>
