var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,staticClass:"preferencesBox",attrs:{"id":"ageAndHeight"}},[_c('div',{staticClass:"ageAndHeightWrapper preferencesContentWrapper"},[_c('div',{staticClass:"titleWrapper"},[_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"title",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight.title =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight.title)+" ")]),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()]),_c('div',{staticClass:"ageFilterWrapper filterWrapper"},[_c('div',{staticClass:"ageTextWrapper textWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"ageText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.matchesPreferences.ageAndHeight.ageBracketQuestion =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight .ageBracketQuestion)+" ")]),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()]),_c('div',{staticClass:"sliderWrapper range"},[_c('vue-slider',{attrs:{"id":"ageFilter","min":_vm.minAgeFilterValue,"max":_vm.maxAgeFilterValue,"tooltip":'always',"tooltip-placement":'bottom',"enable-cross":false},model:{value:(_vm.ageFilterValue),callback:function ($$v) {_vm.ageFilterValue=$$v},expression:"ageFilterValue"}})],1)]),_c('div',{staticClass:"ageImportanceWrapper filterWrapper"},[_c('div',{staticClass:"ageTextWrapper textWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"ageText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.matchesPreferences.ageAndHeight.ageDifferenceImportanceQuestions =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight .ageDifferenceImportanceQuestions)+" ")]),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()]),_c('div',{staticClass:"sliderWrapper steps"},[_c('vue-slider',{attrs:{"id":"ageImportanceFilter","min":1,"max":7,"tooltip":'none',"tooltip-placement":'bottom',"enable-cross":false,"marks":_vm.ageImportanceMarks,"adsorb":true,"included":true},model:{value:(_vm.ageImportanceValue),callback:function ($$v) {_vm.ageImportanceValue=$$v},expression:"ageImportanceValue"}})],1)]),_c('div',{staticClass:"heightWrapper filterWrapper"},[_c('div',{staticClass:"heightTextWrapper textWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"heightText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.matchesPreferences.ageAndHeight.heightRangeQuestion =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight .heightRangeQuestion)+" ")]),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()]),_c('div',{staticClass:"sliderWrapper range"},[_c('vue-slider',{attrs:{"id":"heightFilter","min":_vm.minHeightFilterValue,"max":_vm.maxHeightFilterValue,"interval":_vm.heightFilterInterval,"tooltip":'always',"tooltip-placement":'bottom',"tooltip-formatter":_vm.heightFilterFormatter,"enable-cross":false},model:{value:(_vm.heightFilterValue),callback:function ($$v) {_vm.heightFilterValue=$$v},expression:"heightFilterValue"}})],1)]),_c('div',{staticClass:"heightImportanceWrapper filterWrapper"},[_c('div',{staticClass:"heightTextWrapper textWrapper"},[_c('h3',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"heightText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.matchesPreferences.ageAndHeight.heightDifferenceImportanceQuestion =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.matchesPreferences.ageAndHeight .heightDifferenceImportanceQuestion)+" ")]),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()]),_c('div',{staticClass:"sliderWrapper steps"},[_c('vue-slider',{attrs:{"id":"heightImportanceFilter","min":1,"max":7,"tooltip":'none',"tooltip-placement":'bottom',"enable-cross":false,"marks":_vm.heightImportanceMarks,"adsorb":true,"included":true},model:{value:(_vm.heightImportanceValue),callback:function ($$v) {_vm.heightImportanceValue=$$v},expression:"heightImportanceValue"}})],1)]),_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnSave",on:{"click":function($event){return _vm.sendAgeAndHeightValues()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.save)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.save),expression:"$t($i18n.locale).keys.generic.save"}],style:({ marginLeft: '20px' }),attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.save)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "save", $event.target.value)}}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }