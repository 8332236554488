<template>
  <div id="AlertsWrapper">
    <router-link to="/membership-area/messages" class="alert-link" v-if="type == 'message'">
      <div id="newMessageAlert" class="basicAlert">
        <i class="fa fa-envelope"></i> {{ $t($i18n.locale + '.keys.generic.alerts.newMessageAlert') }}
      </div>
    </router-link>
    <div id="newMessageAlert" class="errorAlert" v-else-if="type == 'default'">
      <i class="fa fa-exclamation-triangle"></i> {{ this.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Alerts",
  props: ["type", "message"],
  data() {
    return {
    };
  },

  mounted() {
  }
};
</script>

<style lang="scss" scoped>

#AlertsWrapper {
  position: relative;
  width:100%;
  height: 44px;
  .alert-link{
    &:hover {
      text-decoration: none;
    }
  }
  #newMessageAlert {
    padding:10px 20px;
    background: #1EB8DE;
    color:#ffffff;
    font-weight: bold;
    &.errorAlert {
      background: #e21919;
    }
  }
}

</style>