<template>
  <div id="desktopPlans_variant_1" class="plansWrapper" >
    <div class="plansBackground"></div>
      
    <div class="wrapper">
      <div
        class="premiumSimplePlanWrapper"
        @click="
          selectPlan(light.id)
        "
      >
          
        <!-- light plan -->
        <div
          class="plansInfoHeader green"
        >
          <h1>
            <span>{{ light.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{
              light.plansInfoHeader.titleType
            }}</span>

            <!-- <span
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.premiumPlans.plansInfo.mainTitle =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.premiumPlans.plansInfo.mainTitle
              }}
            </span> -->

            <!-- <span
              class="title"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.premiumPlans.plansInfo.titleLight =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.premiumPlans.plansInfo.titleLight
              }}</span
            > -->
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansDetails">
            <p>
              {{ light.plansInfoBody.headlinePeriod }}
              {{ light.plansInfoBody.headlineMembership }}
            </p>
          </div>
            <div class="plansBenefits">
          <ul class="benefitsList">
            <li v-for="(benefit, index) in benefitsList" :key="index">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              <p>{{ benefit }}</p>
            </li>
          </ul>
        </div>
        </div>
        <div class="plansPrice" :class="{'leadCurrency':(selectedLanguage == 'en')}">
          <!--
          <p class="oldPrice" v-if="light.plansPrice.discount">
            {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
            <span class="strike">
              <template v-if="selectedLanguage == 'en'">
                {{ light.plansPrice.currencySign }}{{ light.plansPrice.insteadOfPrice }}.{{ light.plansPrice.insteadOfPriceCents }}
              </template>
              <template v-else>
                {{ light.plansPrice.insteadOfPrice }},{{ light.plansPrice.insteadOfPriceCents }}{{ light.plansPrice.currencySign }}
              </template>
            </span>
          </p>
          -->
          <h1>
            <span>
              <template v-if="selectedLanguage == 'en'">
                {{ light.plansPrice.currencySign }}{{ light.plansPrice.visiblePrice }}.
                <sup>
                  {{ light.plansPrice.visiblePriceCents }}
                  <sub :class="domain">{{ light.plansPrice.perMonth }}</sub>
                </sup>
              </template>
              <template v-else>
                {{ light.plansPrice.visiblePrice }},
                <sup>
                  {{ light.plansPrice.visiblePriceCents }}
                  {{ light.plansPrice.currencySign }}
                  <sub :class="domain">{{ light.plansPrice.perMonth }}</sub>
                </sup>
              </template>

            </span>
          </h1>
        </div>
        <div class="buttonWrapper">
          <button
            class="btnContinue"
          >
            {{ $t($i18n.locale + '.keys.generic.continueBtn') }}
          </button>
        </div>
        <div class="promoWrapper" v-if="light.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ light.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>

      <div
        class="premiumClassicPlanWrapper"
        @click="
        selectPlan(comfort.id)
        "
      >
        <div class="plansInfoHeader dark">
          <h1>
            <span>{{ comfort.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{
              comfort.plansInfoHeader.titleType
            }}</span>
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansDetails">
            <p>
              {{ comfort.plansInfoBody.headlinePeriod }}
              {{ comfort.plansInfoBody.headlineMembership }}
            </p>
          </div>
          <div class="plansBenefits">
            <ul class="benefitsList">
              <li v-for="(benefit, index) in benefitsList" :key="index">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <p>{{ benefit }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="plansPrice"  :class="{'leadCurrency':(selectedLanguage == 'en')}">
          <!--
          <p class="oldPrice" v-if="comfort.plansPrice.discount">
            {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
            <span class="strike">
              <template v-if="selectedLanguage == 'en'">
                {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.insteadOfPrice }}.{{ comfort.plansPrice.insteadOfPriceCents }}
              </template>
              <template v-else>
                {{ comfort.plansPrice.insteadOfPrice }},{{ comfort.plansPrice.insteadOfPriceCents }}{{ comfort.plansPrice.currencySign }}
              </template>
            </span>
          </p>
          -->
          <h1>
            <span>
              <template v-if="selectedLanguage == 'en'">
                {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.visiblePrice }}.
                <sup>
                  {{ comfort.plansPrice.visiblePriceCents }}
                  <sub :class="domain">{{ comfort.plansPrice.perMonth }}</sub>
                </sup>
              </template>
              <template v-else>
                {{ comfort.plansPrice.visiblePrice }},
                <sup>
                  {{ comfort.plansPrice.visiblePriceCents }}
                  {{ comfort.plansPrice.currencySign }}
                  <sub :class="domain">{{ comfort.plansPrice.perMonth }}</sub>
                </sup>
              </template>
            </span>
          </h1>
        </div>
        <div class="buttonWrapper">
          <button class="btnContinue">
            {{ $t($i18n.locale).keys.generic.continueBtn }}
          </button>
        </div>
        <div class="mostChosen">
          {{ $t($i18n.locale + '.keys.premiumPlans.mostChosenPlan', {
            productName: $store.getters.productName
          }) }}
        </div>
        <div class="promoWrapper" v-if="comfort.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ comfort.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>

      <div
        class="premiumSimplePlanWrapper"
        @click="
        selectPlan(classic.id)
        "
       >
        <div class="plansInfoHeader dark">
          <h1>
            <span>{{ classic.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{
              classic.plansInfoHeader.titleType
            }}</span>
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansDetails">
            <p>
              {{ classic.plansInfoBody.headlinePeriod }}
              {{ classic.plansInfoBody.headlineMembership }}
            </p>
          </div>
          <div class="plansBenefits">
            <ul class="benefitsList">
              <li v-for="(benefit, index) in benefitsList" :key="index">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <p>{{ benefit }}</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="plansPrice"  :class="{'leadCurrency':(selectedLanguage == 'en')}">
          <!--
          <p class="oldPrice" v-if="classic.plansPrice.discount">
            {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
            <span class="strike">
              <template v-if="selectedLanguage == 'en'">
                {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.insteadOfPrice }}.{{ classic.plansPrice.insteadOfPriceCents }}
              </template>
              <template v-else>
                {{ classic.plansPrice.insteadOfPrice }},{{ classic.plansPrice.insteadOfPriceCents }}{{ classic.plansPrice.currencySign }}
              </template>
            </span>
          </p>
          -->
          <h1>
            <span>
              <template v-if="selectedLanguage == 'en'">
                {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.visiblePrice }}.
                <sup>
                  {{ classic.plansPrice.visiblePriceCents }}
                  <sub :class="domain">{{ classic.plansPrice.perMonth }}</sub>
                </sup>
              </template>
              <template v-else>
                {{ classic.plansPrice.visiblePrice }},
                <sup>
                  {{ classic.plansPrice.visiblePriceCents }}
                  {{ classic.plansPrice.currencySign }}
                  <sub :class="domain">{{ classic.plansPrice.perMonth }}</sub>
                </sup>
              </template>

            </span>
          </h1>
        </div>

        <div class="buttonWrapper">
          <button class="btnContinue">
          <span v-if="classic.plansPrice.discount">
            {{ $t($i18n.locale + '.keys.premiumPlans.continueBtnDiscount') }}
          </span>
          <span v-else>
            {{ $t($i18n.locale + '.keys.generic.continueBtn') }}
          </span>
          </button>
        </div>
        <div class="promoWrapper" v-if="classic.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ classic.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "DesktopPlans_variant1",
  props: [
    "comfort",
    "classic",
    "light",
    "selectedLanguage",
    "domain"
  ],
  data() {
    return {
      benefitsList: [
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.intelligentMatchmaking'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages')
      ]
    };
  },
  computed: {
    user: function(){
        return this.$store.getters.user
      }
  },

  mounted() {

  },

  created() {

  },
  methods: {
    selectPlan(planId){
      this.$emit('selectPlan', planId);
    }
  },
};
</script>

<style lang="scss">



</style>