<template>
  <div id="questionsAnswers" :key="componentKey + '3'">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t(
            $i18n.locale
          ).keys.currentUserProfile.userProfile.questionsAndAnswers.title =
            $event.target.innerText
        "
      >
        {{
          $t($i18n.locale).keys.currentUserProfile.userProfile
            .questionsAndAnswers.title
        }}
      </h1>
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>

    <div class="questionsToEditWrapper" v-if="showQuestions">
      <div
        id="questionSelectWrapper"
        v-if="$store.getters.getRandomQuestions.length > 0"
      >
        <div class="btnWrapper">
          <button
            @click="setRandomQuestion()"
            class="btnChooseQuestion"
          >
            {{
              $t($i18n.locale).keys.currentUserProfile.userProfile
                .questionsAndAnswers.chooseRandomQuestionBtn
            }}
          </button>
          <input
            :style="{ marginLeft: '20px' }"
            v-if="editable"
            v-model="
              $t($i18n.locale).keys.currentUserProfile.userProfile
                .questionsAndAnswers.chooseRandomQuestionBtn
            "
            type="text"
          />
        </div>
        <div class="btnWrapper">
            <button
              @click="$bvModal.show('bv-modal-questions-answers')"
              class="btnChooseQuestion"
            >
              {{
                $t($i18n.locale).keys.currentUserProfile.userProfile
                  .questionsAndAnswers.chooseQuestionBtn
              }}
            </button>
            <input
              :style="{ marginLeft: '20px' }"
              v-if="editable"
              v-model="
                $t($i18n.locale).keys.currentUserProfile.userProfile
                  .questionsAndAnswers.chooseQuestionBtn
              "
              type="text"
            />
          </div>
      </div>
      <div
        class="questionsToEditBox"
        v-if="$store.getters.getRandomQuestions.length > 0"
        :key="componentKey"
      >
        <div class="questionToEditWrapper" v-if="chosenQuestion.translations">
          <h1 class="questionToEdit">
            {{ chosenQuestion.translations[$store.getters.selectedLanguage].question }}
          </h1>
          <textarea
            :class="{ error: !answerCorrect || emptyAnswer }"
            class="answerToEdit"
            v-model="chosenQuestionAnswer"
            :placeholder="$t($i18n.locale).keys.currentUserProfile.userProfile.questionsAndAnswers.typeHere"
          ></textarea>
          <p v-if="!answerCorrect" class="errorText"
            v-langErr :contenteditable="editable"
                  @blur="
          $t(
            $i18n.locale
          ).keys.currentUserProfile.userProfile
            .questionsAndAnswers.validAnswer =
            $event.target.innerText
        "
          >
            {{
          $t($i18n.locale).keys.currentUserProfile.userProfile
            .questionsAndAnswers.validAnswer
        }}
          </p>
          <p v-if="emptyAnswer" class="errorEmptyText"
            v-langErr :contenteditable="editable"
             @blur="
          $t(
            $i18n.locale
          ).keys.currentUserProfile.userProfile.questionsAndAnswers.fieldBlank =
            $event.target.innerText
        "
          >
            {{
          $t($i18n.locale).keys.currentUserProfile.userProfile
            .questionsAndAnswers.fieldBlank
        }}
          </p>
        </div>
        <div class="secondBtnWrapper">
          <button
            class="btnSave"
            @click="
              validateAnswer();
              addQuestion(chosenQuestion);
            "
          >
            {{ $t($i18n.locale).keys.generic.saveBtn }}
          </button>
          <input
            :style="{ marginLeft: '20px' }"
            v-if="editable"
            v-model="$t($i18n.locale).keys.generic.saveBtn"
            type="text"
          />
        </div>
      </div>
      <div class="knownEditableQuestionsWrapper">
        <div class="full-width flex-container">
          <div class="half-width">
            <div class="mainContainer" v-if="randomQuestion118">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer118 }"
                :key="componentKey118"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer118">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion118 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer118 && status108"
                    @click="editRandomAnswer118 = !editRandomAnswer118"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status118"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status118 }"
                  :contenteditable="editRandomAnswer118"
                  @blur="updateRandomAnswer118"
                >
                  {{ randomAnswer118 }}
                </p>
                <div v-if="editRandomAnswer118" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer118 = !editRandomAnswer118;
                      randomAnswer118 = randomSavedAnswer118;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer118 = !editRandomAnswer118;
                      sendUpdatedRandomAnswer(
                        randomQuestion118ID,
                        randomAnswer118
                      );
                      randomSavedAnswer118 = randomAnswer118;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="randomQuestion116">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer116 }"
                :key="componentKey116"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer116">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion116 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer116 && status116"
                    @click="editRandomAnswer116 = !editRandomAnswer116"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status116"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status116 }"
                  :contenteditable="editRandomAnswer116"
                  @blur="updateRandomAnswer116"
                >
                  {{ randomAnswer116 }}
                </p>
                <div v-if="editRandomAnswer116" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer116 = !editRandomAnswer116;
                      randomAnswer116 = randomSavedAnswer116;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer116 = !editRandomAnswer116;
                      sendUpdatedRandomAnswer(
                        randomQuestion116ID,
                        randomAnswer116
                      );
                      randomSavedAnswer116 = randomAnswer116;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="randomQuestion114">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer114 }"
                :key="componentKey114"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer114">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion114 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer114 && status114"
                    @click="editRandomAnswer114 = !editRandomAnswer114"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status114"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status114 }"
                  :contenteditable="editRandomAnswer114"
                  @blur="updateRandomAnswer114"
                >
                  {{ randomAnswer114 }}
                </p>
                <div v-if="editRandomAnswer114" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer114 = !editRandomAnswer114;
                      randomAnswer114 = randomSavedAnswer114;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer114 = !editRandomAnswer114;
                      sendUpdatedRandomAnswer(
                        randomQuestion114ID,
                        randomAnswer114
                      );
                      randomSavedAnswer114 = randomAnswer114;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="randomQuestion112">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer112 }"
                :key="componentKey112"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer112">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion112 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer112 && status112"
                    @click="editRandomAnswer112 = !editRandomAnswer112"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status112"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status112 }"
                  :contenteditable="editRandomAnswer112"
                  @blur="updateRandomAnswer112"
                >
                  {{ randomAnswer112 }}
                </p>
                <div v-if="editRandomAnswer112" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer112 = !editRandomAnswer112;
                      randomAnswer112 = randomSavedAnswer112;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer112 = !editRandomAnswer112;
                      sendUpdatedRandomAnswer(
                        randomQuestion112ID,
                        randomAnswer112
                      );
                      randomSavedAnswer112 = randomAnswer112;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="randomQuestion110">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer110 }"
                :key="componentKey110"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer110">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion110 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer110 && status110"
                    @click="editRandomAnswer110 = !editRandomAnswer110"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status110"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status110 }"
                  :contenteditable="editRandomAnswer110"
                  @blur="updateRandomAnswer110"
                >
                  {{ randomAnswer110 }}
                </p>
                <div v-if="editRandomAnswer110" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer110 = !editRandomAnswer110;
                      randomAnswer110 = randomSavedAnswer110;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer110 = !editRandomAnswer110;
                      sendUpdatedRandomAnswer(
                        randomQuestion110ID,
                        randomAnswer110
                      );
                      randomSavedAnswer110 = randomAnswer110;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="randomQuestion108">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer108 }"
                :key="componentKey108"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer108">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion108 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer108 && status108"
                    @click="editRandomAnswer108 = !editRandomAnswer108"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status108"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status108 }"
                  :contenteditable="editRandomAnswer108"
                  @blur="updateRandomAnswer108"
                >
                  {{ randomAnswer108 }}
                </p>
                <div v-if="editRandomAnswer108" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer108 = !editRandomAnswer108;
                      randomAnswer108 = randomSavedAnswer108;
                      componentKey108++;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer108 = !editRandomAnswer108;
                      sendUpdatedRandomAnswer(
                        randomQuestion108ID,
                        randomAnswer108
                      );
                      randomSavedAnswer108 = randomAnswer108;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" data-question="mainAnswer1" v-if="allAnswer0Render.length > 0">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editMainAnswer1 }"
              >
                <div class="editableQuestionWrapper" data-question="mainAnswer1">
                  <h1 class="editableQuestion">
                    {{ allAnswers[0].question.translations[$store.getters.selectedLanguage].question }}
                  </h1>
                  <img
                    v-if="!editMainAnswer1"
                    @click="editMainAnswer1 = !editMainAnswer1"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                </div>
                <div v-if="!editMainAnswer1" :key="componentKey2">
                  <p
                    v-for="(answer, index) in allAnswer0Render"
                    :key="index + 'answer'"
                    class="editableAnswer"
                  >
                    {{ answer }}
                  </p>
                </div>
                <div v-if="editMainAnswer1" class="buttonWrapper">
                  <div class="answerBtnWrapper">
                    <button
                      class="answerBtn"
                      @click="addAnswer(index + 1)"
                      v-for="(answerOption, index) in allAnswers[0].question
                        .answerOptions"
                      :key="index + 'answerOption'"
                      :class="{
                        active: allAnswer0.includes(answerOption.id - 142)
                      }"
                      :disabled="answer1Disabled"
                    >
                      {{ answerOption.translations[$store.getters.selectedLanguage].answer }}
                    </button>
                  </div>
                  <button
                    @click="
                      editMainAnswer1 = !editMainAnswer1;
                      setRenderAnswers;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editMainAnswer1 = !editMainAnswer1;
                      updateAnswer();
                      sendMainAnswer1(allAnswers[0], allAnswer0);
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="mainContainer" v-if="allAnswers[2]" >
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editMainAnswer3 }"
                :key="mainAnswerKey3"
              >
                <div class="editableQuestionWrapper" data-question="mainAnswer3">
                  <h1 class="editableQuestion">
                    {{ allAnswers[2].question.translations[$store.getters.selectedLanguage].question }}
                  </h1>
                  <img
                    v-if="!editMainAnswer3 && allAnswers[2].status"
                    @click="editMainAnswer3 = !editMainAnswer3"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!allAnswers[2].status"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !allAnswers[2].status }"
                  :contenteditable="editMainAnswer3"
                  @blur="updateAllAnswer2"
                >
                  <span v-if="Array.isArray(allAnswer2)">
                    <span v-for="(answer, index) in allAnswer2" :key="index">
                      {{ answer }}
                    </span>
                  </span>
                  <span v-else>
                    {{ allAnswer2 }}
                  </span>
                  
                  
                </p>
                <div v-if="editMainAnswer3" class="buttonWrapper">
                  <button
                    @click="
                      editMainAnswer3 = !editMainAnswer3;
                      allAnswer2 = savedAnswer2;
                      mainAnswerKey3++;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      sendMainAnswer2(allAnswers[2], allAnswer2);
                      allAnswers[2].status = !allAnswers[2].status;
                      mainAnswerKey3++;
                      editMainAnswer3 = !editMainAnswer3;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="mainContainer" v-if="randomQuestion117">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer117 }"
                :key="componentKey117"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer117">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion117 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer117 && status117"
                    @click="editRandomAnswer117 = !editRandomAnswer117"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status117"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status117 }"
                  :contenteditable="editRandomAnswer117"
                  @blur="updateRandomAnswer117"
                >
                  {{ randomAnswer117 }}
                </p>
                <div v-if="editRandomAnswer117" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer117 = !editRandomAnswer117;
                      randomAnswer117 = randomSavedAnswer117;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer117 = !editRandomAnswer117;
                      sendUpdatedRandomAnswer(
                        randomQuestion117ID,
                        randomAnswer117
                      );
                      randomSavedAnswer117 = randomAnswer117;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="randomQuestion115">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer115 }"
                :key="componentKey115"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer115">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion115 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer115 && status115"
                    @click="editRandomAnswer115 = !editRandomAnswer115"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status115"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status115 }"
                  :contenteditable="editRandomAnswer115"
                  @blur="updateRandomAnswer115"
                >
                  {{ randomAnswer115 }}
                </p>
                <div v-if="editRandomAnswer115" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer115 = !editRandomAnswer115;
                      randomAnswer115 = randomSavedAnswer115;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer115 = !editRandomAnswer115;
                      sendUpdatedRandomAnswer(
                        randomQuestion115ID,
                        randomAnswer115
                      );
                      randomSavedAnswer115 = randomAnswer115;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="randomQuestion113">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer113 }"
                :key="componentKey113"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer113">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion113 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer113 && status113"
                    @click="editRandomAnswer113 = !editRandomAnswer113"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status113"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status113 }"
                  :contenteditable="editRandomAnswer113"
                  @blur="updateRandomAnswer113"
                >
                  {{ randomAnswer113 }}
                </p>
                <div v-if="editRandomAnswer113" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer113 = !editRandomAnswer113;
                      randomAnswer113 = randomSavedAnswer113;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer113 = !editRandomAnswer113;
                      sendUpdatedRandomAnswer(
                        randomQuestion113ID,
                        randomAnswer113
                      );
                      randomSavedAnswer113 = randomAnswer113;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="randomQuestion111">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer111 }"
                :key="componentKey111"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer111">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion111 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer111 && status111"
                    @click="editRandomAnswer111 = !editRandomAnswer111"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status111"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status111 }"
                  :contenteditable="editRandomAnswer111"
                  @blur="updateRandomAnswer111"
                >
                  {{ randomAnswer111 }}
                </p>
                <div v-if="editRandomAnswer111" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer111 = !editRandomAnswer111;
                      randomAnswer111 = randomSavedAnswer111;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer111 = !editRandomAnswer111;
                      sendUpdatedRandomAnswer(
                        randomQuestion111ID,
                        randomAnswer111
                      );
                      randomSavedAnswer111 = randomAnswer111;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="randomQuestion109">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editRandomAnswer109 }"
                :key="componentKey109"
              >
                <div class="editableQuestionWrapper" data-question="randomAnswer109">
                  <h1 class="editableQuestion">
                    {{ randomAnsweredQuestion109 }}
                  </h1>
                  <img
                    v-if="!editRandomAnswer109 && status109"
                    @click="editRandomAnswer109 = !editRandomAnswer109"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!status109"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !status109 }"
                  :contenteditable="editRandomAnswer109"
                  @blur="updateRandomAnswer109"
                >
                  {{ randomAnswer109 }}
                </p>
                <div v-if="editRandomAnswer109" class="buttonWrapper">
                  <button
                    @click="
                      editRandomAnswer109 = !editRandomAnswer109;
                      randomAnswer109 = randomSavedAnswer109;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editRandomAnswer109 = !editRandomAnswer109;
                      sendUpdatedRandomAnswer(
                        randomQuestion109ID,
                        randomAnswer109
                      );
                      randomSavedAnswer109 = randomAnswer109;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="allAnswers[1]" >
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editMainAnswer2 }"
                :key="mainAnswerKey2"
              >
                <div class="editableQuestionWrapper" data-question="mainAnswer2">
                  <h1 class="editableQuestion">
                    {{ allAnswers[1].question.translations[$store.getters.selectedLanguage].question }}
                  </h1>
                  <img
                    v-if="!editMainAnswer2 && allAnswers[1].status"
                    @click="editMainAnswer2 = !editMainAnswer2"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!allAnswers[1].status"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <div class="allAnswer1Wrapper">
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[1].status }"
                    :contenteditable="editMainAnswer2"
                    @blur="updateAllAnswer1"
                  >
                    {{ allAnswer1[0] }}
                  </p>
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[1].status }"
                    :contenteditable="editMainAnswer2"
                    @blur="updateAllAnswer1"
                  >
                    {{ allAnswer1[1] }}
                  </p>
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[1].status }"
                    :contenteditable="editMainAnswer2"
                    @blur="updateAllAnswer1"
                  >
                    {{ allAnswer1[2] }}
                  </p>
                </div>
                <div v-if="editMainAnswer2" class="buttonWrapper">
                  <button
                    @click="
                      editMainAnswer2 = !editMainAnswer2;
                      allAnswer1 = savedAnswer1;
                      mainAnswerKey2++;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      sendMainAnswer2(allAnswers[1], allAnswer1);
                      allAnswers[1].status = !allAnswers[1].status;
                      mainAnswerKey2++;
                      editMainAnswer2 = !editMainAnswer2;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mainContainer" v-if="allAnswers[3]" >
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editMainAnswer4 }"
                :key="mainAnswerKey4"
              >
                <div class="editableQuestionWrapper" data-question="mainAnswer4">
                  <h1 class="editableQuestion">
                    {{ allAnswers[3].question.translations[$store.getters.selectedLanguage].question }}
                  </h1>
                  <img
                    v-if="!editMainAnswer4 && allAnswers[3].status"
                    @click="editMainAnswer4 = !editMainAnswer4"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!allAnswers[3].status"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <div class="allAnswer1Wrapper">
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[3].status }"
                    :contenteditable="editMainAnswer4"
                    @blur="updateAllAnswer3"
                  >
                    {{ allAnswer3[0] }}
                  </p>
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[3].status }"
                    :contenteditable="editMainAnswer4"
                    @blur="updateAllAnswer3"
                  >
                    {{ allAnswer3[1] }}
                  </p>
                  <p
                    class="editableAnswer"
                    :class="{ inReview: !allAnswers[3].status }"
                    :contenteditable="editMainAnswer4"
                    @blur="updateAllAnswer3"
                  >
                    {{ allAnswer3[2] }}
                  </p>
                </div>
                <div v-if="editMainAnswer4" class="buttonWrapper">
                  <button
                    @click="
                      editMainAnswer4 = !editMainAnswer4;
                      allAnswer3 = savedAnswer3;
                      mainAnswerKey4++
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      sendMainAnswer(allAnswers[3], allAnswer3);
                      allAnswers[3].status = !allAnswers[3].status;
                      mainAnswerKey4++;
                      editMainAnswer4 = !editMainAnswer4;
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="bv-modal-questions-answers" hide-footer>
      <template v-slot:modal-title>{{
        $t($i18n.locale).keys.currentUserProfile.userProfile.questionsAndAnswers
          .chooseQuestionBtn
      }}</template>
      <div class="d-block">
        <div
          v-for="(question, index) in $store.getters.getRandomQuestions"
          :key="index + 'question'"
        >
          <div
            :class="{ chosen: index == 0 }"
            class="questionWrapper"
            @click="
              chooseQuestion($event);
              setQuestion(question);
              $bvModal.hide('bv-modal-questions-answers');
            "
            v-if="question.id != 119 && question.id != 120 && question.id != 121"
          >
            <div class="borderBox">
              <h1 class="question">{{ question.translations[$store.getters.selectedLanguage].question }}</h1>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "QuestionsAnswers",
  components: {},
  data() {
    return {
      showQuestions: false,
      emptyAnswer: false,
      answerCorrect: true,
      status108: false,
      status109: false,
      status110: false,
      status111: false,
      status112: false,
      status113: false,
      status114: false,
      status115: false,
      status116: false,
      status117: false,
      status118: false,
      status119: false,
      status120: false,
      status121: false,
      mainAnswerKey2: 0,
      mainAnswerKey3: 0,
      mainAnswerKey4: 0,
      componentKey108: 0,
      componentKey109: 0,
      componentKey110: 0,
      componentKey111: 0,
      componentKey112: 0,
      componentKey113: 0,
      componentKey114: 0,
      componentKey115: 0,
      componentKey116: 0,
      componentKey117: 0,
      componentKey118: 0,
      componentKey119: 0,
      componentKey120: 0,
      componentKey121: 0,
      editMainAnswer1: false,
      editMainAnswer2: false,
      editMainAnswer3: false,
      editMainAnswer4: false,
      allAnswer0: [],
      allAnswer1: [],
      allAnswer2: [],
      allAnswer3: [],
      savedAnswer0: [],
      savedAnswer1: [],
      savedAnswer2: [],
      savedAnswer3: [],
      editRandomAnswer108: false,
      randomQuestion108: false,
      editRandomAnswer109: false,
      randomQuestion109: false,
      editRandomAnswer110: false,
      randomQuestion110: false,
      editRandomAnswer111: false,
      randomQuestion111: false,
      editRandomAnswer112: false,
      randomQuestion112: false,
      editRandomAnswer113: false,
      randomQuestion113: false,
      editRandomAnswer114: false,
      randomQuestion114: false,
      editRandomAnswer115: false,
      randomQuestion115: false,
      editRandomAnswer116: false,
      randomQuestion116: false,
      editRandomAnswer117: false,
      randomQuestion117: false,
      editRandomAnswer118: false,
      randomQuestion118: false,
      editRandomAnswer119: false,
      randomQuestion119: false,
      editRandomAnswer120: false,
      randomQuestion120: false,
      editRandomAnswer121: false,
      randomQuestion121: false,
      randomAnsweredQuestion108: "",
      randomAnswer108: "",
      randomAnsweredQuestion109: "",
      randomAnswer109: "",
      randomAnsweredQuestion110: "",
      randomAnswer110: "",
      randomAnsweredQuestion111: "",
      randomAnswer111: "",
      randomAnsweredQuestion112: "",
      randomAnswer112: "",
      randomAnsweredQuestion113: "",
      randomAnswer113: "",
      randomAnsweredQuestion114: "",
      randomAnswer114: "",
      randomAnsweredQuestion115: "",
      randomAnswer115: "",
      randomAnsweredQuestion116: "",
      randomAnswer116: "",
      randomAnsweredQuestion117: "",
      randomAnswer117: "",
      randomAnsweredQuestion118: "",
      randomAnswer118: "",
      randomAnsweredQuestion119: "",
      randomAnswer119: "",
      randomAnsweredQuestion120: "",
      randomAnswer120: "",
      randomAnsweredQuestion121: "",
      randomAnswer121: "",
      randomSavedAnswer108: "",
      randomSavedAnswer109: "",
      randomSavedAnswer110: "",
      randomSavedAnswer111: "",
      randomSavedAnswer112: "",
      randomSavedAnswer113: "",
      randomSavedAnswer114: "",
      randomSavedAnswer115: "",
      randomSavedAnswer116: "",
      randomSavedAnswer117: "",
      randomSavedAnswer118: "",
      randomSavedAnswer119: "",
      randomSavedAnswer120: "",
      randomSavedAnswer121: "",
      editThreeThingsAnswer: false,
      chosenQuestion: [],
      allAnswers: [
        {
          0: {
            question: {
              translations: {
                en: {
                  question: ""
                }
              }
            }
          }
        },
        {
          1: {
            question: {
              translations: {
                en: {
                  question: ""
                }
              }
            }
          }
        },
        {
          2: {
            question: {
              translations: {
                en: {
                  question: ""
                }
              }
            }
          }
        },
        {
          2: {
            question: {
              translations: {
                en: {
                  question: ""
                }
              }
            }
          }
        },
        {
          3: {
            question: {
              translations: {
                en: {
                  question: ""
                }
              }
            }
          }
        }
      ],
      chosenQuestionAnswer: "",
      ind: 0,
      componentKey: 0,
      answer1Disabled: false,
      allAnswer0Render: [],
      componentKey2: 0,
      randomQuestion108ID: 0,
      randomQuestion109ID: 0,
      randomQuestion110ID: 0,
      randomQuestion111ID: 0,
      randomQuestion112ID: 0,
      randomQuestion113ID: 0,
      randomQuestion114ID: 0,
      randomQuestion115ID: 0,
      randomQuestion116ID: 0,
      randomQuestion117ID: 0,
      randomQuestion118ID: 0,
      randomQuestion119ID: 0,
      randomQuestion120ID: 0,
      randomQuestion121ID: 0,
      show: true,
      isLoading: true,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
    this.setQuestionAndAnswers();

    this.$store.dispatch("fetchRandomQuestions").then(() => {
      this.chosenQuestion = this.$store.getters.getRandomQuestions[this.ind];
    });
  },
  methods: {
    setQuestionAndAnswers(){
       this.allAnswers = this.$store.getters.getMainAnswers;

        this.allAnswers.forEach( item => {
          if(parseInt(item.question.id) == 66){
            this.allAnswer0 = item.answer.map(answer => {
              return answer;
            });
            this.allAnswer0Render = this.allAnswer0.map(answer => {
              // console.log(answer, 'answer')
              return this.allAnswers[0].question.answerOptions[answer - 1]
                .translations[this.$store.getters.selectedLanguage].answer;
            });
          } else if(parseInt(item.question.id) == 83){
            this.allAnswer1 = item.answer.map(answer => {
                            return answer;
            })
                      } else if(parseInt(item.question.id) == 85){
            this.allAnswer2 = item.answer.map(answer => {
              return answer;
            })
            if(this.allAnswer2[0]) this.allAnswer2 = this.allAnswer2[0];
          } else if(parseInt(item.question.id) == 86) {
            this.allAnswer3 = item.answer.map(answer => {
              return answer;
            })
            console.log('answer3')
          }
          console.log(this.allAnswer3, 'allanswer3')
      //     }
      //   })
        // this.allAnswer0 = this.allAnswers[0].answer.map(answer => {
        //   return answer;
        // });
        // console.log(this.allAnswers, 'all ANSWERS')
        // this.allAnswer0Render = this.allAnswer0.map(answer => {
        //   // console.log(answer, 'answer')
        //   return this.allAnswers[0].question.answerOptions[answer - 1]
        //     .translations[this.$store.getters.selectedLanguage].answer;
        // });

        // this.allAnswer2 = this.allAnswers[2].answer[0];
        // this.allAnswer3 = this.allAnswers[3].answer[0];
        this.savedAnswer0 = [...this.allAnswer0];
        this.savedAnswer1 = [...this.allAnswer1];
        this.savedAnswer2 = this.allAnswer2;
        this.savedAnswer3 = [...this.allAnswer3];

      // }
        });

        this.$store.getters.getRandomAnsweredQuestions.forEach(question => {

          switch (question.question.id) {
            case 108:
              this.randomAnsweredQuestion108 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion108ID = question.id;
              this.randomQuestion108 = true;
              this.randomAnswer108 = question.answer[0];
              this.status108 = question.status;
              this.randomSavedAnswer108 = this.randomAnswer108;
              break;
            case 109:
              this.randomAnsweredQuestion109 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion109ID = question.id;
              this.status109 = question.status;

              this.randomQuestion109 = true;
              this.randomAnswer109 = question.answer[0];
              this.randomSavedAnswer109 = this.randomAnswer109;

              break;
            case 110:
              this.randomAnsweredQuestion110 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion110ID = question.id;
              this.status110 = question.status;

              this.randomQuestion110 = true;
              this.randomAnswer110 = question.answer[0];
              this.randomSavedAnswer110 = this.randomAnswer110;

              break;
            case 111:
              this.randomAnsweredQuestion111 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion111ID = question.id;
              this.status111 = question.status;

              this.randomQuestion111 = true;
              this.randomAnswer111 = question.answer[0];
              this.randomSavedAnswer111 = this.randomAnswer111;

              break;
            case 112:
              this.randomAnsweredQuestion112 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion112ID = question.id;
              this.status112 = question.status;

              this.randomQuestion112 = true;
              this.randomAnswer112 = question.answer[0];
              this.randomSavedAnswer112 = this.randomAnswer112;

              break;
            case 113:
              this.randomAnsweredQuestion113 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion113ID = question.id;
              this.status113 = question.status;

              this.randomQuestion113 = true;
              this.randomAnswer113 = question.answer[0];
              this.randomSavedAnswer113 = this.randomAnswer113;

              break;
            case 114:
              this.randomAnsweredQuestion114 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion114ID = question.id;
              this.status114 = question.status;

              this.randomQuestion114 = true;
              this.randomAnswer114 = question.answer[0];
              this.randomSavedAnswer114 = this.randomAnswer114;

              break;
            case 115:
              this.randomAnsweredQuestion115 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion115ID = question.id;
              this.status115 = question.status;

              this.randomQuestion115 = true;
              this.randomAnswer115 = question.answer[0];
              this.randomSavedAnswer115 = this.randomAnswer115;

              break;
            case 116:
              this.randomAnsweredQuestion116 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion116ID = question.id;
              this.status116 = question.status;

              this.randomQuestion116 = true;
              this.randomAnswer116 = question.answer[0];
              this.randomSavedAnswer116 = this.randomAnswer116;

              break;
            case 117:
              this.randomAnsweredQuestion117 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion117ID = question.id;
              this.status117 = question.status;

              this.randomQuestion117 = true;
              this.randomAnswer117 = question.answer[0];
              this.randomSavedAnswer117 = this.randomAnswer117;

              break;
            case 118:
              this.randomAnsweredQuestion118 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion118ID = question.id;
              this.status118 = question.status;

              this.randomQuestion118 = true;
              this.randomAnswer118 = question.answer[0];
              this.randomSavedAnswer118 = this.randomAnswer118;

              break;
            case 119:
              this.randomAnsweredQuestion119 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion119ID = question.id;
              this.status119 = question.status;

              this.randomQuestion119 = true;
              this.randomAnswer119 = question.answer[0];
              this.randomSavedAnswer119 = this.randomAnswer119;

              break;
            case 120:
              this.randomAnsweredQuestion120 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion120ID = question.id;
              this.status120 = question.status;

              this.randomQuestion120 = true;
              this.randomAnswer120 = question.answer[0];
              this.randomSavedAnswer120 = this.randomAnswer120;

              break;
            case 121:
              this.randomAnsweredQuestion121 =
                question.question.translations[this.$store.getters.selectedLanguage].question;
              this.randomQuestion121ID = question.id;
              this.status121 = question.status;

              this.randomQuestion121 = true;
              this.randomAnswer121 = question.answer[0];
              this.randomSavedAnswer121 = this.randomAnswer121;

              break;
          }
        });
        this.showQuestions = true;
        this.componentKey++;
        this.isLoading = false;
    },
    validateAnswer() {
      //let validateText = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
      if (
        //this.chosenQuestionAnswer.match(validateText) &&
        this.chosenQuestionAnswer != ""
      ) {
        this.answerCorrect = true;
        this.emptyAnswer = false;
      } else if (this.chosenQuestionAnswer == "") {
        this.emptyAnswer = true;
      } else {
        this.answerCorrect = false;
      }
    },
    sendUpdatedRandomAnswer(updatedQuestionID, updatedAnswer) {
      const data = {
        customerAnswerId: updatedQuestionID,
        answer: [updatedAnswer]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    updateAnswer() {
      this.allAnswer0Render = this.allAnswer0.map(answer => {
        return this.allAnswers[0].question.answerOptions[answer - 1]
          .translations[this.$store.getters.selectedLanguage].answer;
      });
      this.componentKey2++;
    },
    setRenderAnswers() {
      this.allAnswer0 = [...this.savedAnswer0];
      this.allAnswer0Render = this.allAnswer0.map(answer => {
        return this.allAnswers[0].question.answerOptions[answer - 1]
          .translations[this.$store.getters.selectedLanguage].answer;
      });
    },
    addAnswer(id) {
      if (this.allAnswer0.includes(id)) {
        let index = this.allAnswer0.indexOf(id);
        this.allAnswer0.splice(index, 1);
        this.answer1Disabled = false;
      } else {
        if (this.allAnswer0.length <= 3) {
          this.allAnswer0.push(id);
        }
      }
    },
    updateRandomAnswer108(event) {
      this.randomAnswer108 = event.target.innerText;
      event.target.innerText = this.randomAnswer108;
    },
    updateRandomAnswer109(event) {
      this.randomAnswer109 = event.target.innerText;
      event.target.innerText = this.randomAnswer109;
    },
    updateRandomAnswer110(event) {
      this.randomAnswer110 = event.target.innerText;
      event.target.innerText = this.randomAnswer110;
    },
    updateRandomAnswer111(event) {
      this.randomAnswer111 = event.target.innerText;
      event.target.innerText = this.randomAnswer111;
    },
    updateRandomAnswer112(event) {
      this.randomAnswer112 = event.target.innerText;
      event.target.innerText = this.randomAnswer112;
    },
    updateRandomAnswer113(event) {
      this.randomAnswer113 = event.target.innerText;
      event.target.innerText = this.randomAnswer113;
    },
    updateRandomAnswer114(event) {
      this.randomAnswer114 = event.target.innerText;
      event.target.innerText = this.randomAnswer114;
    },
    updateRandomAnswer115(event) {
      this.randomAnswer115 = event.target.innerText;
      event.target.innerText = this.randomAnswer115;
    },
    updateRandomAnswer116(event) {
      this.randomAnswer116 = event.target.innerText;
      event.target.innerText = this.randomAnswer116;
    },
    updateRandomAnswer117(event) {
      this.randomAnswer117 = event.target.innerText;
      event.target.innerText = this.randomAnswer117;
    },
    updateRandomAnswer118(event) {
      this.randomAnswer118 = event.target.innerText;
      event.target.innerText = this.randomAnswer118;
    },
    updateRandomAnswer119(event) {
      this.randomAnswer119 = event.target.innerText;
      event.target.innerText = this.randomAnswer119;
    },
    updateRandomAnswer120(event) {
      this.randomAnswer120 = event.target.innerText;
      event.target.innerText = this.randomAnswer120;
    },
    updateRandomAnswer121(event) {
      this.randomAnswer121 = event.target.innerText;
      event.target.innerText = this.randomAnswer121;
    },
    updateAllAnswer1(event) {
      let arr = [...event.target.parentElement.children];
      this.allAnswer1 = [];
      arr.forEach( (child) => {
        this.allAnswer1.push(child.innerText);
        }
      )

      arr = arr.map( (child, index) => {
        child.innerText = this.allAnswer1[index];
      })
      // this.allAnswer1 = event.target.innerText;
      // event.target.innerText = this.allAnswer1;
    },
    updateAllAnswer2(event) {
      this.allAnswer2 = event.target.innerText;
      event.target.innerText = this.allAnswer2;
    },
    updateAllAnswer3(event) {
      let arr = [...event.target.parentElement.children];
      this.allAnswer3 = [];
      arr.forEach( (child) => {
        this.allAnswer3.push(child.innerText);
        }
      )

      arr = arr.map( (child, index) => {
        child.innerText = this.allAnswer3[index];
      })
      // this.allAnswer3 = event.target.innerText;
      // event.target.innerText = this.allAnswer3;
    },
    sendMainAnswer(question, answer) {
      console.log(answer, 'answer')
      const data = {
        customerAnswerId: question.id,
        answer: answer
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    sendMainAnswer1(question, answer) {
      const data = {
        customerAnswerId: question.id,
        answer: [...answer]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    sendMainAnswer2(question, answer) {

      var answerData = (typeof answer == 'string') ? [answer] : answer;

      const data = {
        customerAnswerId: question.id,
        answer: answerData
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    chooseQuestion(e) {
      document
        .querySelectorAll(".questionWrapper")
        .forEach(question => question.classList.remove("chosen"));
      e.target.parentElement.parentElement.classList.add("chosen");
    },
    setQuestion(question) {
      this.chosenQuestion = question;
      console.log(this.chosenQuestion, 'chosen question')
    },
    setRandomQuestion() {
      var allQuestions = this.$store.getters.getRandomQuestions;
      var newQuestion = _.sample(allQuestions);

      if(this.chosenQuestion.id != newQuestion.id) this.chosenQuestion = newQuestion;
      else {
        while(this.chosenQuestion.id == newQuestion.id){
          newQuestion = _.sample(allQuestions);
        }
        this.chosenQuestion = newQuestion;
      }
      console.log(this.chosenQuestion, 'chosen question')
    },
    addQuestion(chosenQuestion) {
      console.log(chosenQuestion, 'chosen Question in addQuestion')
      if (this.answerCorrect && !this.emptyAnswer) {
        switch (this.chosenQuestion.id) {
          case 108:
            this.randomAnsweredQuestion108 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer108 = this.chosenQuestionAnswer;
            this.randomQuestion108 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 109:
            this.randomAnsweredQuestion109 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer109 = this.chosenQuestionAnswer;
            this.randomQuestion109 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 110:
            this.randomAnsweredQuestion110 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer110 = this.chosenQuestionAnswer;
            this.randomQuestion110 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 111:
            this.randomAnsweredQuestion111 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer111 = this.chosenQuestionAnswer;
            this.randomQuestion111 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 112:
            this.randomAnsweredQuestion112 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer112 = this.chosenQuestionAnswer;
            this.randomQuestion112 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 113:
            this.randomAnsweredQuestion113 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer113 = this.chosenQuestionAnswer;
            this.randomQuestion113 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 114:
            this.randomAnsweredQuestion114 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer114 = this.chosenQuestionAnswer;
            this.randomQuestion114 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 115:
            this.randomAnsweredQuestion115 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer115 = this.chosenQuestionAnswer;
            this.randomQuestion115 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 116:
            this.randomAnsweredQuestion116 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer116 = this.chosenQuestionAnswer;
            this.randomQuestion116 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 117:
            this.randomAnsweredQuestion117 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer117 = this.chosenQuestionAnswer;
            this.randomQuestion117 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 118:
            this.randomAnsweredQuestion118 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer118 = this.chosenQuestionAnswer;
            this.randomQuestion118 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 119:
            this.randomAnsweredQuestion119 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer119 = this.chosenQuestionAnswer;
            this.randomQuestion119 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 120:
            this.randomAnsweredQuestion120 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer120 = this.chosenQuestionAnswer;
            this.randomQuestion120 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          case 121:
            this.randomAnsweredQuestion121 = this.chosenQuestion.translations[this.$store.getters.selectedLanguage].question;
            this.randomAnswer121 = this.chosenQuestionAnswer;
            this.randomQuestion121 = true;
            this.$store.dispatch(
              "removeChosenQuestion",
              this.chosenQuestion.id
            );
            break;
          default:
          // code block
        }
        const answerData = {
          question: chosenQuestion.id,
          answer: [this.chosenQuestionAnswer],
          stepId: 107
        };
        this.$store.dispatch("updateRandomQuestion", answerData).then(res => {
          if (res == "error") {
            // show error
          } else {
            this.chosenQuestionAnswer = "";
            this.chosenQuestion = this.$store.getters.getRandomQuestions[0];
            this.componentKey++;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
