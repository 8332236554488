<template>
    <div
      id="personalityTest"
      :class="{ 'premiumTest': premiumTest, 'variant2': variant == 2 }"
    >
    <div class="simpleHeader" v-if="!premiumTest && variant != 2">
        <div class="container topnavWrapper">
          <div class="row justify-content-center">
            <div class="logoWrapper col-12 col-md-10">
              <img :src="$store.getters.mainLogos.desktop" alt="Logo" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div 
            class="questionWrapper"
            :class="{
              'col-12 col-md-10': variant != 2
            }"
          >
            <ProgressBar
              v-if="variant != 2 && question"
              class="ProgressBar"
              v-bind:progressData="{
                percentage: percentage,
                currentQuestion: question.orderId,
                totalQuestions: totalQuestions
              }"
            ></ProgressBar>
            <div class="questionWrapperHeader" v-if="variant == 2">
              <img class="logo" :src="$store.getters.mainLogos.desktop" alt="Logo" />
              <p class="info">
                {{ $t($i18n.locale).keys.questionnaire.headerSubline }}
              </p>
            </div>

            <div
              v-if="!loadingQuestion && !hideBack && variant != 2 && question"
              class="changeQuestion goBack"
              :hidden="question.orderId <= 1"
              :disabled="disableBack"
              @click="goBack()"
            ></div>
            <div
              v-if="!loadingQuestion && !hideForward && variant != 2 && question"
              class="changeQuestion goForward"
              :hidden="question.orderId > steps_finished"
              :disabled="disableForward"
              @click="goForward()"
            ></div>
            <div
              class="questionBox"
              v-bind:class="[
                type == 1 ? 'questionBox1' : '',
                type == 2 ? 'questionBox2' : '',
                type == 3 ? 'questionBox3' : '',
                type == 4 ? 'questionBox4' : '',
                type == 5 ? 'questionBox5' : '',
                type == 6 ? 'questionBox6' : '',
                type == 7 ? 'questionBox7' : '',
                type == 8 ? 'questionBox8' : '',
                type == 9 ? 'questionBox9' : '',
                type == 10 ? 'questionBox10' : ''
              ]"
            >
              <Spinner v-if="loadingQuestion"></Spinner>
              <template v-if="!loadingQuestion">
                <div id="question-box-start"></div>

                <div v-if="questionError" class="questionError global">
                  <p v-for="(error, index) in questionError" :key="index">{{ error }}</p>
                </div>

                <div v-if="variant == 2 && question" class="chatStyleQuestion">
                  <img class="chatAvatar" :src="require('@/assets/images/' + $store.getters.assetsPath + '50sloveAvatar.jpg')" />
                  <p class="chatQuestion">
                    <span 
                      class="chatQuestionText"
                      v-html="question.translations[$store.getters.selectedLanguage].question"
                    ></span>
                    <span class="chatQuestionDots">
                      <span class="dot"></span>
                      <span class="dot"></span>
                      <span class="dot"></span>
                    </span>
                  </p>
                </div>

                <QuestionType1
                  v-if="type == 1"
                  :key="componentKey"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @only-post-answer="onlyPostAnswer"
                  @only-load-answer="onlyLoadQuestion"
                  @only-put-answer="onlyPutAnswer"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  
                  @goBack="goBack"
                  class="questiontype1"
                ></QuestionType1>
                <QuestionType2
                  v-if="type == 2"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType2>
                <QuestionType3
                  v-if="type == 3"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType3>
                <QuestionType4
                  v-if="type == 4"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType4>
                <QuestionType5
                  v-if="type == 5"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType5>
                <QuestionType6
                  :key="componentKey"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  v-if="type == 6"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType6>
                <QuestionType7
                  v-if="type == 7"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType7>
                <QuestionType8
                  v-if="type == 8"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType8>
                <QuestionType9
                  v-if="type == 9"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable,
                    questionData: questionData
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType9>
                <QuestionType10
                  v-if="type == 10"
                  @post-answer="postAnswer"
                  @put-answer="putAnswer"
                  @load-next-question="loadNextQuestion"
                  v-bind:questionData="{
                    question: question,
                    answered: answered,
                    skippable: skippable,
                    questionData: questionData
                  }"
                  :variant="variant"
                  :navData ="{
                    hideBack: hideBack,
                    hideNext: hideForward,
                    totalQuestions: totalQuestions,
                    percentage: percentage
                  }"
                  @goBack="goBack"
                ></QuestionType10>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ProgressBar from "@/components/personality/ProgressBar.vue";
import QuestionType1 from "@/components/personality/QuestionType1.vue";
import QuestionType2 from "@/components/personality/QuestionType2.vue";
import QuestionType3 from "@/components/personality/QuestionType3.vue";
import QuestionType4 from "@/components/personality/QuestionType4.vue";
import QuestionType5 from "@/components/personality/QuestionType5.vue";
import QuestionType6 from "@/components/personality/QuestionType6.vue";
import QuestionType7 from "@/components/personality/QuestionType7.vue";
import QuestionType8 from "@/components/personality/QuestionType8.vue";
import QuestionType9 from "@/components/personality/QuestionType9.vue";
import QuestionType10 from "@/components/personality/QuestionType10.vue";
import axios from "axios";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "PersonalityTest",
  components: {
    ProgressBar,
    QuestionType1,
    QuestionType2,
    QuestionType3,
    QuestionType4,
    QuestionType5,
    QuestionType6,
    QuestionType7,
    QuestionType8,
    QuestionType9,
    QuestionType10,
  },
  props: {
    premiumTest: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      totalQuestions: null,
      question: null,
      questionData: null,
      nextQuestion: null,
      previousQuestion: null,
      previousQuestionAnswers: null,
      skippable: 0,
      minChoices: 0,
      maxChoices: 0,
      steps_finished: null,
      disableBack: false,
      disableForward: false,
      loadingQuestion: false,
      testQuestion: 1, // only for testing purposes
      questionID: null,
      componentKey: 0,
      answered: null,
      returnedAnswer: [],
      hideBack: false,
      hideForward: false,
      completed: false,
      questionError: [],
    };
  },
  watch: {
    question: function() {
      this.forceRerender();
    }
  },
  computed: {
    variant() {
      //console.log('Personality Variant user', this.user.settings.questionnaireVariant);
      var variant = this.$store.getters.personalityTestVariant;
      if(this.user.settings) variant = this.user.settings.questionnaireVariant;
      if(this.premiumTest) variant = 1;
      
      return variant; 
    },
    percentage() {
      if(!this.question) return 0;
      return (this.question.orderId / this.totalQuestions) * 100;
    },
    type() {
      console.log("this.question", this.question);
      if(!this.question) return 0;
      if(this.question.id == 2 || this.question.id == 8) return 10;
      return this.question.type;
    },
    currentLoadingQuestion() {
      return this.loadingQuestion;
    },
    ...mapGetters(["allSkippedQuestions", "user"])
  },

  methods: {
    ...mapActions(["skippedQuestions", "loadAllSkippedQuestions"]),
    async postAnswer(data) {
      var URL = `${this.url}/api/personality/answer`;
      if(this.premiumTest) URL = `${this.url}/api/personality/answer_personality`;

      if (data == "") {
        data = [];
      }
      const answerData = {
        question: this.question.id,
        answer: data,
        stepId: this.question.orderId
      };
      console.log("answerData", answerData);

      if(this.variant == 2){
        //Animate the question out before moving on
          //document.querySelector('.chatStyleQuestion').classList.add('fadeOut')
          document.querySelector('.chatQuestion').classList.add('loading')
          document.querySelector('.chatStyleAnswer').classList.add('fadeOut')
         
          await new Promise(resolve => {
              setTimeout(resolve, 300);
          });
          
          //this.loadingQuestion = true;
          
      }else{
        this.loadingQuestion = true;
      }

      axios.post(URL, answerData).then(response => {
        if (!response.errors) {
          this.questionError = [];
          if (this.question.orderId != this.totalQuestions) {
            // if (this.$store.getters.skippedMode == true) {
            //   if (this.$store.getters.allSkippedQuestions.length > 0) {
            //     this.loadSkippedQuestion(
            //       this.$store.getters.allSkippedQuestions
            //     );
            //   } else {
            //     this.$router.push("/completion-message");
            //   }
            // } else {

              //Send activation email
              if(this.question.orderId == (this.totalQuestions-5) && !this.premiumTest){
                console.log("//Send activation email ");
                this.$store.dispatch('resendConfirmationEmail');
              }

              if(response.data.data.triggered) {

              if(response.data.data.triggered == "api.user.registered"){
                this.$gtm.trackEvent({
                  event: 'api_user_registered'
                });
              }

              if(response.data.data.triggered == "api.user.personality.finished") {
                this.$gtm.trackEvent({
                  event: 'api_user_personality_finished'
                });
              }

              //NEEDS REVIEW
              /*
              if(response.data.data.triggered == "api.email.confirmed"){
                console.log("//Send activation email ");
                this.$store.dispatch('resendConfirmationEmail');
                console.log("//Show email activation screen");
                this.$store.dispatch('getEmailConfirmed');
              }
              */

              }

              //Get new question
              console.log('answer response', response.data.data.nextQuestion)


              if(response.data.data.nextQuestion) {

                this.handleResponseQuestionLoading(answerData, response);

              } else {
                this.loadQuestion(this.nextQuestion.id);
              }

            // }
          } else if (this.question.orderId == this.totalQuestions) {
            if(this.premiumTest) this.checkLastQuestionPremium();
            else this.checkLastQuestion();
          }
        }
      }).catch(err => {
        console.log('Question Save Error', err);
        this.questionError = err.response.data.errors;
        this.loadingQuestion = false;
        if(this.variant == 2 ){
          var chatQuestion = document.querySelector('.chatQuestion');
          chatQuestion ? chatQuestion.classList.remove('loading'):null;
          document.querySelector('.chatStyleAnswer').classList.remove('fadeOut')
        }
      });
    },

    onlyPostAnswer(data) {
      var URL = `${this.url}/api/personality/answer`;
      if(this.premiumTest) URL = `${this.url}/api/personality/answer_personality`;

      const answerData = {
        question: this.question.id,
        answer: data,
        stepId: this.question.orderId
      };

      axios.post(URL, answerData).then(response => {
        if (!response.errors) {
          // console.log(response);
        }
      });
    },
    onlyLoadQuestion() {
      this.question = this.nextQuestion;
      this.loadQuestion(this.question.id);
    },
    putAnswer(data) {
      var URL = `${this.url}/api/personality/answer/update`;
      if(this.premiumTest) URL = `${this.url}/api/personality/answer_personality/update`;

      const arr = data.answered;

      if (arr.isArray) {
        this.returnedAnswer = [...data.answered];
      } else {
        this.returnedAnswer = data.answered;
      }

      const answerData = {
        customerAnswerId: data.customerAnswerId,
        answer: this.returnedAnswer
      };

      axios.put(URL, answerData).then(response => {
        if (!response.errors) {
          this.questionError = [];
          if (this.question.orderId != this.totalQuestions) {
            // if (this.$store.getters.skippedMode == true) {
            //   if (this.$store.getters.allSkippedQuestions.length > 0) {
            //     this.loadSkippedQuestion(
            //       this.$store.getters.allSkippedQuestions
            //     );
            //   } else {
            //     this.$store.dispatch("skippedQuestions").then(res => {
            //       //this.$store.dispatch("hideLoader");
            //       if (res == "error") {
            //         //this.$store.dispatch("setErrorVisibility", true);
            //       } else {
            //         this.$store.dispatch("showContent");
            //         if (this.$store.getters.allSkippedQuestions.length > 0) {
            //           this.loadSkippedQuestion(
            //             this.$store.getters.allSkippedQuestions
            //           );
            //         } else {
            //           // console.log("completed, go to finished message");
            //           this.$router.push("/completion-message");
            //         }
            //       }
            //     });
            //   }
            // } else {
              if(response.data.data.nextQuestion) {

                this.handleResponseQuestionLoading(answerData, response);

              } else {
                this.loadQuestion(this.nextQuestion.id);
              }
            // }
          } else if (this.question.orderId == this.totalQuestions) {
            if(this.premiumTest) this.checkLastQuestionPremium();
            else this.checkLastQuestion();
          }
        }
      }).catch(err => {
        console.log('Question Save Error', err);
        this.questionError = err.response.data.errors;
        this.loadingQuestion = false;
        if(this.variant == 2 ){
          var chatQuestion = document.querySelector('.chatQuestion');
          chatQuestion ? chatQuestion.classList.remove('loading'):null;
          document.querySelector('.chatStyleAnswer').classList.remove('fadeOut')
        }
      });
    },
    onlyPutAnswer(data) {
      var URL = `${this.url}/api/personality/answer/update`;
      if(this.premiumTest) URL = `${this.url}/api/personality/answer_personality/update`;

      const arr = data.answered;

      if (arr.isArray) {
        this.returnedAnswer = [...data.asnwered];
      } else {
        this.returnedAnswer = data.answered;
      }

      const answerData = {
        customerAnswerId: data.customerAnswerId,
        answer: this.returnedAnswer
      };

      axios.put(URL, answerData).then(response => {
        if (!response.errors) {
          // console.log(response);
        }
      });
    },
    loadQuestion(questionID) {
      console.log("laoding question triggered", questionID, this.question);
      if (this.currentLoadingQuestion == true) {
        // console.log("here 1");
        return;
      }
      if (this.completed == true) {
        //this.totalQuestions = "107";

        return;
      } else {
        // console.log("here 3");
        var URL = `${this.url}/api/personality/question/${questionID}`;
        if(this.premiumTest) URL = `${this.url}/api/personality/personality_question/${questionID}`;
        
        this.loadingQuestion = true;
        axios
          .get(URL)
          .then(
            response => {
              console.log('chatQuestion response')
              this.question = response.data.data.question.question
              this.questionData = response.data.data
              this.answered = response.data.data.question.answer
              this.nextQuestion = response.data.data.nextQuestion.question
              this.previousQuestion = response.data.data.previousQuestion.question
              this.previousQuestionAnswers = response.data.data.previousQuestion.answer
            }
          )
          .finally(() => {
            //scroll to top to show progress bar
            if(this.premiumTest) document.querySelector('#progressbar').scrollIntoView();
            else window.scrollTo(0,0);

            //count steps_finished
            if( (this.question.orderId - 1) > this.steps_finished) this.steps_finished = this.question.orderId - 1;

            //Virtual tracking of single questions
            var vpv_path = `/api/personality/question/${questionID}`;
            if(this.premiumTest) vpv_path = `/api/personality/personality_question/${questionID}`;

            this.$gtm.trackEvent({
              event: 'virtualpageview',
              vpv_path: vpv_path
            });

            if(this.variant == 2 ){
              var chatQuestion = document.querySelector('.chatQuestion');
              chatQuestion ? chatQuestion.classList.remove('loading'):null;
            }
            this.loadingQuestion = false;
           

            // Needs some timeout, so user can't spam back button
            (setTimeout(() => {
               this.hideBack = false;
            }, 500),

            console.log("false")),
              (this.disableBack = false),
              (this.disableForward = false);
          });
      }
    },
    // loadSkippedQuestion(data) {
    //   this.totalQuestions = "107";
    //   // console.log(data);
    //   this.question = data[0];

    //   const URL = `${this.url}/api/personality/question/${this.question.id}`;
    //   this.loadingQuestion = true;
    //   axios
    //     .get(URL)
    //     .then(
    //       response => (
    //         (this.question = response.data.data.question.question),
    //         (this.questionData = response.data.data),
    //         (this.answered = response.data.data.question.answer)
    //       )
    //     )
    //     .finally(() => {
    //       (this.loadingQuestion = false),
    //         (this.hideBack = true),
    //         (this.hideForward = true)
    //         // this.$store.dispatch("loadAllSkippedQuestions");
    //     });
    // },

    checkCompleted() {
      console.log('checkCompleted');
      const URL = `${this.url}/api/personality/check_completed`;
      axios
        .get(URL)
        .then(response => {
          console.log('checkCompleted response', response);
          (this.totalQuestions = response.data.data.total),
            (this.steps_finished = response.data.data.steps_finished);
          if(this.steps_finished >= this.totalQuestions &&
            response.data.data.additionalQuestionsShown != null){
            this.$router.push("/membership-area/home");
          } else{
            return this.steps_finished + 1;
          }
        })
        .then(result => {
          if (result > this.totalQuestions) {
            this.completed = true;
            console.log("go to image upload");
            this.$router.push("completion-message");
          } else {
            this.loadQuestion(result);
          }
        });
    },
    async checkLastQuestion() {
      const URL = `${this.url}/api/personality/check_completed`;
      axios.get(URL).then(async (response) => {
        if (response.data.data.completed == true && this.question.orderId == this.totalQuestions) {
          //Trigger Registration Event
          var URL = process.env.VUE_APP_URL + '/api/personality/trigger/registration';

          var device = '';
          if(this.$store.getters.isMobile) device = 'mobile';
          else device = 'desktop';

          await axios.post(URL, {
            device: device
          }).then(() => {
            console.log('Sent Registration Event');
            //GA EVENT
            this.$gtm.trackEvent({
              event: 'registration_test_finished'
            });
          });

          //TIK TOK EVENT
          if(typeof window.ttq != 'undefined'){
            console.log('tiktok track');
            window.ttq.track('SubmitForm',{
                content_type: 'product',
                currency: "GBP",
                value: 1,
                content_id: this.user.user_id,
              }
            );
          }else {
            var tiktokint = setInterval( ()=>{
              console.log('tiktok int');
              if(typeof window.ttq != 'undefined'){
                console.log('tiktok found');
                clearInterval(tiktokint);
                window.ttq.track('SubmitForm',{
                    content_type: 'product',
                    currency: "GBP",
                    value: 1,
                    content_id: this.user.user_id,
                  }
                );
              }
            }, 200)

            setTimeout(()=>{
              clearInterval(tiktokint);
            }, 10000)

          }

          // this.$store.dispatch("skippedQuestions").then(res => {
          //   //this.$store.dispatch("hideLoader");
          //   if (res == "error") {
          //     //this.$store.dispatch("setErrorVisibility", true);
          //   } else {
          //     this.$store.dispatch("showContent");
          //     if (this.$store.getters.allSkippedQuestions.length > 0) {
          //       this.loadSkippedQuestion(
          //         this.$store.getters.allSkippedQuestions
          //       );
          //       this.$store.dispatch("skippedMode");
          //     } else {
                // console.log("no skippable questions, go forward");
                // console.log(this.$store.getters.allSkippedQuestions);
                this.$router.push("/completion-message");
              // }
            // }
          // });
        }
      })
    },
    checkLastQuestionPremium() {
      const URL = `${this.url}/api/personality/check_finished`;
      axios.get(URL).then(response => {
        if (response.data.data.completed == true && this.question.orderId == this.totalQuestions) {
          this.$emit("finished", true);
        }
      })
    },

    loadNextQuestion() {
      this.loadQuestion(this.nextQuestion.id);
    },
    goBack() {
      console.log('goBack', this.question.id)
      if (this.question.orderId > 1) {
        this.disableBack = true;
        this.hideBack = true;
        this.loadQuestion(this.previousQuestion.id);
        //this.checkCompleted();
      }
    },
    goForward() {
      if (
        this.question.orderId < this.totalQuestions &&
        this.question.orderId <= this.steps_finished
      ) {
        this.disableForward = true;
        this.loadQuestion(this.nextQuestion.id);
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    checkFinished() {
      console.log('checkCompleted');
      const URL = `${this.url}/api/personality/check_finished`;
      axios
        .get(URL)
        .then(response => {
          console.log('checkFinished response', response);
          (this.totalQuestions = response.data.data.total),
            (this.steps_finished = response.data.data.steps_finished);
          if(this.steps_finished >= this.totalQuestions){
            console.log('premiumtest already finished!!!')
          } else{
            return this.steps_finished + 1;
          }
        })
        .then(result => {
          if (result > this.totalQuestions) {
            this.completed = true;
            console.log("show personality profile");
          } else {
            this.loadQuestion(result);
          }
        });
    },
    handleResponseQuestionLoading(answerData, response) {

        /*
        this.previousQuestion =  this.question;
        this.previousQuestionAnswers = answerData.answer;

        this.question = response.data.data.nextQuestion.question;

        this.answered = response.data.data.nextQuestion.answer ? response.data.data.nextQuestion.answer : false;
        this.nextQuestion = {}
        this.questionData = {
          'nextQuestion': this.nextQuestion,
          'previousQuestion': this.previousQuestion,
          'question': this.question,
        }
        */

        this.question = response.data.data.question.question
        this.questionData = response.data.data
        this.answered = response.data.data.question.answer
        this.nextQuestion = response.data.data.nextQuestion.question
        this.previousQuestion = response.data.data.previousQuestion.question
        this.previousQuestionAnswers = answerData.answer; //response.data.data.previousQuestion.answer


        //scroll to top to show progress bar
        if(this.premiumTest) document.querySelector('#progressbar').scrollIntoView();
        else window.scrollTo(0,0);

        //count steps_finished
        if( (this.question.orderId - 1) > this.steps_finished) this.steps_finished = this.question.orderId - 1;

        //Virtual tracking of single questions
        var vpv_path = `/api/personality/question/${this.question.id}`;
        if(this.premiumTest) vpv_path = `/api/personality/personality_question/${this.question.id}`;

        this.$gtm.trackEvent({
          event: 'virtualpageview',
          vpv_path: vpv_path
        });

        if(this.variant == 2 ){
          var chatQuestion = document.querySelector('.chatQuestion');
          chatQuestion ? chatQuestion.classList.remove('loading'):null;
        }

        this.loadingQuestion = false;

        // Needs some timeout, so user can't spam back button
        setTimeout(() => {
            this.hideBack = false;
        }, 500);

        this.disableBack = false;
        this.disableForward = false;

    },
  },

  mounted() {
    if(this.premiumTest) this.checkFinished();
    else this.checkCompleted();
  }
};
</script>

<style lang="scss" scoped></style>
