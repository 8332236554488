import store from "../../../store";

export default {
  setNotifications(state, payload){
    console.log('setNotifications', payload);
    if(payload.length){
      payload.forEach( (notification) => {
        state.notifications.push(notification);  
        state.storedNotifications.push(notification);
      })
    }else {
      console.log('state.storedNotifications', typeof state.storedNotifications)
      state.notifications.push(payload);  
    }

  },
  removeNotification(state, payload) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== payload
    );
   
  },

  setWebsocket(state, payload) {
    state.socketConnection = payload;
  },
  
  setStoredNotification(state, payload) {
    
    state.storedNotifications.push(payload);

    if(state.storedNotifications.length >= 10){
      //Limit stored notifiactions to 10 elements
      state.storedNotifications = state.storedNotifications.slice(-10);
    }

    //Set stored notifications in session storage
    sessionStorage.setItem('site_notifications', JSON.stringify(state.storedNotifications));
  },

  removeStoredNotification(state, payload) {
    state.storedNotifications = state.storedNotifications.filter(
      (notification) => notification.id !== payload
    );
    sessionStorage.setItem('site_notifications', JSON.stringify(state.storedNotifications));
  },

  updateStoredNotifications(state, payload) {

    console.log('updateStoredNotification', payload)
    
    let filteredNotifications = [];

    state.storedNotifications.forEach(
      (notification) => {
        console.log('userIds OBJ', Object.keys(payload.userIds))
        if(
          Object.keys(payload.userIds).includes(notification.user.profileId.toString()) &&
          !payload.userIds[notification.user.profileId.toString()].blocked
        ){
          notification.user.online = payload.userIds[notification.user.profileId.toString()].online
          if(payload.userIds[notification.user.profileId.toString()].avatar){
            notification.user.picture = payload.userIds[notification.user.profileId.toString()].avatar
          }else{
            notification.user.picture = (notification.user.gender == "Male") ? 
                  require('@/assets/images/' + store.getters.assetsPath + 'silhouette-man.svg') 
                  : require('@/assets/images/' + store.getters.assetsPath + 'silhouette-woman.svg');
      }
          
          filteredNotifications.push(notification)
        }
      }
    )

    state.storedNotifications = filteredNotifications;
    
  },
  cleanupStoredNotifications(state) {
    
    //Remove all stored notifications older than 24 hours
    const now = Date.now();
    const twentyFourHoursAgo = now - (24 * 60 * 60 * 1000);

    state.storedNotifications = state.storedNotifications.filter( (notification) => {
      return notification.date*1000 > twentyFourHoursAgo
    })

    sessionStorage.setItem('site_notifications', JSON.stringify(state.storedNotifications));

  }
  
};
