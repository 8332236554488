var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,staticClass:"preferencesBox",attrs:{"id":"matchingSettings"}},[_c('div',{staticClass:"matchingSettingsWrapper preferencesContentWrapper"},[_c('div',{staticClass:"titleWrapper"},[_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"title",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
          _vm.$i18n.locale
        ).keys.membershipArea.myAccount.matchingSettings.matchingSettings =
          $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingSettings))])]),_c('div',{staticClass:"matchingSettingsWrapper"},[_c('div',{staticClass:"switchWrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.matchingEnabledSlider),expression:"matchingEnabledSlider"},{name:"touch",rawName:"v-touch:start",value:(_vm.changeMatchingFilterValue),expression:"changeMatchingFilterValue",arg:"start"},{name:"touch",rawName:"v-touch:longtap",value:(_vm.changeMatchingFilterValue),expression:"changeMatchingFilterValue",arg:"longtap"}],staticClass:"switchInput",class:{ active: _vm.isActive1 },attrs:{"type":"range","min":"0","max":"1","step":"1"},domProps:{"value":(_vm.matchingEnabledSlider)},on:{"change":function($event){return _vm.changeMatchingFilterValue()},"__r":function($event){_vm.matchingEnabledSlider=$event.target.value}}}),(_vm.matchingEnabledSlider == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"switchName",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
          _vm.$i18n.locale
        ).keys.membershipArea.myAccount.matchingSettings.matchingEnabled =
          $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingEnabled))]):_vm._e(),(_vm.matchingEnabledSlider == 0)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"switchName",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
          _vm.$i18n.locale
        ).keys.membershipArea.myAccount.matchingSettings.matchingDisabled =
          $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingDisabled))]):_vm._e()]),_c('div',{staticClass:"explanationMessageWrapper"},[(_vm.matchingEnabledSlider == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"explanationMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
          _vm.$i18n.locale
        ).keys.membershipArea.myAccount.matchingSettings.youCurrently =
          $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.youCurrently))]):_vm._e(),(_vm.matchingEnabledSlider == 0)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"explanationMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
          _vm.$i18n.locale
        ).keys.membershipArea.myAccount.matchingSettings.matchingDisabled =
          $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.matchingDisabled))]):_vm._e()])]),_c('div',{staticClass:"buttonWrapper"},[_c('router-link',{attrs:{"to":"/membership-area/matches-preferences"}},[_c('button',{staticClass:"btnSave"},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences))])]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences
        ),expression:"\n          $t($i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences\n        "}],attrs:{"type":"text"},domProps:{"value":(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings.changePrefferences
        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.myAccount.matchingSettings, "changePrefferences", $event.target.value)}}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }