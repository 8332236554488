<template>
  <div id="membershipvisitorsList" class="container-lg">
    <!--
    <MembershipMisc  />
    -->
    <div id="visitorsSearch">
      <VisitorsSearchBar />
      <VisitorsFilters v-if="isMobile" @filterResetList="filterResetList" />
    </div>
    <div class="visitorsContainer">
      <div
        class="leftContainer"
        v-if="allVisitors"
      >
        <!-- <VisitorsToPreferences /> -->
        <VisitorsFilters v-if="!isMobile" @filterResetList="filterResetList" />
      </div>
      <div
        class="rightContainer"
        v-if="allFilteredVisitors.length"
      >
        <VisitorsList />
      </div>

      <div
        class="visitorsFreeAccountWrapper"
        v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM') && false"
      >
        <div class="contentWrapper">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'no-views.svg')" alt="No meetings icon" />
          <p class="becomePremiumText">
            <span
              class="text1"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1 =
              $event.target.innerText"
            >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1}}</span>
            <br />
            <span
              class="text2"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2 =
              $event.target.innerText"
            >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2}}</span>
          </p>
          <div class="premiumButtonWrapper">
            <router-link to="/premium-plans/plans?direct=1" class="premiumButton">
              <span
                v-langErr
                :contenteditable="editable"
                @blur="
            $t($i18n.locale).keys.membershipArea.membershipHeader.become =
              $event.target.innerText
          "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.become
                }}
              </span>
              <span
                class="headerPremiumMember"
                v-langErr
                :contenteditable="editable"
                @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.membershipHeader.premiumMember =
              $event.target.innerText
          "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipHeader.premiumMember
                }}
              </span>
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="noVisitorsWrapper rightContainer "
        v-if="!allFilteredVisitors.length"
      >
        <div class="contentWrapper">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'no-visitors-icon.svg')" alt="No visitors icon" />
          <p class="noVisitorsText">
            <span
              class="text1"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3 =
                $event.target.innerText
            "
            >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3}}</span>
            <br />
            <span
              class="text2"
              v-langErr
              :contenteditable="editable"
              @blur="
              $t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4 =
                $event.target.innerText
            "
            >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import VisitorsSearchBar from "@/components/membership-area/membership-visitors/VisitorsSearchBar.vue";
// import VisitorsToPreferences from "@/components/membership-area/membership-visitors/VisitorsToPreferences.vue";
import VisitorsFilters from "@/components/membership-area/membership-visitors/VisitorsFilters.vue";
import VisitorsList from "@/components/membership-area/membership-visitors/VisitorsList.vue";
import { mapGetters } from "vuex";

export default {
  name: "MembershipVisitorsList",
  components: {
    //MembershipMisc,
    VisitorsSearchBar,
    // VisitorsToPreferences,
    VisitorsFilters,
    VisitorsList
  },
  data() {
    return {
      visitorsLoading: false,
      visitorsLoadData: {
        offset: 0,
        limit: 10
      },
    };
  },
   computed: {
    ...mapGetters([
      "galleryViewVisitors",
      "listViewVisitors",
      "allFilteredVisitors",
      "allVisitors",
      "isMobile"
    ]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    window.scrollTo(0,0);
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
  /*
    this.$store.dispatch("visitorsCount").then(res => {
      //this.$store.dispatch("hideLoader");
      if (res == "error") {
        //this.$store.dispatch('setErrorVisibility', true);
      } else {
        //this.$store.dispatch("showContent");
      }
    });
    */
    this.$store.dispatch("fetchVisitors").then(res => {
      this.$store.dispatch("hideLoader");
      if (res == "error") {
        //this.$store.dispatch('setErrorVisibility', true);
      } else {
        this.stickyHeadline();
        this.$store.dispatch("showContent");
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    filterResetList(){
      window.addEventListener('scroll', this.scrollHandler)
    },
    scrollHandler(evt) {
      console.log('scrollHandler Visitors', evt);

      var loadOffset = 100;
      let scrollDiff = (document.documentElement.scrollHeight - window.innerHeight) - window.scrollY;
      var beforeScrollHeight = document.documentElement.scrollHeight;

      console.log('scrollHandler scrollDiff',  scrollDiff, beforeScrollHeight);

      if(scrollDiff < loadOffset && !this.visitorsLoading){

        console.log('scrollHandler DO SOMETHING');
        this.visitorsLoading = true;
        this.visitorsLoadData.offset = this.allFilteredVisitors.length;

        console.log('scrollHandler offset', this.visitorsLoadData.offset);
        this.$store.dispatch("fetchVisitors", this.visitorsLoadData).then(res => {
          console.log('scrollHandler fetchVisitors', res);
          this.$store.dispatch("filterVisitors", this.$store.getters.getActiveVisitorsFilters);
          if(res.visitors.length == 0 ){
            window.removeEventListener('scroll', this.scrollHandler);
            window.scrollTo(0, beforeScrollHeight)
          }

          this.visitorsLoading = false;

        });

      }

    },
    stickyHeadline(){

      this.searchBoxOffset = $('#visitorsSearch').offset().top;

      window.addEventListener('scroll', function() {
        var searchBox = $('#visitorsSearch');
        var visitsList = $('.visitorsContainer');
        var banner = $('.bannerWrapper').is(':visible') ? $('.bannerWrapper') : $('');

        if (window.pageYOffset > this.searchBoxOffset && window.scrollY != 0) {
          searchBox
            .addClass('sticky')
            .css({
              position:'fixed',
              top: $('#stickySection').height() + 'px',
              left:0,
              zIndex: 999,
              padding: 0,
            });
            visitsList.css({
              paddingTop: $('#visitorsSearch').height()
            });
            banner.css({
              marginTop: $('#visitorsSearch').height()+20
            });
        } else {
          searchBox
            .removeClass('sticky')
            .css({
              position:'relative',
              top: 'auto',
              padding: ""
            });
            visitsList.css({
              paddingTop: 0
            });
           
        }

      }.bind(this));
    },
  }
};
</script>

<style lang="scss" scoped>
</style>