<template>
  <div>
    <div id="meetingsItems" v-touch:swipe.left="reject" v-touch:swipe.right="match">
      <div class="meetingsItemsWrapper">
        <div class="meetingsItemWrapper">
          <div class="meetingCard" @click="showEmojiPicker = false">
            <div class="imageWrapper">
              <img
                v-if="meetingData.left_card.public_path"
                :src="meetingData.left_card.public_path"
                class="meetingsImage"
              />
              <img
                v-if="
                  !meetingData.left_card.public_path &&
                    parseInt(meetingData.left_card.gender) == 1
                "
                src="../../../assets/images/silhouette-man.svg"
                class="meetingsImage"
              />
              <img
                v-if="
                  !meetingData.left_card.public_path &&
                    parseInt(meetingData.left_card.gender) == 2
                "
                src="../../../assets/images/silhouette-woman.svg"
                class="meetingsImage"
              />
            </div>
            <div class="infoWrapper">
              <div class="infoTexts">
                <div class="nameAgeTextWrapper">
                  <!--
                  <img
                    v-if="parseInt(meetingData.left_card.entertainment) == 1"
                    src="../../../assets/images/heart-green.svg"
                    alt="Heart icon"
                  />
                  -->
                  <div class="nameAgeText" v-if="meetingData.left_card.name">
                    {{
                      meetingData.left_card.name.length > 18
                        ? meetingData.left_card.name
                            .substring(0, 17)
                            .concat("...")
                        : meetingData.left_card.name
                    }},
                    {{ getUserAge(meetingData.left_card.age.date) }}
                  </div>
                </div>

                <div class="workWrapper">
                  <img src="../../../assets/images/job-icon.svg" />
                  <p class="workText" v-if="meetingData.left_card.job">
                    {{
                      meetingData.left_card.job.length > 20
                        ? meetingData.left_card.job
                            .substring(0, 19)
                            .concat("...")
                        : meetingData.left_card.job
                    }}
                  </p>
                </div>
                <div class="locationWrapper">
                  <img src="../../../assets/images/house-icon.svg" />
                  <p class="locationText" v-if="meetingData.left_card.city">
                    {{
                      meetingData.left_card.city.length > 20
                        ? meetingData.left_card.city
                            .substring(0, 19)
                            .concat("...")
                        : meetingData.left_card.city
                    }}
                  </p>
                </div>
              </div>
              <div class="iconsWrapper">
                <div
                  @click="reject(meetingData.meeting_id)"
                  class="dismissIcon"
                ></div>
                <div
                  @click="match(meetingData.meeting_id)"
                  class="interestedIcon"
                ></div>
              </div>
            </div>
          </div>
          <div class="meetingInfo">
            <div class="meetingInfoWrapper">
              <div class="basicInfo" @click="showEmojiPicker = false">
                <div class="leftConaiter">
                  <div class="heightWrapper">
                    <img src="../../../assets/images/height-icon.svg" />
                    <p class="height">{{ meetingData.right_card.height }}cm</p>
                  </div>
                  <div class="raceWrapper">
                    <img src="../../../assets/images/race-icon.svg" />
                    <p class="race">
                      {{ meetingData.right_card.ethnic }}
                    </p>
                  </div>
                  <div class="religionWrapper">
                    <img src="../../../assets/images/religion-icon.svg" />
                    <p class="religion">
                      {{ meetingData.right_card.religion }}
                      <!-- {{ this.MemberData.description.religion.question.answerOptions[0].translations[selectedLanguage].answer}} -->
                    </p>
                  </div>
                </div>
                <div class="rightContainer">
                  <div class="smokingWrapper">
                    <img src="../../../assets/images/smoke-icon.svg" />
                    <p class="smoking">
                      {{ meetingData.right_card.smoking }}
                    </p>
                  </div>
                  <div class="educationWrapper">
                    <img src="../../../assets/images/studies-icon.svg" />
                    <p class="education">
                      {{ meetingData.right_card.education }}
                    </p>
                  </div>
                  <div class="languageWrapper">
                    <img src="../../../assets/images/language-icon.svg" />
                    <p class="language">
                      {{ meetingData.right_card.languages[0] }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="detailedInfoWrapper invisible-scrollbar"
                @click="showEmojiPicker = false"
              >
                <div
                  v-for="(answerItem, index) in meetingData.right_card.additional_info.text_answers"
                  :key="index + 999"
                  class="textAnswersWrapper"
                >
                  <h1 class="textAnswersTitle">
                    {{ answerItem.headline[selectedLanguage].question }}
                  </h1>
                  <p class="textAnswersText">
                    {{ answerItem.answer }}
                  </p>
                </div>
                <div
                  v-for="(answerItem, index) in meetingData.right_card.additional_info.choice_answers"
                  :key="index + 6666"
                  class="choiceWrapper"
                >
                  <h1 class="choiceAnswersTitle">
                    {{ answerItem.headline[selectedLanguage].question }}
                  </h1>
                  <div class="choicesAnswers">
                    <button
                      class="choiceButton"
                      v-for="(selectedAnswer,
                      index) in answerItem.selected_answers"
                      :key="index + 5555"
                    >
                      {{
                        selectedAnswer[$store.getters.selectedLanguage].answer
                      }}
                    </button>
                  </div>
                </div>
                <picker
                  :style="{ position: 'absolute', top: '127px' }"
                  v-show="showEmojiPicker"
                  title="Pick your emoji..."
                  emoji="point_up"
                  @select="addEmoji"
                  v-if="loadPicker"
                />
              </div>
              <div class="chatWrapper">
                <!-- now working -->
                <form   v-on:submit.prevent="sendMessage(message)">
                <input
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.membershipMeetings
                      .sendMessage
                  "
                  ref="textarea"
                  v-model="message"
                  class="chatInput"
                  type="text"
                />

                <button
                  class="emoji-trigger showEmoji"
                  :class="{ triggered: showEmojiPicker }"
                  @click.stop.prevent="toggleEmojiPicker"
                  id="emoji-button"
                ></button>
                <div @click="sendMessage(message)" class="sendIcon"></div>
                </form>
              </div>
            </div>

            <div class="meetingsChatBox"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUserAge from "../../../helpers/userAge";
import { Picker } from "emoji-mart-vue";
import { mapGetters } from "vuex";

export default {
  components: { Picker },
  name: "MeetingsItems",
  props: ["meetingData", "nopicker"],

  computed: {
    ...mapGetters(["MemberData", "selectedLanguage"]),
  },

  data() {
    return {
      url: process.env.VUE_APP_URL,
      message: "",
      showEmojiPicker: false,
      loadPicker: false
    };
  },

  methods: {
    getUserAge(birthday) {
      return getUserAge(birthday);
    },
    sendMessage(messageData) {
      this.$emit("sendMessage", messageData);
      this.showEmojiPicker = false;
      this.message = "";
    },
    match() {
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
        this.$emit("clickedMatch");
        this.showEmojiPicker = false;
      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
    reject() {
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
        this.$emit("clickedReject");
        this.showEmojiPicker = false;
      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
      this.loadPicker = true;
    },
    addEmoji(emoji) {
      const textarea = this.$refs.textarea;
      const cursorPosition = textarea.selectionEnd;
      const start = this.message.substring(0, textarea.selectionStart);
      const end = this.message.substring(textarea.selectionStart);
      this.message = start + emoji.native + end;
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + emoji.native.length;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
