<template>
  <div id="Shift4Payment">
    <form
      id="payment-form"
      class="wpwl-form wpwl-form-card"
      @submit.prevent="submitSecurionPay"
    >
      <span class="payment-errors">{{ paymentErrors }}</span>

      <div class="form-group wpwl-group">

          <input
            class="wpwl-control"
            type="tel"
            size="20"
            name="cardnumber"
            data-securionpay="number"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.cardNumber')"
            v-cardformat:formatCardNumber
            v-model="cardNumber"
            ref="_cardNumber"

          />
          <div v-if="cardErrors.cardNumber" class="error">
          <small>{{ cardErrors.cardNumber }}</small>
        </div>
      </div>

      <div class="form-group wpwl-group">

        <input
          type="tel"
          v-model="shift4Data.expireDate"
          class="wpwl-control"
          name="mm/yy"
          :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireCombined')"
          v-cardformat:formatCardExpiry
          ref="_cardExpire"

        />
        <div v-if="cardErrors.cardExpiry" class="error">
          <small>{{ cardErrors.cardExpiry }}</small>
        </div>
        <div class="form-expiration-group" style="display:none;">
          <input
            class="wpwl-control expMonth"
            type="text"
            size="2"
            data-securionpay="expMonth"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireMM')"
            maxlength="2"
            v-model="shift4Data.expireMonth"
          />
          <span class="form-spacer"> / </span>
          <input
            class="wpwl-control expYear"
            type="text"
            size="4"
            data-securionpay="expYear"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireYYYY')"
            maxlength="4"
            v-model="shift4Data.expireYear"
          />
        </div>
      </div>
      <div class="form-group wpwl-group">

          <input
            class="wpwl-control"
            type="tel"
            size="4"
            name="cid"
            data-securionpay="cvc"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.CID')"
            v-cardformat:formatCardCVC
            v-model="cardCvc"

          />
          <div v-if="cardErrors.cardCvc" class="error">
          <small>{{ cardErrors.cardCvc }}</small>
        </div>
      </div>

      <button class="wpwl-button" type="submit" :disabled="formLocked">
        {{paymentButtonText}}
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </form>    
  </div>
</template>
<script>
//Shift4 (Securion) Payment Integration

import Vue from "vue";

import { mapGetters } from "vuex";
import Shift4_helpers from "@/helpers/payments/Shift4_functions";

export default {
  name: "Shift4Payment",
    props: [
      'paymentButtonText'
    ],
    data() {
      return {
        shift4Data: {
          expireDate: '',
          expireMonth: '',
          expireYear: '',
        },
        securionFallback: false,
        retrySecurion: false,
        SECURION_PK_60sLove: process.env.VUE_APP_SECURION_60SLOVE_PK,
        SECURION_PK_40smeet: process.env.VUE_APP_SECURION_40SMEET_PK,
        SECURION_PK_casualxdates: process.env.VUE_APP_SECURION_PK_casualxdates,
        SECURION_PK_loveat50: process.env.VUE_APP_SECURION_LOVEAT50_PK,
        SECURION_PK_our50s: process.env.VUE_APP_SECURION_OUR50S_PK,
        SECURION_PK_affaireclub: process.env.VUE_APP_SECURION_PK_affaireclub,
        SECURION_PK_fremdgehboerse: process.env.VUE_APP_SECURION_PK_fremdgehboerse,
        SECURION_PK_LIEBEUE50: process.env.VUE_APP_SECURION_PK_LIEBEUE50,
        SECURION_PK_affaere1: process.env.VUE_APP_SECURION_PK_affaere1,
        SECURION_PK_illicitoasis: process.env.VUE_APP_SECURION_PK_illicitoasis,
        $cardFormat: Vue.prototype.$cardFormat,
        cardNumber: null,
        cardCvc:null,
        cardErrors: {},
        formLocked: false,
        paymentErrors:null,
        countPaymentAttempt: 1,
      }
    },
    watch: {
      'shift4Data.expireDate': function(val){
        setTimeout(() => {
          if(val != this.$refs._cardExpire.value) this.shift4Data.expireDate = this.$refs._cardExpire.value;
  
          let plainValue = val.replace(/\s/g, '');
          let plainValueSplit = plainValue.split('/');
          if(plainValueSplit.length == 2){
            if(plainValueSplit[0].length == 1) this.shift4Data.expireDate = '0'+val;
          }
          if(plainValue.length > 5) this.shift4Data.expireDate = val.slice(0, -1);
          if(plainValue.length == 5) {
            this.shift4Data.expireMonth = plainValue.slice(0,2);
            this.shift4Data.expireYear = '20' + plainValue.slice(3,5);
          }else{
            this.shift4Data.expireMonth = '';
            this.shift4Data.expireYear = '';
          }
         }, 1);
      },
    },
    computed: {
      ...mapGetters(["user","getActivePlan"]),
      SECURION_PK_Credorax: function(){

        if(typeof process.env.VUE_APP_SECURION_PUBLIC_KEY != 'undefined'  && process.env.VUE_APP_SECURION_PUBLIC_KEY){
          return process.env.VUE_APP_SECURION_PUBLIC_KEY
        }else{
          return process.env.VUE_APP_SECURION_PK_UK
        }
  
      },
      securionPrimaryKey: function(){

        //Primary MID coming from backend

        if(this.getActivePlan.plansPrice.tariffMap){
          //Set descriptor text for primary key
          this.$store.dispatch("setDescriptor", 
          { 
            brand: 'server', 
            payment: 'sec', 
            text: this.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary.descriptor
          });
          //Check if fallback key is set 
          if(this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback){
            //Set descriptor text for fallback key
            this.$store.dispatch("setDescriptor", 
            { 
              brand: 'server', 
              payment: 'sec', 
              text: this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.descriptor,
              type: 'fallback'
            });
          }

          return this.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary.key;
        }

        //Manual MID settings
        
        //Use MID for all CH domains instead of Magnius
      
        if(this.getActivePlan.plansPrice.tariffIds.Magnius && domain == 'ch') {
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: 'fremdgehboerse', payment: 'sec'});
          //Set fallback descriptor text
          this.$store.dispatch("setDescriptor", { brand: 'affaere1', payment: 'sec', type:'fallback'});
          return this.SECURION_PK_fremdgehboerse;
        }

        if(domain == 'ch') {
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: 'illicitoasis', payment: 'sec'});
          return this.SECURION_PK_illicitoasis; // MID Overwrite
        
        }

        //Set correct MID for Loveat50 and liebeü50
        if(host == 'loveat50'){
          if(domain == 'au' || domain == 'nz'){
            //Set correct descriptor text for Loveat50
            this.$store.dispatch("setDescriptor", { brand: 'affaireclub', payment: 'sec'});

            return this.SECURION_PK_affaireclub;
          }else{
            //Set correct descriptor text for Loveat50
            this.$store.dispatch("setDescriptor", { brand: 'loveat50', payment: 'sec'});

            return this.SECURION_PK_loveat50;
          }
        }else if (host == 'xn--liebe50-r2a'){

          if( domain == 'ch'){
            //Set correct descriptor text for Liebeü50
            this.$store.dispatch("setDescriptor", { brand: 'fremdgehboerse', payment: 'sec'});
            
            return this.SECURION_PK_fremdgehboerse // MID Overwrite
          } else {
            //Set correct descriptor text for Liebeü50
            this.$store.dispatch("setDescriptor", { brand: 'liebeue50', payment: 'sec'});

            return this.SECURION_PK_LIEBEUE50
          }
         
        }

        if (
         host == 'xn--liebeber40-eeb' ||
         host == 'xn--liebeber50-eeb' ||
         host == 'xn--liebeber60-eeb' 
        ){
           //Set descriptor text
           this.$store.dispatch("setDescriptor", { brand: '60slove', payment: 'sec'});
          return this.SECURION_PK_60sLove; //60sLove MID
        
        }else if (
         domain == 'nz'
        ){
          if(host == 'loveat50'){
            //Set descriptor text
            this.$store.dispatch("setDescriptor", { brand: 'affaireclub', payment: 'sec'});
            return this.SECURION_PK_affaireclub; // MID Overwrite
          }
          else {
            //Set descriptor text
            this.$store.dispatch("setDescriptor", { brand: '60slove', payment: 'sec'});
            return this.SECURION_PK_60sLove; //MID Overwrite
          } 
        
        }else if (
          domain == 'au'
        ){
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: 'casualxdates', payment: 'sec'});
          return this.SECURION_PK_casualxdates; //MID Overwrite
        
        }else{
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: host, payment: 'sec'});
          return this.SECURION_PK_Credorax;
        }
        
      },
      securionFallbackKey: function(){

        if(this.getActivePlan.plansPrice.tariffMap){
          //Secondary MID coming from backend
          if(this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback){
            console.log('securionSecondaryKey', this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.key);
            return this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.key;
          }else{
            return '';
          }
        }

        //Manual MID settings

        //Use MID for all CH domains instead of Magnius
        if(this.getActivePlan.plansPrice.tariffIds.Magnius && domain == 'ch') {
          return this.SECURION_PK_affaere1;
        }
      
        if(domain == 'ch' ) {
          return ''; // MID Overwrite
        }else{
          return this.SECURION_PK_Credorax;
        }

      },
    },
    created: function () {
      console.log('Shift4 Payment Integration loaded')
      if(typeof Securionpay == 'undefined') this.initSecurionPay()
      else {
        //Set new Public Key
        console.log('Set new Public Key', this.securionPrimaryKey)
        SecurionPay.setPublicKey(this.securionPrimaryKey);
      } 
      
    },
    methods: {
      // INITIALIZE PAYMENT SYSTEM
      initSecurionPay() {

       //SEND TO HELPER FUNCTION 
       Shift4_helpers._initialze({}, this)

      },
      // FORM DATA VALIDATION
      validateSec() {
       
        //SEND TO HELPER FUNCTION 
       Shift4_helpers._validation({}, this)

      },
      //SEND PAYMENT DATA
      submitSecurionPay: function() {

        var form = document.querySelector('#payment-form');
        
        //SEND TO HELPER FUNCTION 
        Shift4_helpers._submitData({form: form}, this)
  
        //Prevent default form action 
        return false;
  
      },
      submitSecurionPayMobile: function() {
        //SEND TO HELPER FUNCTION 
        Shift4_helpers._submitData({ isMobile: true }, this)
      },
      
    },
  }

</script>