<template>
  <div>
    <b-modal id="bv-modal-report" hide-footer @hide="close" ref="modalReport">
        <template v-slot:modal-title>
          {{ $t($i18n.locale + '.keys.userReportModal.title') }}
        </template>
        <div class="d-block text-center">
          <div class="loader" v-if="loading">
            <span  class="spinner"></span>
          </div>
          <h3 v-html="$t($i18n.locale + '.keys.userReportModal.headline')"></h3>
          <div id="reportReasonWrapper">
            <textarea 
              id="reportReason" 
              v-model="reason" 
              :class="{
                error: emptyReason
              }"
            ></textarea>
          </div>
        </div>
        <div class="actionButtons">
          <b-button
            class="my-3 sendReportButton"
            :disabled="loading"
            @click="sendReport"
          >
            {{ $t($i18n.locale + '.keys.userReportModal.sendButton') }}
            
          </b-button>
        </div>
  
      </b-modal>
  </div>
  </template>
  
  <script>
  
  export default {
    name: "ModalUserReport",
    data() {
      return {
        loading: false,
        reason: '',
        target: null,
        profile_id: 0,
        emptyReason: false,
      };
    },
    watch: {
      reason(val){
        if(val != '') this.emptyReason = false;
      }
    },
    computed: {
  
    },
    mounted() {
  
    },
    methods: {
      show(profile_id, target){
        this.profile_id = profile_id;
        if(typeof target != "undefined"){
          this.target = target;
        }
        this.$refs.modalReport.show();
      },
      close() {
        this.target = '';
      },
    
      sendIBQ(){
        console.log('sending icebreaker question', this.selectedIBQId);
  
        if(!this.selectedIBQId) return true;
        else {
          this.$store.dispatch("sendIcebreakerQuestions", {
            profile_id:this.profile_id,
            question_id: this.selectedIBQId
          }).then(res => {
            if (res == "error") {
              // show error
              //this.$store.dispatch("setErrorVisibility", true);
            } else {
              console.log('***************SEND IBQ*****************');
              if(this.target) $(this.target).remove();
              this.$bvModal.hide('bv-modal-IBQ')
            }
          });
        }
      },
      sendReport(){
        console.log('sending report', this.reason, this.profile_id);
        if(this.loading || !this.profile_id) return false;
        if(this.reason == ''){
          this.emptyReason = true;
          return false;
        } 
        this.loading = true; 
        this.$store.dispatch("sendReport", {
            profile_id:this.profile_id,
            reason: this.reason
          }).then(res => {
            console.log('sendReport res' , res)
            this.loading = false;
            if(this.target) $(this.target).remove();
            this.$bvModal.hide('bv-modal-report')
  
          });
      }
    },
  
  };
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  