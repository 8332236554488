<template>
  <div id="memberInterests">
    <Spinner v-if="isLoading" />
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.memberProfile.memberInterests.title =
            $event.target.innerText
        "
      >
        {{ $t($i18n.locale).keys.memberProfile.memberInterests.title }}
      </h1>
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
    </div>
    <div class="interestsWrapper" v-if="!boxBlurred">
      <div class="leftContainer">
        <span
          v-for="(answer, index) in InterestsData.data.answers"
          :key="index"
        >
          <div
            v-if="
              InterestsData.data.answers &&
                getInterestsKey(index) % 2 == 1 &&
                answer !== null
            "
            class="editabelHobbiesWrapper"
          >
            <div class="editableTitleWrapper">
              <h1 class="editableTitle">
                {{ answer.question.translations[selectedLanguage].translation }}
              </h1>
            </div>
            <ol>
              <span
                v-for="(questionAnswer, index2) in answer.question
                  .answerOptions"
                :key="index2"
                class="editableAnswer"
              >
                <li v-if="answer.answer.includes(index2 + 1)">
                  {{ questionAnswer.translations[selectedLanguage].answer }}
                </li>
              </span>
            </ol>
            <div class="iconsWrapper">
              <!-- <p>{{ answer.answerLikes }}</p> -->
              <img
                v-if="!answer.answerLikes.length"
                :class="{ smileSent: !!answer.answerLikes.length }"
                class="sendLike"
                @click="likeAnswer(answer.id)"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                alt="Like icon"
              />
              <img
                v-if="!answer.answerLikes.length"
                :class="{ commentSent: !!answer.answerLikes.length }"
                class="sendComment"
                @click="showModal(getInterestsKey(index))"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                alt="Message icon"
              />
              <img
                v-if="!!answer.answerLikes.length"
                :class="{ smileSent: !!answer.answerLikes.length }"
                class="sendLike"
                :src="require('@/assets/images/like-grey-icon.svg')"
                alt="Like icon"
              />
              <img
                v-if="!!answer.answerLikes.length"
                :class="{ commentSent: !!answer.answerLikes.length }"
                class="sendComment"
                :src="require('@/assets/images/Message_grey-icon.svg')"
                alt="Message icon"
              />

            </div>
                  <div class="commentInputWrapper">
                <div
                  class="triangle-with-shadow"
                  v-if="form.comment_modals[getInterestsKey(index)]"
                ></div>
                <div class="commentInput" v-if="form.comment_modals[getInterestsKey(index)]">
                  <textarea
                    v-model="form.question_comments[getInterestsKey(index)]"
                    class="commentTextArea"
                    :placeholder="$t($i18n.locale).keys.generic.typeHere"
                    maxlength="100"
                  ></textarea>
                  <!-- <input  >  -->
                  <img
                    @click="showModal(getInterestsKey(index))"
                    class="closeBtn"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                    alt=""
                  />
                  <button
                    @click="
                      commentAnswer({
                        answerID: answer.id,
                        comment: form.question_comments[getInterestsKey(index)]
                      })
                    "
                    class="sendBtn"
                  >
                    {{
                      $t($i18n.locale).keys.memberProfile.userProfile
                        .questionsAndAnswers.send
                    }}
                  </button>
                </div>
              </div>
          </div>
        </span>
      </div>
      <div class="leftContainer">
        <span
          v-for="(answer, index) in InterestsData.data.answers"
          :key="index"
        >
          <div
            v-if="
              InterestsData.data.answers &&
                getInterestsKey(index) % 2 !== 1 &&
                answer !== null
            "
            class="editabelHobbiesWrapper"
          >
            <div class="editableTitleWrapper">
              <h1 class="editableTitle">
                {{ answer.question.translations[selectedLanguage].translation }}
              </h1>
            </div>
            <ol>
              <span
                v-for="(questionAnswer, index2) in answer.question
                  .answerOptions"
                :key="index2"
                class="editableAnswer"
              >
                <li v-if="answer.answer.includes(index2 + 1)">
                  {{ questionAnswer.translations[selectedLanguage].answer }}
                </li>
              </span>
            </ol>
            <div class="iconsWrapper">
              <img
                v-if="!answer.answerLikes.length"
                :class="{ smileSent: !!answer.answerLikes.length }"
                class="sendLike"
                @click="likeAnswer(answer.id)"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'like-green-icon.svg')"
                alt="Like icon"
              />
              <img
                v-if="!answer.answerLikes.length"
                :class="{ commentSent: !!answer.answerLikes.length }"
                class="sendComment"
                @click="showModal(getInterestsKey(index))"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                alt="Message icon"
              />
              <img
                v-if="!!answer.answerLikes.length"
                :class="{ smileSent: !!answer.answerLikes.length }"
                class="sendLike"
                :src="require('@/assets/images/like-grey-icon.svg')"
                alt="Like icon"
              />
              <img
                v-if="!!answer.answerLikes.length"
                :class="{ commentSent: !!answer.answerLikes.length }"
                class="sendComment"
                :src="require('@/assets/images/Message_grey-icon.svg')"
                alt="Message icon"
              />
                <!-- <h1>soup time{{form.comment_modals}}</h1> -->

            </div>
                  <div class="commentInputWrapper">
                <div
                  class="triangle-with-shadow"
                  v-if="form.comment_modals[getInterestsKey(index)]"
                ></div>
                <div class="commentInput" v-if="form.comment_modals[getInterestsKey(index)]">
                  <textarea
                    v-model="form.question_comments[getInterestsKey(index)]"
                    class="commentTextArea"
                    :placeholder="$t($i18n.locale).keys.generic.typeHere"
                    maxlength="100"
                  ></textarea>
                  <!-- <input  >  -->
                  <img
                    @click="showModal(getInterestsKey(index))"
                    class="closeBtn"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'Cross-little.svg')"
                    alt=""
                  />
                  <button
                    @click="
                      commentAnswer({
                        answerID: answer.id,
                        comment: form.question_comments[getInterestsKey(index)]
                      })
                    "
                    class="sendBtn"
                  >
                    {{
                      $t($i18n.locale).keys.memberProfile.userProfile
                        .questionsAndAnswers.send
                    }}
                  </button>
                </div>
              </div>
          </div>
        </span>
      </div>
    </div>
    <ProfileBlurBanner v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileBlurBanner from "@/components/generic/ProfileBlurBanner.vue";

export default {
  name: "MemberInterests",
  components: {
    ProfileBlurBanner
  },
  props: [
    "profileId"
  ],
  data() {
    return {
      componentKey: 0,
      lockRequests:false,
      form: {
        question_comments: [],
        comment_modals: []
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["InterestsData", "MemberData", "selectedLanguage", "user"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    boxBlurred: function(){ 
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 8;
    },
  },
    created() {

    this.hideAllCommentModals();
  },
  mounted() {
     this.$store.dispatch("fetchInterestsData", this.profileId).then(() => {
       this.isLoading = false;
     });
  },
  methods: {
    getInterestsKey(keytoFind) {
      return Object.keys(this.InterestsData.data.answers).indexOf(keytoFind);
    },

    fetchInterests() {
      this.$store
        .dispatch("fetchInterestsData", this.MemberData.profile_id)
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            // show error
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
          }
        });

      this.componentKey += 1;
    },

    showModal(index) {
      //console.log(index);
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
        this.form.comment_modals[index] = !this.form.comment_modals[index];
        this.form.comment_modals = [...this.form.comment_modals];
      }else{
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
    likeAnswer(answerID) {
      if(this.lockRequests) return false;
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){
        this.lockRequests = true;
        this.$store.dispatch("likeAnswer", answerID).then(() => {
          this.lockRequests = false;
          this.componentKey += 1;
          this.fetchInterests();
        });
      }else{
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },

    hideAllCommentModals() {
      for (let x in this.form.comment_modals) {
        this.form.comment_modals[x] = false;
      }
    },
    commentAnswer(commentData) {
      let data = {
        answerID: commentData.answerID,
        comment: commentData.comment,
      };
      if(data.comment && data.comment != ''){
        if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
          this.$store.dispatch("commentAnswer", data).then(()=>{
            this.fetchInterests();
            this.componentKey += 1;
          });
        } else {
          localStorage.setItem("premium", 'false');
          this.$router.push(`/premium-plans`);
        }

      }
      this.hideAllCommentModals();
      this.form.comment_modals = [...this.form.comment_modals];
    }
  }

};
</script>

<style lang="scss">
.smileSent {
  pointer-events: none;
  background: url("/src/assets/images/Close-cross-White.svg");
}

.commentSent {
  pointer-events: none;
  background: url("/src/assets/images/Close-cross-White.svg");
}

.commentInputWrapper {
  position: relative;
  width: 100%;

  .sendBtn {
    right: 25px;
    background: #ffffff;
    position: absolute;
    bottom: 15px;
    width: 100px;
    height: 30px;
    color: #008860;
    box-shadow: none;
    text-transform: uppercase;
    color: #008860;
    border: 1px solid #008860;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .closeBtn {
    height: 15px;
    width: 15px;
    position: absolute;
    margin: 0;
    right: 10px;
    top: 10px;
  }
  .commentTextArea {
    left: 0px;
    position: absolute;
    height: 98%;
    width: 75%;
    resize: none;
    border: none;
    padding: 20px;
    font-size: 20px;
  }

  .triangle-with-shadow {
    width: 100%;
    height: 80px;
    position: absolute;
    overflow: hidden;
    top: -79px;
    z-index: 5;
    transform: translateX(-25px);
  }
  .triangle-with-shadow:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 75px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    left: 50%;
  }

  .commentInput {
    border: solid 1px #dadde4;
    height: 121.6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    width: 100%;
    background-color: #ffffff;
  }

}
.interestsWrapper {
    .leftContainer:last-of-type {
      .commentInput {
          top:-180px;
        }
      .triangle-with-shadow {
        top: -59px;
        transform: translateX(-25px);
        &:after {
          top:-45px;
        }
      }
    }
  }

</style>
