import axios from "axios";
import Vue from 'vue';
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
    async fetchUserInterests({
        commit
    }) {

        try {
            const response = await axios.get(BASE_URL + '/api/profile/interests',handlerEnabled);
            console.log(response.data.data, 'interests')
            commit('setUserInterests', response.data.data)
        } catch (error) {
            return 'error';
        }
    },
    async fetchUserPersonality({
        commit
    },locale) {

        try {
            const response = await axios.get(BASE_URL + `/api/profile/personality/${locale}`,handlerEnabled);
            Vue.gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Profile Updated',
                label: 'Interests Updated'
            });
            commit('setUserPersonality', response.data.data)
        } catch (error) {
            return 'error';
        }
    },
    async updateInterestsAnswer({
        commit
    }, data) {

        try {
            await axios.put(BASE_URL + '/api/personality/answer/update', data,handlerEnabled);
            commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    },
    async sendInterestsAnswer({
        commit
    }, data) {

        try {
            await axios.post(BASE_URL + '/api/personality/answer', data,handlerEnabled);
            commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    }
}