<template>
  <div id="questionType3" class="questionComponent">
    <h1 v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>
   <!--
    <p
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >{{$t($i18n.locale).keys.generic.skippableQuestionMessage}}</p>
   -->
    <div class="chatStyleAnswer">
      <div class="answerWrapper">
        <p
          class="additionalMessage"
          v-if="questionData.question.id == 101"
        >{{$t($i18n.locale).keys.questionnaire.type2.nameVisibilityMessage}}</p>
        <input v-model="answer" class="genericInput" type="text" :placeholder="$t($i18n.locale).keys.questionnaire.type3.typeHere" maxlength="32" />
      </div>
    </div>
    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
        skippable: questionData.question.skippable
      }"
      :nextDisabled="answer.length < 3"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"
      @answerLater="emptyAnswers(); sendAnswer(); resendAnswer()"
    />
    <button
      v-else
      v-langErr :contenteditable="editable"
      @click="sendAnswer(); resendAnswer()"
      v-bind:class="{ 'btnContinue-disabled': answer.length < 3 || answer == answerExist() }"
      :disabled="answer.length < 3 || answer == answerExist()"
      class="genericButton btnContinue"
    >{{$t($i18n.locale).keys.generic.continueBtn}}</button>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  props: [
  "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType3",
  components: {
    QuestionNavigation
  },
  data() {
    return {
      answer: "",
      lockSend: false,
    };
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.answer = "";
      } else {
        if (this.questionData.answered) {
          this.answer = this.questionData.answered.answer[0];
        }
      }
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
        selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
  },
  methods: {
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [this.answer]);
      }
      else if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: [this.answer],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: [this.answer],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    emptyAnswers(){
      this.answersArray = [];
    },
    answerExist() {
      if (this.questionData.answered) {
        return this.questionData.answered.answer;
      } else {
        return false;
      }
    },
   
    answerChanged(answer) {
      if (this.questionData.answered) {
        if (answer == this.questionData.answered.answer[0]) {
          true;
        } else {
          false;
        }
      }
    }
  },
 
};
</script>
