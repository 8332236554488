<template>
  <div id="smokingAndDrinkingHabits" :key="componentKey" class="preferencesBox">
    <div class="smokingAndDrinkingHabitsWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="smokingHabitsWrapper filterWrapper">
        <div class="smokingHabitsTextWrapper textWrapper">
          <h3
            class="smokingHabitsText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerSmokingHabitsQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerSmokingHabitsQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="smokingFrequencyWrapper inputWrapper">
          <select class="smokingFrequency">
            <option
              value="1"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.smokingFrequency.never}}</option>
            <option
              value="2"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.smokingFrequency.severalTimesAYear}}</option>
            <option
              value="3"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.smokingFrequency.aboutOnceAWeek}}</option>
            <option
              value="4"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.smokingFrequency.aFewTimesAWeek}}</option>
            <option
              value="5"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.smokingFrequency.everyday}}</option>
          </select>
        </div>
      </div>
      <div class="smokingFrequencyImportanceWrapper filterWrapper">
        <div class="smokingFrequencyTextWrapper textWrapper">
          <h3
            class="smokingFrequencyText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerSmokingHabitsImportanceQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerSmokingHabitsImportanceQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="smokingFrequencyImportanceFilter"
            v-model="smokingFrequencyImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="smokingFrequencyImportanceMarks"
            :adsorb="true"
            :included="true"
          ></vue-slider>
        </div>
      </div>

      <div class="drinkingHabitsWrapper filterWrapper">
        <div class="drinkingHabitsTextWrapper textWrapper">
          <h3
            class="drinkingHabitsText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerDrinkingHabitsQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerDrinkingHabitsQuestion}}</h3>
          <img
            v-if="editable"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="drinkingFrequencyWrapper inputWrapper">
          <select class="drinkingFrequency">
            <option
              value="1"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.drinkingFrequency.never}}</option>
            <option
              value="2"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.drinkingFrequency.severalTimesAYear}}</option>
            <option
              value="3"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.drinkingFrequency.aboutOnceAWeek}}</option>
            <option
              value="4"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.drinkingFrequency.aFewTimesAWeek}}</option>
            <option
              value="5"
            >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.drinkingFrequency.everyday}}</option>
          </select>
        </div>
      </div>
      <div class="drinkingFrequencyImportanceWrapper filterWrapper">
        <div class="drinkingFrequencyTextWrapper textWrapper">
          <h3
            class="drinkingFrequencyText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerDrinkingHabitsImportanceQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.smokingAndDrinkingHabits.partnerDrinkingHabitsImportanceQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="drinkingFrequencyImportanceFilter"
            v-model="drinkingFrequencyImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="drinkingFrequencyImportanceMarks"
            :adsorb="true"
            :included="true"
          ></vue-slider>
        </div>
      </div>
      <div class="buttonWrapper">
        <button
          @click="sendSmokingAndDrinkingHabitsValues()"
          class="btnSave"
        >{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SmokingAndDrinkingHabits",

  data() {
    return {
      componentKey: 0,
      smokingFrequencyImportanceValue: 3,
      smokingFrequencyImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant,
        },
        "2": {
          label: "",
        },
        "3": {
          label: "",
        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant,
        },
        "5": {
          label: "",
        },
        "6": {
          label: "",
        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant,
        }
      },
      smokingAndDrinkingHabitsFilters: {},
      drinkingFrequencyImportanceValue: 3,
      drinkingFrequencyImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant,
        },
        "2": {
          label: "",
        },
        "3": {
          label: "",

        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant,

        },
        "5": {
          label: "",

        },
        "6": {
          label: "",

        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant,

        }
      }
    };
  },
  mounted() {
    this.setResponsiveSteps();
  },
  computed: {
    ...mapGetters(["getSmokingAndDrinkingHabits"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch:{
    getSmokingAndDrinkingHabits: function(newVal){
      if(!_.isEmpty(newVal))  this.setSmokingAndDrinkingHabitsFilters();
    }
  },
  methods: {
    setSmokingAndDrinkingHabitsFilters() {

        this.smokingFrequencyImportanceValue = this.getSmokingAndDrinkingHabits.importanceSmoking;
        this.drinkingFrequencyImportanceValue = this.getSmokingAndDrinkingHabits.importanceDrinking;
        this.componentKey++;

        this.$nextTick(function(){
          document.querySelector(
            ".smokingFrequency"
          ).value = this.getSmokingAndDrinkingHabits.partnerSmoking.id;
          document.querySelector(
            ".drinkingFrequency"
          ).value = this.getSmokingAndDrinkingHabits.partnerDrinking.id;
        });

    },
    sendSmokingAndDrinkingHabitsValues() {
      this.smokingAndDrinkingHabitsFilters = {
        importanceDrinking: parseInt(this.drinkingFrequencyImportanceValue),
        importanceSmoking: parseInt(this.smokingFrequencyImportanceValue),
        partnerDrinking: parseInt(
          document.querySelector(".drinkingFrequency").value
        ),
        partnerSmoking: parseInt(
          document.querySelector(".smokingFrequency").value
        )
      };
      this.$store
        .dispatch(
          "updateSmokingAndDrinkingHabits",
          this.smokingAndDrinkingHabitsFilters
        )
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            });
              this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Smoking & Drinking'
              });
            // this.componentKey++;
          }
        });
    },
    setResponsiveSteps() {
     
    }
  }
};
</script>
