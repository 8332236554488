import axios from "axios";
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
import router from "../../../router";
import i18n from '../../../i18n';

// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};
export default {
  async getConversations({
    commit
  },data) {
    try {

      console.log('getConversations', data);

      // ?offset=0&limit=10
      let offset = 0;
      let limit = 10;
      if(typeof data != 'undefined' && data && data != 'autoupdate' ){
        if(data.offset) offset = data.offset;
        if(data.limit) limit = data.limit;
      }

      const resp = await axios.get(BASE_URL + `/api/chat/conversations/?offset=${offset}&limit=${limit}`, handlerEnabled) ;

      console.log('getConversations resp', resp);

      //generic welcome msg
      var gM = false;
      if( resp.data.data.length < 10 ){
        gM = {
          birthday: "",
          created_at: "",
          entertainment: "0",
          firstName: store.getters.welcomeMessageSender, //i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.sender',{productName: store.getters.productName,}),
          gender: "2",
          id: "1",
          matched_user_id: "1",
          message: i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.subject',{productName: store.getters.productName,}),
          messageId: "1",
          new: "0",
          photo_id: "1",
          public_path: require('@/assets/images/' + store.getters.assetsPath + '50sloveAvatar.jpg'),
          type: "1",
          blurred: false,
          blockSend: true
        }

        //resp.data.data.push(gM);
      }

      if(offset != 0) {

        let hasWelcomeMessage = store.getters.conversations.find( el => {
          return el.id == 1;
        })

        console.log('generic welcome msg', hasWelcomeMessage, gM)

        if(!hasWelcomeMessage && gM ){
          console.log('generic welcome msg ADD')
          resp.data.data.push(gM);
        }
        commit("update_conversations", {conversations: resp.data.data, direction: 'after'});
        return resp.data.data;
      }else if(data == 'autoupdate'){
        commit("update_conversations", {conversations: resp.data.data, direction: 'before'});
      }else {

        if(gM){
          console.log('generic welcome msg ADD')
          resp.data.data.push(gM);
        }

        commit("load_conversations", {conversations: resp.data.data, direction: 'replace'});
        return resp.data.data;
      }

    } catch (error) {
      console.log('getConversations', error)
      return 'error';
    }
  },
  deleteConversation({commit}, match_id){
    commit("delete_conversation", {match_id: match_id})
  },

  async getMessages({
    commit,
    dispatch,
    getters
  }, conversationID) {
    if(conversationID == -1) {
      //resetting the message state
      commit("load_info", {firstName: "test", birthday: "1933-01-03", profession: "test", location: "test", id: -1});
      return true;
    }
    if(conversationID == 1) {
      console.log('LOAD DUMMY MESSAGE');
      await store.dispatch("fetchProfileData").then(() => {
        var username = getters.getProfileCard.name;
        var messageContentKey = '.keys.membershipChat.welcomeMsg.content'
        if(domain == 'at'){
          messageContentKey = '.keys.membershipChat.welcomeMsg.content_at'
        }
        var dM = {
          created_at: "",
          message: i18n.t(i18n.locale + messageContentKey, {senderName: store.getters.welcomeMessageSender, username: username, IBQ_Icon:require('@/assets/images/' + store.getters.assetsPath + 'icebreaker-green-button.svg'), Smiley_Icon:require('@/assets/images/' + store.getters.assetsPath + 'smile-green-button.svg'),productName: store.getters.productName }),
          messageId: "1",
          question_type: null,
          quote_question: null,
          quote_text: null,
          sender: store.getters.welcomeMessageSender, //i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.sender',{productName: store.getters.productName,}),
          type: "-1",
          blurred: false,
          blockSend: true
        };
        commit("load_messages", [dM]);

        //resetting the message state
        commit("load_info", {
          firstName: store.getters.welcomeMessageSender, //i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.sender',{productName: store.getters.productName,}),
          birthday: "",
          profession: "",
          location: "",
          id: 1,
          gender:2,
          public_path: require('@/assets/images/' + store.getters.assetsPath + '50sloveAvatar.jpg'),
        });
        console.log('FINISHED DUMMY MESSAGE');
        return true;
      });
    }else{
      try {
        const resp = await axios.get(
          BASE_URL + `/api/chat/conversation/${conversationID}?beforeId=0`, handlerEnabled
        );
        commit("load_messages", resp.data.data.messages);
        commit("load_info", resp.data.data.info);
        dispatch("getNewMessagesNumber");

      } catch (res) {
        return 'error';
      }
      // resolve(resp.data.data);
    }
  },

  async getNewMessagesNumber({
    commit
  }) {
    try {
      const response = await axios.get(BASE_URL + `/api/chat/conversations/new`, handlerEnabled);
      commit("new_messages", response.data.data[0].new);
      return response.data.data[0].new;
    } catch (error) {
      return 'error';
    }
  },

  async sendSmile({
    dispatch,
    commit
  }, matchId) {

    try {
      const res = await axios.get(BASE_URL + `/api/matches/smile/${matchId}`,handlerEnabled);
      console.log("sendSmile action", res);
      dispatch("getConversations");
      dispatch("getNewMessagesNumber");
      dispatch("matchesCount");
      commit("changeMatchSmile", matchId)
      return res;
    } catch (error) {
      console.log("sendSmile action", error);
      let errResponse = '';
      if(error.response) errResponse = error.response.data;

      return errResponse;
    }
  },

  async sendPhotoRequest({
    dispatch
  }, profile_id) {

    console.log('sendPhotoRequest', profile_id);

    try {
      await axios.post(BASE_URL + `/api/chat/photo_request/${profile_id}`,handlerEnabled);
      dispatch("getConversations");
      dispatch("getNewMessagesNumber");
      dispatch("matchesCount");
    } catch (error) {
      return 'error';
    }

  },


  async initiateConversation({
    dispatch
  }, match) {
    try {
      console.log('initiate', match);
      if(!match.blockSend || store.getters.user.settings.paywallOnSend == 1){
        var matchID = match.id;
        
        var requestURL = BASE_URL + `/api/matches/initiate/${matchID}`;
        if(match.isVisitor){
          matchID = match.match_id;
          requestURL = BASE_URL + `/api/visitors/initiate/${matchID}`;
        }

        console.log('initiate2')
        const resp = await axios.get(requestURL ,handlerEnabled);
        let conversationID = resp.data.data.conversation_id;
        dispatch("getMessages", conversationID).then(() => {
          console.log("dispatch - getMessages", conversationID);
          router.push(`/membership-area/messages/${conversationID}`)
        }
        );
      } else {
        localStorage.setItem("premium", 'false');
        router.push(`/premium-plans`);
      }

    } catch (error) {
      return 'error';
    }
  },

  async setMessages({
    commit
  }, messages) {
    commit("load_messages", messages);
  },

  async sendSmileVisitors({dispatch,commit},matchId) {

    const response = await axios.get(BASE_URL + `/api/visitors/smile/${matchId}`, handlerEnabled);
    try {
      if (response) {
        dispatch("getConversations");
        dispatch("getNewMessagesNumber");
        dispatch("visitorsCount");
        commit("changeVisitorSmile", matchId)
       return response;
      } else {
        return 'error';
      }
    } catch (error) {
      return 'error';
    }
  },

    addActionCount({commit}){
    commit('addActionCount');
  },

  async getIcebreakerQuestions({
    commit
  }, profile_id) {
    console.log('getIcebreakerQuestions');
    try {
      const response = await axios.get(BASE_URL + `/api/chat/icebreaker/get/${profile_id}`, handlerEnabled);
      console.log(response);
      commit("set_icebreaker", response.data.data.questions);
      /*
      let test_data = [
        {
          id:2,
          question:"loaded data"
        },
        {
          id:3,
          question:"loaded data 2"
        }
      ];
      commit("set_icebreaker", test_data);
      */
    } catch (error) {
      return 'error';
    }
  },
  async sendIcebreakerQuestions({
    dispatch
  }, data) {
    //data:
    //{profile_id, question_id}
    console.log('sendIcebreakerQuestions', data);

    try {
      await axios.post(BASE_URL + `/api/chat/icebreaker/send/${data.profile_id}/${data.question_id}`,handlerEnabled);
      dispatch("getConversations");
      dispatch("getNewMessagesNumber");
      dispatch("matchesCount");
    } catch (error) {
      return 'error';
    }

  },

  async updateChatMessages(
  {
    commit
  }, data) {
      console.log('getScrollMessages', data);
    //DO SOMETHING

    let query = '';
    let direction ='';
    if(data.beforeId) {
      query = `?beforeId=${data.beforeId}`;
      direction = 'after';
    }
    else if(data.afterId){
      query = `?afterId=${data.afterId}`;
      direction = 'before';
    }

    const res = await axios.get(BASE_URL + `/api/chat/conversation/${data.conversationID}/${query}`,handlerEnabled    );
    //console.log('getScrollMessages res', res);
    if(res.data.data.messages.length)  commit('update_messages', {messages: res.data.data.messages, direction: direction})
    return res;

  },

  async sendChatMessage(
    {commit},data
  ) {
    console.log('sendChatMessage', data);

    try {
      var res = await axios({ url:  BASE_URL + "/api/chat/", data: data, method: "POST" });  
      if(!res.data.data.paywall)  commit('update_messages', {messages: res.data.data.messages, direction: 'before'})
    } catch (error) {
      res = error.response;
    }
    
    console.log('sendChatMessage ACTION', res);
    
    return res;

  },
};