<template>
  <div id="membershipOnlineList" class="container-lg">
    <div class=" matchesContainer">
      <div class="rightContainer" v-if="showMembers">
        <h1 id="mainHeadline">
          <span >
            {{ $t($i18n.locale + '.keys.membershipArea.membershipOnlineBox.title') }}
          </span>
          <img
            src="../../../assets/images/onlineUser_icon_green_solid.svg"
            alt="icon"
            class="icon"
          />
        </h1>
        <OnlineList />
      </div>
    </div>

  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import OnlineList from "@/components/membership-area/membership-online/OnlineList.vue";

import { mapGetters } from "vuex";
//import jwt from "jsonwebtoken";

export default {
  name: "MembershipOnlineList",
  components: {
    //MembershipMisc,
    OnlineList,
  },
  data() {
    return {
      show1: false,
      searchBoxOffset: 0,
      showMembers: false,
      userId: 0,
    };
  },
  computed: {
    ...mapGetters([
      "onlineUsers",
      "isMobile"
    ]),
  },
  mounted() {

    window.scrollTo(0,0);

    this.$store.dispatch("fetchOnlineUsers").then(() => {
      console.log('fetchOnlineUsers done')
      this.$store.dispatch("showContent");
      this.$store.dispatch("hideLoader").then(()=>{
          this.showMembers = true;
      });
    });

  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>

</style>