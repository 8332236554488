<template>
  <div id="HaveYouMetCards">
  <!-- <h1>{{swipeDisabled}}</h1> -->


    <section class="container" id="container" v-if="current">
      <div
        v-if="current"
        class="absolute absolute-center currentCard"
        style="z-index: 3"
        :class="{
          transitionRight: moveRight,
          transitionLeft: moveLeft
        }"
      >
        <div id="movingCard" v-if="isVisible" class="card">
          <MeetingsItems
            @sendMessage="sendMessage"
            @clickedMatch="match"
            @clickedReject="reject"
            v-if="current"
            v-bind:meetingData="current"
          />
        </div>
      </div>
      <div
        v-if="next"
        class=" card second-card absolute absolute-center"
        style="z-index: 2"
      >
         <Loader v-if="showLoader"></Loader>
        <MeetingsItems v-bind:meetingData="next" />
      </div>
      <div
        v-if="index + 2 < meetings.length"
        class=" card third-card absolute absolute-center"
        style="z-index: 1"
      >
        <div style="height: 100%; background: #fffff"></div>
      </div>
    </section>
    <div class="container flex flex-center noMeetingsContainer" id="container" v-else >
      <!-- space for "No meetings found or left" -->
      <div class="noMeetingsWrapper" v-if="meetings.length == 0" >
          <div class="contentWrapper">
            <img src="../../../assets/images/no-meet.svg" alt="No meetings icon">
            <p class="noMeetingsText">
              <span class="text1"
                v-langErr :contenteditable="editable"
                @blur="$t($i18n.locale).keys.membershipArea.membershipMeetings.noMeetings1 =
                  $event.target.innerText"
              >{{$t($i18n.locale).keys.membershipArea.membershipMeetings.noMeetings1}}</span><br>
              <span class="text2"
                v-langErr :contenteditable="editable"
                @blur="$t($i18n.locale).keys.membershipArea.membershipMeetings.noMeetings2 =
                  $event.target.innerText"
              >{{$t($i18n.locale).keys.membershipArea.membershipMeetings.noMeetings2}}</span>
              <img
                v-if="editable"
                :style="{marginLeft : '20px'}"
                src="../../../assets/images/Translate-Icon.svg"
                alt="Translation icon"
              />
            </p>
          </div>
      </div>
    </div>

    <div class="mobileBottomBar" v-if="current">
    <SendMobileMessage
      @changeChat="hideChat"
      @sendMessage="sendMessage"
      v-if="current"
      v-bind:meetingData="current"
    />
      <div class="iconsWrapper">
        <div @click="reject" class="dismissIcon"></div>
        <div @click="displayChat" class="chatIcon"></div>
        <div @click="match" class="smileIcon"></div>
      </div>
    </div>
  </div>
</template>
<script>
import MeetingsItems from "@/components/membership-area/membership-meetings/MeetingsItems";
import SendMobileMessage from "@/components/membership-area/membership-meetings/SendMobileMessage.vue";
import Loader from "@/components/generic/Loader.vue";

export default {
  name: "HaveYouMetmeetings",
  components: { MeetingsItems, SendMobileMessage, Loader },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      isVisible: true,
      moveRight: false,
      moveLeft: false,
      showLoader: false,
      index: 0,
      swipeDisabled: false,
      lockSend: false,
    };
  },
  computed: {
    meetings() {
      return this.$store.getters.meetings;
    },
    current() {
      if(!this.meetings.length) return false;
      if(this.meetings.length && this.meetings[this.index]){
        return this.meetings[this.index];
      }else{
        return false;
      }

    },
    next() {
      if(this.meetings.length && this.meetings[this.index + 1]){
        return this.meetings[this.index + 1];
      }else{
        return false;
      }
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  created() {
  },
  mounted() {
    if (this.current) {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    }
  },
  methods: {

    handleResize() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 768) {
        document.getElementById("container").style.display = "block";
        document.getElementById("SendMobileMessage").style.display = "none";
      }
    },
    displayChat() {
      //document.getElementById("container").style.display = "none";
      //document.getElementById("SendMobileMessage").style.display = "block";
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){
        if($('#SendMobileMessage').is(':visible')){
            $('#SendMobileMessage').animate({
            opacity:0,
            top: '77px'
          },() => {
              $('#SendMobileMessage').hide();
          });
        }else{
          $('#SendMobileMessage').show().animate({
            opacity:1,
            top: '-77px'
          });
        }
      } else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });

        localStorage.setItem("premium", 'false');
        //this.$router.push(`/premium-plans`);
        location.href="/premium-plans";
      }

    },
    hideChat() {
      document.getElementById("container").style.display = "block";
      document.getElementById("SendMobileMessage").style.display = "none";
    },
    sendMessage(message) {
      if(this.$store.getters.userRole.includes("ROLE_PREMIUM")){
        if(this.lockSend || message == '') return false;
        this.lockSend = true;
      this.$store.dispatch("meetingsConversation", {
              meeting_id: this.current.meeting_id,
              message: message
            }).then( (res) => {
              this.$store.dispatch('hideLoader');
              this.lockSend = false;
              if(res == 'error'){
                //this.$store.dispatch('setErrorVisibility', true);
              } else {
                this.$store.dispatch('showContent');
                this.$store.dispatch("getConversations");
              }
            });
            this.hideChat( this.emitAndNext("right"));
      } else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });

        localStorage.setItem("premium", 'false');
        //this.$router.push(`/premium-plans`);
        location.href="/premium-plans";
      }



    },
    match() {
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
        if(this.swipeDisabled){return}
        this.emitAndNext("right");
        this.$store.dispatch("sendMeetingSmile", this.current.meeting_id).then((res) => {
          this.swipeDisabled = true;
          if(res == 'error'){
          this.swipeDisabled = false
            //this.$store.dispatch('setErrorVisibility', true);
          } else {
            this.$store.dispatch('showContent');
            this.$store.dispatch("getConversations").then(this.swipeDisabled = false);
          }
        })
       }else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Smile'
        });

        localStorage.setItem("premium", 'false');
        //this.$router.push(`/premium-plans`);
        location.href="/premium-plans";
      }
    },
    reject() {
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
        if(this.swipeDisabled){return}
        this.emitAndNext("left");
        this.$store.dispatch("sendMeetingRemove", this.current.meeting_id).then((res) => {
          this.swipeDisabled = true;
          this.$store.dispatch('hideLoader');
          if(res == 'error'){
            this.swipeDisabled = false
            //this.$store.dispatch('setErrorVisibility', true);
          } else {
            this.$store.dispatch('showContent');
            this.$store.dispatch("getConversations").then(this.swipeDisabled = false);
          }
        })
      } else {
        localStorage.setItem("premium", 'false');
        //this.$router.push(`/premium-plans`);
        location.href="/premium-plans";

      }
    },
    resetAnimations() {
      this.moveLeft = false;
      this.moveRight = false;
      if(window.outerWidth < 768){
          var offset = 45;
            $('#HaveYouMetCards #container').height($('.currentCard ').height()+offset);
        }
        window.scrollTo(0,0);
    },
emitAndNext(event) {
      this.showLoader = true;
      if (event == "left") {
        this.moveLeft = true;
      } else {
        this.moveRight = true;
      }
      this.isVisible = true;
      //setTimeout(() => (this.isVisible = false), 500);
      setTimeout(() => {
        this.isVisible = true;
        this.showLoader = false;
        this.index++;

      }, 250);
          setTimeout(() => ( this.resetAnimations()), 250);
    }
  }
};
</script>

<style lang="scss" scoped></style>
