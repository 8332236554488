var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'hidePersonality':!_vm.showPersonality},attrs:{"id":"memberTabs"}},[_c('router-link',{staticClass:"profile",class:[
      _vm.$route.path == '/membership-area/member-profile/profile' ? 'profile-active' : ''
    ],attrs:{"to":'/membership-area/member-profile/profile' + _vm.profileAdd},nativeOn:{"click":function($event){return _vm.onClickNavigation(0)}}},[_c('div',{staticClass:"image"}),_c('div',{staticClass:"wrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"memberTabsProfile",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.profile = $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.profile))]),(_vm.editable)?_c('img',{style:({marginLeft : '20px'}),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()])]),_c('router-link',{staticClass:"gallery",class:[_vm.$route.path == '/membership-area/member-profile/gallery' ? 'gallery-active' : ''],attrs:{"to":'/membership-area/member-profile/gallery' + _vm.profileAdd},nativeOn:{"click":function($event){return _vm.onClickNavigation(1)}}},[_c('div',{staticClass:"image"}),_c('div',{staticClass:"wrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"memberTabsGallery",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.gallery = $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.gallery))]),(_vm.editable)?_c('img',{style:({marginLeft : '20px'}),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()])]),(_vm.showPersonality)?_c('router-link',{staticClass:"personality",class:[
        _vm.$route.path == '/membership-area/member-profile/personality' ? 'personality-active' : ''
      ],attrs:{"to":'/membership-area/member-profile/personality' + _vm.profileAdd},nativeOn:{"click":function($event){return _vm.onClickNavigation(2)}}},[_c('div',{staticClass:"image"}),_c('div',{staticClass:"wrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"memberTabsPersonality",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.personality = $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.personality))]),(_vm.editable)?_c('img',{style:({marginLeft : '20px'}),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()])]):_vm._e(),_c('router-link',{staticClass:"interests",class:[
      _vm.$route.path == '/membership-area/member-profile/interests' ? 'interests-active' : ''
    ],attrs:{"to":'/membership-area/member-profile/interests' + _vm.profileAdd},nativeOn:{"click":function($event){return _vm.onClickNavigation(3)}}},[_c('div',{staticClass:"image"}),_c('div',{staticClass:"wrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"memberTabsInterests",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.interests = $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.memberProfile.memberTabs.interests))]),(_vm.editable)?_c('img',{style:({marginLeft : '20px'}),attrs:{"src":require("../../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }