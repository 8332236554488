<template>
  <div id="ethnicityAndReligion" :key="componentKey" class="preferencesBox">
    <div class="ethnicityAndReligionWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="ethnicityWrapper filterWrapper">
        <div class="ethnicityTextWrapper textWrapper">
          <h3
            class="ethnicityText"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroupsQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroupsQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="whiteOrCaucasianFilterSlider"
            class="switchInput"
            id="ethnicity1"
            v-bind:class="{ active: isActive1 }"
            v-touch:start="changeWhiteOrCaucasianFilterValue"
            v-touch:longtap="changeWhiteOrCaucasianFilterValue"
            @change="changeWhiteOrCaucasianFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.whiteOrCaucasian = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.whiteOrCaucasian}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="nativeAmericanFilterSlider"
            class="switchInput"
            id="ethnicity2"
            v-bind:class="{ active: isActive2 }"
            v-touch:start="changeNativeAmericanFilterValue"
            v-touch:longtap="changeNativeAmericanFilterValue"
            @change="changeNativeAmericanFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.nativeAmericanIndianOrNativeAlaskan = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.nativeAmericanIndianOrNativeAlaskan}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="asianFilterSlider"
            class="switchInput"
            id="ethnicity3"
            v-bind:class="{ active: isActive3 }"
            v-touch:start="changeAsianFilterValue"
            v-touch:longtap="changeAsianFilterValue"
            @change="changeAsianFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.asian = $event.target.innerText"
            class="switchName"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.asian}}</p>
        </div>
        <div class="switchWrapper">
          <input
            v-model="havaiianFilterSlider"
            class="switchInput"
            id="ethnicity4"
            v-bind:class="{ active: isActive4 }"
            v-touch:start="changeHavaiianFilterValue"
            v-touch:longtap="changeHavaiianFilterValue"
            @change="changeHavaiianFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.nativeHavaiianOrPacificIslander = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.nativeHavaiianOrPacificIslander}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="africanAmericanFilterSlider"
            class="switchInput"
            id="ethnicity5"
            v-bind:class="{ active: isActive5 }"
            v-touch:start="changeAfricanAmericanFilterValue"
            v-touch:longtap="changeAfricanAmericanFilterValue"
            @change="changeAfricanAmericanFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.blackOrAfricanAmerican = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.blackOrAfricanAmerican}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="latinoFilterSlider"
            class="switchInput"
            id="ethnicity6"
            v-bind:class="{ active: isActive6 }"
            v-touch:start="changeLatinoFilterValue"
            v-touch:longtap="changeLatinoFilterValue"
            @change="changeLatinoFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.latinoOrHispanic = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.latinoOrHispanic}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="otherEthnicityFilterSlider"
            class="switchInput"
            id="ethnicity7"
            v-bind:class="{ active: isActive7 }"
            v-touch:start="changeOtherEthnicityFilterValue"
            v-touch:longtap="changeOtherEthnicityFilterValue"
            @change="changeOtherEthnicityFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.other = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.other}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="anyEthnicityFilterSlider"
            class="switchInput"
            id="ethnicity8"
            v-bind:class="{ active: isActive8 }"
            v-touch:start="changeAnyEthinicityFilterValue"
            v-touch:longtap="changeAnyEthinicityFilterValue"
            @change="changeAnyEthinicityFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.IDontMind = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.ethnicGroups.IDontMind}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="badFormatError" v-if="ethnicityEmpty">Bitte wählen Sie min. 1 Antwort aus</div>
        <div class="ethnicityImportanceWrapper filterWrapper">
          <div class="ethnicityTextWrapper textWrapper">
            <h3
              class="ethnicityText"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.sameEthnicGroupsQuestion = $event.target.innerText"
            >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.sameEthnicGroupsQuestion}}</h3>
            <img
              v-if="editable"
              :style="{marginLeft : '20px'}"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
          </div>
          <div class="sliderWrapper steps">
            <vue-slider
              id="ethnicityImportanceFilter"
              v-model="ethnicityImportanceValue"
              :min="1"
              :max="7"
              :tooltip="'none'"
              :tooltip-placement="'bottom'"
              :enable-cross="false"
              :marks="ethnicityImportanceMarks"
              :adsorb="true"
              :included="true"
            ></vue-slider>
          </div>
        </div>
      </div>

      <div class="religionWrapper filterWrapper">
        <div class="religionTextWrapper textWrapper">
          <h3
            class="religionText"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.whichReligionPartnerQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.whichReligionPartnerQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="christianFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive9 }"
            v-touch:start="changeChristianityFilterValue"
            v-touch:longtap="changeChristianityFilterValue"
            @change="changeChristianityFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.christian = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.christian}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="catholicFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive10 }"
            v-touch:start="changeCatholicismFilterValue"
            v-touch:longtap="changeCatholicismFilterValue"
            @change="changeCatholicismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.catholic = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.catholic}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="muslimFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive18 }"
            v-touch:start="changeMuslimFilterValue"
            v-touch:longtap="changeMuslimFilterValue"
            @change="changeMuslimFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.muslim = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.muslim}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="judaismFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive11 }"
            v-touch:start="changeJudaismFilterValue"
            v-touch:longtap="changeJudaismFilterValue"
            @change="changeJudaismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.jewish = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.jewish}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="buddhistFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive12 }"
            v-touch:start="changeBuddhismFilterValue"
            v-touch:longtap="changeBuddhismFilterValue"
            @change="changeBuddhismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.buddhist = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.buddhist}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="hinduFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive13 }"
            v-touch:start="changeHinduismFilterValue"
            v-touch:longtap="changeHinduismFilterValue"
            @change="changeHinduismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.hindu = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.hindu}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="otherReligionFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive14 }"
            v-touch:start="changeOtherReligionFilterValue"
            v-touch:longtap="changeOtherReligionFilterValue"
            @change="changeOtherReligionFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.other = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.other}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="agnosticFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive15 }"
            v-touch:start="changeAgnosticismFilterValue"
            v-touch:longtap="changeAgnosticismFilterValue"
            @change="changeAgnosticismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.agnostic = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.agnostic}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="atheistFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive16 }"
            v-touch:start="changeAtheismFilterValue"
            v-touch:longtap="changeAtheismFilterValue"
            @change="changeAtheismFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.atheist = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.atheist}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper">
          <input
            v-model="anyReligionFilterSlider"
            class="switchInput"
            v-bind:class="{ active: isActive17 }"
            v-touch:start="changeAnyReligionFilterValue"
            v-touch:longtap="changeAnyReligionFilterValue"
            @change="changeAnyReligionFilterValue()"
            type="range"
            min="0"
            max="1"
            value="1"
            step="1"
          />
          <p
            class="switchName"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.IDontMind = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.religions.IDontMind}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="badFormatError" v-if="religionEmpty">Bitte wählen Sie min. 1 Antwort aus</div>
        <div class="ethnicityImportanceWrapper filterWrapper">
          <div class="ethnicityTextWrapper textWrapper">
            <h3
              class="ethnicityText"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.partnerRiligionImportanceQuestion = $event.target.innerText"
            >{{$t($i18n.locale).keys.matchesPreferences.ethnicityAndReligion.partnerRiligionImportanceQuestion}}</h3>
            <img
              v-if="editable"
              :style="{marginLeft : '20px'}"
              src="../../../assets/images/Translate-Icon.svg"
              alt="Translation icon"
            />
          </div>
          <div class="sliderWrapper steps">
            <vue-slider
              id="religionImportanceFilter"
              v-model="religionImportanceValue"
              :min="1"
              :max="7"
              :tooltip="'none'"
              :tooltip-placement="'bottom'"
              :enable-cross="false"
              :marks="religionImportanceMarks"
              :adsorb="true"
              :included="true"
            ></vue-slider>
          </div>
        </div>
      </div>
      <div class="buttonWrapper">
        <button
          @click="sendEthnicityAndReligionValues()"
          class="btnSave"
          :disabled = "(religionEmpty || ethnicityEmpty) ? true : false"
        >{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          :style="{marginLeft : '20px'}"
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EthnicityAndReligion",

  data() {
    return {
      componentKey: 0,
      whiteOrCaucasianFilterSlider: 0,
      isActive1: false,
      nativeAmericanFilterSlider: 0,
      isActive2: false,
      asianFilterSlider: 0,
      isActive3: false,
      havaiianFilterSlider: 0,
      isActive4: false,
      africanAmericanFilterSlider: 0,
      isActive5: false,
      latinoFilterSlider: 0,
      isActive6: false,
      otherEthnicityFilterSlider: 0,
      isActive7: false,
      anyEthnicityFilterSlider: 0,
      isActive8: false,
      ethnicityImportanceValue: 3,
      ethnicityImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .notImportant,

        },
        "2": {
          label: "",

        },
        "3": {
          label: "",

        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .somewhatImportant,

        },
        "5": {
          label: "",

        },
        "6": {
          label: "",

        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .veryImportant,

        }
      },
      christianFilterSlider: 0,
      isActive9: false,
      catholicFilterSlider: 0,
      isActive10: false,
      judaismFilterSlider: 0,
      isActive11: false,
      buddhistFilterSlider: 0,
      isActive12: false,
      hinduFilterSlider: 0,
      isActive13: false,
      otherReligionFilterSlider: 0,
      isActive14: false,
      agnosticFilterSlider: 0,
      isActive15: false,
      atheistFilterSlider: 0,
      isActive16: false,
      anyReligionFilterSlider: 0,
      isActive17: false,
      muslimFilterSlider: 0,
      isActive18: false,
      religionImportanceValue: 3,
      ethnicityAndReligionFilters: {},
      religionImportanceMarks: {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .notImportant,

        },
        "2": {
          label: "",

        },
        "3": {
          label: "",

        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .somewhatImportant,

        },
        "5": {
          label: "",

        },
        "6": {
          label: "",

        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels
            .veryImportant,

        }
      }
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getEthnicityAndReligion"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    ethnicityEmpty: function() {
      if(
        this.isActive1 == 0 &&
        this.isActive2 == 0 &&
        this.isActive3 == 0 &&
        this.isActive4 == 0 &&
        this.isActive5 == 0 &&
        this.isActive6 == 0 &&
        this.isActive7 == 0 &&
        this.isActive8 == 0
      ){
        return true;
      }else{
        return false;
      }
    },
    religionEmpty: function(){
      if(
        this.isActive9 == 0 &&
        this.isActive10 == 0 &&
        this.isActive11 == 0 &&
        this.isActive12 == 0 &&
        this.isActive13 == 0 &&
        this.isActive14 == 0 &&
        this.isActive15 == 0 &&
        this.isActive16 == 0 &&
        this.isActive17 == 0 &&
        this.isActive18 == 0
      ){
        return true;
      }else{
        return false;
      }
    }
  },
  watch:{
    getEthnicityAndReligion: function(newVal){
      if(!_.isEmpty(newVal))  this.setEthnicityAndReligionFilters();
    }
  },
  methods: {
    setEthnicityAndReligionFilters() {

          this.ethnicityImportanceValue = this.$store.getters.getEthnicityAndReligion.importanceEthnic;
          this.religionImportanceValue = this.$store.getters.getEthnicityAndReligion.importanceReligion;
          //this.$store.getters.getEthnicityAndReligion.partnerEthnic.forEach(
            Object.keys(this.$store.getters.getEthnicityAndReligion.partnerEthnic).forEach(
            key => {
              const ethnicity = this.$store.getters.getEthnicityAndReligion.partnerEthnic[key];
              switch (ethnicity.id) {
                case 1:
                  this.whiteOrCaucasianFilterSlider = 1;
                  this.isActive1 = true;
                  break;
                case 2:
                  this.nativeAmericanFilterSlider = 1;
                  this.isActive2 = true;
                  break;
                case 3:
                  this.asianFilterSlider = 1;
                  this.isActive3 = true;
                  break;
                case 4:
                  this.havaiianFilterSlider = 1;
                  this.isActive4 = true;
                  break;
                case 5:
                  this.africanAmericanFilterSlider = 1;
                  this.isActive5 = true;
                  break;
                case 6:
                  this.latinoFilterSlider = 1;
                  this.isActive6 = true;
                  break;
                case 7:
                  this.otherEthnicityFilterSlider = 1;
                  this.isActive7 = true;
                  break;
                case 8:
                  this.changeAnyEthinicityFilterValue();
                  //this.anyEthnicityFilterSlider = 1;
                  //this.isActive8 = true;

                  break;
              }
            }
          );
          Object.keys(this.$store.getters.getEthnicityAndReligion.partnerReligion).forEach(
          //this.$store.getters.getEthnicityAndReligion.partnerReligion.forEach(
            key => {
              const religion = this.$store.getters.getEthnicityAndReligion.partnerReligion[key];
              switch (religion.id) {
                case 1:
                  this.christianFilterSlider = 1;
                  this.isActive9 = true;
                  break;
                case 2:
                  this.catholicFilterSlider = 1;
                  this.isActive10 = true;
                  break;
                case 3:
                  this.muslimFilterSlider = 1;
                  this.isActive18 = true;
                  break;
                case 4:
                  this.judaismFilterSlider = 1;
                  this.isActive11 = true;
                  break;
                case 5:
                  this.buddhistFilterSlider = 1;
                  this.isActive12 = true;
                  break;
                case 6:
                  this.hinduFilterSlider = 1;
                  this.isActive13 = true;
                  break;
                case 7:
                  this.otherReligionFilterSlider = 1;
                  this.isActive14 = true;
                  break;
                case 8:
                  this.agnosticFilterSlider = 1;
                  this.isActive15 = true;
                  break;
                case 9:
                  this.atheistFilterSlider = 1;
                  this.isActive16 = true;
                  break;
                case 10:
                  this.changeAnyReligionFilterValue();
                  //this.anyReligionFilterSlider = 1;
                  //this.isActive17 = true;
                  break;
              }
            }
          );
          this.componentKey++;

    },
    sendEthnicityAndReligionValues() {
      if(this.religionEmpty || this.ethnicityEmpty) return false;
      let partnerEthnicValues = [];
      if (this.whiteOrCaucasianFilterSlider == 1) {
        partnerEthnicValues.push(1);
      }
      if (this.nativeAmericanFilterSlider == 1) {
        partnerEthnicValues.push(2);
      }
      if (this.asianFilterSlider == 1) {
        partnerEthnicValues.push(3);
      }
      if (this.havaiianFilterSlider == 1) {
        partnerEthnicValues.push(4);
      }
      if (this.africanAmericanFilterSlider == 1) {
        partnerEthnicValues.push(5);
      }
      if (this.latinoFilterSlider == 1) {
        partnerEthnicValues.push(6);
      }
      if (this.otherEthnicityFilterSlider == 1) {
        partnerEthnicValues.push(7);
      }
      if (this.anyEthnicityFilterSlider == 1) {
        partnerEthnicValues.push(8);
      }

      let partnerReligionValues = [];
      if (this.christianFilterSlider == 1) {
        partnerReligionValues.push(1);
      }
      if (this.catholicFilterSlider == 1) {
        partnerReligionValues.push(2);
      }
      if (this.muslimFilterSlider == 1) {
        partnerReligionValues.push(3);
      }
      if (this.judaismFilterSlider == 1) {
        partnerReligionValues.push(4);
      }
      if (this.buddhistFilterSlider == 1) {
        partnerReligionValues.push(5);
      }
      if (this.hinduFilterSlider == 1) {
        partnerReligionValues.push(6);
      }
      if (this.otherReligionFilterSlider == 1) {
        partnerReligionValues.push(7);
      }
      if (this.agnosticFilterSlider == 1) {
        partnerReligionValues.push(8);
      }
      if (this.atheistFilterSlider == 1) {
        partnerReligionValues.push(9);
      }
      if (this.anyReligionFilterSlider == 1) {
        partnerReligionValues.push(10);
      }
      this.ethnicityAndReligionFilters = {
        importanceEthnic: parseInt(this.ethnicityImportanceValue),
        importanceReligion: parseInt(this.religionImportanceValue),
        partnerEthnic: partnerEthnicValues,
        partnerReligion: partnerReligionValues
      };

      this.$store
        .dispatch(
          "updateEthnicityAndReligion",
          this.ethnicityAndReligionFilters
        )
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            });
            this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Ethnicity & Religion'
              });
            this.componentKey++;
          }
        });
    },
    changeWhiteOrCaucasianFilterValue() {
      if (this.whiteOrCaucasianFilterSlider == 0) {
        this.whiteOrCaucasianFilterSlider = 1;
      } else if (this.whiteOrCaucasianFilterSlider == 1) {
        this.whiteOrCaucasianFilterSlider = 0;
      }
      if (this.whiteOrCaucasianFilterSlider == 0) {
        this.isActive1 = false;
      } else if (this.whiteOrCaucasianFilterSlider == 1) {
        this.isActive1 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // whiteOrCaucasianColouring() {
    //   if (this.whiteOrCaucasianFilterSlider == 0) {
    //     this.isActive1 = false;
    //   } else if (this.whiteOrCaucasianFilterSlider == 1) {
    //     this.isActive1 = true;
    //   }
    // },
    changeNativeAmericanFilterValue() {
      if (this.nativeAmericanFilterSlider == 0) {
        this.nativeAmericanFilterSlider = 1;
      } else if (this.nativeAmericanFilterSlider == 1) {
        this.nativeAmericanFilterSlider = 0;
      }
      if (this.nativeAmericanFilterSlider == 0) {
        this.isActive2 = false;
      } else if (this.nativeAmericanFilterSlider == 1) {
        this.isActive2 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // nativeAmericanColouring() {
    //   if (this.nativeAmericanFilterSlider == 0) {
    //     this.isActive2 = false;
    //   } else if (this.nativeAmericanFilterSlider == 1) {
    //     this.isActive2 = true;
    //   }
    // },
    changeAsianFilterValue() {
      if (this.asianFilterSlider == 0) {
        this.asianFilterSlider = 1;
      } else if (this.asianFilterSlider == 1) {
        this.asianFilterSlider = 0;
      }
      if (this.asianFilterSlider == 0) {
        this.isActive3 = false;
      } else if (this.asianFilterSlider == 1) {
        this.isActive3 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // asianColouring() {
    //   if (this.asianFilterSlider == 0) {
    //     this.isActive3 = false;
    //   } else if (this.asianFilterSlider == 1) {
    //     this.isActive3 = true;
    //   }
    // },
    changeHavaiianFilterValue() {
      if (this.havaiianFilterSlider == 0) {
        this.havaiianFilterSlider = 1;
      } else if (this.havaiianFilterSlider == 1) {
        this.havaiianFilterSlider = 0;
      }
      if (this.havaiianFilterSlider == 0) {
        this.isActive4 = false;
      } else if (this.havaiianFilterSlider == 1) {
        this.isActive4 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // havaiianColouring() {
    //   if (this.havaiianFilterSlider == 0) {
    //     this.isActive4 = false;
    //   } else if (this.havaiianFilterSlider == 1) {
    //     this.isActive4 = true;
    //   }
    // },
    changeAfricanAmericanFilterValue() {
      if (this.africanAmericanFilterSlider == 0) {
        this.africanAmericanFilterSlider = 1;
      } else if (this.africanAmericanFilterSlider == 1) {
        this.africanAmericanFilterSlider = 0;
      }
      if (this.africanAmericanFilterSlider == 0) {
        this.isActive5 = false;
      } else if (this.africanAmericanFilterSlider == 1) {
        this.isActive5 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // africanAmericanColouring() {
    //   if (this.africanAmericanFilterSlider == 0) {
    //     this.isActive5 = false;
    //   } else if (this.africanAmericanFilterSlider == 1) {
    //     this.isActive5 = true;
    //   }
    // },
    changeLatinoFilterValue() {
      if (this.latinoFilterSlider == 0) {
        this.latinoFilterSlider = 1;
      } else if (this.latinoFilterSlider == 1) {
        this.latinoFilterSlider = 0;
      }
      if (this.latinoFilterSlider == 0) {
        this.isActive6 = false;
      } else if (this.latinoFilterSlider == 1) {
        this.isActive6 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // latinoColouring() {
    //   if (this.latinoFilterSlider == 0) {
    //     this.isActive6 = false;
    //   } else if (this.latinoFilterSlider == 1) {
    //     this.isActive6 = true;
    //   }
    // },
    changeOtherEthnicityFilterValue() {
      if (this.otherEthnicityFilterSlider == 0) {
        this.otherEthnicityFilterSlider = 1;
      } else if (this.otherEthnicityFilterSlider == 1) {
        this.otherEthnicityFilterSlider = 0;
      }
      if (this.otherEthnicityFilterSlider == 0) {
        this.isActive7 = false;
      } else if (this.otherEthnicityFilterSlider == 1) {
        this.isActive7 = true;
      }
      if (
        this.whiteOrCaucasianFilterSlider == 0 ||
        this.asianFilterSlider == 0 ||
        this.nativeAmericanFilterSlider == 0 ||
        this.havaiianFilterSlider == 0 ||
        this.africanAmericanFilterSlider == 0 ||
        this.latinoFilterSlider == 0 ||
        this.otherEthnicityFilterSlider == 0
      ) {
        this.anyEthnicityFilterSlider = 0;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
      if (
        this.whiteOrCaucasianFilterSlider == 1 &&
        this.nativeAmericanFilterSlider == 1 &&
        this.asianFilterSlider == 1 &&
        this.havaiianFilterSlider == 1 &&
        this.africanAmericanFilterSlider == 1 &&
        this.latinoFilterSlider == 1 &&
        this.otherEthnicityFilterSlider == 1
      ) {
        this.anyEthnicityFilterSlider = 1;
        if (this.anyEthnicityFilterSlider == 0) {
          this.isActive8 = false;
        } else if (this.anyEthnicityFilterSlider == 1) {
          this.isActive8 = true;
        }
      }
    },
    // otherEthnicityColouring() {
    //   if (this.otherEthnicityFilterSlider == 0) {
    //     this.isActive7 = false;
    //   } else if (this.otherEthnicityFilterSlider == 1) {
    //     this.isActive7 = true;
    //   }
    // },
    changeAnyEthinicityFilterValue() {
      if (this.anyEthnicityFilterSlider == 0) {
        this.anyEthnicityFilterSlider = 1;
      } else if (this.anyEthnicityFilterSlider == 1) {
        this.anyEthnicityFilterSlider = 0;
      }
      if (this.anyEthnicityFilterSlider == 0) {
        this.isActive8 = false;
      } else if (this.anyEthnicityFilterSlider == 1) {
        this.isActive8 = true;
      }
      if (this.anyEthnicityFilterSlider == 1) {
        this.whiteOrCaucasianFilterSlider = 1;
        this.isActive1 = 1;
        this.nativeAmericanFilterSlider = 1;
        this.isActive2 = 1;
        this.asianFilterSlider = 1;
        this.isActive3 = 1;
        this.havaiianFilterSlider = 1;
        this.isActive4 = 1;
        this.africanAmericanFilterSlider = 1;
        this.isActive5 = 1;
        this.latinoFilterSlider = 1;
        this.isActive6 = 1;
        this.otherEthnicityFilterSlider = 1;
        this.isActive7 = 1;
      } else if (this.anyEthnicityFilterSlider == 0) {
        this.isActive1 = 0;
        this.whiteOrCaucasianFilterSlider = 0;
        this.isActive2 = 0;
        this.asianFilterSlider = 0;
        this.isActive3 = 0;
        this.nativeAmericanFilterSlider = 0;
        this.isActive4 = 0;
        this.havaiianFilterSlider = 0;
        this.isActive5 = 0;
        this.africanAmericanFilterSlider = 0;
        this.isActive6 = 0;
        this.latinoFilterSlider = 0;
        this.isActive7 = 0;
        this.otherEthnicityFilterSlider = 0;
        this.isActive8 = 0;
      }

    },
    // anyEthnicityColouring() {
    //   if (this.anyEthnicityFilterSlider == 0) {
    //     this.isActive8 = false;
    //   } else if (this.anyEthnicityFilterSlider == 1) {
    //     this.isActive8 = true;
    //   }
    // },
    changeChristianityFilterValue() {
      if (this.christianFilterSlider == 0) {
        this.christianFilterSlider = 1;
      } else if (this.christianFilterSlider == 1) {
        this.christianFilterSlider = 0;
      }
      if (this.christianFilterSlider == 0) {
        this.isActive9 = false;
      } else if (this.christianFilterSlider == 1) {
        this.isActive9 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1 &&
        this.anyReligionFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // christianityColouring() {
    //   if (this.christianFilterSlider == 0) {
    //     this.isActive9 = false;
    //   } else if (this.christianFilterSlider == 1) {
    //     this.isActive9 = true;
    //   }
    // },
    changeCatholicismFilterValue() {
      if (this.catholicFilterSlider == 0) {
        this.catholicFilterSlider = 1;
      } else if (this.catholicFilterSlider == 1) {
        this.catholicFilterSlider = 0;
      }
      if (this.catholicFilterSlider == 0) {
        this.isActive10 = false;
      } else if (this.catholicFilterSlider == 1) {
        this.isActive10 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    changeMuslimFilterValue() {
      if (this.muslimFilterSlider == 0) {
        this.muslimFilterSlider = 1;
      } else if (this.muslimFilterSlider == 1) {
        this.muslimFilterSlider = 0;
      }
      if (this.muslimFilterSlider == 0) {
        this.isActive18 = false;
      } else if (this.muslimFilterSlider == 1) {
        this.isActive18 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // catholicismColouring() {
    //   if (this.catholicFilterSlider == 0) {
    //     this.isActive10 = false;
    //   } else if (this.catholicFilterSlider == 1) {
    //     this.isActive10 = true;
    //   }
    // },
    changeJudaismFilterValue() {
      if (this.judaismFilterSlider == 0) {
        this.judaismFilterSlider = 1;
      } else if (this.judaismFilterSlider == 1) {
        this.judaismFilterSlider = 0;
      }
      if (this.judaismFilterSlider == 0) {
        this.isActive11 = false;
      } else if (this.judaismFilterSlider == 1) {
        this.isActive11 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // judaismColouring() {
    //   if (this.judaismFilterSlider == 0) {
    //     this.isActive11 = false;
    //   } else if (this.judaismFilterSlider == 1) {
    //     this.isActive11 = true;
    //   }
    // },
    changeBuddhismFilterValue() {
      if (this.buddhistFilterSlider == 0) {
        this.buddhistFilterSlider = 1;
      } else if (this.buddhistFilterSlider == 1) {
        this.buddhistFilterSlider = 0;
      }
      if (this.buddhistFilterSlider == 0) {
        this.isActive12 = false;
      } else if (this.buddhistFilterSlider == 1) {
        this.isActive12 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // buddhismColouring() {
    //   if (this.buddhistFilterSlider == 0) {
    //     this.isActive12 = false;
    //   } else if (this.buddhistFilterSlider == 1) {
    //     this.isActive12 = true;
    //   }
    // },
    changeHinduismFilterValue() {
      if (this.hinduFilterSlider == 0) {
        this.hinduFilterSlider = 1;
      } else if (this.hinduFilterSlider == 1) {
        this.hinduFilterSlider = 0;
      }
      if (this.hinduFilterSlider == 0) {
        this.isActive13 = false;
      } else if (this.hinduFilterSlider == 1) {
        this.isActive13 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // hinduismColouring() {
    //   if (this.hinduFilterSlider == 0) {
    //     this.isActive13 = false;
    //   } else if (this.hinduFilterSlider == 1) {
    //     this.isActive13 = true;
    //   }
    // },
    changeOtherReligionFilterValue() {
      if (this.otherReligionFilterSlider == 0) {
        this.otherReligionFilterSlider = 1;
      } else if (this.otherReligionFilterSlider == 1) {
        this.otherReligionFilterSlider = 0;
      }
      if (this.otherReligionFilterSlider == 0) {
        this.isActive14 = false;
      } else if (this.otherReligionFilterSlider == 1) {
        this.isActive14 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // otherReligionColouring() {
    //   if (this.otherReligionFilterSlider == 0) {
    //     this.isActive14 = false;
    //   } else if (this.otherReligionFilterSlider == 1) {
    //     this.isActive14 = true;
    //   }
    // },
    changeAgnosticismFilterValue() {
      if (this.agnosticFilterSlider == 0) {
        this.agnosticFilterSlider = 1;
      } else if (this.agnosticFilterSlider == 1) {
        this.agnosticFilterSlider = 0;
      }
      if (this.agnosticFilterSlider == 0) {
        this.isActive15 = false;
      } else if (this.agnosticFilterSlider == 1) {
        this.isActive15 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // agnosticismColouring() {
    //   if (this.agnosticFilterSlider == 0) {
    //     this.isActive15 = false;
    //   } else if (this.agnosticFilterSlider == 1) {
    //     this.isActive15 = true;
    //   }
    // },
    changeAtheismFilterValue() {
      if (this.atheistFilterSlider == 0) {
        this.atheistFilterSlider = 1;
      } else if (this.atheistFilterSlider == 1) {
        this.atheistFilterSlider = 0;
      }
      if (this.atheistFilterSlider == 0) {
        this.isActive16 = false;
      } else if (this.atheistFilterSlider == 1) {
        this.isActive16 = true;
      }
      if (
        this.christianFilterSlider == 0 ||
        this.catholicFilterSlider == 0 ||
        this.muslimFilterSlider == 0 ||
        this.judaismFilterSlider == 0 ||
        this.buddhistFilterSlider == 0 ||
        this.hinduFilterSlider == 0 ||
        this.otherReligionFilterSlider == 0 ||
        this.agnosticFilterSlider == 0 ||
        this.atheistFilterSlider == 0
      ) {
        this.anyReligionFilterSlider = 0;
        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
      if (
        this.christianFilterSlider == 1 &&
        this.catholicFilterSlider == 1 &&
        this.muslimFilterSlider == 1 &&
        this.judaismFilterSlider == 1 &&
        this.buddhistFilterSlider == 1 &&
        this.hinduFilterSlider == 1 &&
        this.otherReligionFilterSlider == 1 &&
        this.agnosticFilterSlider == 1 &&
        this.atheistFilterSlider == 1
      ) {
        this.anyReligionFilterSlider = 1;

        if (this.anyReligionFilterSlider == 0) {
          this.isActive17 = false;
        } else if (this.anyReligionFilterSlider == 1) {
          this.isActive17 = true;
        }
      }
    },
    // atheismColouring() {
    //   if (this.atheistFilterSlider == 0) {
    //     this.isActive16 = false;
    //   } else if (this.atheistFilterSlider == 1) {
    //     this.isActive16 = true;
    //   }
    // },
    changeAnyReligionFilterValue() {
      if (this.anyReligionFilterSlider == 0) {
        this.anyReligionFilterSlider = 1;
        this.isActive9 = 1;
        this.christianFilterSlider = 1;
        this.isActive10 = 1;
        this.catholicFilterSlider = 1;
        this.isActive11 = 1;
        this.muslimFilterSlider = 1;
        this.isActive18 = 1;
        this.judaismFilterSlider = 1;
        this.isActive12 = 1;
        this.buddhistFilterSlider = 1;
        this.isActive13 = 1;
        this.hinduFilterSlider = 1;
        this.isActive14 = 1;
        this.otherReligionFilterSlider = 1;
        this.isActive15 = 1;
        this.agnosticFilterSlider = 1;
        this.isActive16 = 1;
        this.atheistFilterSlider = 1;
      } else if (this.anyReligionFilterSlider == 1) {
        this.isActive9 = 0;
        this.anyReligionFilterSlider = 0;
        this.isActive10 = 0;
        this.christianFilterSlider = 0;
        this.isActive11 = 0;
        this.catholicFilterSlider = 0;
        this.isActive12 = 0;
        this.muslimFilterSlider = 0;
        this.isActive13 = 0;
        this.judaismFilterSlider = 0;
        this.isActive14 = 0;
        this.buddhistFilterSlider = 0;
        this.isActive15 = 0;
        this.hinduFilterSlider = 0;
        this.isActive16 = 0;
        this.otherReligionFilterSlider = 0;
        this.isActive18 = 0;
        this.agnosticFilterSlider = 0;
        this.atheistFilterSlider = 0;
      }
      if (this.anyReligionFilterSlider == 0) {
        this.isActive17 = false;
      } else if (this.anyReligionFilterSlider == 1) {
        this.isActive17 = true;
      }
    },
    // anyReligionColouring() {
    //   if (this.anyReligionFilterSlider == 0) {
    //     this.isActive17 = false;
    //   } else if (this.anyReligionFilterSlider == 1) {
    //     this.isActive17 = true;
    //   }
    // },
    
  }
};
</script>
