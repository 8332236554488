export default {
    galleryView: state => state.galleryView,
    listView: state => state.listView,
    allMatches: state => state.matches,
    allMatchesCount: state => state.matches.length,
    allFilteredMatches: state => state.filteredMatches,
    allAlternativeMatches: state => state.alternativeMatches,
    filteredMatchesCount: state => state.filteredMatches.length,
    matchesQuantity: state => state.matchesQuantity,
    searchValue: state => state.matchesSearchValue,
    getBookmarks: state => state.bookmarks,
    getDefaultFilters: state => state.defaultFilters,
    getMatchesList: state => state.matchesList,
    getMatchesActionsCount: state => state.matchesActionsCount,
    getActiveMatchesFilters: state => state.activeMatchesFilters,
}