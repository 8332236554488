  <template lang="html">
    <div class="profileBlurBanner" >
      <img class="bgImage" :src="require('@/assets/images/profileBannerBg.jpg')" />
      <div class="content">
        <p>{{$t($i18n.locale+'.keys.profileBlurBanner.infoText')  }}</p>
        <div class="btnWrapper">
          <button class="btnPremium" @click="sendToPremium">
            <span>
              {{ $t($i18n.locale+'.keys.profileBlurBanner.premiumButton') }}
            </span>
          </button>
        </div> 
      </div> 

    </div>
  </template>
  
  <script>
  export default {
    name: "ProfileBlurBanner",
    props: {
     
    },
  
    data () { 
      return {
       
      }
    },
  
  computed: {
   
  },
    methods: {
      sendToPremium(){
      this.$router.push(`/premium-plans`);
      },
      
    },
    
    mounted () {
      
    }
  }
  </script>
  <style lang="scss" scoped>
  </style>