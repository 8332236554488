<template>
  <div id="distance" :key="componentKey" class="preferencesBox">
    <div class="distanceWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.distance.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesPreferences.distance.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="addressWrapper filterWrapper">
        <div class="addressTextWrapper textWrapper">
          <h3
            class="addressText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.distance.address = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.distance.address}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="addressInfoWrapper inputWrapper">
            <vue-google-autocomplete
              id="userHomeCity"
              class="genericInput"
              ref="userHomeCity"
              :placeholder="$t($i18n.locale).keys.matchesPreferences.distance.enterCityName"
              v-on:placechanged="getAddressData"
              :country="country"
              types="(cities)"
            ></vue-google-autocomplete>
        </div>
      </div>
      <div class="distanceToTravelFilterWrapper filterWrapper">
        <div class="distanceTextWrapper textWrapper">
          <h3
            class="distanceText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.distance.distanceToTravelQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.distance.distanceToTravelQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="distanceFilter"
            v-model="distance"
            :min="sliderMin"
            :max="sliderMax"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :marks="distanceMarks2"
            :adsorb="true"
            :included="true"
            :tooltip-formatter="formatter"
          ></vue-slider>
        </div>
      </div>
      <div class="distanceImportanceWrapper filterWrapper">
        <div class="distanceTextWrapper textWrapper">
          <h3
            class="distanceText"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.distance.distanceBetweenPotentialPartnerQuestion = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.distance.distanceBetweenPotentialPartnerQuestion}}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            src="../../../assets/images/Translate-Icon.svg"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="distanceImportanceFilter"
            v-model="distanceImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="distanceImportanceMarks"
            :adsorb="true"
            :included="true"
          ></vue-slider>
        </div>
      </div>
      <div class="hintMessageWrapper">
        <h3 class="hintMessage">
          <span
            class="hint"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.distance.hint = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.distance.hint}}</span>
          <span
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.matchesPreferences.distance.hintMessage = $event.target.innerText"
          >{{$t($i18n.locale).keys.matchesPreferences.distance.hintMessage}}</span>
        </h3>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="../../../assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="buttonWrapper">
        <button @click="sendDistanceValues()" class="btnSave">{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          :style="{marginLeft : '20px'}"
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "Distance",
  components: { VueGoogleAutocomplete },
  data() {
    return {
      componentKey: 0,
      address: {},
      answers: {},
      distance: 500,
      sliderMin:50,
      sliderMax:600,
      lat: 0,
      lng: 0,
      autocompleteMoved: false,
      iDontMind: this.$t(this.$i18n.locale).keys.matchesPreferences.distance.iDontMind,
      country:'',
      formatter: v => {
        var unit = "km";
        if(!this.metricUnits){
          unit = "mi";
        }
        if(v == this.sliderMax){
          $('.distanceToTravelFilterWrapper .vue-slider-dot-tooltip').css({
            left:0,
            transform: 'translate(-65%,100%)'
          });
          return this.iDontMind;
        }else{
          $('.distanceToTravelFilterWrapper .vue-slider-dot-tooltip').css({
            left:'50%',
            transform: 'translate(-50%,100%)'
          });
          return v + " " + unit;
        }

        },
     distanceMarksKM: {
        "50": {},
        "100":{},
        "200":{},
        "500":{},
        "600": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
      distanceMarksMiles: {
        "30": {},
        "60": {},
        "120": {},
        "300": {
          label: (
              <span>300</span>
          ),
        },
        "350": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
       distanceMarksAU: {
        "100": {},
        "200":{},
        "500":{},
        "1000":{},
        "1500": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
      distanceMarks2:{},
      distanceImportanceValue: 3,
      distanceToSend: {},
      distanceImportanceMarks: {
        "1": {
          label: (
            <span>
              {
                this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant
              }
            </span>
            )

        },
        "2": {
          label: ""

        },
        "3": {
          label: ""

        },
        "4": {
          label: (
              <span>
                {
                  this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant
                }
              </span>
            )

        },
        "5": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)"
          },
          labelStyle: {
            color: "#000000"
          }
        },
        "6": {
          label: ""

        },
        "7": {
          label: (
            <span>
              {
                this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant
              }
            </span>
          )

        }
      }
    };
  },

  created() {

    if(!this.metricUnits){
      //set slider to miles
      this.distanceMarks2 = this.distanceMarksMiles;
      this.distance= 120;
      this.distanceAnswer= 120;
      this.sliderMin=30;
      this.sliderMax=350;
    }else if(this.$store.getters.getCountry == 'au'){
      this.distanceMarks2 = this.distanceMarksAU;
      this.distance= 200;
      this.distanceAnswer= 200;
      this.sliderMin=100;
      this.sliderMax=1500;
    }else{
      this.distanceMarks2 = this.distanceMarksKM;
    }

    this.country = [this.$store.getters.getCountry];
     this.$store.dispatch('setAdditionalTranslations').then(() => {
       this.iDontMind = this.$store.getters.getAdditionalTranslations.filters[0].i_dont_mind;
      this.componentKey++;
     })
  },
  computed: {
    ...mapGetters(["getDistance", "getCountry"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      return (this.getCountry != 'uk');
    }
  },
  watch:{
    getDistance: function(newVal){
      if(!_.isEmpty(newVal)){
        this.setDistanceFilters();
      }
    }
  },
  methods: {
    getAddressData: function(addressData, /*place*/) {
      //console.log('addressData', addressData);
      this.address = addressData;
      if(!this.address.locality){
        //console.log('NO CITY', this.$refs.userHomeCity.autocompleteText);
        this.$refs.userHomeCity.clear();
        document.getElementById("userHomeCity").value =
            this.answers.city +
            " , " +
            this.answers.country;
        return false;
      }
      this.answers = {
        country: this.address.country,
        city: this.address.locality,
        lat: this.address.latitude,
        lng: this.address.longitude,
        zip: (typeof this.address.postal_code != 'undefined') ? this.address.postal_code : "0"
      };
      this.autocompleteMoved = true;
      this.lat = this.answers.lat;
      this.lng = this.answers.lng;
    },
    setDistanceFilters() {

      if (this.getDistance.maxDistance == 5000) {
        this.distance = this.sliderMax;
      } else {
        this.distance = this.getDistance.maxDistance;
      }
      this.distanceImportanceValue = this.getDistance.importanceDistance;

      this.answers = {
        country: this.getDistance.country,
        city: this.getDistance.city,
        lat: this.getDistance.lat,
        lng: this.getDistance.lng,
        zip: (typeof this.getDistance.zip != 'undefined') ? this.getDistance.zip : "0"
      };

      setTimeout(() => {
        this.$refs.userHomeCity.$el.value = this.getDistance.city + ", " + this.getDistance.country;
        if (typeof this.getDistance.zip == 'undefined') {
          this.$refs.userHomeCity.updateCoordinates({lat: parseFloat(this.getDistance.lat), lng: parseFloat(this.getDistance.lng)})
        }
      }, 200);

      this.componentKey++;

    },

    sendDistanceValues() {
      let maxDistance;
      if (this.distance == 600) {
        maxDistance = 5000;
        localStorage.distanceFilterValue = 600;
        localStorage.distanceFilterValueVisitors = 600;
      } else {
        maxDistance = this.distance;
        localStorage.distanceFilterValue = this.distance;
        localStorage.distanceFilterValueVisitors = this.distance;
      }
      //let arr = document.getElementById("userHomeCity").value;
      //arr.split(",");
      //let city = arr.split(",")[0];
      //let country = arr.split(",")[1];
      //if (this.autocompleteMoved == false) {
      //  this.lat = parseFloat(this.$store.getters.getDistance.lat);
      //  this.lng = parseFloat(this.$store.getters.getDistance.lng);
      //}
      this.distanceToSend = {
        city: this.answers.city,
        country: this.answers.country,
        importanceDistance: parseInt(this.distanceImportanceValue),
        maxDistance: parseInt(maxDistance),
        lat: parseFloat(this.answers.lat),
        lng: parseFloat(this.answers.lng),
        zip: this.answers.zip,
      };
      this.$store
        .dispatch("updateDistance", this.distanceToSend)
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            });
            this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Distance'
              });
            this.componentKey++;
          }
        })
        .then(() => {
          document.getElementById("userHomeCity").value =
            this.$store.getters.getDistance.city +
            " , " +
            this.$store.getters.getDistance.country;
        });
    },
   
  }
};
</script>

<style>
.pac-container {
  z-index: 9999 !important;
}
</style>
