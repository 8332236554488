<template>
  <div
    id="membershipMisc"
    :class="{admin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editingMode: $store.getters.editable, premium: $store.getters.userRole.includes('ROLE_PREMIUM')}"
  >
    <div class="profilePhotoWrapper" :style="{ backgroundImage: mainBackground }">
      <div class="container-lg photoContainer">
        <img
          class="photoEditButton"
          @click="$bvModal.show('bv-modal-backgrounds')"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
          alt="Edit button"
        />
        <div class="profileWrapper" :key="componentProfileCardKey">
          <div class="photoWrapper">
            <div
              v-if="profilePicture"
              class="profilePhoto"
              v-bind:style="{ backgroundImage: 'url(' + profilePicture + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }"
            ></div>
            <img
              class="showBackgroundIcon"
              @click="$bvModal.show('bv-modal-backgrounds')"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'background-icon.svg')"
              alt="Mobile background icon"
            />
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 1)"
              class="profilePhoto"
              :style="{backgroundImage: 'url('+require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')}"
            ></div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 2)"
              class="profilePhoto"
              :style="{backgroundImage: 'url('+require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')}"
            ></div>
            <router-link to="/membership-area/user-profile/gallery">
              <img
                @click="openProfileGallery(1)"
                class="editButton"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
            </router-link>
          </div>
          <div class="userInfoWrapper">
            <div class="infoWrapper">
              <div class="nameAgeTextWrapper">
                <!--
                <img
                  v-if="profileEntertainment == 1"
                  src="../../assets/images/heart-green.svg"
                  alt="Heart icon"
                />
                -->
                <h1 v-if="profileName"
                  class="nameAgeText"
                >
                <span class="username">{{profileName}}</span>
                 <span>
                 , {{ profileAge }}
                 </span>

                </h1>
                <!-- {{ profileName.length > 9 ? profileName.substring(0, 8).concat('...') : profileName }} -->
                <!-- match.firstname.length > 15 ? match.firstname. : match.firstname -->
              </div>
              <div class="jobWrapper" :key="componentKey">
                <div class="jobInfoWrapper">
                  <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'job-icon.svg')" alt="Job icon" />
                  <p v-if="profileJob"
                    class="jobText"
                    :class="{ inReview: !jobStatus }"
                  >
                  <span class="jobDesc">{{profileJob.answer[0]}}</span>
                  <!-- {{ profileJob.answer[0].length > 12 ? profileJob.answer[0].substring(0, 10).concat('...') : profileJob.answer[0] }} -->
                  </p>

                <img
                  v-if="jobStatus"
                  @click="showEditJob = !showEditJob; setUserJob()"
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                  alt="Edit button"
                />

                <img v-if="!jobStatus" :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')" alt="Review icon" />
                </div>
                <div class="editJobWrapper" v-if="showEditJob">
                  <div class="editUserWrapper">
                    <input
                      class="editUserJobField"
                      @keyup="checkJobAnswer()"
                      v-model="profileJob.answer[0]"
                      :placeholder="$t($i18n.locale).keys.membershipArea.membershipMisc.hereMyJob"
                    />
                  </div>
                  <div class="buttonWrapper">
                    <button
                      @click="
                        showEditJob = !showEditJob;
                        restoreProfileJob();
                      "
                      class="btnCancel"
                    >
                      {{
                      $t($i18n.locale).keys.membershipArea.membershipMisc.cancel
                      }}
                    </button>
                    <input
                      v-if="editable"
                      v-model="
                        $t($i18n.locale).keys.membershipArea.membershipMisc.cancel
                      "
                      type="text"
                    />
                    <button
                      @click="
                        sendJobAnswer();
                        showEditJob = !showEditJob;
                      "
                      :disabled="emptyAnswer ||  !answerCorrect "
                      class="btnSave"
                    >
                      {{
                      $t($i18n.locale).keys.membershipArea.membershipMisc.save
                      }}
                    </button>
                    <input
                      v-if="editable"
                      v-model="
                        $t($i18n.locale).keys.membershipArea.membershipMisc.save
                      "
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="locationWrapper">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'house-icon.svg')" alt="Location icon" />
                <p
                  class="locationText"
                >{{ profileLocation }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-membership" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && !hideBanner">
      <div class="bannerWrapper"  @click="trackClick">
        <div class="bannerImage"></div>
        <img src="../../assets/images/BlueBand-banner.png" class="bannerOverlay" />
        <div class="offerText">
          <div class="textLeft">
            <h1
              class="specialOfferText1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipMisc.specialOfferText1 =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipMisc.specialOfferText1
              }}
            </h1>
            <h1 class="specialOfferText2">
              <span
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.specialOfferText2 =
                    $event.target.innerText
                "
              >
              {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .specialOfferText2
                }}
              </span>
              <span
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.specialOfferText3 =
                    $event.target.innerText
                  "
                >
                 {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .specialOfferText3
                 }}
                <!--
                {{
                $t($i18n.locale + '.keys.membershipArea.membershipMisc.specialOfferText2', {curr: currency})
                }}
                -->

              </span>

            </h1>
              <button class="bannerButton">
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .bannerButton
                }}
              </button>
              <input
                v-if="editable"
                v-model="
                        $t($i18n.locale).keys.membershipArea.membershipMisc
                          .bannerButton
                      "
                type="text"
              />
          </div>
          <div class="textRight">
            <div class="advantagesText">
              <img src="../../assets/images/check-icon.svg" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.unlimitedCommunication =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .unlimitedCommunication
                }}
              </p>
            </div>
            <div class="advantagesText">
              <img src="../../assets/images/check-icon.svg" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.viewVisitors =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .viewVisitors
                }}
              </p>
            </div>
            <div class="advantagesText">
              <img src="../../assets/images/check-icon.svg" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.accessAllPhotos =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .accessAllPhotos
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-backgrounds" hide-footer>
      <template
        v-slot:modal-title
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipMisc.backgroundImage =
            $event.target.innerText
        "
      >
        {{
        $t($i18n.locale).keys.membershipArea.membershipMisc.backgroundImage
        }}
      </template>
      <div class="text-center">
        <div
          class="backgroundChoicesWrapper"
          :class="{ active: backgroundSet1 }"
          v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
          @click="
            mainBackground = background1;
            setBackground(1);
            backgroundSet1 = !backgroundSet1;
          "
        >
          <div class="backgroundChoices" :style="{ backgroundImage: background1 }"></div>
        </div>
        <div
          class="backgroundChoicesWrapper"
          :class="{ active: backgroundSet2 }"
          v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
          @click="
            mainBackground = background2;
            setBackground(2);
            backgroundSet2 = !backgroundSet2;
          "
        >
          <div class="backgroundChoices" :style="{ backgroundImage: background2 }"></div>
        </div>
        <div
          class="backgroundChoicesWrapper"
          :class="{ active: backgroundSet3 }"
          v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
          @click="
            mainBackground = background3;
            setBackground(3);
            backgroundSet3 = !backgroundSet3;
          "
        >
          <div class="backgroundChoices" :style="{ backgroundImage: background3 }"></div>
        </div>
        <div
          class="backgroundChoicesWrapper"
          :class="{ active: backgroundSet4 }"
          v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
          @click="
            mainBackground = background4;
            setBackground(4);
            backgroundSet4 = !backgroundSet4;
          "
        >
          <div class="backgroundChoices" :style="{ backgroundImage: background4 }"></div>
        </div>
        <router-link v-if="$store.getters.userRole.includes('ROLE_FRONTEND')" to="/premium-plans">
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet5, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background5;
              setBackground(5);
              backgroundSet5 = !backgroundSet5;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background5 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
          v-if="$store.getters.userRole.includes('ROLE_PREMIUM')"
          class="backgroundChoicesWrapper"
          :class="{ active: backgroundSet5, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
          v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
          @click="
              mainBackground = background5;
              setBackground(5);
              backgroundSet5 = !backgroundSet5;
            "
        >
          <div class="backgroundChoices" :style="{ backgroundImage: background5 }"></div>
          <div
            class="premiumMembersTextWrapper"
            v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          >
            <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
            <p
              class="onlyForText"
              v-langErr
              :contenteditable="editable"
              @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
            >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
            <p
              class="premiumMembers"
              v-langErr
              :contenteditable="editable"
              @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipMisc
              .premiumMembers
              }}
            </p>
          </div>
        </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet6, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background6;
              setBackground(6);
              backgroundSet6 = !backgroundSet6;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background6 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet6, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background6;
              setBackground(6);
              backgroundSet6 = !backgroundSet6;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background6 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet7, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background7;
              setBackground(7);
              backgroundSet7 = !backgroundSet7;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background7 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet7, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background7;
              setBackground(7);
              backgroundSet7 = !backgroundSet7;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background7 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet8, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background8;
              setBackground(8);
              backgroundSet8 = !backgroundSet8;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background8 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet8, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background8;
              setBackground(8);
              backgroundSet8 = !backgroundSet8;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background8 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet9, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background9;
              setBackground(9);
              backgroundSet9 = !backgroundSet9;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background9 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet9, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background9;
              setBackground(9);
              backgroundSet9 = !backgroundSet9;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background9 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet10, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background10;
              setBackground(10);
              backgroundSet10 = !backgroundSet10;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background10 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet10, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background10;
              setBackground(10);
              backgroundSet10 = !backgroundSet10;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background10 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet11, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background11;
              setBackground(11);
              backgroundSet11 = !backgroundSet11;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background11 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet11, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background11;
              setBackground(11);
              backgroundSet11 = !backgroundSet11;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background11 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet12, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background12;
              setBackground(12);
              backgroundSet12 = !backgroundSet12;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background12 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet12, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background12;
              setBackground(12);
              backgroundSet12 = !backgroundSet12;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background12 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet13, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background13;
              setBackground(13);
              backgroundSet13 = !backgroundSet13;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background13 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet13, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background13;
              setBackground(13);
              backgroundSet13 = !backgroundSet13;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background13 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet14, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background14;
              setBackground(14);
              backgroundSet14 = !backgroundSet14;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background14 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet14, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background14;
              setBackground(14);
              backgroundSet14 = !backgroundSet14;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background14 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet15, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background15;
              setBackground(15);
              backgroundSet15 = !backgroundSet15;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background15 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet15, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background15;
              setBackground(15);
              backgroundSet15 = !backgroundSet15;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background15 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet16, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background16;
              setBackground(16);
              backgroundSet16 = !backgroundSet16;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background16 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet16, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background16;
              setBackground(16);
              backgroundSet16 = !backgroundSet16;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background16 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet17, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background17;
              setBackground(17);
              backgroundSet17 = !backgroundSet17;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background17 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet17, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background17;
              setBackground(17);
              backgroundSet17 = !backgroundSet17;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background17 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet18, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background18;
              setBackground(18);
              backgroundSet18 = !backgroundSet18;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background18 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet18, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background18;
              setBackground(18);
              backgroundSet18 = !backgroundSet18;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background18 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet19, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background19;
              setBackground(19);
              backgroundSet19 = !backgroundSet19;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background19 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet19, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background19;
              setBackground(19);
              backgroundSet19 = !backgroundSet19;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background19 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet20, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background20;
              setBackground(20);
              backgroundSet20 = !backgroundSet20;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background20 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet20, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background20;
              setBackground(20);
              backgroundSet20 = !backgroundSet20;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background20 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet21, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background21;
              setBackground(21);
              backgroundSet21 = !backgroundSet21;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background21 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet21, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background21;
              setBackground(21);
              backgroundSet21 = !backgroundSet21;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background21 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet22, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background22;
              setBackground(22);
              backgroundSet22 = !backgroundSet22;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background22 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet22, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background22;
              setBackground(22);
              backgroundSet22 = !backgroundSet22;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background22 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        <router-link
          v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
          to="/premium-plans"
        >
          <div
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet23, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background23;
              setBackground(23);
              backgroundSet23 = !backgroundSet23;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background23 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
        </router-link>
        <div
            v-if="this.$store.getters.userRole.includes('ROLE_PREMIUM')"
            class="backgroundChoicesWrapper"
            :class="{ active: backgroundSet23, notPremium: $store.getters.userRole.includes('ROLE_FRONTEND') }"
            v-on:click="unsetActive; $bvModal.hide('bv-modal-backgrounds')"
            @click="
              mainBackground = background23;
              setBackground(23);
              backgroundSet23 = !backgroundSet23;
            "
          >
            <div class="backgroundChoices" :style="{ backgroundImage: background23 }"></div>
            <div
              class="premiumMembersTextWrapper"
              v-if="!$store.getters.userRole.includes('ROLE_PREMIUM')"
            >
              <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
              <p
                class="onlyForText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}</p>
              <p
                class="premiumMembers"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.premiumMembers =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .premiumMembers
                }}
              </p>
            </div>
          </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "MembershipMisc",
  components: {},
  props: {
    hideBanner: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      currency: '€',
      url: process.env.VUE_APP_URL,
      componentKey: 0,
      userJob: "",
      showEditJob: false,
      profileName: "",
      profileAge: 0,
      profilePicture: null,
      profileJob: "",
      profileLocation: "",
      mainBackground: "",
      backgroundSet1: false,
      backgroundSet2: false,
      backgroundSet3: false,
      backgroundSet4: false,
      backgroundSet5: false,
      backgroundSet6: false,
      backgroundSet7: false,
      backgroundSet8: false,
      backgroundSet9: false,
      backgroundSet10: false,
      backgroundSet11: false,
      backgroundSet12: false,
      backgroundSet13: false,
      backgroundSet14: false,
      backgroundSet15: false,
      backgroundSet16: false,
      backgroundSet17: false,
      backgroundSet18: false,
      backgroundSet19: false,
      backgroundSet20: false,
      backgroundSet21: false,
      backgroundSet22: false,
      backgroundSet23: false,
      jobStatus: false,
      answerCorrect: true,
      emptyAnswer: false,
      profileGender: 1,
      profileEntertainment: 1,
      componentProfileCardKey: 0
    };
  },
  watch: {
    getProfilePictureChange: function() {
      this.$store.dispatch("fetchProfileData").then(() => {
        this.profilePicture = this.$store.getters.getProfileCard.profilePicture;
        this.componentProfileCardKey++;
      });
    }
  },
  computed: {
    ...mapGetters(["editable", "getProfilePictureChange"]),
    background1() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG1.jpg')})`;
    },
    background2() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG2.jpg')})`;
    },
    background3() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG3.jpg')})`;
    },
    background4() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG4.jpg')})`;
    },
    background5() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG5.jpg')})`;
    },
    background6() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG6.jpg')})`;
    },
    background7() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG7.jpg')})`;
    },
    background8() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG8.jpg')})`;
    },
    background9() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG9.jpg')})`;
    },
    background10() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG10.jpg')})`;
    },
    background11() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG11.jpg')})`;
    },
    background12() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG12.jpg')})`;
    },
    background13() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG13.jpg')})`;
    },
    background14() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG14.jpg')})`;
    },
    background15() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG15.jpg')})`;
    },
    background16() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG16.jpg')})`;
    },
    background17() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG17.jpg')})`;
    },
    background18() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG18.jpg')})`;
    },
    background19() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG19.jpg')})`;
    },
    background20() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG20.jpg')})`;
    },
    background21() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG21.jpg')})`;
    },
    background22() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG22.jpg')})`;
    },
    background23() {
      return `url(${require('@/assets/images/' + this.$store.getters.assetsPath + 'BG23.jpg')})`;
    }
  },
  mounted() {
    //  this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");

    this.profileName = this.$store.getters.getProfileCard.name;
        this.profilePicture = this.$store.getters.getProfileCard.profilePicture;
        this.profileGender = parseInt(
          this.$store.getters.getProfileCard.gender
        );
        this.profileEntertainment = parseInt(
          this.$store.getters.getProfileCard.entertainment
        );

        const birthday = this.$store.getters.getProfileCard.age;
        const today = new Date();
        const birthDate = new Date(birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        this.profileAge = age;
        this.profileJob = this.$store.getters.getProfileCard.job;

        this.profileLocation = this.$store.getters.getProfileCard.location;
        if(this.$store.getters.getProfileCard.job) this.jobStatus = this.$store.getters.getProfileCard.job.status;
        const backgroundID = this.$store.getters.getProfileCard.profileBackground;
        this.mainBackground = `url(${require(`@/assets/images/${this.$store.getters.assetsPath}BG${backgroundID}.jpg`)})`;

      return true;
/*
    this.$store.dispatch("fetchProfileData").then(res => {
      if (res == "error") {
        // //this.$store.dispatch("setErrorVisibility", true);
      } else {
        this.profileName = this.$store.getters.getProfileCard.name;
        this.profilePicture = this.$store.getters.getProfileCard.profilePicture;
        this.profileGender = parseInt(
          this.$store.getters.getProfileCard.gender
        );
        this.profileEntertainment = parseInt(
          this.$store.getters.getProfileCard.entertainment
        );

        const birthday = this.$store.getters.getProfileCard.age;
        const today = new Date();
        const birthDate = new Date(birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        this.profileAge = age;
        this.profileJob = this.$store.getters.getProfileCard.job;

        this.profileLocation = this.$store.getters.getProfileCard.location;
        if(this.$store.getters.getProfileCard.job) this.jobStatus = this.$store.getters.getProfileCard.job.status;
        const backgroundID = this.$store.getters.getProfileCard
          .profileBackground;
        this.mainBackground = `url(${require(`./../../assets/images/BG${backgroundID}.jpg`)})`;
        // this.$store.dispatch("showContent");
      }
    });
*/
    // if (localStorage.mainBackground) {
    //   this.mainBackground = localStorage.mainBackground;
    // } else {
    //   this.mainBackground = `url(${require("./../../assets/images/BG4.jpg")})`;
    // }
  },
  methods: {
    trackClick() {
      this.$gtm.trackEvent({
            event: 'payment',
            category: 'Payment',
            action: 'Banner',
            label: 'Click'
          });

      this.$router.push(`/premium-plans/plans?direct=1`);
    },
    checkJobAnswer() {
      let validateText = /^[0-9a-zA-Z@]+$/;
      if (this.profileJob.match(validateText) && this.profileJob != "") {
        this.answerCorrect = true;
        this.emptyAnswer = false;
      } else if (this.profileJob == "") {
        this.emptyAnswer = true;
      } else {
        this.answerCorrect = false;
      }
    },
    setBackground(backgroundID) {
      this.$store.dispatch("setBackground", backgroundID);
    },
    sendJobAnswer() {
      if (this.answerCorrect && !this.emptyAnswer) {
        const data = {
          customerAnswerId: this.$store.getters.getProfileCard.job.id,
          answer: [this.profileJob.answer[0]]
        };
        this.$store.dispatch("updatePersonalDescription", data).then(res => {
          if (res == "error") {
            // show error
          }
        });
        this.$store.dispatch("setJobStatus");
        this.jobStatus = this.$store.getters.getProfileCard.job.status;

        this.componentKey++;
      }
    },
    setUserJob() {
      if (this.showEditJob == true && this.$store.getters.getProfileCard.job) {
        this.userJob = this.profileJob.answer[0];
      }
    },
    restoreProfileJob() {
      if(this.profileJob) this.profileJob.answer[0] = this.userJob;
    },
    unsetActive() {
      document.querySelectorAll(".backgroundChoicesWrapper").forEach(item => {
        item.classList.remove("active");
      });
      this.backgroundSet1 = false;
      this.backgroundSet2 = false;
      this.backgroundSet3 = false;
      this.backgroundSet4 = false;
      this.backgroundSet5 = false;
      this.backgroundSet6 = false;
      this.backgroundSet7 = false;
      this.backgroundSet8 = false;
      this.backgroundSet9 = false;
      this.backgroundSet10 = false;
      this.backgroundSet11 = false;
      this.backgroundSet12 = false;
      this.backgroundSet13 = false;
      this.backgroundSet14 = false;
      this.backgroundSet15 = false;
      this.backgroundSet16 = false;
      this.backgroundSet17 = false;
      this.backgroundSet18 = false;
      this.backgroundSet19 = false;
      this.backgroundSet20 = false;
      this.backgroundSet21 = false;
      this.backgroundSet22 = false;
      this.backgroundSet23 = false;
    },
    openProfileGallery(value) {
      this.$emit("galleryChosen", value);
      const el = document.getElementById("userTabs");
      el.scrollIntoView(true);
    }
  }
};
</script>

<style lang="scss" scoped></style>


