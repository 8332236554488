<template>
  <div id="matchesToPreferences">
    <img
      v-if="editable"
      :style="{marginLeft : 'auto', display: 'block', marginRight: '10px', paddingTop: '10px'}"
      src="../../../assets/images/Translate-Icon.svg"
      alt="Translation icon"
    />
    <div class="preferencesTextWrapper">
      <h1 class="preferencesText">
        <span
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesToPreferences.preferencesText1 = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesToPreferences.preferencesText1}}</span>

        <br />
        <span class="preferences"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesToPreferences.preferencesText2 = $event.target.innerText"
        >{{$t($i18n.locale).keys.matchesToPreferences.preferencesText2}}</span>
      </h1>
    </div>
    <div class="buttonWrapper">
      <router-link
        to="/membership-area/matches-preferences"
        class="preferencesButton"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.matchesToPreferences.preferencesText3 = $event.target.innerText"
      >{{$t($i18n.locale).keys.matchesToPreferences.preferencesText3}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchesToPreferences",
  components: {},
  data() {
    return {};
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>