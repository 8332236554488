export default {
    lastCompleted: 1,
    skippedQuestions: [],
    skippedMode: false,
    additionalQuestions: [],
    questionnaireCompleted: false,
    answered: 0,
    randomNames: [],
    additionalQuestionsAnswered: false,
    hideFirstMessage: false,
    completed: null,
    finished: null,
    personalityTest: null,
    personalityTestVariant: 1
};