var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionComponent",attrs:{"id":"questionType6"}},[(_vm.variant != 2)?_c('h1',{staticClass:"questionText"},[_vm._v(" "+_vm._s(_vm.questionData.question.translations[_vm.selectedLanguage].question)+" ")]):_vm._e(),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require("../../assets/images/Translate-Icon.svg"),"alt":"Translation icon"}}):_vm._e(),(_vm.questionData.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"skippableQuestionMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.skippableQuestionMessage =
        $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.skippableQuestionMessage)+" ")]):_vm._e(),_c('div',{class:{'chatStyleAnswer' : _vm.variant == 2}},[_c('div',{staticClass:"answerWrapper"},[(_vm.variant == 2)?_c('div',{staticClass:"images-container variant2"},_vm._l((_vm.imagesArray),function(picture,i){return _c('div',{key:i,staticClass:"imageWrapper",class:{'selected' : i + 1 === _vm.activeItem,
                    'fullheight': _vm.imagesArray.length <= 2
                  },on:{"click":function($event){return _vm.clickHandler(i + 1);}}},[_c('img',{staticClass:"selection_img",attrs:{"src":picture}}),_vm._m(0,true)])}),0):_c('div',{staticClass:"images-container"},_vm._l((_vm.imagesArray),function(picture,i){return _c('div',{key:i,staticClass:"image",class:[i + 1 === _vm.activeItem ? 'imageChosen' : ''],style:({
            'background-image':
              'url(' + picture + ')'
          }),on:{"click":function($event){return _vm.clickHandler(i + 1);}}})}),0)])]),(_vm.variant == 2)?_c('QuestionNavigation',{attrs:{"progressData":{
      ..._vm.navData,
      currentQuestion: _vm.questionData.question.orderId,
    },"nextDisabled":!_vm.answer,"prevDisabled":false},on:{"sendAnswer":_vm.sendAnswer,"goBack":function($event){return _vm.$emit('goBack')}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selection_overlay"},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }